import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {moodlightApi} from '../api/apis';
import {
  accountMoodLightsSelector,
  peakMoodLightsSelector,
  updateUsernameCache,
  usernameCacheSelector,
} from '../redux/slices/moodLightSlice';
import {userSelector} from '../redux/slices/userSlice';
import {isCustomMoodLight} from '../types';

export function useGetAndUpdateUsernameCache() {
  const dispatch = useDispatch();
  const loggedIn = !!useSelector(userSelector);
  const usernameCache = useSelector(usernameCacheSelector);

  const peakMoodLights = useSelector(peakMoodLightsSelector);
  const accountMoodLights = useSelector(accountMoodLightsSelector);

  React.useEffect(() => {
    if (!loggedIn) return;

    const ids = [
      ...peakMoodLights
        .map(moodLight =>
          isCustomMoodLight(moodLight) ? moodLight.originalMoodLightId : null,
        )
        .filter((id): id is string => !!id), // we only want custom mood lights with originalMoodLightIds
      ...accountMoodLights.map(moodLight => moodLight.originalMoodLightId),
    ];

    if (!ids.length) return;

    moodlightApi
      .getMoodLightCreatorUsernamesBulk({requestBody: ids})
      .then(({data}) => dispatch(updateUsernameCache(data)));
  }, [accountMoodLights, peakMoodLights, loggedIn]);

  return usernameCache;
}
