import debounce from 'lodash/debounce';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

import {Constants} from '../../../constants';
import {Messages, Strings} from '../../../constants/Strings';
import {ChevronRightIcon} from '../../../icons';
import {CheckboxUI, Led3Value, MoodLightUI} from '../../../lib/types';
import {roundToDecimals} from '../../../lib/utils/math';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';
import {Checkbox} from './Checkbox';
import {MoodlightSlider} from './MoodlightSlider';

const {DYNAMIC_INHALE_TITLE, DYNAMIC_INHALE_MSG} = Messages;
const {TEMPO, COMPLEXITY, RANDOMNESS, ANIMATION} = Strings;
const {MOOD_TYPE_AND_VALUE} = Constants;

const keyTitleMap: {[x: string]: string} = {
  tempoFrac: TEMPO,
  compFrac: COMPLEXITY,
  randFrac: RANDOMNESS,
  dynamicInhale: DYNAMIC_INHALE_TITLE,
};

export const ControlPanelComponent = ({
  moodLightValues,
  setMoodLightValues,
  navigateTo,
  animation,
  uiList,
}: {
  uiList: MoodLightUI[];
  moodLightValues: Led3Value[];
  setMoodLightValues: (v: Led3Value[]) => void;
  animation?: number;
  navigateTo?: () => void;
}) => {
  const setValue = React.useCallback(
    (key: string, value: number) => {
      const newValue = Number(Number(value).toFixed(2));

      setMoodLightValues(
        moodLightValues.map(item =>
          item.key === key
            ? {
                ...item,
                value: newValue,
              }
            : item,
        ),
      );
    },
    [moodLightValues],
  );

  return (
    <>
      <ControlPanelButtonContainer onPress={() => navigateTo?.()}>
        <ButtonTitle numberOfLines={1}>{ANIMATION}</ButtonTitle>
        <ButtonEnd>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Text
              style={{
                color: colors.white50,
                fontWeight: '400',
                fontFamily: 'Roboto-Regular',
              }}>
              {Object.keys(MOOD_TYPE_AND_VALUE).find(
                v =>
                  MOOD_TYPE_AND_VALUE[v as keyof typeof MOOD_TYPE_AND_VALUE] ===
                  animation,
              )}
            </Text>

            <ChevronRightIcon containerSize={36} size={32} />
          </View>
        </ButtonEnd>
      </ControlPanelButtonContainer>
      {/* TODO test with real data */}
      {uiList.map((ui, i) => {
        if (!(ui.key in keyTitleMap || ui.label)) return null;
        if (ui.type === 'slider') {
          const displayValue =
            ui.max === 1
              ? (value: number) => `${Math.round(value * 100)}%`
              : (value: number) => Math.round(value);

          const parse =
            ui.max === 1
              ? (value: number) => roundToDecimals(value, 2)
              : (value: number) => Math.round(value);

          const debouncedSetValue = debounce(setValue, 100, {trailing: true});

          return (
            <MoodlightSlider
              key={i}
              title={keyTitleMap[ui.key] || ui.label || ''}
              value={
                (moodLightValues.find(val => val.key === ui.key)
                  ?.value as number) ?? 0
              }
              {...{parse}}
              min={ui.min}
              max={ui.max}
              style={{paddingBottom: 24}}
              onChange={value => debouncedSetValue(ui.key, value)}
              onEnd={value => debouncedSetValue(ui.key, value)}>
              {displayValue}
            </MoodlightSlider>
          );
        } else if (ui.type === 'checkbox')
          return (
            <React.Fragment key={i}>
              <Checkbox
                title={keyTitleMap[ui.key] || ui.label || ''}
                ui={ui as CheckboxUI}
                value={
                  moodLightValues.find(val => val.key === ui.key)?.value as
                    | 0
                    | 1
                }
                setValue={val => setValue(ui.key, val)}
              />
              {ui.key === 'dynamicInhale' && (
                <Text
                  style={{
                    fontSize: 11,
                    color: colors.white50,
                    letterSpacing: 0.03,
                    lineHeight: 18,
                    fontWeight: '400',
                    fontFamily: 'Roboto-Regular',
                    width: '100%',
                  }}>
                  {DYNAMIC_INHALE_MSG}
                </Text>
              )}
            </React.Fragment>
          );
        else return null;
      })}
    </>
  );
};

const ControlPanelButtonContainer = styled(TouchableOpacity)({
  height: 56,
  paddingLeft: 24,
  paddingRight: 18,
  backgroundColor: colors.black30,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 22,
  borderRadius: 8,
  alignItems: 'center',
});

const ButtonTitle = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  color: colors.white,
  flex: 1,
});

const ButtonEnd = styled(View)({
  flex: 1,
  alignItems: 'flex-end',
});
