import React from 'react';
import {Circle, Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const DevicesSwitchIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M8 21V14" />
    <Path d="M8 7V3" />
    <Circle cx={8} cy={9} r={2} />
    <Path d="M16 3L16 10" />
    <Path d="M16 17L16 21" />
    <Circle cx={16} cy={15} r={2} transform="rotate(-180 16 15)" />
  </Icon>
);
