import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';

import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {Screens} from '../../constants/navigation';
import {CheckIcon} from '../../icons';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../../lib/redux/slices/appSettingsSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import styled from '../../lib/utils/styled';
import {updateUser} from '../../lib/utils/updateUser';
import type {HomeEmulatedDrawerStackScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors} from '../../styles';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.TemperatureSettings
>;

export const TemperatureSettingsScreen = (_props: ScreenProps) => {
  useAdaptiveSafeArea();

  const dispatch = useAppDispatch();

  const settings = useSelector(appSettingsSelector);
  const user = useSelector(userSelector);

  const handleSelection = React.useCallback(
    async (tempPreference: TemperatureUnit) => {
      if (settings.tempPreference === tempPreference) return;

      dispatch(updateAppSettings({...settings, tempPreference}));

      if (user) updateUser(dispatch, {tempPreference});
    },
    [user, settings],
  );

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <SectionContainer>
          <TemperatureSectionContainer>
            <SectionItem
              onPress={() => handleSelection(TemperatureUnit.Fahrenheit)}>
              <SectionHeader>FAHRENHEIT</SectionHeader>

              <Checkbox
                style={
                  settings.tempPreference === TemperatureUnit.Fahrenheit && {
                    backgroundColor: colors.mediumDarkGray,
                  }
                }>
                {settings.tempPreference === TemperatureUnit.Fahrenheit && (
                  <CheckIcon
                    color={colors.white}
                    containerSize={14}
                    size={14}
                  />
                )}
              </Checkbox>
            </SectionItem>

            <SectionItem
              onPress={() => handleSelection(TemperatureUnit.Celsius)}>
              <SectionHeader>CELSIUS</SectionHeader>

              <Checkbox
                style={
                  settings.tempPreference === TemperatureUnit.Celsius && {
                    backgroundColor: colors.mediumDarkGray,
                  }
                }>
                {settings.tempPreference === TemperatureUnit.Celsius && (
                  <CheckIcon
                    color={colors.white}
                    containerSize={14}
                    size={14}
                  />
                )}
              </Checkbox>
            </SectionItem>
          </TemperatureSectionContainer>
        </SectionContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 30,
});

const Checkbox = styled(View)({
  width: 18,
  height: 18,
  borderColor: colors.mediumDarkGray,
  borderWidth: 1,
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
  borderRadius: 9,
});

const TemperatureSectionContainer = styled(View)({
  borderBottomWidth: 1,
  borderTopWidth: 1,
  borderColor: colors.greySettings10,
  flexDirection: 'column',
  height: 88,
  marginTop: 64,
});

const SectionItem = styled(TouchableOpacity)({
  borderBottomWidth: 1,
  borderColor: colors.greySettings20,
  flexDirection: 'row',
  height: 44,
  justifyContent: 'space-between',
});

const SectionHeader = styled(Text)({
  alignSelf: 'center',
  color: colors.baseText,
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  marginLeft: 18,
  fontWeight: '400',
});
