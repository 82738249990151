import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const LightbulbIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M12 2C8.13401 2 5 5.08825 5 8.89779C5 11.5447 6.51303 13.8435 8.73333 15H15.2667C17.487 13.8435 19 11.5447 19 8.89779C19 5.08825 15.866 2 12 2Z" />
    <Path d="M9 18H15" />
    <Path d="M12 22C12.5523 22 13 21.5523 13 21H11C11 21.5523 11.4477 22 12 22Z" />
  </Icon>
);
