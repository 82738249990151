import React from 'react';
import {StyleSheet, View} from 'react-native';

import {MoodLightList} from '../../components/MoodLightList';
import {withConnectedGuard} from '../../components/hocs/withConnectedGuard';
import {withDeeplink} from '../../components/hocs/withDeeplink';
import {withUserGuard} from '../../components/hocs/withUserGuard';
import {Navigators, Screens} from '../../constants/navigation';
import {PlusIcon} from '../../icons';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useBackPress} from '../../lib/hooks/useBackPress';
import type {ControlCenterNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors, fillStyle} from '../../styles';

interface Props
  extends ControlCenterNavigatorScreenProps<typeof Screens.MoodLightLibrary> {}

const Screen: React.FC<Props> = ({navigation}) => {
  const insets = useAdaptiveSafeArea();

  const onBackPress = React.useCallback(() => {
    navigation.goBack();

    return true;
  }, [navigation]);

  useBackPress(onBackPress);

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <PlusIcon
          onPress={() =>
            navigation.navigate(Navigators.MoodLightController, {
              screen: Screens.MoodLightCreate,
            })
          }
        />
      ),
    });
  }, [navigation]);

  return (
    <View
      style={[
        styles.container,
        {
          paddingTop: insets.top + 60,
          paddingBottom: insets.bottom,
          backgroundColor: colors.black,
        },
      ]}>
      <MoodLightList />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {...fillStyle, flexDirection: 'column', justifyContent: 'center'},
});

export const MoodLightLibraryScreen = withDeeplink(
  withUserGuard(withConnectedGuard(Screen)),
);
