import React from 'react';

const BLUR_RADIUS = 15;

export const BlurView = ({
  style,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    style={{
      ...style,
      backdropFilter: `blur(${BLUR_RADIUS}px)`,
      WebkitBackdropFilter: `blur(${BLUR_RADIUS}px)`,
    }}
    {...rest}
  />
);
