import {
  peachBlackBackgroundHomeScreen,
  peakBaseShadow,
  peakPeachBlack,
  peakPeachBlackBase,
  peakPeachBlackChamberRegular,
  peakPeachBlackChamberXl,
  peakPeachBlackGlass,
  peakPeachBlackLightsBaseLeft,
  peakPeachBlackLightsBaseRight,
} from '../assets/images';
import {addOpacityToColorHex} from '../lib/utils/colors';
import {colors} from '../styles';
import {defaultTheme} from './DefaultTheme';
// Import from exact file to prevent cycle
import {
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  PeakImageTheme,
  Theme,
} from './Theme';
import {whitePeachTheme} from './WhitePeachTheme';

const {
  lightLayers: {glass},
} = whitePeachTheme.peakImageTheme;

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakPeachBlack,
  peakLayers: {
    glass: peakPeachBlackGlass,
    base: peakPeachBlackBase,
    chamber: {
      regular: peakPeachBlackChamberRegular,
      xl: peakPeachBlackChamberXl,
    },
    shadow: peakBaseShadow,
  },
  lightLayers: {
    glass,
    base: {
      left: peakPeachBlackLightsBaseLeft,
      right: peakPeachBlackLightsBaseRight,
    },
    ring: {},
  },
  peakDarkScreen: peakPeachBlack,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: colors.white,
  dabbingScreenFadedText: colors.mediumGray50,
  dabbingButtonBackground: colors.bone,
  dabbingButtonBackgroundPressed: colors.black,
  dabbingButtonForeground: colors.black,
  dabbingButtonForegroundPressed: colors.bone,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: colors.white,
  iconColor: colors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: colors.white,
  },
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: colors.black,
  durationFontColor: colors.white,
  specularColorStart: colors.clear,
  specularColorEnd: colors.clear,
};

export const blackPeachTheme: Theme = {
  ...defaultTheme,
  sectionDividerColor: colors.white20,
  dabbingScreenTheme,
  tabBarTheme: {
    ...defaultTheme.tabBarTheme,
    backgroundColor: colors.invisible,
  },
  homeScreenTheme: {
    ...defaultTheme.homeScreenTheme,
    background: {
      image: peachBlackBackgroundHomeScreen,
    },
    // Metric info
    dataBackgroundGradientArray: [
      {color: addOpacityToColorHex(colors.black, 0.9), location: 0},
      {color: addOpacityToColorHex(colors.black, 0), location: 1},
    ],
  },
  heatProfileCardTheme,
  heatProfileEditScreenTheme,

  // Hide Logo
  logoTint: colors.invisible,
  spinnerColor: colors.white,
  peakImageTheme,

  dabTickTheme: {
    color: colors.white,
  },
};
