import {HeatProfileUpdateDto} from 'puffco-api-axios-client';
import {useAsyncFn} from 'react-use';

import {heatprofileApi} from '../api/apis';
import {MoodLight} from '../types/MoodLight';
import {Profile} from '../types/Profile';
import {convertUpdateHeatProfileToDto} from '../utils/convertUpdateHeatProfileToDto';

export type UpdateHeatProfileDto = Profile & {
  userId: number;
  moodLight?: MoodLight & {userId: number};
};

export const updateHeatProfiles = async (profiles: UpdateHeatProfileDto[]) => {
  const heatProfileUpdateDto = profiles.map<HeatProfileUpdateDto>(
    convertUpdateHeatProfileToDto,
  );

  const {data} = await heatprofileApi.updateProfiles({heatProfileUpdateDto});

  return data;
};

export function useUpdateHeatProfiles() {
  return useAsyncFn(updateHeatProfiles, []);
}
