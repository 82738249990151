import React from 'react';
import {View} from 'react-native';

import {StyledButton} from '../../components/StyledButton';
import {Video} from '../../components/Video';
import {Screens} from '../../constants/navigation';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useTheme} from '../../lib/hooks/useTheme';
import type {MainNavigatorScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {colors} from '../../styles';

const onBackPress = () => true;

export interface Props extends WithOptionalRedirect {}

type ScreenProps = MainNavigatorScreenProps<typeof Screens.LimitedEditionVideo>;

export const LimitedEditionVideoScreen = ({route, navigation}: ScreenProps) => {
  const {bottom} = useSafeArea();
  const {limitedEditionVideoScreenTheme, styledButtonTheme} = useTheme();
  const videoUrl = limitedEditionVideoScreenTheme?.videoUrl;

  const redirect = route.params?.redirect;

  useBackPress(onBackPress);

  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: colors.black,
      }}>
      {videoUrl && (
        <Video
          source={{uri: videoUrl}}
          shouldPlay={true}
          useNativeControls={false}
          // @ts-expect-error enum should be used instea
          resizeMode={'cover'}
          style={{height: '100%', width: '100%'}}
          isLooping
        />
      )}
      <StyledButton
        title={'NEXT'}
        style={{
          alignSelf: 'center',
          position: 'absolute',
          bottom: bottom + 50,
        }}
        theme={styledButtonTheme}
        onPress={() => {
          navigation.navigate(Screens.Connect, {
            redirectedFromLimitedEdition: true,
            redirect,
          });
        }}
      />
    </View>
  );
};
