import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from '@react-navigation/native';
import {StackScreenProps, createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Navigators, Screens} from '../constants/navigation';
import {BluetoothStartPairingScreen, PairInstructionsScreen} from '../screens';
import {
  ConnectScreen,
  type Props as ConnectScreenProps,
} from '../screens/DeviceConnect/ConnectScreen';
import {
  LimitedEditionModalScreen,
  type Props as LimitedEditionModalScreenProps,
} from '../screens/DeviceConnect/LimitedEditionModalScreen';
import {
  LimitedEditionVideoScreen,
  type Props as LimitedEditionVideoScreenProps,
} from '../screens/DeviceConnect/LimitedEditionVideoScreen';
import {
  HomeDrawerNavigator,
  type HomeDrawerParamList,
} from './HomeDrawerNavigator';
import {
  MarketOptInStackNavigator,
  type MarketOptInStackParamList,
} from './MarketOptInStackNavigator';
import {
  MoodLightControllerStackNavigator,
  type MoodLightControllerStackParamList,
} from './MoodLightControllerStackNavigator';
import type {RootStackScreenProps} from './RootStackNavigator';
import {defaultNavigationOptions} from './common';
import {WithOptionalRedirect} from './params';

export type MainStackParamList = {
  [Navigators.HomeDrawerNavigator]: NavigatorScreenParams<HomeDrawerParamList>;
  [Navigators.MarketOptIn]: NavigatorScreenParams<MarketOptInStackParamList>;
  [Navigators.MoodLightController]: NavigatorScreenParams<MoodLightControllerStackParamList>;

  [Screens.BluetoothStartPairing]: WithOptionalRedirect | undefined;
  [Screens.PairInstructions]: WithOptionalRedirect | undefined;

  [Screens.Connect]: ConnectScreenProps | undefined;
  [Screens.LimitedEditionModal]: LimitedEditionModalScreenProps;
  [Screens.LimitedEditionVideo]: LimitedEditionVideoScreenProps | undefined;
};

type MainStackScreenProps<T extends keyof MainStackParamList> =
  CompositeScreenProps<
    StackScreenProps<MainStackParamList, T>,
    RootStackScreenProps<typeof Navigators.MainNavigator>
  >;

export type HomeDrawerNavigatorScreenProps<
  T extends keyof HomeDrawerParamList,
> = CompositeScreenProps<
  StackScreenProps<HomeDrawerParamList, T>,
  MainStackScreenProps<keyof MainStackParamList>
>;

export type MarketOptInNavigatorScreenProps<
  T extends keyof MarketOptInStackParamList,
> = CompositeScreenProps<
  StackScreenProps<MarketOptInStackParamList, T>,
  MainStackScreenProps<keyof MainStackParamList>
>;

export type MoodLightControllerNavigatorScreenProps<
  T extends keyof MoodLightControllerStackParamList,
> = CompositeScreenProps<
  StackScreenProps<MoodLightControllerStackParamList, T>,
  MainStackScreenProps<keyof MainStackParamList>
>;

const MainStack = createStackNavigator<MainStackParamList>();

export const MainStackNavigator = () => {
  return (
    <MainStack.Navigator
      screenOptions={{...defaultNavigationOptions, headerShown: false}}>
      <MainStack.Screen
        name={Navigators.HomeDrawerNavigator}
        component={HomeDrawerNavigator}
      />

      <MainStack.Screen name={Screens.Connect} component={ConnectScreen} />

      <MainStack.Screen
        name={Screens.LimitedEditionModal}
        component={LimitedEditionModalScreen}
      />

      <MainStack.Screen
        name={Screens.LimitedEditionVideo}
        component={LimitedEditionVideoScreen}
      />

      <MainStack.Screen
        name={Navigators.MoodLightController}
        component={MoodLightControllerStackNavigator}
      />

      <MainStack.Screen
        name={Screens.BluetoothStartPairing}
        component={BluetoothStartPairingScreen}
      />

      <MainStack.Screen
        name={Screens.PairInstructions}
        component={PairInstructionsScreen}
      />

      <MainStack.Screen
        name={Navigators.MarketOptIn}
        component={MarketOptInStackNavigator}
        options={{gestureEnabled: false}}
      />
    </MainStack.Navigator>
  );
};
