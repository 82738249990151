import React from 'react';
import {StyleSheet, ViewProps} from 'react-native';

import {colors} from '../styles';
import {Theme} from '../themes';
import {guardianTheme, opalTheme} from '../themes';
import {LayeredBackground} from './LayeredBackground';

const {black, white} = colors;

export type HeatProfileScreenBaseBackgroundProps = {
  theme: Theme;
} & ViewProps;

/*
 * Background componenent for base backgrounds that do not change for each profile.
 */
export const HeatProfileScreenBaseBackground = (
  props: React.PropsWithChildren<HeatProfileScreenBaseBackgroundProps>,
) => {
  const {theme, children, style, onLayout} = props;
  const isOpal = opalTheme === theme;
  const isGuardian = guardianTheme === theme;
  const {background} = theme.heatProfileScreenBackgroundTheme;

  return (
    <LayeredBackground
      onLayout={onLayout}
      style={{
        flex: 1,
        ...StyleSheet.flatten(style),
      }}
      backgrounds={
        isOpal || isGuardian
          ? [
              {viewProps: {style: {backgroundColor: white}}},
              background && !isGuardian
                ? {imageBackgroundProps: {source: background}}
                : {},
            ]
          : [{viewProps: {style: {backgroundColor: black}}}]
      }>
      {children}
    </LayeredBackground>
  );
};
