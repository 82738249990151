import {RouteProp, useRoute} from '@react-navigation/native';
import {useGoogleLogin as use} from '@react-oauth/google';
import React from 'react';

import {RedirectOptions} from '../../../navigators/params';
import {createSocialAuthState, getSocialAuthCallback} from '../../../util/url';
import {UseGoogleLoginOptions} from './useGoogleLoginOptions';

export const useGoogleLogin = ({state}: UseGoogleLoginOptions) => {
  const route =
    useRoute<RouteProp<{[x: string]: {redirect?: RedirectOptions}}>>();

  const redirect = route.params?.redirect;

  const ref = React.useRef<{
    resolve: (code: string) => void;
    reject: (error: Error) => void;
  }>();

  const onLogin = use({
    onSuccess: ({code}) => ref.current?.resolve(code),
    onError: ({error_description, error}) =>
      ref.current?.reject(
        new Error(
          error_description ?? `Google error thrown: ${error ?? 'unknown'}.`,
        ),
      ),
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: getSocialAuthCallback('google'),
    state: createSocialAuthState({state, redirect}),
  });

  return React.useCallback(() => {
    return new Promise<string>((resolve, reject) => {
      ref.current = {resolve, reject};

      onLogin();
    });
  }, [onLogin]);
};
