import {Share} from '../lib/types';
import {base64ToFile} from '../lib/utils/base64ToFile';

export const sharePress = async (data: Share) => {
  const cloneData: Partial<Share & {files: [File]}> = data;

  const arr = data.url?.split(',');

  if (!arr) return;

  const isBase64 = arr[0] === 'data:image/png;base64';

  if (isBase64) {
    const file = await base64ToFile(
      arr[1],
      'image/png',
      'Puffco-ScreenShot-' + Date.now() + '.png',
    );
    if (file) {
      cloneData.files = [file];
    }
    delete cloneData.url;
  }

  //navigator.share will only work on https. See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
  if (navigator.share && navigator?.canShare(cloneData)) {
    await navigator.share(cloneData);
  }
};
