import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {pushService} from '../../services/push';
import {deviceTokenApi, userApi} from '../api/apis';
import {appSettingsSelector} from '../redux/slices/appSettingsSlice';
import {updateUser, userSelector} from '../redux/slices/userSlice';
import {appLog} from '../utils/Logger';
import {useRemoteData} from './useRemoteData';

export const usePushEnabled = () => {
  const {data: enabled} = useRemoteData(
    {key: 'usePushEnabled'},
    () => pushService.enabled(),
    {revalidateOnFocus: true},
  );

  return enabled;
};

export const usePushNotification = () => {
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const enabled = usePushEnabled();

  const loggedIn = !!user;
  const enabledInStore = user?.pushNotifications;

  const agreedLegal = agreeAgreement.PP && agreeAgreement.TC;
  const turnedOff = user?.pushNotifications === false;
  const shouldAsk = agreedLegal && !turnedOff;

  React.useEffect(() => {
    if (!shouldAsk) return;

    pushService
      .getToken()
      .then(token => {
        if (!token) return;
        appLog.info('Sync device token.');
        return deviceTokenApi.updateDeviceToken({token});
      })
      .catch(() => void 0);

    return pushService.onTokenUpdated(token => {
      appLog.info('Sync device token.');
      return deviceTokenApi.updateDeviceToken({token});
    });
  }, [enabled, shouldAsk]);

  React.useEffect(() => {
    if (!loggedIn) return;

    if (enabledInStore === enabled) return;

    dispatch(updateUser({pushNotifications: enabled}));

    userApi
      .updateUserById({
        id: 'me',
        userUpdateDto: {pushNotifications: enabled},
      })
      .catch(() => void 0);
  }, [enabled, enabledInStore, loggedIn, dispatch]);
};
