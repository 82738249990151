import React from 'react';
import {ImageStyle} from 'react-native';

import {useTheme} from '../lib/hooks/useTheme';
import {Image} from './ImageWithFilter';

export type LogoProps = {
  /*
   * The overall size of the icon, default is 60 (login screen size) with vertical margins
   */
  size?: number;
  color?: string;
  style?: ImageStyle;
};

export const Logo = ({size, color, style}: LogoProps) => {
  const {logoIcon} = useTheme();

  return (
    <Image
      source={logoIcon}
      style={[
        {
          width: size || 60,
          height: size || 60,
          resizeMode: 'contain',
          tintColor: color,
        },
        style,
      ]}
    />
  );
};
