import {differenceInMilliseconds, intervalToDuration, isPast} from 'date-fns';
import React from 'react';
import {StyleProp, StyleSheet, Text, View, ViewStyle} from 'react-native';
import {useInterval} from 'react-use';

import {Constants} from '../../constants';
import {Strings} from '../../constants/Strings';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import {colors} from '../../styles';
import {Colon} from './Colon';

interface Props {
  targetDate: Date;
  displayAfter?: Date;
  style?: StyleProp<ViewStyle>;
}

interface State {
  isRunning: boolean;
  now?: Date;
}

export const Countdown: React.FC<Props> = ({
  targetDate,
  displayAfter,
  style,
}) => {
  const [state, setState] = React.useState<State>({isRunning: false});

  React.useEffect(() => {
    const now = new Date();

    if (!displayAfter) return setState({isRunning: true, now});

    const difference = differenceInMilliseconds(displayAfter, now);

    const tid =
      difference < 0
        ? setTimeout(() => {
            setState({isRunning: true, now});
          }, difference)
        : undefined;

    return () => {
      if (!tid) return;

      clearTimeout(tid);
    };
  }, [displayAfter, targetDate]);

  useInterval(
    () => {
      if (isPast(targetDate)) {
        return setState({isRunning: false});
      }

      setState(prev => ({...prev, now: new Date()}));
    },
    state.isRunning ? 1000 : null,
  );

  if (!state.now) return null;

  const difference = intervalToDuration({start: state.now, end: targetDate});

  const {days, hours, minutes, seconds} = difference;

  return (
    <View style={[styles.container, style]}>
      {!!days && (
        <>
          <TimeDisplay time={days ?? 0} label={Strings.DAYS} />
          <Colon style={styles.colon} />
        </>
      )}

      <TimeDisplay time={hours ?? 0} label={Strings.HOURS} />
      <Colon style={styles.colon} />

      <TimeDisplay time={minutes ?? 0} label={Strings.MINUTES} />

      <Colon style={styles.colon} />

      <TimeDisplay time={seconds ?? 0} label={Strings.SECONDS} />
    </View>
  );
};

const TimeDisplay: React.FC<{time: number; label: string}> = ({
  time,
  label,
}) => (
  <View style={styles.timeContainer}>
    <Text style={styles.time}>{String(time).padStart(2, '0')}</Text>

    <Text style={styles.label}>{label}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
  },
  time: {
    fontSize: 68,
    color: colors.white,
    fontFamily: 'BigShouldersDisplay-Bold',
    minWidth: Constants.DIMENSIONS.WIDTH / 5.5,
    textAlign: 'center',
    letterSpacing: 2.16,
  },
  label: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: addOpacityToColorHex(colors.white, 0.5),
    textTransform: 'uppercase',
    marginTop: 5,
  },
  colon: {
    marginBottom: 10,
  },
});
