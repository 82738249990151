import React from 'react';
import {ImageSourcePropType, TouchableOpacity, View} from 'react-native';

import {ImgBackground} from '../../components/ImgBackground';
import {Text} from '../../components/Typography';
import {Strings} from '../../constants/Strings';
import styled from '../../lib/utils/styled';
import {colors} from '../../styles';

export interface Props {
  background: ImageSourcePropType;
  title: string;
  body: string;
  buttonTitle?: string;
  onPress(): void;
}

export const DesktopConfirmationScreen: React.FC<Props> = ({
  background: source,
  title,
  body,
  onPress,
  buttonTitle,
}) => (
  <Background {...{source}}>
    <Container>
      <Header variant="h2">{title}</Header>

      <Body>{body}</Body>

      <Button {...{onPress}}>
        <ButtonText variant="lead">
          {buttonTitle || Strings.DEFAULT_BTN}
        </ButtonText>
      </Button>
    </Container>
  </Background>
);

const Background = styled(ImgBackground)({
  flex: 1,
  flexBasis: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const Container = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  gap: 24,
  flex: 0.5,
  maxWidth: 400,
});

const Header = styled(Text)({
  textAlign: 'center',
  color: colors.baseText,
});

const Body = styled(Text)({
  textAlign: 'center',
  color: colors.baseText,
  fontSize: 16,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: colors.baseText,
  borderRadius: 25,
  height: 50,
  textAlign: 'center',
  justifyContent: 'center',
  letterSpacing: 0.75,
  width: 320,
});

export const ButtonText = styled(Text)({
  textAlign: 'center',
  textTransform: 'uppercase',
});
