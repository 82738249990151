import React from 'react';
import {
  ActivityIndicator,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  View,
} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {Alert} from '../../components/Alert';
import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {SegmentedProgress} from '../../components/SegmentedProgress';
import {StyledButton} from '../../components/StyledButton';
import {Constants} from '../../constants';
import {Screens} from '../../constants/navigation';
import {supportAccess} from '../../lib/api';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {currentDeviceSelector} from '../../lib/redux/slices/bleSlice';
import {warrantySelector} from '../../lib/redux/slices/warrantySlice';
import styled from '../../lib/utils/styled';
import type {WarrantyNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors} from '../../styles';

const createMessage = (
  answers: {
    questionOne: string;
    questionTwo?: string;
  },
  message: string,
) => {
  return `What seems to be the problem? ${answers.questionOne}
  .${
    answers.questionTwo &&
    ` \n\nAre you using the included USB charger or wireless charging? ${answers.questionTwo}`
  } \n\nAdditional Comments: ${message}`;
};

interface ScreenProps
  extends WarrantyNavigatorScreenProps<
    typeof Screens.WarrantyClaimScreenMessage
  > {}

export const WarrantyClaimScreenMessage: React.FC<ScreenProps> = ({
  navigation,
}) => {
  const warrantyClaimMessage = React.useRef(null);
  const [message, setMessage] = React.useState('');
  const warranty = useSelector(warrantySelector);
  const answers = warranty.warrantyAnswers;
  const device = useSelector(currentDeviceSelector);
  const platform = Platform.OS;
  const [
    {error: claimSubmissionError, loading: isSubmittingToZendesk},
    submitClaim,
  ] = useAsyncFn(supportAccess.submitClaim, []);

  useAdaptiveSafeArea();

  React.useEffect(() => {
    if (claimSubmissionError) {
      Alert.alert('Something went wrong. Please try again later');
      navigation.navigate(Screens.WarrantyClaim);
    }
  }, [claimSubmissionError, navigation]);

  const submit = async () => {
    const name = answers.userInfo.name;
    const email = answers.userInfo.email;
    const customFields = [
      {id: 360035210654, value: device?.serialNumberString}, //Serial Number
      {id: 360035104273, value: device?.gitHashString}, //Firmware NEED TO UPDATE WHEN FIRMWARE VERSION IS ADDED
      {id: 360035104253, value: device?.name}, //Device Name
      {id: 360035104333, value: platform}, //Operating System
      {id: 360035210634, value: device?.hits}, //Hit Count
    ];
    const data = {
      request: {
        comment: createMessage(answers, message),
        requester: {name, email},
        subject: 'PEAK PRO - WARRANTY CLAIM - MOBILE APP',
        deviceId: device?.serialNumberString,
        tags: ['peak', 'pro'],
      },
      customFields,
      arrayBuffer: answers.arrayBuffer,
    };
    await submitClaim(data);

    navigation.navigate(Screens.WarrantyClaimScreenSuccess);
    Keyboard.dismiss();
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, alignItems: 'center'}}>
        <KeyboardAvoidingView
          behavior={'height'}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            flex: 1,
          }}>
          <TitleContainer>
            <TitleText>Anything else you&apos;d like to add?</TitleText>
            <SegmentedProgress progress={4} />
          </TitleContainer>

          <TextContainer>
            <TextInput
              editable={true}
              keyboardAppearance="dark"
              onChangeText={text => {
                setMessage(text);
              }}
              multiline={true}
              numberOfLines={8}
              placeholder="Enter your message here..."
              ref={warrantyClaimMessage}
              returnKeyType="done"
              style={{
                backgroundColor: colors.white,
                color: colors.black,
                fontFamily: 'Roboto-Regular',
                fontWeight: '400',
                fontSize: 12,
                height: '100%',
                lineHeight: 15,
                paddingHorizontal: 12,
                paddingTop: 20,
                width: '100%',
                textAlignVertical: 'top',
                borderRadius: 4,
              }}
              blurOnSubmit={true}
              value={message}
              maxLength={Constants.TEXT_PROMPT_MAX_LENGTH}
            />
          </TextContainer>

          <SpinnerContainer>
            {isSubmittingToZendesk && (
              <ActivityIndicator size="large" color={colors.white} />
            )}
          </SpinnerContainer>

          <ButtonContainer>
            <StyledButton
              style={{marginBottom: 40}}
              disabled={isSubmittingToZendesk}
              onPress={submit}
              title="Submit"
            />
          </ButtonContainer>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ImgBackground>
  );
};

const TitleContainer = styled(View)({
  marginTop: 64,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  height: 80,
});

const TitleText = styled(Text)({
  color: colors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 30,
  marginLeft: '5%',
  flex: 1,
  fontWeight: '400',
});

const TextContainer = styled(View)({
  width: '90%',
  marginVertical: 30,
  flex: 2.5,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  flex: 4,
});

const SpinnerContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 0.4,
});
