import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {Modal} from '../components/Modal';
import {WebviewInterstitial} from '../components/WebviewInterstitial';
import {Screens} from '../constants/navigation';
import {geolocationApi, interstitialApi} from '../lib/api/apis';
import {useRemoteData} from '../lib/hooks/useRemoteData';
import {appFlagsSelector} from '../lib/redux/slices/appFlagsSlice';
import {
  connectedPeakSelector,
  currentDeviceSelector,
} from '../lib/redux/slices/bleSlice';
import {
  interstitialsSelector,
  markAsSeen as markAsSeenReducer,
} from '../lib/redux/slices/interstitialsSlice';
import {userSelector} from '../lib/redux/slices/userSlice';
import {
  Interstitial as InterstitialType,
  RemoteModalInterstitial,
  isRemoteModalInterstitial,
  isStaticModalInterstitial,
  isStaticScreenInterstitial,
} from '../lib/types/Interstitial';
import NavigationService from '../lib/utils/NavigationService';
import {
  MarketOptInInterstitial,
  SMSMarketOptInInterstitial,
  XLUnlockedInterstitial,
  flourishMoodlightInterstitial,
} from '../screens/main/interstitials';
import {appVersion} from '../util/AppVersion';
import {createContainer} from './unstated-next';

const onlyActive = ({startDate, endDate}: InterstitialType) => {
  const now = new Date();

  return now > startDate && (endDate ? now < endDate : true);
};

const staticInterstitials = [
  flourishMoodlightInterstitial,
  XLUnlockedInterstitial,
  MarketOptInInterstitial,
  SMSMarketOptInInterstitial,
].filter(onlyActive);

type Navigation = DrawerContentComponentProps['navigation'];

const useInterstitial = () => {
  const currentDevice = useSelector(currentDeviceSelector);
  const connectedDevice = useSelector(connectedPeakSelector);
  const appFlags = useSelector(appFlagsSelector);
  const user = useSelector(userSelector);
  const navigation = useNavigation<Navigation>();
  const dispatch = useDispatch();

  const {history} = useSelector(interstitialsSelector);

  const currentScreen = NavigationService.getCurrentRouteName();

  const [currentlyVisible, setCurrentlyVisible] =
    React.useState<InterstitialType>();
  const limit = React.useRef(false);

  const remote = useRemoteData({key: 'useLoadInterstitials'}, () =>
    interstitialApi
      .getActive({xAppVersion: appVersion})
      .then(({data}) => {
        const mapped: RemoteModalInterstitial = {
          key: data.id.toString(),
          // TODO: expose through api
          startDate: new Date(),
          // TODO: expose through api
          endDate: new Date(),
          type: 'remote',
          display: {as: 'modal'},
          options: {url: data.url},
        };

        return [mapped];
      })
      .catch(() => [] as RemoteModalInterstitial[]),
  );

  const country = useAsync(async () => {
    const res = await geolocationApi.getCountry().catch(() => undefined);

    if (!res) return;

    return res.data.ipcountry;
  }, []);

  const markAsSeen = React.useCallback((key: string) => {
    dispatch(markAsSeenReducer({key}));
  }, []);

  const notSeenInterstitials = React.useMemo(() => {
    if (limit.current) return [];

    return [
      ...(remote.data ?? []),
      ...staticInterstitials.sort(
        (one, other) => one.startDate.getTime() - other.startDate.getTime(),
      ),
    ].filter(i => !history[i.key]?.lastSeenAt);
  }, [history, remote.data, limit]);

  React.useEffect(() => {
    if (remote.isLoading || country.loading || currentScreen !== Screens.Home)
      return;

    const toBeDisplayed = notSeenInterstitials.find(interstitial => {
      if (isRemoteModalInterstitial(interstitial)) {
        return true;
      }

      return interstitial.display.if({
        currentDevice,
        connectedDevice,
        user,
        launchCount: appFlags.launchCount,
        country: country.value,
      });
    });

    setCurrentlyVisible(toBeDisplayed ?? undefined);

    // Limit to one interstitial per app visit
    limit.current = true;
  }, [
    history,
    remote.isLoading,
    country.loading,
    currentScreen,
    notSeenInterstitials,
    currentDevice,
    connectedDevice,
    user,
    appFlags.launchCount,
    country.value,
    limit,
  ]);

  const display = React.useCallback(
    (interstitial: InterstitialType) => {
      if (isRemoteModalInterstitial(interstitial)) {
        Modal.display({
          element: <WebviewInterstitial {...{interstitial}} />,
          onClose: () => {
            markAsSeen(interstitial.key);
          },
        });

        return;
      }

      if (isStaticModalInterstitial(interstitial)) {
        Modal.display({
          element: <interstitial.options.modal />,
          onClose: () => {
            markAsSeen(interstitial.key);
          },
        });

        return;
      }

      if (isStaticScreenInterstitial(interstitial)) {
        navigation.navigate(Screens.StaticScreenInterstitial, {
          component: interstitial.options.screen,
          onNavigatingAway: () => {
            markAsSeen(interstitial.key);
          },
        });

        return;
      }
    },
    [markAsSeen],
  );

  React.useEffect(() => {
    if (!currentlyVisible) return;

    display(currentlyVisible);
  }, [currentlyVisible, display]);

  // TODO: Make this support more than one interstitial at a time
  return React.useMemo(
    () => ({remoteInterstitial: remote.data?.[0], display}),
    [remote.data, display],
  );
};

export const Interstitial = createContainer(useInterstitial);
