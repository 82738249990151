import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';
import {ulid} from 'ulid';

import {Alert} from '../../components/Alert';
import {Constants} from '../../constants';
import {Alerts} from '../../constants/Strings';
import {colors} from '../../styles';
import {
  CustomMoodLight,
  ExclusiveMoodLight,
  MoodLight,
  Profile,
  ProfilePreT,
  ProfileT,
  isPreTHeatProfile,
  isTHeatProfile,
  isTHeatProfileMoodLight,
  isTHeatProfileNoMoodLight,
} from '../types';
import {findMatchingMoodLight} from './moodLightFunctions';
import {recursiveDeepCopy} from './recursiveDeepCopy';
import {Temperature} from './temperature';

export const enforceProfileOrder = (profileList: Profile[]) => {
  for (let i = 0; i < profileList.length; i++) {
    profileList[i].order = i;
  }
};

export const mergeProfilesImmutable = (
  aConst: Profile[],
  bConst: Profile[],
): Profile[] => {
  const a = recursiveDeepCopy(aConst || []);
  const b = recursiveDeepCopy(bConst || []);
  for (const profile of b) {
    if (!a.find((p: Profile) => p.id === profile.id)) {
      a.push(profile);
    }
  }
  enforceProfileOrder(a);

  return a;
};

export const maxProfileGuard = (numArchives: number, action: () => void) => {
  if (numArchives < Constants.MAX_HEAT_PROFILES) {
    action();
    return true;
  } else {
    Alert.alert(
      Alerts.MAX_HEAT_PROFILES_REACHED_TITLE,
      Alerts.MAX_HEAT_PROFILES_REACHED_MESSAGE,
    );
    return false;
  }
};

const getRoundedTemp = (profile: Profile) =>
  Math.round(
    Temperature.convert(profile.temperature, {
      from: profile.units,
      to: TemperatureUnit.Celsius,
    }),
  );

export const profilesMatch = (a: Profile, b: Profile) => {
  if (isTHeatProfile(a) && isTHeatProfile(b) && a.id && b.id) {
    return a.id === b.id;
  } else {
    return profilesMatchRawValues(a, b);
  }
};

export const profilesMatchRawValues = (
  a: Profile,
  b: Profile,
  aMoodLight?: MoodLight,
  bMoodLight?: MoodLight,
) =>
  !!a &&
  !!b &&
  !a.deleted &&
  !b.deleted &&
  a.name.toUpperCase() === b.name.toUpperCase() &&
  a.duration === b.duration &&
  getRoundedTemp(a) === getRoundedTemp(b) &&
  (((isPreTHeatProfile(a) || isTHeatProfileNoMoodLight(a)) &&
    (isPreTHeatProfile(b) || isTHeatProfileNoMoodLight(b)) &&
    a.color &&
    b.color &&
    a.color.toUpperCase() === b.color.toUpperCase()) ||
    (isTHeatProfileMoodLight(a) &&
    isTHeatProfileMoodLight(b) &&
    !(aMoodLight && bMoodLight)
      ? a.moodLightId === b.moodLightId
      : aMoodLight &&
        bMoodLight &&
        findMatchingMoodLight(aMoodLight, [bMoodLight])));

export const swapProfiles = (
  listA: Profile[],
  listB: Profile[],
  indexA: number,
  indexB: number,
) => {
  if (indexA >= 0 && indexB >= 0) {
    const tempProfile = listA[indexA];

    listA[indexA] = listB[indexB];
    listB[indexB] = tempProfile;
  }
};

export type InsertProfileProps = {
  activeIndex: number;
  archiveIndex: number;
  currentArchives: Profile[];
  newActives: Profile[];
  newArchives: Profile[];
  userId: number;
};

export const insertProfile = ({
  activeIndex,
  archiveIndex,
  currentArchives,
  newActives,
  newArchives,
  userId,
}: InsertProfileProps) => {
  if (activeIndex >= 0 && archiveIndex >= 0) {
    const activeProfile = cloneDeep(newActives[activeIndex]);
    const archiveProfile = cloneDeep(newArchives[archiveIndex]);

    //check if profile is in archive list already
    const duplicateIndex = currentArchives.findIndex(archiveProfile =>
      profilesMatch(archiveProfile, activeProfile),
    );
    if (duplicateIndex === Constants.INDEX_NOT_FOUND) {
      return maxProfileGuard(
        currentArchives.filter(profile => !profile.deleted)?.length,
        () => {
          //prepare insert by shifting archives
          for (let i = archiveIndex; i < newArchives.length; i++) {
            newArchives[i].order++;
          }
          //insert
          newArchives.splice(archiveIndex, 0, activeProfile);
          newArchives[archiveIndex].id = ulid();
          newArchives[archiveIndex].userId = userId;
          newActives[activeIndex] = archiveProfile;
        },
      );
    } else {
      swapProfiles(newArchives, newArchives, duplicateIndex, archiveIndex);
      newActives[activeIndex] = archiveProfile;
      return true;
    }
  }
  return false;
};

export const convertToPreferredUnits = (
  profile: Profile,
  tempPreference: TemperatureUnit,
) => {
  const temperature = Math.round(
    Temperature.convert(profile.temperature, {
      from: profile.units,
      to: tempPreference,
    }),
  );

  return {
    ...profile,
    temperature,
    units: tempPreference,
  };
};

// Ensures transformation of modified from Date to number in milliseconds
export const getProfileModifiedTime = (profile: Profile) =>
  (profile.modified ? new Date(profile.modified) : new Date()).getTime();

export const migrateProfileToT: (profile: Profile) => ProfileT = (
  profile: Profile,
) =>
  !profile.version || isPreTHeatProfile(profile)
    ? {
        ...profile,
        version: ProfileVersion.T,
        modified: getProfileModifiedTime(profile),
        isMoodLight: false,
      }
    : profile;

export const migrateProfileToPreT = (
  profile: Omit<Profile, 'version'> & {version: ProfileVersion | undefined},
) => {
  if (profile.version) {
    const newProfile = profile as Profile;
    if (isTHeatProfile(newProfile)) {
      return {
        ...omit(profile, [
          'created',
          'wasSyncedWithActive',
          'isMoodLight',
          'moodLightId',
        ]),
        ...(profile.modified && {
          modified: new Date(profile.modified).getTime(),
        }),
        color: isTHeatProfileMoodLight(newProfile)
          ? colors.defaultColor
          : newProfile.color,
        version: ProfileVersion.PreT,
      } as ProfilePreT;
    } else {
      return profile as ProfilePreT;
    }
  } else {
    return {...profile, version: ProfileVersion.PreT} as ProfilePreT;
  }
};

// Transforms profiles received from the server to match the Profile type
export const transformApiProfileToProfile: (profile: Profile) => Profile = (
  profile: Profile,
) => ({
  ...(omit(profile, 'created') as Profile),
  modified: getProfileModifiedTime(profile),
});

// TODO: Remove tempporary function when the rest of mood light builds are in
export const getMoodLightColor = (profile: Profile): string => {
  return isTHeatProfileMoodLight(profile)
    ? '#000000' // TODO: use multicolor gradient
    : profile.color;
};

export const getActiveProfileMoodLights = (
  profiles: Profile[],
  getMoodLight: (
    id: string,
  ) => CustomMoodLight | ExclusiveMoodLight | undefined,
) => {
  return profiles.map(profile => {
    return isTHeatProfileMoodLight(profile)
      ? getMoodLight(profile.moodLightId)
      : undefined;
  });
};

export const setProfileToMoodLight = (profile: ProfileT, moodLightId: string) =>
  ({
    ...omit(profile, ['color']),
    isMoodLight: true,
    moodLightId,
  }) as ProfileT;

export const setProfileToColor: (
  profile: ProfileT,
  color: string,
) => ProfileT = (profile: ProfileT, color: string) => ({
  ...profile,
  isMoodLight: false,
  moodLightId: null,
  color,
});

export const setProfileToWhite = (profile: ProfileT) =>
  setProfileToColor(profile, colors.defaultColor);
