import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const LightningIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M13 2L3 14H12L11 22L21 10H12L13 2Z" />
  </Icon>
);
