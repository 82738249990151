import {ProductName} from 'pikaparam';
import {TimeSlug} from 'puffco-api-axios-client';
import {ImageSourcePropType} from 'react-native';

import {
  afternoonDabber,
  morningDabber,
  nightDabber,
  le04Peak as peakDesert,
  le05Peak as peakFlourish,
  le03Peak as peakGuardian,
  le02Peak as peakIndiglow,
  peak as peakOG,
  peakPeachBlack as peakOnyx,
  peakOpal,
  peakPeachWhite as peakPearl,
  peakStorm,
  weekendDabber,
} from '../../assets/images';
import {ProductMap} from '../../lib/types/ProductMap';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import {colors} from '../../styles';

export interface BaseRehashTheme {
  colors: string[];
  image?: ImageSourcePropType;
}

export interface FavoriteDeviceTheme extends BaseRehashTheme {
  controlsColor: string;
  gradientColors: string[];
  illustrationColor: string;
  modelNameColor: string;
  titleColor: string;
  nameColor: string;
}

const OgTheme: FavoriteDeviceTheme = {
  controlsColor: colors.white,
  gradientColors: [colors.black, '#5A5A5A'],
  illustrationColor: colors.white,
  modelNameColor: addOpacityToColorHex(colors.white, 0.5),
  titleColor: addOpacityToColorHex(colors.white, 0.5),
  nameColor: colors.white,
  image: peakOG,
  colors: ['#5B5B5B', '#181818'],
};

const deviceThemes: ProductMap<FavoriteDeviceTheme> = {
  OG: OgTheme,
  Opal: {
    controlsColor: colors.black,
    gradientColors: ['#B2BDD1', '#D3DCEC'],
    illustrationColor: colors.black,
    modelNameColor: addOpacityToColorHex(colors.black, 0.5),
    titleColor: addOpacityToColorHex(colors.black, 0.5),
    nameColor: colors.black,
    image: peakOpal,
    colors: [colors.white, '#D3DCEC'],
  },
  Indiglow: {
    controlsColor: colors.white,
    gradientColors: ['#824EA1', '#80A3E3'],
    illustrationColor: colors.white,
    modelNameColor: colors.white,
    titleColor: addOpacityToColorHex(colors.white, 0.5),
    nameColor: colors.white,
    image: peakIndiglow,
    colors: ['#3C70C7', '#8C489B'],
  },
  Guardian: {
    controlsColor: colors.black,
    gradientColors: ['#888', '#ECECEC'],
    illustrationColor: colors.black,
    modelNameColor: addOpacityToColorHex(colors.black, 0.5),
    titleColor: addOpacityToColorHex(colors.white, 0.5),
    nameColor: colors.white,
    image: peakGuardian,
    colors: [colors.white, '#7E7E7E'],
  },
  Pearl: {
    controlsColor: colors.black,
    gradientColors: ['#CDCDCD', '#EEE'],
    illustrationColor: '#4D4D4D',
    modelNameColor: addOpacityToColorHex(colors.black, 0.5),
    titleColor: addOpacityToColorHex(colors.black, 0.5),
    nameColor: colors.black,
    image: peakPearl,
    colors: [colors.white, '#B3B3B3'],
  },
  Onyx: {
    controlsColor: colors.white,
    gradientColors: [colors.black, '#5A5A5A'],
    illustrationColor: colors.white,
    modelNameColor: addOpacityToColorHex(colors.white, 0.5),
    titleColor: addOpacityToColorHex(colors.white, 0.5),
    nameColor: colors.white,
    image: peakOnyx,
    colors: ['#5B5B5B', '#181818'],
  },
  Desert: {
    controlsColor: colors.white,
    gradientColors: ['#9D8F84', '#DFCDBF'],
    illustrationColor: colors.black,
    modelNameColor: colors.black,
    titleColor: addOpacityToColorHex(colors.black, 0.5),
    nameColor: colors.black,
    image: peakDesert,
    colors: ['#C2B3A6', '#C2B3A6'],
  },
  Flourish: {
    controlsColor: colors.white,
    gradientColors: ['#5D7B64', '#A7C3AE'],
    illustrationColor: colors.white,
    modelNameColor: colors.white,
    titleColor: addOpacityToColorHex(colors.white, 0.5),
    nameColor: colors.white,
    image: peakFlourish,
    colors: ['#AFC5B4', '#4E6252'],
  },
  Storm: {
    controlsColor: colors.white,
    gradientColors: ['#30354B', '#8E97C6'],
    illustrationColor: colors.white,
    modelNameColor: colors.white,
    titleColor: colors.white,
    nameColor: colors.white,
    image: peakStorm,
    colors: ['#8897D9', '#3C4153'],
  },
  Daybreak: OgTheme,
};

export const dabTimeThemes = {
  [TimeSlug.WakeBaker]: {image: morningDabber, colors: ['#D033B0', '#F8F838']},
  [TimeSlug.HighNooner]: {
    image: afternoonDabber,
    colors: ['#FF7A0E', '#FFD000'],
  },
  [TimeSlug.NightToker]: {image: nightDabber, colors: ['#2A1346', '#5238F8']},
  [TimeSlug.WeekendRipper]: {
    image: weekendDabber,
    colors: ['#0EDBFF', '#FCD422'],
  },
};

export const getDeviceTheme: (
  model?: ProductName,
) => FavoriteDeviceTheme = model => deviceThemes?.[model ?? 'OG'] ?? OgTheme;
