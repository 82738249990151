import React from 'react';
import {StyleSheet, Text} from 'react-native';

import {Strings} from '../constants/Strings';
import {ChevronUpIcon} from '../icons';
import {addOpacityToColorHex} from '../lib/utils/colors';
import {colors} from '../styles';
import {PressableOpacity} from './PressableOpacity';

export interface Props {
  onPress: () => void;
}

export const BackToTopButton = ({onPress}: Props) => {
  return (
    <PressableOpacity style={styles.container} {...{onPress}}>
      <ChevronUpIcon size={32} containerSize={56} style={styles.icon} />

      <Text style={styles.text}>{Strings.BACK_TO_TOP}</Text>
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  icon: {
    backgroundColor: addOpacityToColorHex(colors.white, 0.1),
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: colors.white,
    marginHorizontal: 20,
    marginTop: 10,
  },
});
