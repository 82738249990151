import React from 'react';
import {Circle, Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const LockIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M18.2222 8H5.77778C4.79594 8 4 9.13964 4 10.5455V19.4545C4 20.8604 4.79594 22 5.77778 22H18.2222C19.2041 22 20 20.8604 20 19.4545V10.5455C20 9.13964 19.2041 8 18.2222 8Z" />
    <Circle cx="12" cy="15" r="2" />
    <Path d="M7 8V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V8" />
  </Icon>
);
