import {Navigators, Screens} from '../../constants/navigation';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigators/RootStackNavigator';
import {User} from '../types';

type Navigate = RootStackScreenProps<
  keyof RootStackParamList
>['navigation']['navigate'];

// User privileges
// Reference: https://docs.google.com/spreadsheets/d/1sNOwP0CIqfqJpPOMuIcVTpCbqsTLHvRA
export const verifyGuard = (
  user: User,
  action: () => void,
  navigate: Navigate,
  overrideVerificationCheck?: boolean,
) => {
  if (user.verified || overrideVerificationCheck) {
    action();
    return true;
  } else {
    navigate(Navigators.MainNavigator, {
      screen: Navigators.HomeDrawerNavigator,
      params: {
        screen: Navigators.HomeEmulatedDrawer,
        params: {
          screen: Screens.EmailVerify,
        },
      },
    });
    return false;
  }
};

export const isLegacyUser = (user: User): boolean => {
  let result = false;
  if (user && user.created) {
    const userCreated = new Date(user.created);
    const legacyUserUpdateDate = new Date('2022-06-11'); // date 1.5.0 was released on play store
    if (userCreated.getTime() < legacyUserUpdateDate.getTime()) {
      result = true;
    }
  }
  return result;
};
