export {default as addPhoto} from './add-photo-web.png';
export {default as backgroundAccountCreated} from './background-account-created.jpg';
export {default as backgroundCleaningReminder} from './background-cleaning-reminder.png';
export {default as backgroundFlourishMoodlightInterstitial} from './background-flourish-moodlight-interstitial.jpg';
export {default as backgroundMarketOptInEarlyAccess} from './background-early-access.jpg';
export {default as backgroundForgotPassword} from './background-forgot-password.jpeg';
export {default as backgroundLogin} from './background-login.jpg';
export {default as backgroundOpal} from './background-main-opal.png';
export {default as background} from './background-main.png';
export {default as backgroundMarketOptInConfirmed} from './background-market-opt-in-confirmed.jpg';
export {default as backgroundMarketOptIn} from './background-market-opt-in.png';
export {default as backgroundMetricsAlertScreen} from './background-metrics-alert-screen.jpeg';
export {default as backgroundUpdateUsernameNew} from './background-new-update-username.jpg';
export {default as backgroundPasswordConfirmation} from './background-password-confirmation.jpg';
export {default as backgroundPasswordResetRequest} from './background-password-reset-request.jpeg';
export {default as backgroundProductRelease} from './background-product-release.jpg';
export {default as backgroundRegister} from './background-register.jpg';
export {default as backgroundUpdateUsername} from './background-update-username.jpg';
export {default as backgroundV3ChamberScreen} from './background-v3-chamber-screen.jpg';
export {default as backgroundVaporScreen} from './background-vapor-screen.jpg';
export {default as backgroundWelcome} from './background-welcome.jpg';
export {default as backgroundXLUnlockedAlertScreen} from './background-xl-unlocked-alert-screen.jpg';
export {default as backgroundXLUpsellAlertScreen} from './background-xl-upsell-alert-screen.jpg';
export {default as brainSignal} from './brain-signal.png';
export {default as chamberPerformance} from './chamber-performance.png';
export {default as colorWheel} from './color-wheel.png';
export {default as dataUnavailable} from './data-unavailable.png';
export {default as firstViewAdvancedMetrics} from './first-view-advanced-metrics.png';
export {default as flourishMoodlightInterstitialText} from './flourish-moodlight-interstitial-text.png';

// Icons only found in control center or moodlights , will be deleted when implementing redesign
export {default as noLightbulb} from './icon-no-lightbulb.png';
export {default as power} from './icon-power.png';
export {default as pulse} from './icon-pulse.png';
export {default as radiate} from './icon-radiate.png';
export {default as rocket} from './icon-rocket.png';
export {default as spin} from './icon-spin.png';

export {default as picker} from './picker.png';
export {default as pickerThinBorder} from './icon-picker-thin-border.png';
export {default as pickerThickBorder} from './icon-picker-thick-border.png';
export {default as pickerShadow} from './icon-picker-shadow.png';
export {default as removePicker} from './icon-remove-picker.png';
export {default as addPicker} from './icon-add-picker.png';
export {default as borealis} from './shader-borealis.png';

export {default as trophy} from './icon-trophy.png';

export {default as logoClose2} from './logo-close-2.png'; // not an icon
export {default as logoClose} from './logo-close.png';
export {default as logoInverted} from './logo-inverted.png'; // not an icon
export {default as logoExtended} from './logo-extended.png';
// Tint color does not change it on Path Browser
export {default as logoExtendedBlack} from './logo-extended-black.png';

// Standard Peak
export {default as peakBackgroundGlow} from './peak-background-glow.png';
export {default as peakLightsBaseLeft} from './peak-lights-base-left.png';
export {default as peakLightsBaseRight} from './peak-lights-base-right.png';
export {default as peakLightsGlassLeft} from './peak-lights-glass-left.png';
export {default as peakLightsGlassRight} from './peak-lights-glass-right.png';
export {default as peak} from './peak.png';

// LE1 Opal
export {default as backgroundGlitter} from './background-glitter.png';
export {default as le01Gem} from './le-01-gem.png';
export {default as multiColorOpalBackground2Color1} from './multi-color-opal-background-2-color-1.png';
export {default as multiColorOpalBackground2Color2} from './multi-color-opal-background-2-color-2.png';
export {default as multiColorOpalBackground3Color1} from './multi-color-opal-background-3-color-1.png';
export {default as multiColorOpalBackground3Color2} from './multi-color-opal-background-3-color-2.png';
export {default as multiColorOpalBackground3Color3} from './multi-color-opal-background-3-color-3.png';
export {default as multiColorOpalBackground4Color1} from './multi-color-opal-background-4-color-1.png';
export {default as multiColorOpalBackground4Color2} from './multi-color-opal-background-4-color-2.png';
export {default as multiColorOpalBackground4Color3} from './multi-color-opal-background-4-color-3.png';
export {default as multiColorOpalBackground4Color4} from './multi-color-opal-background-4-color-4.png';
export {default as multiColorOpalBackground5Color1} from './multi-color-opal-background-5-color-1.png';
export {default as multiColorOpalBackground5Color2} from './multi-color-opal-background-5-color-2.png';
export {default as multiColorOpalBackground5Color3} from './multi-color-opal-background-5-color-3.png';
export {default as multiColorOpalBackground5Color4} from './multi-color-opal-background-5-color-4.png';
export {default as multiColorOpalBackground5Color5} from './multi-color-opal-background-5-color-5.png';
export {default as multiColorOpalBackground6Color1} from './multi-color-opal-background-6-color-1.png';
export {default as multiColorOpalBackground6Color2} from './multi-color-opal-background-6-color-2.png';
export {default as multiColorOpalBackground6Color3} from './multi-color-opal-background-6-color-3.png';
export {default as multiColorOpalBackground6Color4} from './multi-color-opal-background-6-color-4.png';
export {default as multiColorOpalBackground6Color5} from './multi-color-opal-background-6-color-5.png';
export {default as multiColorOpalBackground6Color6} from './multi-color-opal-background-6-color-6.png';
export {default as peakOpalDarkScreen} from './peak-opal-dark-screen.png';
export {default as peakOpalLightsBaseFarLeft} from './peak-opal-lights-base-far-left.png';
export {default as peakOpalLightsBaseFarRight} from './peak-opal-lights-base-far-right.png';
export {default as peakOpalLightsBaseMidLeft} from './peak-opal-lights-base-mid-left.png';
export {default as peakOpalLightsBaseMidRight} from './peak-opal-lights-base-mid-right.png';
export {default as peakOpalLightsBaseRingFarLeft} from './peak-opal-lights-base-ring-far-left.png';
export {default as peakOpalLightsBaseRingFarRight} from './peak-opal-lights-base-ring-far-right.png';
export {default as peakOpalLightsBaseRingMidLeft} from './peak-opal-lights-base-ring-mid-left.png';
export {default as peakOpalLightsBaseRingMidRight} from './peak-opal-lights-base-ring-mid-right.png';
export {default as peakOpalLightsGlassFarLeft} from './peak-opal-lights-glass-far-left.png';
export {default as peakOpalLightsGlassFarRight} from './peak-opal-lights-glass-far-right.png';
export {default as peakOpalLightsGlassMidLeft} from './peak-opal-lights-glass-mid-left.png';
export {default as peakOpalLightsGlassMidRight} from './peak-opal-lights-glass-mid-right.png';
export {default as peakOpal} from './peak-opal.png';

// LE2 Indiglow
export {default as le02BackgroundHomeScreen} from './le-02-background-home-screen.png';
export {default as le02BackgroundIntro} from './le-02-background-intro.png';
export {default as le02BackgroundProfile} from './le-02-background-profile.png';
export {default as le02GemMask} from './le-02-gem-mask.png';
export {default as le02Gem} from './le-02-gem.png';
export {default as le02HeatProfileCardBackground} from './le-02-heat-profile-card-background.png';
export {default as le02HeatProfileListBackground} from './le-02-heat-profile-list-background.png';
export {default as le02LatticeIntroBottom} from './le-02-lattice-intro-bottom.png';
export {default as le02LatticeIntroModal} from './le-02-lattice-intro-modal.png';
export {default as le02PeakLightsBaseFarLeft} from './le-02-peak-lights-base-far-left.png';
export {default as le02PeakLightsBaseFarRight} from './le-02-peak-lights-base-far-right.png';
export {default as le02PeakLightsBaseMidLeft} from './le-02-peak-lights-base-mid-left.png';
export {default as le02PeakLightsBaseMidRight} from './le-02-peak-lights-base-mid-right.png';
export {default as le02PeakLightsBaseRingFarLeft} from './le-02-peak-lights-base-ring-far-left.png';
export {default as le02PeakLightsBaseRingFarRight} from './le-02-peak-lights-base-ring-far-right.png';
export {default as le02PeakLightsBaseRingMidLeft} from './le-02-peak-lights-base-ring-mid-left.png';
export {default as le02PeakLightsBaseRingMidRight} from './le-02-peak-lights-base-ring-mid-right.png';
export {default as le02PeakLightsGlassFarLeft} from './le-02-peak-lights-glass-far-left.png';
export {default as le02PeakLightsGlassFarRight} from './le-02-peak-lights-glass-far-right.png';
export {default as le02PeakLightsGlassMidLeft} from './le-02-peak-lights-glass-mid-left.png';
export {default as le02PeakLightsGlassMidRight} from './le-02-peak-lights-glass-mid-right.png';
export {default as le02Peak} from './le-02-peak.png';

// LE3 Guardian
export {default as le03BackgroundHomeScreen} from './le-03-background-home-screen.png';
export {default as le03BackgroundNamePeak} from './le-03-background-intro-name.png';
export {default as le03BackgroundIntro} from './le-03-background-intro.jpg';
export {default as le03BackgroundProfile} from './le-03-background-profile.png';
export {default as le03Gem} from './le-03-gem.png';
export {default as le03GroundLayer} from './le-03-ground-layer.png';
export {default as le03HeatProfileCardBackgroundEdit} from './le-03-heat-profile-card-background-edit.png';
export {default as le03HeatProfileCardBackground} from './le-03-heat-profile-card-background.png';
export {default as le03HeatProfileListBackground} from './le-03-heat-profile-list-background.jpeg';
export {default as le03HeatProfileListNavBackground} from './le-03-heat-profile-list-nav-background.png';
export {default as le03Peak} from './le-03-peak.png';
export {default as peakGuardianLightsBaseFarLeft} from './peak-guardian-lights-base-far-left.png';
export {default as peakGuardianLightsBaseFarRight} from './peak-guardian-lights-base-far-right.png';
export {default as peakGuardianLightsBaseMidLeft} from './peak-guardian-lights-base-mid-left.png';
export {default as peakGuardianLightsBaseMidRight} from './peak-guardian-lights-base-mid-right.png';
export {default as peakGuardianLightsBaseRingFarLeft} from './peak-guardian-lights-base-ring-far-left.png';
export {default as peakGuardianLightsBaseRingFarRight} from './peak-guardian-lights-base-ring-far-right.png';
export {default as peakGuardianLightsBaseRingMidLeft} from './peak-guardian-lights-base-ring-mid-left.png';
export {default as peakGuardianLightsBaseRingMidRight} from './peak-guardian-lights-base-ring-mid-right.png';
export {default as peakGuardianLightsGlassFarLeft} from './peak-guardian-lights-glass-far-left.png';
export {default as peakGuardianLightsGlassFarRight} from './peak-guardian-lights-glass-far-right.png';
export {default as peakGuardianLightsGlassMidLeft} from './peak-guardian-lights-glass-mid-left.png';
export {default as peakGuardianLightsGlassMidRight} from './peak-guardian-lights-glass-mid-right.png';

// LE4 Peak Pro Desert
/* Rest is same with Peach Peak */
export {default as le04Peak} from './peak-desert.png';

// LE5 Peak Pro Flourish
/* Rest is same with Peach Peak */
export {default as le05Peak} from './peak-flourish.png';

// LE5 Peak Pro Storm
export {default as peakStormGlass} from './peak-storm-glass.png';
export {default as peakStorm} from './peak-storm.png';
export {default as peakStormBase} from './peak-storm-base.png';
export {default as peakStormChamberRegular} from './peak-storm-chamber-regular.png';
export {default as peakStormChamberXl} from './peak-storm-chamber-xl.png';

export {default as glow} from './glow.png';
export {default as ring} from './ring.png';

export {default as peakShadow} from './peak-shadow.png';

export {default as heatProfileCoreCircleMask} from './heat-profile-core-circle-mask.png';

//Heat Glow Images
export {default as tintableHeatGlow1Color1} from './heat-glow-1-color-1.png';
export {default as tintableHeatGlow2Color1} from './heat-glow-2-color-1.png';
export {default as tintableHeatGlow2Color2} from './heat-glow-2-color-2.png';
export {default as tintableHeatGlow3Color1} from './heat-glow-3-color-1.png';
export {default as tintableHeatGlow3Color2} from './heat-glow-3-color-2.png';
export {default as tintableHeatGlow3Color3} from './heat-glow-3-color-3.png';
export {default as tintableHeatGlow4Color1} from './heat-glow-4-color-1.png';
export {default as tintableHeatGlow4Color2} from './heat-glow-4-color-2.png';
export {default as tintableHeatGlow4Color3} from './heat-glow-4-color-3.png';
export {default as tintableHeatGlow4Color4} from './heat-glow-4-color-4.png';
export {default as tintableHeatGlow5Color1} from './heat-glow-5-color-1.png';
export {default as tintableHeatGlow5Color2} from './heat-glow-5-color-2.png';
export {default as tintableHeatGlow5Color3} from './heat-glow-5-color-3.png';
export {default as tintableHeatGlow5Color4} from './heat-glow-5-color-4.png';
export {default as tintableHeatGlow5Color5} from './heat-glow-5-color-5.png';
export {default as tintableHeatGlow6Color1} from './heat-glow-6-color-1.png';
export {default as tintableHeatGlow6Color2} from './heat-glow-6-color-2.png';
export {default as tintableHeatGlow6Color3} from './heat-glow-6-color-3.png';
export {default as tintableHeatGlow6Color4} from './heat-glow-6-color-4.png';
export {default as tintableHeatGlow6Color5} from './heat-glow-6-color-5.png';
export {default as tintableHeatGlow6Color6} from './heat-glow-6-color-6.png';

// Cleaning Reminder
export {default as cleaningInstructionsThumbnail} from './cleaning-instructions-thumbnail.jpg';

// other sign in logo
export {default as appleLogo} from './icon-apple-logo.png';
export {default as googleLogo} from './icon-google-logo.png';

export {default as bluetoothStartPairing} from './bluetooth-start-pairing.jpg';

export {default as carouselAdvancedDab} from './background-carousel-advanced-dab.jpg';
export {default as carouselHeatProfile} from './background-carousel-heat-profile.jpg';
export {default as carouselMoodLight} from './background-carousel-mood-lights.jpg';

// Peach
export {default as peachBlackBackgroundHomeScreen} from './peach-black-background.png';
export {default as peachWhiteBackgroundHomeScreen} from './peach-white-background.png';

// Common Layers
export {default as peakBaseShadow} from './peak-base-shadow.png';

// Common LED Layers
export {default as peakPeachLightsGlassFarLeft} from './peak-peach-lights-glass-far-left.png';
export {default as peakPeachLightsGlassFarRight} from './peak-peach-lights-glass-far-right.png';
export {default as peakPeachLightsGlassMidLeft} from './peak-peach-lights-glass-mid-left.png';
export {default as peakPeachLightsGlassMidRight} from './peak-peach-lights-glass-mid-right.png';

export {default as legacyPeakPeachLightsGlassFarLeft} from './peak-peach-lights-glass-far-left-legacy.png';
export {default as legacyPeakPeachLightsGlassFarRight} from './peak-peach-lights-glass-far-right-legacy.png';
export {default as legacyPeakPeachLightsGlassMidLeft} from './peak-peach-lights-glass-mid-left-legacy.png';
export {default as legacyPeakPeachLightsGlassMidRight} from './peak-peach-lights-glass-mid-right-legacy.png';

// Peach Black Peak Layers
export {default as peakPeachBlackGlass} from './peak-peach-black-glass.png';
export {default as peakPeachBlack} from './peak-peach-black.png';
export {default as peakPeachBlackBase} from './peak-peach-black-base.png';
export {default as peakPeachBlackChamberRegular} from './peak-peach-black-chamber-regular.png';
export {default as peakPeachBlackChamberXl} from './peak-peach-black-chamber-xl.png';

// Peach Black LED Layers
export {default as peakPeachBlackLightsBaseLeft} from './peak-peach-black-lights-base-left.png';
export {default as peakPeachBlackLightsBaseRight} from './peak-peach-black-lights-base-right.png';

export {default as legacyPeakPeachBlackLightsBaseLeft} from './peak-peach-black-lights-base-left-legacy.png';
export {default as legacyPeakPeachBlackLightsBaseRight} from './peak-peach-black-lights-base-right-legacy.png';

// Peach White Peak Layers
export {default as peakPeachWhiteGlass} from './peak-peach-white-glass.png';
export {default as peakPeachWhite} from './peak-peach-white.png';
export {default as peakPeachWhiteBase} from './peak-peach-white-base.png';
export {default as peakPeachWhiteChamberRegular} from './peak-peach-white-chamber-regular.png';
export {default as peakPeachWhiteChamberXl} from './peak-peach-white-chamber-xl.png';

// Peach White LED Layers
export {default as peakPeachWhiteLightsBaseFarLeft} from './peak-peach-white-lights-base-far-left.png';
export {default as peakPeachWhiteLightsBaseFarRight} from './peak-peach-white-lights-base-far-right.png';
export {default as peakPeachWhiteLightsBaseMidLeft} from './peak-peach-white-lights-base-mid-left.png';
export {default as peakPeachWhiteLightsBaseMidRight} from './peak-peach-white-lights-base-mid-right.png';

export {default as peakPeachWhiteLightsRingFarLeft} from './peak-peach-white-lights-ring-far-left.png';
export {default as peakPeachWhiteLightsRingFarRight} from './peak-peach-white-lights-ring-far-right.png';
export {default as peakPeachWhiteLightsRingMidLeft} from './peak-peach-white-lights-ring-mid-left.png';
export {default as peakPeachWhiteLightsRingMidRight} from './peak-peach-white-lights-ring-mid-right.png';

export {default as peakWhitePeach} from './peak-peach-white.png';

export {default as skeletonLoading} from './background-loading-skeleton.png';

export {default as searchNotFound} from './icon-search-not-found.png';

// Referral program
export {default as backgroundRewardEarned} from './background-reward-earned.jpg';
export {default as backgroundReferAFriend} from './background-refer-a-friend.jpg';
export {default as backgroundMyRewards} from './background-my-rewards.jpg';
export {default as noRewards} from './no-rewards.png';

// Rehash
export * from './rehash';
