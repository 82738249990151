import {TextStyle} from 'react-native';

import {
  le02BackgroundHomeScreen,
  le02BackgroundIntro,
  le02BackgroundProfile,
  le02Gem,
  le02GemMask,
  le02HeatProfileCardBackground,
  le02HeatProfileListBackground,
  le02LatticeIntroBottom,
  le02LatticeIntroModal,
  le02Peak,
  le02PeakLightsBaseFarLeft,
  le02PeakLightsBaseFarRight,
  le02PeakLightsBaseMidLeft,
  le02PeakLightsBaseMidRight,
  le02PeakLightsBaseRingFarLeft,
  le02PeakLightsBaseRingFarRight,
  le02PeakLightsBaseRingMidLeft,
  le02PeakLightsBaseRingMidRight,
  le02PeakLightsGlassFarLeft,
  le02PeakLightsGlassFarRight,
  le02PeakLightsGlassMidLeft,
  le02PeakLightsGlassMidRight,
} from '../assets/images';
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
// Import from exact file to prevent cycle
import {shadeColor} from '../lib/utils/colors/shadeColor';
import {colors} from '../styles';
import {defaultTheme} from './DefaultTheme';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  HeatProfileCardTheme,
  HeatProfileCoreImageTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  TabBarTheme,
  Theme,
} from './Theme';

const textShadowStyle: TextStyle = {
  textShadowColor: colors.black,
  textShadowRadius: 4,
  textShadowOffset: {width: 0, height: 2},
};

// General
const durationStyle: TextStyle = {
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 14,
  letterSpacing: 0.75,
  textAlign: 'center',
  lineHeight: 20,
  ...textShadowStyle,
};

const temperatureBigStyle: TextStyle = {
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 65,
  color: colors.white,
  letterSpacing: 0,
  textAlign: 'center',
  lineHeight: 41,
  ...textShadowStyle,
};

// Components

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  backgroundImage: le02HeatProfileCardBackground,
  durationFontColor: colors.white,
  durationBubbleColor: colors.black,
  gradientShadeFunction: (color: string) =>
    [0, 0].map(shade => shadeColor(color, shade)),
  overLayGradientColors: [
    addOpacityToColorHex(colors.black, 0.8),
    addOpacityToColorHex(colors.black, 0.2),
    addOpacityToColorHex(colors.black, 0),
  ],
};

const heatProfileCoreImageTheme: HeatProfileCoreImageTheme = {
  baseImageSource: le02Gem,
  maskImageSource: le02GemMask,
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  ...defaultTheme.heatProfileScreenBackgroundTheme,
  background: le02BackgroundProfile,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le02Peak,
  lightLayers: {
    glass: {
      farLeft: le02PeakLightsGlassFarLeft,
      farRight: le02PeakLightsGlassFarRight,
      midLeft: le02PeakLightsGlassMidLeft,
      midRight: le02PeakLightsGlassMidRight,
    },
    base: {
      farLeft: le02PeakLightsBaseFarLeft,
      farRight: le02PeakLightsBaseFarRight,
      midLeft: le02PeakLightsBaseMidLeft,
      midRight: le02PeakLightsBaseMidRight,
    },
    ring: {
      farLeft: le02PeakLightsBaseRingFarLeft,
      farRight: le02PeakLightsBaseRingFarRight,
      midLeft: le02PeakLightsBaseRingMidLeft,
      midRight: le02PeakLightsBaseRingMidRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: le02Peak,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  backgroundColor: colors.darkPurple,
};

// Screens

const connectScreenTheme: ConnectScreenTheme = {
  background: {image: le02BackgroundIntro},
  peakNameBackgroundColor: colors.white,
  groundLayerImage: le02LatticeIntroBottom,
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: colors.white,
};

const dabbingScreenTheme = {
  ...defaultTheme.dabbingScreenTheme,
  targetTemperatureStyle: {
    fontFamily: 'BigShouldersDisplay-Bold',
    ...textShadowStyle,
  },
  timeStatusStyle: {
    fontFamily: 'Roboto-Bold',
    ...textShadowStyle,
  },
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {image: le02HeatProfileListBackground},
  hoverBackgroundColor: colors.white,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  ...defaultTheme.heatProfileSelectScreenTheme,
  durationColor: colors.white,
  startTextStyle: {
    ...defaultTheme.heatProfileSelectScreenTheme.startTextStyle,
    fontFamily: 'Roboto-Bold',
    ...textShadowStyle,
  },
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,
  // General
  background: {
    image: le02BackgroundHomeScreen,
  },
  groundLayerImage: le02LatticeIntroBottom,

  // Status info
  batteryBarBackgroundColor: colors.white20,
  preserveIndicator: colors.white,
  batteryDisconnectedColor: colors.white,
  statusTitleTextColor: colors.white,

  // Metric info
  dataBackgroundGradientArray: [
    {color: '#85009B73', location: 0},
    {color: '#2B65FF00', location: 1},
  ],

  // Limited edition info
  limitedEditionText: {
    leftBorderGradientArray: [colors.white, colors.white],
    name: {color: colors.white, value: 'Indiglow'},
    numberString: {
      color: addOpacityToColorHex(colors.white, 0.8),
      value: '02',
    },
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {image: le02BackgroundIntro},
  groundLayerImage: le02LatticeIntroModal,
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/indiglow.mp4',
};

export const indiglowTheme: Theme = {
  ...defaultTheme,

  // General
  durationStyle,
  temperatureBigStyle,

  // Components
  heatProfileScreenBackgroundTheme,
  heatProfileCardTheme,
  heatProfileCoreImageTheme,
  peakImageTheme,
  tabBarTheme,

  // Screens

  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    ...(defaultTheme.controlCenterTheme.background && {
      background: defaultTheme.controlCenterTheme.background,
    }),
  },

  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  heatProfileSelectScreenTheme,
  heatProfileListScreenTheme,
  homeScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
};
