import {ProductMap} from '../lib/types/ProductMap';
import {blackPeachTheme} from './BlackPeachTheme';
import {defaultTheme} from './DefaultTheme';
import {desertTheme} from './DesertTheme';
import {flourishTheme} from './FlourishTheme';
import {guardianTheme} from './GuardianTheme';
import {indiglowTheme} from './IndiglowTheme';
import {opalTheme} from './OpalTheme';
import {stormTheme} from './StormTheme';
import {Theme} from './Theme';
import {whitePeachTheme} from './WhitePeachTheme';

export * from './BlackPeachTheme';
export * from './DefaultTheme';
export * from './DesertTheme';
export * from './FlourishTheme';
export * from './GuardianTheme';
export * from './IndiglowTheme';
export * from './OpalTheme';
export * from './WhitePeachTheme';
export * from './StormTheme';
export * from './Theme';

export const THEME_RECORD: ProductMap<Theme> = {
  Guardian: guardianTheme,
  Indiglow: indiglowTheme,
  Opal: opalTheme,
  OG: defaultTheme,
  Pearl: whitePeachTheme,
  Onyx: blackPeachTheme,
  Desert: desertTheme,
  Flourish: flourishTheme,
  Storm: stormTheme,
  Daybreak: defaultTheme,
};
