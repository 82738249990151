import {DrawerActions, useNavigation} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useSelector} from 'react-redux';

import {Connection} from '../contexts/connection';
import {Context, Feature} from '../contexts/context';
import {MenuTripleIcon} from '../icons';
import {useReferrals} from '../lib/hooks/useReferrals';
import {useTheme} from '../lib/hooks/useTheme';
import {appFlagsSelector} from '../lib/redux/slices/appFlagsSlice';
import {isOtaAvailableSelector} from '../lib/redux/slices/bleSlice';
import {colors} from '../styles';
import {Badge} from './Badge';

interface Props {
  themed?: boolean;
}

export const HamburgerMenuButton: React.FC<Props> = ({themed}) => {
  const {getFeatureIfEnabled} = Context.useContainer();

  const navigation = useNavigation();

  const {peak} = Connection.useContainer();
  const isOtaAvailable = useSelector(isOtaAvailableSelector);

  const {hasSeenRehash} = useSelector(appFlagsSelector);

  const referrals = useReferrals();

  const {navMenuIconColor} = useTheme();

  const rehash = getFeatureIfEnabled(Feature.Rehash);

  const connected = !!peak;

  const otaCount = connected && isOtaAvailable ? 1 : 0;

  const rehashCount = !rehash?.label
    ? 0
    : hasSeenRehash?.[rehash.label]
      ? 0
      : 1;

  const totalCount = referrals.unused.length + otaCount + rehashCount;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}>
        <MenuTripleIcon
          size={32}
          color={themed ? navMenuIconColor : colors.white}
        />

        {totalCount > 0 && (
          <Badge style={styles.badge}>
            <Text numberOfLines={1} style={styles.count}>
              {totalCount}
            </Text>
          </Badge>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {marginLeft: 6},
  badge: {
    zIndex: 10,
    position: 'absolute',
    bottom: -2,
    right: 0,
  },
  count: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    textAlign: 'center',
  },
});
