import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {MoodLight} from '../lib/types';
import {Theme} from '../themes';
import {MoodLightCardBackground} from './MoodLightCardBackground';

export type MoodLightCircleProps = {
  moodLight: MoodLight;
  theme: Theme;
  style?: StyleProp<ViewStyle>;
  size?: number;
  convertToBase64?: boolean;
  gradientStyle?: ViewStyle;
};

export const MoodLightCircle = ({
  moodLight,
  theme,
  style,
  size = 56,
  convertToBase64,
  gradientStyle,
}: MoodLightCircleProps) => {
  const {gradientShadeFunction} = theme.moodLightCardTheme;

  return (
    <MoodLightCardBackground
      width={size}
      height={size}
      moodLight={moodLight}
      gradientShadeFunction={gradientShadeFunction}
      borderRadius={1000}
      convertToBase64={convertToBase64}
      style={style}
      gradientStyle={gradientStyle}
    />
  );
};
