import {Constants} from '../../constants/Constants';

export const checkStringBytes = (
  str: string,
  type: 'device' | 'profile',
  isPup = false,
) => {
  str = str.toString();
  let len = str.length;
  for (let i = str.length; i--; ) {
    const code = str.charCodeAt(i);
    if (0xdc00 <= code && code <= 0xdfff) {
      i--;
    }
    if (0x7f < code && code <= 0x7ff) {
      len++;
    } else if (0x7ff < code && code <= 0xffff) {
      len += 2;
    }
  }
  // Current Atmosic FW has a crashing bug caused by names longer than 26 bytes.
  // Currently all Atmosic Peaks have PUP and we’re using that as a proxy for identifying them
  const maxLength = isPup
    ? Constants.PEAK_NAME_PUP_MAX_LENGTH
    : Constants.PEAK_NAME_MAX_LENGTH;
  return (
    (type === 'device' && len <= maxLength) ||
    (type === 'profile' && len <= Constants.PROFILE_NAME_MAX_LENGTH)
  );
};
