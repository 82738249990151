import {Formik, FormikHelpers, FormikProps} from 'formik';
import {MarketOptIn, UserRegisterDto} from 'puffco-api-axios-client';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  KeyboardAvoidingView,
  View,
} from 'react-native';
import {useDispatch} from 'react-redux';
import {useAsyncFn} from 'react-use';
import * as Yup from 'yup';

import {Alert} from '../../components/Alert';
import {AppleSignIn} from '../../components/AppleSignIn';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StyledButton} from '../../components/StyledButton';
import {CheckBoxContent, StyledCheckBox} from '../../components/StyledCheckBox';
import {
  StyledField,
  displayRightIconMessage,
  getRightIcon,
} from '../../components/StyledField';
import {Text} from '../../components/Typography';
import {Constants} from '../../constants';
import {ErrorMessages, Messages, Strings} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {ClosedEyeIcon, EyeIcon, LockIcon, MailIcon} from '../../icons';
import {userApi} from '../../lib/api/apis';
import {marketOptInAccess} from '../../lib/api/market-opt-in-access';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {
  useAppleLogin,
  useGoogleLogin,
  useLogin,
} from '../../lib/hooks/useLogin';
import {updateAppFlags} from '../../lib/redux/slices/appFlagsSlice';
import NavigationService from '../../lib/utils/NavigationService';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {isNavigatingTo, optionsToRoute, toHome} from '../../navigators/util';
import {analytics} from '../../services/analytics';
import {colors} from '../../styles';
import {
  GoogleSignIn,
  LogoHeader,
  LogoTitle,
  RightHeaderButton,
  TermsAndPrivacy,
} from '../components';

interface RegisterValues {
  email: string;
  password: string;
  marketOptIn: boolean;
}

const {
  SCREEN_HEIGHT: {GALAXY_S8, PX_785},
} = Constants;
const {height} = Dimensions.get('window');
const isSmall = height <= GALAXY_S8;
const isMedium = height <= PX_785;

export interface Props extends WithOptionalRedirect {
  appleCode?: string;
  googleCode?: string;
  email?: string;
}

interface ScreenProps extends RootStackScreenProps<typeof Screens.Register> {}

export const RegisterScreen: React.FC<ScreenProps> = ({navigation, route}) => {
  const [{loading}, create] = useAsyncFn(
    async (userRegisterDto: UserRegisterDto) => {
      try {
        const {data} = await userApi.register({userRegisterDto});
        return data;
      } catch (error: any) {
        if (error && error.name === 'Bad Request')
          Alert.alert(Strings.ERROR, error.message);
        else if (error) Alert.alert(Strings.ERROR, ErrorMessages.NETWORK_ERROR);

        throw error;
      }
    },
    [],
  );

  const [, login] = useLogin();

  const ref =
    React.useRef<
      FormikProps<{email: string; password: string; marketOptIn: boolean}>
    >(null);
  const dispatch = useDispatch();
  const [hidePassword, setHidePassword] = React.useState(true);
  const [google, loginWithGoogle] = useGoogleLogin();
  const [apple, loginWithApple] = useAppleLogin();

  const {email, redirect} = route.params ?? {};

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(ErrorMessages.REQUIRED_EMAIL)
      .email(ErrorMessages.INVALID_EMAIL)
      .test({
        name: 'checkEmail',
        test: async function (value) {
          if (!value || !value.includes('@') || !value.includes('.'))
            return true;

          const {data} = await userApi.validateFields({
            userValidateFieldsDto: {email: value},
          });

          if (data.email === 'ok') return true;

          const message =
            data.email === 'taken'
              ? 'Email already in use'
              : 'Invalid email address';

          return this.createError({message, path: 'email'});
        },
      }),
    password: Yup.string()
      .required(ErrorMessages.REQUIRED_PASSWORD)
      .min(8, ErrorMessages.PASSWORD_MINIMUM_ERROR)
      .matches(
        Constants.VALID_PASSWORD_REGEX,
        ErrorMessages.PASSWORD_FORMAT_ERROR,
      ),
    marketOptIn: Yup.boolean(),
  });

  useBackPress(() => {
    if (
      redirect &&
      (isNavigatingTo(Navigators.HomeDrawerNavigator, redirect) ||
        isNavigatingTo(Screens.Home, redirect))
    ) {
      navigation.navigate(Screens.Login, {
        redirect,
        email: ref.current?.values.email,
      });
    }
    return true;
  });

  const handleSubmit = async (
    values: RegisterValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    const email = values.email.trim();
    const password = values.password;

    await create({
      password,
      email,
      ...(values.marketOptIn && {marketOptIn: MarketOptIn.Email}),
    });

    analytics.trackEvent('sign up', {method: 'credentials'});

    if (values.marketOptIn)
      await marketOptInAccess.subscribeToEmail({email}).then(() => {
        analytics.trackEvent('optin', {content_type: 'email'});
      });

    dispatch(
      updateAppFlags({hasSeenNewUsername: false, hasSeenMarketOptIn: true}),
    );

    await login({email, password});

    NavigationService.instance()?.resetRoot({
      index: 0,
      routes: [
        optionsToRoute([
          Screens.AccountCreated,
          {email, redirect: redirect ?? toHome},
        ]),
      ],
    });

    actions.setSubmitting(false);
  };

  return (
    <Container>
      <InnerContainer>
        <TopRightContainer>
          <RightHeaderButton
            name={'SKIP'}
            redirection={redirect ?? toHome}
            textColor={colors.black50}
          />
        </TopRightContainer>

        <Formik
          initialValues={{
            email: email ?? '',
            password: '',
            marketOptIn: false,
          }}
          innerRef={ref}
          onSubmit={handleSubmit}
          validateOnMount
          validationSchema={RegisterSchema}>
          {({
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            isValid,
            submitForm,
            touched,
            values,
            errors,
          }) => (
            <FormContainer>
              <SpinnerContainer>
                {(isSubmitting ||
                  loading ||
                  google.loading ||
                  apple.loading) && (
                  <ActivityIndicator size="large" color={colors.black} />
                )}
              </SpinnerContainer>

              <LogoHeader style={{marginTop: 48}}>
                <LogoTitle>{Messages.CREATE_ACCOUNT_TWO}</LogoTitle>
              </LogoHeader>

              <BodyContainer>
                <FieldsContainer>
                  <FieldContainer>
                    <StyledField
                      icon={MailIcon}
                      borderColor={colors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      keyboardType="email-address"
                      leftIconColor={colors.black30}
                      iconPress={() =>
                        !!errors.email && setFieldValue('email', '')
                      }
                      rightIcon={getRightIcon(values.email, errors.email)}
                      rightIconColor={errors.email ? colors.red : colors.green}
                      rightIconMessage={displayRightIconMessage(
                        values.email,
                        errors.email,
                      )}
                      placeholder={'Email'}
                      isBordered={true}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <StyledField
                      icon={LockIcon}
                      borderColor={colors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      password={true && hidePassword}
                      onChangeText={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      leftIconColor={colors.black30}
                      placeholder={'Password'}
                      isBordered={true}
                      rightIcon={props =>
                        !hidePassword ? (
                          <ClosedEyeIcon
                            size={16}
                            containerSize={36}
                            {...props}
                          />
                        ) : (
                          <EyeIcon size={16} containerSize={36} {...props} />
                        )
                      }
                      rightIconColor={colors.black30}
                      rightIconCircled={false}
                      iconPress={() => setHidePassword(pass => !pass)}
                    />
                    {displayRightIconMessage(
                      values.password,
                      errors.password,
                      touched.password,
                    ) && (
                      <ErrorText>
                        {displayRightIconMessage(
                          values.password,
                          errors.password,
                          touched.password,
                        )}
                      </ErrorText>
                    )}
                  </FieldContainer>
                  <CheckboxContainer>
                    <StyledCheckBox
                      checked={values.marketOptIn}
                      onChange={value => setFieldValue('marketOptIn', value)}
                      style={{
                        alignItems: 'center',
                      }}>
                      <CheckBoxContent>
                        {Messages.MARKET_OPT_IN_PROMP}
                      </CheckBoxContent>
                    </StyledCheckBox>
                  </CheckboxContainer>
                </FieldsContainer>
                <ButtonContainer
                  style={
                    isSmall ? {flex: 0.5, justifyContent: 'center'} : undefined
                  }>
                  <StyledButton
                    title={Messages.CREATE_ACCOUNT_TWO}
                    disabled={!isValid || isSubmitting || loading}
                    onPress={submitForm}
                    style={{
                      backgroundColor: colors.black,
                      width: '100%',
                      height: isMedium ? 45 : 56,
                    }}
                    textStyle={{color: colors.white}}
                  />
                </ButtonContainer>

                <BlackTextContainer>
                  <Text
                    variant="s"
                    style={{
                      marginBottom: 15,
                      marginTop: 0,
                      textAlign: 'center',
                    }}>
                    Already have an account?{' '}
                    <UnderlinedSignInText
                      variant="s"
                      onPress={() => {
                        navigation.navigate(Screens.Login, {
                          redirect,
                          email: ref.current?.values.email,
                        });
                      }}>
                      Sign in
                    </UnderlinedSignInText>
                  </Text>
                </BlackTextContainer>

                <ButtonContainer
                  style={
                    isSmall ? {flex: 1, justifyContent: 'center'} : undefined
                  }>
                  <FieldContainer>
                    <AppleSignIn
                      title={Messages.CONTINUE_WITH_APPLE}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      screen="register"
                      onSubmit={loginWithApple}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <GoogleSignIn
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      screen="register"
                      onSubmit={loginWithGoogle}
                    />
                  </FieldContainer>
                </ButtonContainer>
              </BodyContainer>
              <BottomContainer>
                <TermsAndPrivacy />
              </BottomContainer>
            </FormContainer>
          )}
        </Formik>
      </InnerContainer>
    </Container>
  );
};

const ErrorText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
  paddingLeft: 10,
  color: colors.gray67,
  marginTop: 8,
});

const BlackTextContainer = styled(View)({
  ...(isSmall
    ? {paddingBottom: 0, flex: 0.3, justifyContent: 'center'}
    : {paddingBottom: 8}),
});

const BodyContainer = styled(View)({
  flex: 1,
  paddingTop: isSmall ? 8 : 24,
  width: '100%',
  alignItems: 'center',
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: 24,
  zIndex: 2,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: isSmall ? 8 : 16,
  paddingBottom: isSmall ? 8 : 16,
  width: '100%',
});

const Container = styled(KeyboardAvoidingView)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flex: 1,
});

const InnerContainer = styled(SafeAreaView)({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: colors.white,
});

const FieldContainer = styled(View)({
  paddingBottom: 8,
  width: '100%',
});

const CheckboxContainer = styled(View)({
  backgroundColor: colors.veryLightGray,
  padding: 10,
  borderRadius: 4,
  marginLeft: 6,
  marginRight: -6,
  marginTop: isSmall ? 0 : 16,
});

const FieldsContainer = styled(View)({
  width: '100%',
});

const FormContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
  paddingHorizontal: 24,
  flex: 1,
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '30%',
  zIndex: 2,
});

const UnderlinedSignInText = styled(Text)({
  textDecorationLine: 'underline',
});

const TopRightContainer = styled(View)({
  position: 'relative',
  width: '100%',
  top: 24,
  paddingRight: 12,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  zIndex: 2,
});
