import {BaseRehashDto} from 'puffco-api-axios-client';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {ResponsiveText} from '../../../components/ResponsiveText';
import {StyledButton} from '../../../components/StyledButton';
import {SwitchableBackground} from '../../../components/SwitchableBackground';
import {Screens} from '../../../constants/navigation';
import {formatText} from '../../../lib/utils';
import type {RehashStoryScreenProps} from '../../../navigators/RehashStoryNavigator';
import {colors} from '../../../styles';
import {Underlined} from '../components';
import {RehashScreen} from '../components/RehashScreen';

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashCreateAccount> {}

export type Props = BaseRehashDto;

const theme = {
  background: {color: colors.black},
};

export const CreateAccount: React.FC<ScreenProps> = ({route, navigation}) => {
  const {title, subtitle} = route.params;

  return (
    <RehashScreen as={SwitchableBackground} background={theme.background}>
      <View style={styles.container}>
        <ResponsiveText size={80} style={[styles.text, styles.title]}>
          {formatText(title, text => (
            <Underlined
              size={76}
              lineHeight={80}
              underlineHeight={8}
              {...{text}}
            />
          ))}
        </ResponsiveText>

        <StyledButton
          style={styles.button}
          textStyle={styles.buttonText}
          onPress={() => {
            navigation.navigate(Screens.Register);
          }}
          title={subtitle}
        />
      </View>
    </RehashScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '8%',
  },
  title: {
    paddingBottom: '8%',
    textAlign: 'right',
  },
  text: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    lineHeight: 84,
  },
  button: {
    width: 'auto',
    alignSelf: 'flex-end',
    paddingHorizontal: 24,
    height: 56,
    backgroundColor: '#F6FF00',
    fontFamily: 'Roboto-Medium',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    letterSpacing: 0.48,
  },
});
