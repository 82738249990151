import {MaterialTopTabNavigationOptions} from '@react-navigation/material-top-tabs';
import {useRef} from 'react';
import {Animated, GestureResponderEvent, StyleSheet} from 'react-native';

import {Constants} from '../../constants';
import {colors} from '../../styles';

const DISTANCE_FROM_HEADER = 20;

export const useTabBarAnimation = () => {
  const tabBarTranslateY = useRef(
    new Animated.Value(DISTANCE_FROM_HEADER),
  ).current;

  const initialTouchY = useRef(0);
  const currentTouchY = useRef(0);
  const initialTouchX = useRef(0);
  const currentTouchX = useRef(0);

  // Handle touch start to capture initial touch positions
  const onTouchStart = (event: GestureResponderEvent) => {
    initialTouchY.current = event.nativeEvent.pageY;
    initialTouchX.current = event.nativeEvent.pageX;
  };

  // Handle touch end and calculate movement to determine tab bar animation
  const onTouchMove = (event: GestureResponderEvent) => {
    currentTouchY.current = event.nativeEvent.pageY;
    currentTouchX.current = event.nativeEvent.pageX;

    const deltaY = currentTouchY.current - initialTouchY.current;
    const deltaX = currentTouchX.current - initialTouchX.current;

    // If horizontal move > vertical move, do nothing (it's a swipe)
    if (Math.abs(deltaX) > Math.abs(deltaY)) return;

    if (Math.abs(deltaY) < 5) return;

    // Animate tab bar after movement ends
    const toValue = deltaY > 0 ? DISTANCE_FROM_HEADER : -64;

    Animated.timing(tabBarTranslateY, {
      toValue,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const screenOptions: MaterialTopTabNavigationOptions = {
    tabBarStyle: {
      ...styles.tabBar,
      marginTop: tabBarTranslateY,
    },
    tabBarContentContainerStyle: {
      display: 'flex',
      justifyContent: 'center',
    },
    tabBarGap: 4,
    tabBarIndicatorStyle: styles.indicator,
    tabBarBounces: false,
    tabBarScrollEnabled: false, // Disable scrolling to ensure all tabs are visible
    tabBarItemStyle: {
      width: 'auto',
    },
  };

  const animation = {onTouchStart, onTouchMove};

  return {animation, screenOptions};
};

const styles = StyleSheet.create({
  tabBar: {
    width: Constants.DIMENSIONS.WIDTH,
    backgroundColor: colors.black,
  },
  indicator: {
    display: 'none',
  },
});
