import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const MenuTripleIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M3 18H12" />
    <Path d="M3 12H21" />
    <Path d="M3 6H21" />
  </Icon>
);
