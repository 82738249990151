import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const CloseIcon: React.FC<IconProps> = props => (
  <Icon containerSize={40} {...props}>
    <Path d="M6 6L18 18" />
    <Path d="M18 6L6 18" />
  </Icon>
);
