import React from 'react';
import {LayoutChangeEvent, View} from 'react-native';
import {Line, Svg} from 'react-native-svg';

import styled from '../../lib/utils/styled';
import {colors as appColors, centerStyle, fillStyle} from '../../styles';
import {Theme} from '../../themes';
import {HeatProfileCoreImage} from '../HeatProfileCoreImage';
import {Image} from '../ImageWithFilter';
import {Ring} from './Ring';
import {TINTABLE_HEAT_GLOW_IMAGE_SOURCES} from './TintableHeatGlowImageSources';

export type GlowingHaloProps = {
  colors: string[];
  theme: Theme;
  seconds: number;
  innerComponent?: JSX.Element;
  marginTop?: number;
  glowWidthHeight?: number;
  onCoreImageLayout?: (layout: {height: number; y: number}) => void;
};

export const GLOW_WD_HT_DEFAULT = 284;
const OPAL_INNER_MULTIPLIER = 0.72;
const ringRotationMatchingAnimation = [0, 0, 90, -220, -220, -240, -240];
const heatGlowImgRotationToAlignWithRing = [0, 0, -90, -130, -90, -85, -60];

const makeRange = (count: number) => {
  const results = [];
  for (let i = 0; i < Math.round(count); i++) {
    results.push(i * (360 / Math.round(count)));
  }

  return results;
};

export const GlowingHalo = (props: GlowingHaloProps) => {
  const {
    colors,
    theme,
    seconds,
    innerComponent = null,
    marginTop = 0,
    glowWidthHeight = GLOW_WD_HT_DEFAULT,
    onCoreImageLayout,
  } = props;
  const {heatProfileCoreImageTheme, primaryColor, dabTickTheme} = theme;
  const ticks = makeRange(seconds);
  const scaleRatio = glowWidthHeight / GLOW_WD_HT_DEFAULT;
  const tickContainerXYPos = -142 * scaleRatio;
  const tickContainerViewBox = `${tickContainerXYPos} ${tickContainerXYPos} ${glowWidthHeight} ${glowWidthHeight}`;
  const tickInnerPixelPos = -109.99 * scaleRatio;
  const tickOutterPixelPos = -112.7 * scaleRatio;

  const ringRotation = () => {
    return ringRotationMatchingAnimation[colors.length];
  };
  const heatGlowImageSliceRotation = () => {
    return heatGlowImgRotationToAlignWithRing[colors.length];
  };

  const onLayout = (evt: LayoutChangeEvent) => {
    if (onCoreImageLayout) {
      // React Native and React Native Web seem to have target in different places
      const target = (evt.target ||
        (evt.nativeEvent as any).target) as unknown as View;
      setTimeout(() => {
        target.measure((_x, _y, _width, height, _pageX, pageY) => {
          onCoreImageLayout({height, y: pageY});
        });
      }, 50);
    }
  };

  return (
    <Container
      style={{
        marginTop: marginTop,
        width: glowWidthHeight,
        height: glowWidthHeight,
      }}>
      <GlowRingContainer
        style={{
          width: '100%',
          height: '100%',
          transform: [{rotate: `${ringRotation()}deg`}],
        }}>
        {colors.map((color: string, index: number) => (
          <Image
            key={`heat-glow-${index}-${color}`}
            source={TINTABLE_HEAT_GLOW_IMAGE_SOURCES[colors.length - 1][index]}
            style={{
              tintColor: color,
              width: '100%',
              height: '100%',
              zIndex: 0,
              transform: [{rotate: `${heatGlowImageSliceRotation()}deg`}],
              position: 'absolute',
            }}
            resizeMode="center"
          />
        ))}
        <Ring
          colors={colors.length > 1 ? colors : [appColors.white]}
          glowWidthHeight={glowWidthHeight * 0.99}
        />
      </GlowRingContainer>

      <Svg
        height={glowWidthHeight * 0.99}
        width={glowWidthHeight * 0.99}
        style={{position: 'absolute'}}
        viewBox={tickContainerViewBox}>
        {ticks.map(tick => (
          <Line
            key={tick}
            x1="0"
            y1={tickInnerPixelPos}
            x2="0"
            y2={tickOutterPixelPos}
            stroke={
              dabTickTheme?.color ||
              heatProfileCoreImageTheme?.tickColor ||
              primaryColor
            }
            strokeWidth="1.5"
            strokeOpacity="0.8"
            rotation={tick}
          />
        ))}
      </Svg>
      <HaloInner
        style={{
          height: glowWidthHeight * OPAL_INNER_MULTIPLIER,
          width: glowWidthHeight * OPAL_INNER_MULTIPLIER,
          padding: glowWidthHeight * 0.05,
        }}>
        {heatProfileCoreImageTheme && (
          <OpalContainer>
            <HeatProfileCoreImage
              theme={heatProfileCoreImageTheme}
              colors={colors}
              style={{
                ...fillStyle,
                borderRadius: glowWidthHeight * OPAL_INNER_MULTIPLIER,
              }}
              onLayout={onLayout}
            />
          </OpalContainer>
        )}
        {innerComponent}
      </HaloInner>
    </Container>
  );
};

const Container = styled(View)({
  ...centerStyle,
});

const GlowRingContainer = styled(View)({
  ...centerStyle,
  position: 'absolute',
});

const HaloInner = styled(View)({
  ...centerStyle,
  position: 'absolute',
  zIndex: 1,
});

const OpalContainer = styled(View)({
  ...centerStyle,
  ...fillStyle,
  position: 'absolute',
});
