import React from 'react';
import {
  ImageSourcePropType,
  ImageStyle,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {ImgBackground} from '../../components/ImgBackground';
import styled from '../../lib/utils/styled';
import {colors} from '../../styles';

export type Props = {
  background: ImageSourcePropType;
  title: string;
  body?: string;
  onButtonClick?: () => void;
  buttonTitle?: string;
  disableButton?: boolean;
  headerStyle?: TextStyle;
  bodyStyle?: TextStyle;
  hasMaybeLater?: boolean;
  onMaybeButtonClick?: () => void;
  aboveHeaderString?: string;
  aboveHeaderStyle?: TextStyle;
  innerStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  style?: ViewStyle;
  backButton?: () => void;
  isTermsAndPrivacy?: boolean;
  isWeb?: boolean;
  contentStyle?: ViewStyle;
  containerStyle?: ViewStyle;
};

export const ConfirmationScreen = ({
  background,
  title,
  body,
  onButtonClick,
  buttonTitle,
  children,
  disableButton,
  headerStyle,
  bodyStyle,
}: React.PropsWithChildren<Props>) => {
  return (
    <ImgBackground source={background}>
      <Container>
        <InnerContainer>
          <Header style={headerStyle}>{title}</Header>
          <Body style={bodyStyle}>{body}</Body>
          {children}
          <View style={{marginBottom: 0}}>
            {!disableButton && (
              <Button onPress={onButtonClick}>
                <ButtonText>{buttonTitle || 'Got it!'}</ButtonText>
              </Button>
            )}
          </View>
        </InnerContainer>
      </Container>
    </ImgBackground>
  );
};

const Container = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: '100%',
});

const InnerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Header = styled(Text)({
  textAlign: 'center',
  color: colors.baseText,
  fontFamily: 'BigShouldersDisplay-Medium',
  fontSize: 42,
  textTransform: 'uppercase',
  letterSpacing: 0.75,
  marginBottom: 26,
  lineHeight: 50,
});

const Body = styled(Text)({
  textAlign: 'center',
  color: colors.baseText,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  marginHorizontal: 48,
  letterSpacing: 0.25,
  lineHeight: 26,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: colors.baseText,
  borderRadius: 25,
  height: 50,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginHorizontal: 48,
  marginBottom: 75,
});

export const ButtonText = styled(Text)({
  textAlign: 'center',
  color: colors.black,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  fontSize: 16,
  textTransform: 'uppercase',
  letterSpacing: 0.75,
});
