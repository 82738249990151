import {EmitterEvent, Handler} from '../types';

export class SimpleEmitter<T> {
  private handler: Handler<T> = () => null;

  emit(t: T | EmitterEvent) {
    this.handler(t);
  }

  setHandler(handler: Handler<T>) {
    this.handler = handler;
  }
}
