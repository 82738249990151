import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const StatsIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M15 19L15 14" />
    <Path d="M11 19L11 12" />
    <Path d="M19 19V10" />
    <Path d="M7 19V16" />
    <Path d="M4 13L10.3385 6.66153C10.7107 6.28927 11.3079 6.26939 11.7041 6.61607L14.2959 8.88393C14.6921 9.23061 15.2893 9.21073 15.6615 8.83846L20 4.5" />
  </Icon>
);
