import React from 'react';

import {analytics} from '../../services/analytics';
import {MoodLightController} from './MoodLightControllerScreen';

export type Props = Record<string, never>;

export const MoodLightCreateScreen = () => {
  const onSave = React.useCallback(() => {
    analytics.trackEvent('mood light create', {});
  }, []);

  return <MoodLightController {...{onSave}} />;
};
