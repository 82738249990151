import React from 'react';
import {View, ViewProps} from 'react-native';
import Animated, {SharedValue, useAnimatedProps} from 'react-native-reanimated';
import Svg, {Circle, Path} from 'react-native-svg';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

interface Props extends ViewProps {
  percentage: SharedValue<number>;
  color: string;
}

const AnimatedPath = Animated.createAnimatedComponent(Path);

export const LoadingHalo = ({percentage, color, ...rest}: Props) => {
  // From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
  function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) {
    'worklet';
    const angleInRadians = ((angleInDegrees - 270) * Math.PI) / 180.0; // -270 to start progress under peak. Changed from 90.

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  function describeArc(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number,
  ) {
    'worklet';
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(' ');

    return d;
  }

  const animatedProps = useAnimatedProps(() => ({
    d: describeArc(50, 50, 48, 1, 360 * percentage.value),
  }));

  return (
    <HaloContainer {...rest}>
      <View
        style={{
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
        <Svg
          height="100%"
          width="100%"
          viewBox="0 0 100 100"
          style={{alignSelf: 'center'}}>
          <Circle
            r={48}
            cx={50}
            cy={50}
            fill={'transparent'}
            stroke={colors.lightMediumGray}
            strokeWidth="0.5"
            strokeDasharray=".5 1"
            strokeOpacity={0.3}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={7}
            fill="none"
            strokeOpacity={0.01}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={6}
            fill="none"
            strokeOpacity={0.02}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={5}
            fill="none"
            strokeOpacity={0.03}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={4}
            fill="none"
            strokeOpacity={0.04}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={3}
            fill="none"
            strokeOpacity={0.05}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={2}
            fill="none"
            strokeOpacity={0.06}
          />
          <AnimatedPath
            {...{animatedProps}}
            stroke={color}
            strokeWidth={1}
            fill="none"
            strokeOpacity={1.0}
          />
        </Svg>
      </View>
    </HaloContainer>
  );
};

const HaloContainer = styled(View)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  padding: 15,
});
