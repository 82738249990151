import React from 'react';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';

import {logoInverted} from '../assets/images';
import {addOpacityToColorHex} from '../lib/utils/colors';
import {colors} from '../styles';

export const MoodLightIcon = ({style = {}, iconStyle = {}, ...rest}) => (
  <View style={[defaultStyle, style]} {...rest}>
    <Image
      source={logoInverted}
      style={{width: '50%', height: '50%', ...StyleSheet.flatten(iconStyle)}}
    />
  </View>
);

const defaultStyle: ViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: addOpacityToColorHex(colors.white, 0.3),
  borderRadius: 50,
  height: 50,
  width: 50,
  padding: 0,
};
