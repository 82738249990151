import {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import React from 'react';
import {ImageBackground, View} from 'react-native';

import {Constants} from '../../constants';
import {Screens} from '../../constants/navigation';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useTheme} from '../../lib/hooks/useTheme';
import NavigationService from '../../lib/utils/NavigationService';
import {colors} from '../../styles';
import {StyledTabBarInner} from './StyledTabBarInner';

const {IS_WEB} = Constants;

export const StyledTabBar: React.FC<BottomTabBarProps> = props => {
  const [peak, setPeak] = React.useState(props.state.index === 0);
  const insets = useSafeArea();
  const {backgroundColor, heatProfileListNavBg} = useTheme().tabBarTheme;
  const currentActiveRouteName = NavigationService.getCurrentRouteName();

  React.useEffect(() => {
    setPeak(props.state.index === 0);
  }, [props.state.index]);

  return heatProfileListNavBg &&
    currentActiveRouteName === Screens.HeatProfileList ? (
    <View
      style={{
        position: 'absolute',
        bottom: IS_WEB ? -1 * insets.bottom : -20,
        left: 0,
        right: 0,
      }}>
      <ImageBackground
        source={heatProfileListNavBg}
        style={{
          width: '100%',
          height: IS_WEB ? (insets.bottom > 0 ? 150 : undefined) : 86,
        }}
        resizeMode={'stretch'}>
        <StyledTabBarInner
          hideTopBorder={
            heatProfileListNavBg &&
            currentActiveRouteName === Screens.HeatProfileList
              ? true
              : false
          }
          peak={peak}
          setPeak={setPeak}
        />
      </ImageBackground>
    </View>
  ) : (
    <View
      style={{
        backgroundColor: peak ? colors.invisible : backgroundColor,
        paddingBottom: insets.bottom,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}>
      <StyledTabBarInner peak={peak} setPeak={setPeak} />
    </View>
  );
};
