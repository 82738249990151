import React from 'react';

import {ManualPdf} from '../../components/ManualPdf';
import {Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import type {HomeEmulatedDrawerStackScreenProps} from '../../navigators/HomeDrawerNavigator';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.CleaningManual
>;

export const CleaningManualScreen = ({route}: ScreenProps) => {
  useAdaptiveSafeArea();

  const source = route.params.source;

  return <ManualPdf source={source} />;
};
