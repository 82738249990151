import {MoodLightCategory} from 'puffco-api-axios-client';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {Constants} from '../../constants';
import {moodlightApi} from '../api/apis';
import {setExclusiveMoodLights} from '../redux/slices/moodLightSlice';
import {userSelector} from '../redux/slices/userSlice';
import {ExclusiveMoodLight} from '../types';
import {convertAnimArrayToNumArray, convertHexStringToNumArray} from '../utils';
import {convertHexArrayToNumArray} from '../utils/colors';

export function useGetAndStoreExclusiveMoodLights(doesAutoGet = true) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [state, get] = useAsyncFn(async () => {
    const {data} = await moodlightApi.getActiveExclusiveMoodLights();

    dispatch(
      setExclusiveMoodLights(
        data.map<ExclusiveMoodLight>(
          ({
            cardMedia,
            fullscreenMedia,
            tableColor: tableColorString,
            colorArray,
            offsetArray,
            animationArray,
            ...rest
          }) => {
            const tableColorArr = convertHexStringToNumArray(tableColorString);

            return {
              ...rest,
              fullscreenMedia: {},
              category: MoodLightCategory.Exclusive,
              cardImageUrl: cardMedia.originalUrl,
              fullScreenImageUrl: fullscreenMedia.originalUrl,
              rawMoodLight: {
                tableColor: {
                  brightness:
                    tableColorArr[Constants.TABLE_COLOR_BYTES.BRIGHTNESS],
                  speed: tableColorArr[Constants.TABLE_COLOR_BYTES.SPEED],
                  lumaAnimation:
                    tableColorArr[Constants.TABLE_COLOR_BYTES.LUMA_ANIMATION],
                  phaseLockNumerator:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR
                    ],
                  phaseLockDenominator:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR
                    ],
                  arrayIndices:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
                    ],
                  colorArrayLength:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH
                    ],
                },
                colorArray: convertHexArrayToNumArray(colorArray),
                offsetArray,
                ...(animationArray && {
                  animationArray: convertAnimArrayToNumArray(animationArray),
                }),
              },
            };
          },
        ),
      ),
    );

    return data;
  }, [user]);

  React.useEffect(() => {
    if (!doesAutoGet) return;
    get();
  }, [doesAutoGet, get]); // Pull fresh exclusives on login

  return [state, get] as const;
}
