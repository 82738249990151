import React from 'react';
import {ScrollView, Text} from 'react-native';

import {HeaderSafeAreaView} from '../../components/HeaderSafeAreaView';
import {LayeredBackground} from '../../components/LayeredBackground';
import {MoodLightFadedText} from '../../components/MoodLightFadedText';
import {Constants} from '../../constants';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {ChevronLeftIcon} from '../../icons';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useDisconnectGuard} from '../../lib/hooks/useDisconnectGuard';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';
import {defaultTheme} from '../../themes';

export interface Props {
  exclusive?: {
    description: string;
    fullScreenImageUrl: string;
    name: string;
  };
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.ExclusiveMood> {}

export const ExclusiveMoodScreen = ({route, navigation}: ScreenProps) => {
  useDisconnectGuard();
  useAdaptiveSafeArea();

  const description = route.params?.exclusive?.description;
  const fullScreenImageUrl = route.params?.exclusive?.fullScreenImageUrl;
  const name = route.params?.exclusive?.name;

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'EXCLUSIVE MOOD',
      headerTitleAlign: 'center',
      headerTitleStyle: defaultTheme.navMenuTitleStyle,
      headerLeft: () => (
        <ChevronLeftIcon
          color={defaultTheme.navMenuIconColor}
          onPress={() => navigation.goBack()}
        />
      ),
    });
  }, [navigation]);

  return (
    <LayeredBackground
      backgrounds={[
        {viewProps: {style: {backgroundColor: colors.black}}}, // Default in case image loads slowly or errors
        {
          imageBackgroundProps: {
            source: {
              uri: fullScreenImageUrl,
            },
          },
        },
        {
          linearGradientProps: {
            colors: [
              addOpacityToColorHex(colors.black, 0.0),
              addOpacityToColorHex(colors.black, 1.0),
            ],
            locations: [0.0, 1.0],
            useAngle: true,
            angle: 180,
            angleCenter: {x: 0.5, y: 0.5},
            style: {opacity: 0.2},
          },
        },
      ]}>
      <HeaderSafeAreaView
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <ExclusiveName>{name}</ExclusiveName>

        <MoodLightFadedText>{Strings.EXCLUSIVE}</MoodLightFadedText>

        <ScrollViewBody
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
          }}>
          <Description>{description}</Description>
        </ScrollViewBody>

        <Disclaimer>{Strings.EXCLUSIVE_DISCLAIMER}</Disclaimer>
      </HeaderSafeAreaView>
    </LayeredBackground>
  );
};

const Description = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  letterSpacing: 0.75,
  color: colors.white,
  lineHeight: 29,
  textAlign: 'center',
  fontWeight: '400',
});

const Disclaimer = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 13,
  letterSpacing: 0.38,
  color: colors.white,
  lineHeight: 15,
  marginBottom: 20,
  fontWeight: '400',
});

const ExclusiveName = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontSize: 20,
  letterSpacing: 0.38,
  color: colors.white,
  textTransform: 'uppercase',
  lineHeight: 25,
  marginTop: Constants.IS_NATIVE_ANDROID ? 30 : 10,
  fontWeight: '400',
});

const ScrollViewBody = styled(ScrollView)({
  display: 'flex',
  flex: 1,
  width: '100%',
  paddingHorizontal: 32,
  marginVertical: 20,
});
