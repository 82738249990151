import React from 'react';
import {Text, TextStyle, View} from 'react-native';

import {VaporModeText} from '../../components/VaporModeText';
import {Vapor} from '../../lib/types';
import styled from '../../lib/utils/styled';
import {colors} from '../../styles';
import {profileDurationTitleStyle as durationStyle} from '../../styles';
import {AppText} from '../AppText';
import {ProfileTemperature} from '../ProfileTemperature';

type DabberInnerProps = {
  currentTemp: number;
  temperatureUnit: string;
  tempFontSize: number;
  dabbingScreenActiveText: string;
  temperatureBigStyle: TextStyle | undefined;
  durationColor: string;
  timeStatusStyle: TextStyle | undefined;
  primaryTextColor: string;
  timeTextPrompt: string | null;
  vapor: Vapor;
  isVaporEnabled: boolean;
};

export const DabberInner = React.memo(function DabberInner({
  currentTemp,
  temperatureUnit,
  tempFontSize,
  dabbingScreenActiveText,
  temperatureBigStyle,
  durationColor,
  timeStatusStyle,
  primaryTextColor,
  timeTextPrompt,
  vapor,
  isVaporEnabled,
}: DabberInnerProps) {
  return currentTemp ? (
    <InnerContainer>
      <View style={{height: durationStyle.fontSize + 4, width: 5}} />
      <ProfileTemperature
        style={{
          paddingLeft: 14,
          paddingTop: 16,
          paddingBottom: 15,
        }}
        temperature={currentTemp}
        temperatureUnit={temperatureUnit}
        textStyle={{
          fontSize: tempFontSize,
          color: dabbingScreenActiveText,
          ...temperatureBigStyle,
        }}
      />
      <TimeStatusText
        style={{
          color: durationColor,
          fontWeight: '400',
          ...timeStatusStyle,
        }}>
        {timeTextPrompt}
      </TimeStatusText>
      {isVaporEnabled && (
        <VaporModeContainer>
          <VaporModeText {...{vapor}} />
        </VaporModeContainer>
      )}
    </InnerContainer>
  ) : (
    <InnerContainer>
      <NoChamberText style={{color: primaryTextColor}}>
        Chamber Not Detected
      </NoChamberText>
    </InnerContainer>
  );
});

const InnerContainer = styled(View)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 1,
});

const TimeStatusText = styled(Text)({
  ...durationStyle,
  includeFontPadding: false,
  marginTop: -4,
  height: durationStyle.fontSize + 4,
});

const NoChamberText = styled(AppText)({
  fontSize: 20,
  fontFamily: 'BigShouldersDisplay-Bold',
  letterSpacing: 0.6,
});

const VaporModeContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 10,
  paddingVertical: 4,
  backgroundColor: colors.black,
  borderRadius: 100,
  marginTop: 6,
});
