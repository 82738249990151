import React from 'react';
import {Path} from 'react-native-svg';

import {colors} from '../styles';
import {Icon, IconProps} from './Icon';

export const PlusIcon: React.FC<IconProps> = props => (
  <Icon strokeWidth={0.5} {...props}>
    <Path
      fill={props.color ?? colors.white}
      d="M12.78 3.75C12.78 3.33579 12.4442 3 12.03 3C11.6158 3 11.28 3.33579 11.28 3.75V11.2197H3.75C3.33579 11.2197 3 11.5555 3 11.9697C3 12.3839 3.33579 12.7197 3.75 12.7197H11.28L11.28 20.25C11.28 20.6642 11.6158 21 12.03 21C12.4442 21 12.78 20.6642 12.78 20.25L12.78 12.7197L20.25 12.7197C20.6642 12.7197 21 12.3839 21 11.9697C21 11.5555 20.6642 11.2197 20.25 11.2197L12.78 11.2197V3.75Z"
    />
  </Icon>
);
