import {ConnectedDevice, Pikaparam, Uuids} from 'pikaparam';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {bleLog} from '../../utils/Logger';
import {sleep} from '../../utils/sleep';
import {ConnectionMetadata} from './ConnectionMetadata';

export interface OtaDeviceOptions {
  device: ConnectedDevice;
  pikaparam: Pikaparam;
  metadata: ConnectionMetadata;
}

export class OtaDevice {
  public readonly peripheralId: string;
  public readonly name?: string;
  public readonly metadata: ConnectionMetadata;

  private readonly pikaparam: Pikaparam;

  constructor({device, pikaparam, metadata}: OtaDeviceOptions) {
    this.peripheralId = device.id;
    this.name = device.name;
    this.pikaparam = pikaparam;
    this.metadata = metadata;
  }

  get deviceState() {
    return this.pikaparam.ota.deviceState;
  }

  get deviceStateObs() {
    return this.pikaparam.ota.deviceStateObs;
  }

  get flashState() {
    return this.pikaparam.ota.flashState;
  }

  get flashStateObs() {
    return this.pikaparam.ota.flashStateObs;
  }

  get serialNumber() {
    return this.pikaparam.ota.serialNumber;
  }

  public isInBootloaderMode(): boolean {
    return this.pikaparam.ota.isConnectedLoader;
  }

  public async rebootToBootloader(): Promise<string> {
    bleLog.info('Reboot to bootloader');

    const name = await this.pikaparam.ota.enterBootloader();

    // We have to wait for the device to restart
    // TODO: isn't there a better way to know when it restarted?
    // Broadcast is supported only by Peach
    await sleep(5000);

    return name;
  }

  public getNormalizedSerialNumber() {
    const name = this.name;

    if (!name) return this.serialNumber;

    // Pikachu doesn't expose the serial number while in bootloader
    // state, so we use a fake serial number just like the debrick tool.
    if (Uuids.silabsOuis.some(prefix => name.startsWith(prefix)))
      return '21??-???0000-00000';

    return this.serialNumber;
  }

  public async flash(data: ArrayBuffer): Promise<void> {
    await this.pikaparam.ota.flash(data);
  }

  public subscribeToFlashProgress(callback: (value: number) => void) {
    return this.pikaparam.ota.flashProgressObs
      .pipe(
        map(value => Math.round(value ?? 0)),
        distinctUntilChanged(),
      )
      .subscribe(callback);
  }

  public async disconnect() {
    bleLog.info('Ota device disconnect.');
    this.pikaparam.conn.disconnect();
    await this.pikaparam.ota.awaitDisconnect();
  }
}
