import React from 'react';
import {Dimensions, Text, TextStyle, View} from 'react-native';

import {Constants} from '../../constants';
import styled from '../../lib/utils/styled';
import {profileTitleStyle} from '../../styles';
import {AppText} from '../AppText';

const TITLE_WIDTH = Dimensions.get('window').width * 0.8;

type DabberHeaderProps = {
  paddingTop: number;
  primaryTextColor: string;
  titlePaddingTop: number;
  profileName: string;
  fadedTextColor: string;
  targetTemperatureStyle: TextStyle | undefined;
  targetTempTextPrompt: string;
};

export const DabberHeader = React.memo(function DabberHeader({
  paddingTop,
  primaryTextColor,
  titlePaddingTop,
  profileName,
  fadedTextColor,
  targetTemperatureStyle,
  targetTempTextPrompt,
}: DabberHeaderProps) {
  return (
    <HeaderContainer
      style={{
        paddingTop,
      }}>
      <TitleContainer>
        <Title
          style={{
            color: primaryTextColor,
            paddingTop: titlePaddingTop,
          }}
          numberOfLines={Constants.IS_WEB ? 2 : 0}>
          {profileName.toUpperCase()}
        </Title>
      </TitleContainer>
      <TargetTemperatureText
        style={{
          color: fadedTextColor,
          ...targetTemperatureStyle,
        }}>
        {targetTempTextPrompt}
      </TargetTemperatureText>
    </HeaderContainer>
  );
});

const HeaderContainer = styled(View)({
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
});

const TitleContainer = styled(View)({
  width: TITLE_WIDTH,
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(AppText)({
  ...profileTitleStyle,
  zIndex: 1,
});

const TargetTemperatureText = styled(Text)({
  fontSize: 16,
  fontFamily: 'BigShouldersDisplay-Medium',
  letterSpacing: 0.6,
  includeFontPadding: false,
  fontWeight: '400',
});
