import {Text} from 'react-native';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

export const HeadingText = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 32,
  letterSpacing: 0.32,
  textTransform: 'uppercase',
  color: colors.white,
});
