// TODO: Replace with the typography text component
import React from 'react';
import {Dimensions, Text, TextProps} from 'react-native';

import {responsive} from '../lib/utils';

const {width} = Dimensions.get('window');

interface Props extends TextProps {
  size?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

const breakpoints = [
  {name: 'xs', width: 0},
  {name: 'sm', width: 375},
  {name: 'md', width: 425},
  {name: 'lg', width: 768},
] as const;

type Breakpoint = (typeof breakpoints)[number];
type ValuedBreakpoint = Breakpoint & {
  value?: number;
};

const choose = (breakpoints: ValuedBreakpoint[]) => {
  let breakpoint = breakpoints[0];

  for (const b of breakpoints) {
    if (b.value !== undefined && width >= b.width) {
      breakpoint = b;
    } else {
      break;
    }
  }

  return breakpoint;
};

export const ResponsiveText: React.FC<Props> = ({
  size = 16,
  children,
  style,
  ...props
}) => {
  const fontSize = React.useMemo(() => {
    const mapped = breakpoints.map(({name, ...rest}) => ({
      name,
      ...rest,
      value: props[name],
    })) as ValuedBreakpoint[];

    const foundValue = choose(mapped);

    return foundValue.value ?? responsive(size);
  }, [props.xs, props.sm, props.md, props.lg, size]);

  return (
    <Text
      style={[
        {
          fontSize,
          fontFamily: 'Roboto-Regular',
        },
        style,
      ]}
      {...props}>
      {children}
    </Text>
  );
};
