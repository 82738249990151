import React from 'react';
import {View, ViewProps} from 'react-native';
import {interpolate} from 'react-native-reanimated';

import {LinearGradient} from '../../components/LinearGradient';
import {Constants} from '../../constants';
import {convertHexToHsl, convertHslToHex} from '../../lib/utils/colors';
import {HorizontalSlider} from '../components/HorizontalSlider';
import {useSyncedSharedValue} from '../components/Slider';

const fromValue = (value: number) => interpolate(value, [0, 1], [0.001, 0.999]);
const toValue = (value: number) => interpolate(value, [0.001, 0.999], [0, 1]);

const Gradient: React.FC<React.ComponentProps<typeof View>> = ({style}) => {
  const colors = React.useMemo(
    () =>
      Array.from({length: 13}).map((_, index) =>
        convertHslToHex(
          fromValue((1 / 12) * index),
          Constants.STD_HSL_VALUES.saturation,
          Constants.STD_HSL_VALUES.lightness,
          true,
        ),
      ),
    [],
  );

  return <LinearGradient {...{colors, style}} angle={90} useAngle={true} />;
};

interface Props extends ViewProps {
  value: string[];
  onChange(value: string[]): void;
  onEnd(value: string[]): void;
}

export const ColorSlider = ({
  value: initialValue,
  style,
  onChange,
  onEnd,
}: Props) => {
  const value = useSyncedSharedValue(
    convertHexToHsl(initialValue[0]),
    value => toValue(value),
    [initialValue],
  );

  return (
    <HorizontalSlider
      {...{value, style}}
      track={Gradient}
      activeTrackStyle={{display: 'none'}}
      onChange={value =>
        onChange?.([
          convertHslToHex(
            fromValue(value),
            Constants.STD_HSL_VALUES.saturation,
            Constants.STD_HSL_VALUES.lightness,
            true,
          ),
        ])
      }
      onEnd={value =>
        onEnd?.([
          convertHslToHex(
            fromValue(value),
            Constants.STD_HSL_VALUES.saturation,
            Constants.STD_HSL_VALUES.lightness,
            true,
          ),
        ])
      }
    />
  );
};
