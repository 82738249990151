import React from 'react';
import {ViewProps} from 'react-native';
import {interpolate} from 'react-native-reanimated';

import {Strings} from '../../constants/Strings';
import {HorizontalSlider} from '../components/HorizontalSlider';
import {useSyncedSharedValue} from '../components/Slider';
import {
  SliderContainer,
  SliderValue,
  SliderValues,
} from '../components/SliderContainer';

interface Props extends ViewProps {
  value: number;
  min: number;
  max: number;
  onEnd(value: number): void;
}

const fromValue = (value: number, min: number, max: number) =>
  Math.round(interpolate(value, [0, 1], [min, max]));
const toValue = (value: number, min: number, max: number) =>
  interpolate(value, [min, max], [0, 1]);

export const TemperatureSlider = ({
  value: initialValue,
  min,
  max,
  style,
  onEnd,
}: Props) => {
  const [temperature, setTemperature] = React.useState(
    Math.round(initialValue),
  );

  const value = useSyncedSharedValue(
    Math.round(initialValue),
    value => toValue(value, min, max),
    [initialValue, min, max],
  );

  return (
    <SliderContainer {...{style}}>
      <SliderValues>
        <SliderValue highlighted>{Strings.SLIDER.TEMPERATURE}</SliderValue>
        <SliderValue highlighted>{temperature}°</SliderValue>
      </SliderValues>

      <HorizontalSlider
        {...{value}}
        onChange={value => setTemperature(fromValue(value, min, max))}
        onEnd={value => onEnd?.(fromValue(value, min, max))}
      />

      <SliderValues>
        <SliderValue>{min}°</SliderValue>
        <SliderValue>{max}°</SliderValue>
      </SliderValues>
    </SliderContainer>
  );
};
