import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {Constants} from '../../constants';
import {Connection} from '../../contexts/connection';
import {colors} from '../../styles';
import {
  currentDeviceSWRevisionSelector,
  userLanternPreferenceSelector,
} from '../redux/slices/bleSlice';
import {getMoodLightSelector} from '../redux/slices/moodLightSlice';
import {MoodLight} from '../types';
import {meetsMinimumFirmware} from '../utils';
import {useAppState} from './useAppState';
import {useDebouncedWriteLanternMoodLight} from './useDebouncedWriteLanternMoodLight';

let numOfInstances = 0;
const {defaultColor} = colors;

/* Hook that accepts a hex color string or MoodLight and turns on lantern mode and sets
 * the lantern to display the lantern color or MoodLight as long as the screen is active.
 * When leaving the screen, resets the lantern settings back to the previous settings. If
 * the app goes to the background, the lantern will turn off unless lantern mode is already
 * on. Lantern mode will turn on again when the app returns from the background.
 */
export function useLantern(
  lanternProp: string | MoodLight | undefined, // Color hex string or MoodLight
  updateWhileNotFocused?: boolean, //Update moodlight even not focused in screen
) {
  const {peak} = Connection.useContainer();
  const connected = !!peak;
  const connectedRef = React.useRef(connected);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);
  const lanternPropRef = React.useRef(lanternProp);
  const userLanternPreference = useSelector(userLanternPreferenceSelector);
  const userLanternPreferencePropRef = React.useRef(userLanternPreference);

  const lanternMode =
    userLanternPreferencePropRef?.current?.lanternMode ?? false;
  const lanternModeRef = React.useRef(lanternMode);
  const lanternPattern = userLanternPreferencePropRef?.current?.lanternPattern;
  const lanternColor = userLanternPreferencePropRef?.current?.lanternColor;
  const partyMode = userLanternPreferencePropRef?.current?.partyMode;
  const getMoodLight = useSelector(getMoodLightSelector);
  const lanternMoodLight =
    userLanternPreferencePropRef?.current?.moodLightId &&
    getMoodLight(userLanternPreferencePropRef?.current?.moodLightId); // Requires a minimum of firmware T
  const lanternMoodLightRef = React.useRef(lanternMoodLight);

  const isFocused = useIsFocused();
  const isFocusedRef = React.useRef(isFocused);
  const canUseMoodLights = meetsMinimumFirmware(
    deviceSWRevision,
    Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
  );
  const isAppStateActive = useAppState() === 'active';
  const isAppStateActiveRef = React.useRef(isAppStateActive);
  const debouncedWriteLanternMoodLight = useDebouncedWriteLanternMoodLight();

  React.useEffect(() => {
    lanternMoodLightRef.current = lanternMoodLight;
  }, [lanternMoodLight]);

  // Firmware < T: Restores lantern color if there was one
  // Firmware >= T: Restores mood lighting or lantern color with lantern pattern
  const restorePreviousSettings = React.useCallback(async () => {
    if (canUseMoodLights) {
      if (lanternMoodLightRef.current) {
        await peak?.writeLanternMoodLight(lanternMoodLightRef.current);
      } else if (partyMode) {
        await peak?.setPartyMode();
      } else {
        await peak?.writeLanternColor(
          lanternColor ?? defaultColor,
          lanternPattern,
        );
        await peak?.clearScratchpad();
      }
    } else {
      if (partyMode) {
        await peak?.setPartyMode();
      } else {
        await peak?.writeLanternColor(
          lanternColor ?? defaultColor,
          lanternPattern,
        );
      }
    }
  }, [peak, canUseMoodLights, lanternColor, lanternPattern, partyMode]);

  // Firmware < T: Temporarily sets the lantern color to the passed in color
  // Firmware >= T: Temporarily sets mood lighting or lantern color based on passed in mood lighting/color
  const setTemporaryLanternSettings = React.useCallback(async () => {
    if (typeof lanternPropRef.current === 'string') {
      await peak?.writeLanternColor(lanternPropRef.current, lanternPattern);
    } else {
      if (canUseMoodLights && lanternPropRef.current) {
        debouncedWriteLanternMoodLight(lanternPropRef.current);
      } else {
        // MoodLight loaded but firmware can't support it
        await peak?.writeLanternColor(defaultColor);
      }
    }
  }, [peak, canUseMoodLights, debouncedWriteLanternMoodLight, lanternPattern]);

  React.useEffect(() => {
    connectedRef.current = !!peak;
    peak?.startLantern();
  }, [peak]);

  React.useEffect(() => {
    numOfInstances++;
    return () => {
      if (connectedRef.current && numOfInstances === 1) {
        !lanternModeRef.current && peak?.stopLantern();
        restorePreviousSettings();
      }
      numOfInstances--;
    };
  }, []);

  React.useEffect(() => {
    lanternModeRef.current = lanternMode;
  }, [lanternMode]);

  React.useEffect(() => {
    isFocusedRef.current = isFocused;
  }, [isFocused]);

  React.useEffect(() => {
    lanternPropRef.current = lanternProp;
  }, [lanternProp]);

  React.useEffect(() => {
    if ((isFocused || updateWhileNotFocused) && peak) {
      if (isAppStateActive) {
        if (
          isAppStateActiveRef.current === isAppStateActive || // If app status changed
          lanternModeRef.current
        ) {
          setTemporaryLanternSettings();
        } else {
          peak.startLantern();
        }
      } else {
        if (
          isAppStateActiveRef.current === isAppStateActive || // If app status changed
          lanternModeRef.current
        ) {
          restorePreviousSettings();
        } else {
          peak.stopLantern();
        }
      }
    }
    isAppStateActiveRef.current = isAppStateActive;
  }, [
    connected,
    peak,
    isAppStateActive,
    isFocused,
    lanternProp,
    setTemporaryLanternSettings,
    restorePreviousSettings,
    updateWhileNotFocused,
  ]);
}
