import {AgreementType} from 'puffco-api-axios-client';
import React from 'react';
import {useAsync} from 'react-use';

import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {WebView} from '../../components/WebView';
import {Screens} from '../../constants/navigation';
import {agreementApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';

export interface Props {}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.TermsConditions> {}

export const TermsConditionsScreen: React.FC<ScreenProps> = () => {
  useAdaptiveSafeArea();

  const {value: agreementData} = useAsync(
    () => agreementApi.getLatestAgreement({type: AgreementType.Tc}),
    [],
  );

  return (
    <ImgBackground>
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: colors.baseBackground,
        }}>
        <WebView
          source={{html: agreementData?.data.content ?? ''}}
          scalesPageToFit={false}
        />
      </SafeAreaView>
    </ImgBackground>
  );
};
