import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import Svg, {SvgProps} from 'react-native-svg';

import {colors} from '../styles';

export type IconProps = SvgProps &
  TouchableOpacityProps & {
    /**
     * style of the container
     */
    style?: ViewStyle;
    /**
     * side length of the icon, defualt to 20
     */
    size?: number;
    /**
     * size of the container, default to size + 20
     */
    containerSize?: number;
    /**
     * color of the icon
     */
    color?: string;
    /**
     * icon style
     */
    iconStyle?: ViewStyle;
    /**
     * viewBox height
     */
    baseHeight?: number;
    /**
     * viewBox width
     */
    baseWidth?: number;
    /**
     * determine wether the icon is solid or not
     */
    solid?: boolean;
  };

export const Icon: React.FC<IconProps> = ({
  baseHeight = 24,
  baseWidth = 24,
  strokeWidth = 1.5,
  onPress,
  disabled,
  color = colors.white,
  size,
  solid,
  style,
  containerSize,
  onPressIn,
  onPressOut,
  onLongPress,
  iconStyle,
  children,
  ...props
}) => {
  const scale = size ? size / baseWidth : undefined;

  const height = scale ? baseHeight * scale : props.height ?? baseHeight;

  disabled =
    disabled || (!onPress && !onPressIn && !onPressOut && !onLongPress);

  const sideLength = containerSize ? containerSize : size ? size + 16 : 40;

  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          borderRadius: (containerSize && containerSize / 2) || size || 10,
          height: sideLength,
          width: sideLength,
        },
        style,
      ]}
      {...{disabled, onPress, onPressIn, onPressOut, onLongPress}}>
      <Svg
        width={size ?? baseWidth}
        fill={solid ? color : 'none'}
        stroke={color}
        viewBox={`0 0 ${baseWidth} ${baseHeight}`}
        style={iconStyle}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...{height, strokeWidth}}
        {...props}>
        {children}
      </Svg>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
