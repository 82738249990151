import {Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {Alert} from '../../components/Alert';
import {AppleSignIn} from '../../components/AppleSignIn';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StyledButton} from '../../components/StyledButton';
import {StyledField} from '../../components/StyledField';
import {Text} from '../../components/Typography';
import {Constants} from '../../constants';
import {ErrorMessages, Messages, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {ClosedEyeIcon, EyeIcon, LockIcon, MailIcon} from '../../icons';
import {isHttpError} from '../../lib/api/apis';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {
  useAppleLogin,
  useGoogleLogin,
  useLogin,
} from '../../lib/hooks/useLogin';
import {updateAppFlags} from '../../lib/redux/slices/appFlagsSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import NavigationService from '../../lib/utils/NavigationService';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {optionsToRoute, toHome} from '../../navigators/util';
import {analytics} from '../../services/analytics';
import {colors} from '../../styles';
import {
  GoogleSignIn,
  LogoHeader,
  LogoTitle,
  TermsAndPrivacy,
} from '../components';
import {AccountStatus, UserAttemptData} from './components/AccountStatus';

interface LoginValues {
  email: string;
  password: string;
}

const {GALAXY_S8, PX_785} = Constants.SCREEN_HEIGHT;
const {height} = Dimensions.get('window');
const isSmall = height <= GALAXY_S8;
const isMedium = height <= PX_785;

const schema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(ErrorMessages.REQUIRED_EMAIL)
    .email(ErrorMessages.INVALID_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD),
});

export interface Props extends WithOptionalRedirect {
  token?: string;
  email?: string;
  appleCode?: string;
  googleCode?: string;
}

interface ScreenProps extends RootStackScreenProps<typeof Screens.Login> {}

export const LoginScreen: React.FC<ScreenProps> = ({route, navigation}) => {
  const [{loading}, login] = useLogin();

  const ref =
    React.useRef<FormikProps<{email: string; password: string}>>(null);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [displayPassword, setDisplayPassword] = React.useState(false);
  const [attemptData, setAttemptData] = React.useState<UserAttemptData>({
    countAttempt: 5,
    accountStatus: false,
    invalidPass: false,
  });

  const [google, loginWithGoogle] = useGoogleLogin();
  const [apple, loginWithApple] = useAppleLogin();

  const {redirect} = route.params ?? {};

  const handleSubmit = React.useCallback(
    async (values: LoginValues, actions: FormikHelpers<typeof values>) => {
      Keyboard.dismiss();
      actions.setSubmitting(true);

      try {
        await login({email: values.email.trim(), password: values.password});

        analytics.trackEvent('login', {method: 'credentials'});
      } catch (error) {
        if (!isHttpError(error)) throw error;

        interface LoginStatus {
          type?: string;
          remainAttempts?: number;
          unlockTime?: number;
        }

        const status = (error.response?.data ?? {}) as LoginStatus | undefined;

        if (status?.type === 'Attempt') {
          setAttemptData({
            countAttempt: status.remainAttempts,
            invalidPass: true,
            accountStatus: true,
          });

          if (!!status.remainAttempts && status.remainAttempts > 2) {
            Alert.alert('Error', ErrorMessages.INVALID_CREDENTIALS);
          }
        } else if (status?.type === 'Temporary') {
          setAttemptData({
            countAttempt: 0,
            invalidPass: true,
            accountStatus: true,
          });
        } else {
          Alert.alert('Error', ErrorMessages.INVALID_CREDENTIALS);
        }

        throw error;
      }

      actions.setSubmitting(false);

      // Check if there is a legacy account
      dispatch(updateAppFlags({hasSeenNewUsername: !user?.defaultUsername}));

      NavigationService.instance()?.resetRoot({
        index: 0,
        routes: [optionsToRoute(redirect ?? toHome)],
      });
    },
    [login, redirect, user?.defaultUsername],
  );

  useBackPress(() => {
    navigation.goBack();
    return true;
  });

  React.useEffect(() => {
    ref.current?.setFieldValue('email', route.params?.email);
  }, [route.params?.email]);

  return (
    <Container>
      <InnerContainer>
        <Formik
          initialValues={{email: route?.params?.email ?? '', password: ''}}
          innerRef={ref}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          {({
            handleBlur,
            handleChange,
            isSubmitting,
            isValid,
            submitForm,
            values,
          }) => (
            <FormContainer>
              <SpinnerContainer>
                {(isSubmitting ||
                  loading ||
                  google.loading ||
                  apple.loading) && (
                  <ActivityIndicator size="large" color={colors.black} />
                )}
              </SpinnerContainer>

              <LogoHeader style={{marginTop: Constants.IS_WEB ? 34 : 40}}>
                <LogoTitle>{Messages.LOGIN_TITLE}</LogoTitle>
              </LogoHeader>

              <BodyContainer>
                <FieldsContainer>
                  <FieldContainer>
                    <StyledField
                      icon={MailIcon}
                      borderColor={colors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      placeholder={'Email'}
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      keyboardType="email-address"
                      isBordered={true}
                      leftIconColor={colors.black30}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <StyledField
                      icon={LockIcon}
                      borderColor={colors.lightMediumGray}
                      height={isMedium ? 45 : 56}
                      password={!displayPassword}
                      onChangeText={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      leftIconColor={colors.black30}
                      placeholder={'Password'}
                      isBordered={true}
                      rightIcon={props =>
                        !displayPassword ? (
                          <ClosedEyeIcon
                            size={16}
                            containerSize={36}
                            {...props}
                          />
                        ) : (
                          <EyeIcon size={16} containerSize={36} {...props} />
                        )
                      }
                      rightIconColor={colors.black30}
                      rightIconCircled={false}
                      iconPress={() => setDisplayPassword(pass => !pass)}
                    />
                  </FieldContainer>
                  <ForgotText
                    variant="xs"
                    onPress={() =>
                      navigation.navigate(Screens.ForgotPassword, {
                        email: values.email,
                      })
                    }>
                    Forgot password?
                  </ForgotText>
                </FieldsContainer>
                <ButtonContainer
                  style={
                    isSmall ? {flex: 0.5, justifyContent: 'center'} : undefined
                  }>
                  <StyledButton
                    title={Strings.SIGN_IN}
                    disabled={!isValid || isSubmitting}
                    onPress={submitForm}
                    style={{
                      backgroundColor: colors.black,
                      width: '100%',
                      height: isMedium ? 45 : 56,
                    }}
                    textStyle={{color: colors.white}}
                  />
                </ButtonContainer>
                <BlackTextContainer>
                  <Text variant="s">
                    {Messages.NO_ACCOUNT}{' '}
                    <UnderlinedSignUpText
                      variant="s"
                      onPress={() => {
                        navigation.navigate(Screens.Register, {
                          redirect,
                          email: ref.current?.values.email,
                        });
                      }}>
                      {Strings.SIGN_UP}
                    </UnderlinedSignUpText>
                  </Text>
                </BlackTextContainer>
                <ButtonContainer
                  style={
                    isSmall ? {flex: 1, justifyContent: 'center'} : undefined
                  }>
                  <FieldContainer>
                    <AppleSignIn
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      screen="login"
                      onSubmit={loginWithApple}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <GoogleSignIn
                      title={Strings.GOOGLE_SIGN_IN}
                      buttonStyle={{height: isMedium ? 45 : 56}}
                      disabled={
                        isSubmitting ||
                        loading ||
                        apple.loading ||
                        google.loading
                      }
                      screen="login"
                      onSubmit={loginWithGoogle}
                    />
                  </FieldContainer>
                </ButtonContainer>
              </BodyContainer>

              <BottomContainer>
                <TermsAndPrivacy />
              </BottomContainer>
            </FormContainer>
          )}
        </Formik>
        {(attemptData.countAttempt === 2 ||
          attemptData.countAttempt === 1 ||
          attemptData.countAttempt === 0) &&
        attemptData.accountStatus ? (
          <AccountStatus
            setAttemptData={setAttemptData}
            attemptData={attemptData}
          />
        ) : null}
      </InnerContainer>
    </Container>
  );
};

const BodyContainer = styled(View)({
  flex: 1,
  paddingTop: isSmall ? 8 : 24,
  width: '100%',
  alignItems: 'center',
});

const BottomContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: 24,
  zIndex: 2,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: isSmall ? 8 : 16,
  paddingBottom: isSmall ? 8 : 16,
  width: '100%',
});

const Container = styled(KeyboardAvoidingView)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flex: 1,
});

const InnerContainer = styled(SafeAreaView)({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: colors.white,
});

const FieldContainer = styled(View)({
  paddingBottom: 8,
  width: '100%',
});

const FieldsContainer = styled(View)({
  width: '100%',
});

const FormContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
  paddingHorizontal: 24,
  flex: 1,
});

const SpinnerContainer = styled(View)({
  position: 'absolute',
  top: '30%',
  zIndex: 2,
});

const UnderlinedSignUpText = styled(Text)({
  textDecorationLine: 'underline',
});

const ForgotText = styled(Text)({
  alignSelf: 'flex-end',
  marginRight: 8,
});

const BlackTextContainer = styled(View)({
  ...(isSmall
    ? {paddingBottom: 0, flex: 0.3, justifyContent: 'center'}
    : {paddingBottom: 8}),
});
