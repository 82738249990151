import {Buffer} from 'buffer';
import {CBOR} from 'cbor-redux';
import {Ulid} from 'id128';
import {chunk, isEqual, isNil, pick} from 'lodash';
import {RgbtColor, codecs} from 'pikaparam';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';

import {Constants, ScratchpadVersions} from '../../../../constants';
import {
  convertMoodLightToRaw,
  convertMoodLightToTableColor,
  getDeviceModelType,
  getLightingPattern,
  getLumaAnimation,
  splitIndices,
} from '../../../../lib/utils';
import {colors} from '../../../../styles';
import {LED3Data} from '../../../led3/led3data';
import {
  ChamberType,
  DeviceSettings,
  Dictionary,
  ExclusiveMoodLight,
  Led3Meta,
  Led3MoodLight,
  LightingPattern,
  LumaAnimation,
  MoodLight,
  MoodType,
  OperatingState,
  Profile,
  ProfileNewest,
  ProfileScratchpadId,
  Scratchpad,
  TableColor,
  isCustomMoodLight,
  isPreTHeatProfile,
  isTHeatProfile,
  isTHeatProfileNoMoodLight,
} from '../../../types';
import {getSecondsFromMilliseconds, meetsMinimumFirmware} from '../../../utils';
import {bleLog} from '../../../utils/Logger';
import {convertHexToRgbArray} from '../../../utils/colors';
import {convertRgbArrayToHex} from '../../../utils/colors/convertRgbArrayToHex';
import {writeAnimNumArrayToAnimFrame} from '../../../utils/convertWriteValue';
import {sleep} from '../../../utils/sleep';
import {Temperature} from '../../../utils/temperature';
import {isDefined} from '../../../utils/types';
import {PATH_CONFIG} from '../../paths';
import {
  HeatCycleArrayIndex,
  LED_API_VERSIONS,
  LoraxProperties,
  NvmIndex,
  TEMP_HEAT_CYCLE_ARRAY_INDEX,
  isHeatCycleArrayIndex,
  isLed3ColorPointer,
  isNvmIndex,
  isRgbtColor,
  isTempHeatProfileIndex,
} from '../pikaparam';
import {
  Broadcast,
  FirmwareType,
  HeatProfileIndex,
  IPeakDevice,
} from './IPeakDevice';
import {PeakDeviceBase, PeakDeviceBaseOptions} from './PeakDeviceBase';

const {
  FACTORY_HEAT_PROFILE_DEFAULT_BYTE,
  LED_API_TYPE_CODE,
  MODE_COMMAND,
  TABLE_COLOR_BYTES,
  UNIT_CONVERSION,
  NVM_ARRAY_INDICES,
} = Constants;

export class PeakLoraxDevice
  extends PeakDeviceBase<LoraxProperties>
  implements IPeakDevice
{
  constructor(o: Omit<PeakDeviceBaseOptions, 'firmwareType'>) {
    super({...o, firmwareType: FirmwareType.Lorax});
  }

  public async initialize() {
    await super.initialize();

    this.broadcast = await this.readBroadcast();
  }

  private async readBroadcast(): Promise<Broadcast | undefined> {
    try {
      if (!this.tryGetApi('broadcastDataCounter')) return;

      return {
        counter: await this.readCommand('broadcastDataCounter'),
        key: await this.readCommand('broadcastDataKey'),
      };
    } catch (error) {
      // Broadcasting is not a core feature, so we can move forward if reading failed.
      // This might happen because of incorrect Peak identification.
      bleLog.error('Reading broadcast failed.', {error});
      return;
    }
  }

  protected async readChamberType(): Promise<ChamberType> {
    const value = await this.readCommand('heaterType');

    if (Object.values(ChamberType).includes(value)) return value as ChamberType;

    return ChamberType.Classic;
  }

  public async stopDabbing(): Promise<void> {
    await this.writeCommand('modeCmd', MODE_COMMAND.heatCycleAbort);

    // if we send the IDLE command too fast, Lorax doesn't seem to like it
    // probably need to wait for Operating State to change before issuing IDLE
    // Question: Why do we go to IDLE also?
    await sleep(100);

    await this.writeCommand('modeCmd', MODE_COMMAND.idle);
  }

  public async readLanternSettings(
    exclusiveMoodlights: Dictionary<string, ExclusiveMoodLight>,
  ): Promise<{
    lColor?: string | undefined;
    lPattern?: number | undefined;
    partyMode?: boolean | undefined;
    lanternMoodLight?: MoodLight | undefined;
  }> {
    let lColor: string | undefined;
    let lPattern: number | undefined;
    let lanternMoodLight: MoodLight | undefined;

    const lanternProperty =
      `lanternColorApi${this.getLedApiVersion()}` as const;

    const lanternColor = await this.readCommand(lanternProperty);

    if (!isRgbtColor(lanternColor)) {
      const projector = CBOR.decode(lanternColor);

      const partyMode = this.isPartyModeProjector(projector);

      if (partyMode) {
        lColor = colors.defaultColor;
        lPattern = LightingPattern.STEADY;
      } else if (this.isSingleColorSolidProjector(projector)) {
        lColor = projector.lamp.param.color
          ? convertRgbArrayToHex(
              projector.lamp.param.color as unknown as number[],
            )
          : '#ffffff';
      } else if (this.isSingleColorPikaProjector(projector)) {
        lColor = projector.meta?.userColors?.[0]
          ? convertRgbArrayToHex(projector.meta.userColors[0])
          : '#ffffff';
        lPattern = getLightingPattern(
          projector.lamp.param.anim as LumaAnimation,
        );
      } else if (projector.meta?.moodUlid) {
        const meta = this.unbinary(projector.meta);
        if (
          meta.version === ScratchpadVersions.LanternExclusiveNewest ||
          meta.version === ScratchpadVersions.ProfileExclusiveNewest
        ) {
          const knownExclusive = exclusiveMoodlights[meta.moodUlid];
          if (knownExclusive) {
            lanternMoodLight = knownExclusive;
          }
        } else {
          lanternMoodLight = this.getMoodLightFromPeakMeta(meta);
        }
      }

      return {
        lColor,
        lPattern,
        partyMode,
        lanternMoodLight,
      };
    }

    const lanternColorArray = [
      ...Buffer.from(this.convertColorToBuffer(lanternColor)),
    ];

    const partyMode = isEqual(lanternColorArray, Constants.PARTY_MODE.T);
    if (partyMode) {
      lColor = colors.defaultColor;
      lPattern = LightingPattern.STEADY;
    } else {
      if (
        lanternColorArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
        LED_API_TYPE_CODE.LANTERN_COLOR
      ) {
        lColor = convertRgbArrayToHex(lanternColorArray.slice(0, 4));
        lPattern = getLightingPattern(lanternColorArray[4] as LumaAnimation);
      } else {
        const lanternScratchpadBuffer =
          await this.readLanternScratchpadBuffer();
        let scratchpad: Scratchpad | undefined;
        lanternScratchpadBuffer &&
          (scratchpad = await this.readScratchpad(lanternScratchpadBuffer));
        if (scratchpad) {
          if (
            scratchpad.version === ScratchpadVersions.LanternExclusiveNewest ||
            scratchpad.version === ScratchpadVersions.ProfileExclusiveNewest
          ) {
            const knownExclusive = exclusiveMoodlights[scratchpad.moodUlid];
            if (knownExclusive) {
              lanternMoodLight = knownExclusive;
            }
          } else {
            lanternMoodLight = this.getMoodLightFromScratchpad(scratchpad);
          }
        }
      }
    }

    return {
      lColor,
      lPattern,
      partyMode,
      lanternMoodLight,
    };
  }

  public async setPartyMode() {
    const property = `lanternColorApi${this.getLedApiVersion()}` as const;

    await this.writeCommand(
      property,
      this.convertColorApiProperty([...Constants.PARTY_MODE.T]),
    );
  }

  public async readHeatProfile(
    index: HeatProfileIndex,
  ): Promise<{profile: Profile; moodLight: MoodLight | undefined} | undefined> {
    const base = isTempHeatProfileIndex(index)
      ? ('tempHeatCycle' as const)
      : (`heatCycle${index}` as const);

    let name: string | undefined;
    if (!isTempHeatProfileIndex(index)) {
      name = await this.readCommand(`${base}Name`);
    }

    const temperature = await this.readCommand(`${base}Temp`);

    const duration = await this.readCommand(`${base}Time`);

    const colorBufferProperty =
      `${base}ColorApi${this.getLedApiVersion()}` as const;

    const colorBuffer = await this.readCommand(colorBufferProperty);

    let intensity: number | undefined;
    if (
      meetsMinimumFirmware(
        this.softwareRevision,
        Constants.MINIMUM_FIRMWARE_VERSION.VAPOR_SETTING,
      )
    ) {
      intensity = await this.readCommand(`${base}Intensity`);
    }

    const scratchpadBuffer = await this.readCommand(`${base}Scratchpad`);

    const colorArray = [...Buffer.from(this.convertColorToBuffer(colorBuffer))];
    let id: string | null = null;
    let moodLight: MoodLight | undefined;
    let moodLightId: string | undefined;
    let modified: number | undefined;
    let isMoodLight: boolean | undefined;
    let color: string | undefined;

    const version = ProfileVersion.T;
    isMoodLight =
      colorArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
      LED_API_TYPE_CODE.TABLE_COLOR;
    let scratchpad: Scratchpad | undefined;
    if (!isMoodLight) {
      color = convertRgbArrayToHex(colorArray.slice(0, 4));
    }
    if (scratchpadBuffer) {
      scratchpad = this.parseScratchpad(Buffer.from(scratchpadBuffer));
    }
    if (scratchpad) {
      if (
        isMoodLight &&
        scratchpad.version !== ScratchpadVersions.ProfileNewest
      ) {
        moodLight = this.getMoodLightFromScratchpad(scratchpad);
        moodLightId = scratchpad.moodUlid;
      }

      if (
        scratchpad.version === ScratchpadVersions.ProfileNewest ||
        scratchpad.version === ScratchpadVersions.ProfileExclusiveNewest ||
        scratchpad.version === ScratchpadVersions.ProfileCustomNewest
      ) {
        id = scratchpad.heatProfileUlid ?? null;
        const scratchpadDate: number | undefined =
          scratchpad.heatProfileDateModified;
        modified = (
          scratchpadDate === undefined
            ? new Date()
            : new Date(scratchpadDate * UNIT_CONVERSION.SECONDS_TO_MILLISECONDS)
        ).getTime();
      }
    } else {
      modified = new Date().getTime();
      if (isMoodLight) {
        const byte6 =
          colorArray[TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES] & 0x0f;
        color = FACTORY_HEAT_PROFILE_DEFAULT_BYTE.includes(
          byte6 as (typeof FACTORY_HEAT_PROFILE_DEFAULT_BYTE)[number],
        )
          ? colors.heatProfileColor[index]
          : colors.defaultColor;
        isMoodLight = false;
      }
    }

    if (
      (name === undefined || typeof name === 'string') &&
      typeof temperature === 'number' &&
      typeof duration === 'number'
    ) {
      return {
        profile: {
          version,
          id,
          order: index,
          ...(name !== undefined && {name}),
          temperature,
          duration: Math.floor(duration),
          units: TemperatureUnit.Celsius,
          ...(modified && {modified}),
          ...(isMoodLight && {isMoodLight}),
          ...(color && {color}),
          ...(moodLightId !== undefined && {moodLightId}),
          ...(intensity !== undefined && {vaporSetting: intensity}),
        } as Profile,
        moodLight,
      };
    }
  }

  protected async loraxWriteHeatProfileBase(
    i: HeatProfileIndex,
    values: {
      name: string;
      temperature: number;
      duration: number;
      vaporSetting?: number;
    },
  ): Promise<void> {
    const {name, temperature, duration, vaporSetting} = values;
    const base = isTempHeatProfileIndex(i)
      ? ('tempHeatCycle' as const)
      : (`heatCycle${i}` as const);

    if (!isTempHeatProfileIndex(i)) {
      await this.writeCommand(`${base}Name`, name);
    }

    await this.writeCommand(`${base}Temp`, temperature);

    await this.writeCommand(`${base}Time`, duration);

    if (typeof vaporSetting === 'number') {
      if (
        meetsMinimumFirmware(
          this.softwareRevision,
          Constants.MINIMUM_FIRMWARE_VERSION.VAPOR_SETTING,
        )
      ) {
        const isVaporSettingValid = () => {
          if (!this.attributes) return false;

          const range =
            this.attributes.chamberType === ChamberType.XL
              ? [0, 0.5, 1, 1.5]
              : [0, 0.5, 1];

          return range.includes(vaporSetting);
        };

        await this.writeCommand(
          `${base}Intensity`,
          isVaporSettingValid() ? vaporSetting : 1,
        );
      }
    }
  }

  async writeColor(
    color: string,
    lightingPattern: LightingPattern,
    isLantern: boolean,
    index: HeatProfileIndex,
  ): Promise<void> {
    let colorArray = convertHexToRgbArray(color);
    colorArray = [...colorArray, getLumaAnimation(lightingPattern), 0, 0, 0];

    if (isLantern) {
      await this.writeCommand(
        `lanternColorApi${this.getLedApiVersion()}`,
        this.convertColorApiProperty(colorArray),
      );
    } else {
      const property =
        index === -1
          ? (`tempHeatCycleColorApi${this.getLedApiVersion()}` as const)
          : (`heatCycle${index}ColorApi${this.getLedApiVersion()}` as const);

      await this.writeCommand(
        property,
        this.convertColorApiProperty(colorArray),
      );
    }
  }

  async writeHeatProfile(
    profile: Profile,
    moodLight?: MoodLight | undefined,
  ): Promise<void> {
    const temperature = Temperature.convert(profile.temperature, {
      from: profile.units,
      to: TemperatureUnit.Celsius,
    });

    const order =
      isHeatCycleArrayIndex(profile.order) ||
      isTempHeatProfileIndex(profile.order)
        ? profile.order
        : undefined;

    if (order === undefined) {
      throw new Error(
        `Can't write profile at non-existent index ${profile.order}`,
      );
    }

    await this.loraxWriteHeatProfileBase(order, {
      name: profile.name,
      temperature,
      duration: profile.duration,
      vaporSetting: isTHeatProfile(profile) ? profile.vaporSetting : 0,
    });

    if (moodLight) {
      await this.writeMoodLight(
        PATH_CONFIG.heatCycleColor.path,
        this.addNvmIndices(NVM_ARRAY_INDICES.HEAT_PROFILE_0, order),
        moodLight,
        profile,
      );
    } else if (
      isPreTHeatProfile(profile) ||
      isTHeatProfileNoMoodLight(profile)
    ) {
      // Assuming LumaAnimation isn't defined
      await this.writeColor(
        profile.color,
        LightingPattern.STEADY,
        false,
        order,
      );
      const scratchpad: ProfileNewest = {
        version: ProfileScratchpadId.PROFILE_NEWEST,
        heatProfileUlid: profile.id,
        heatProfileDateModified: getSecondsFromMilliseconds(
          profile.version === ProfileVersion.T
            ? profile.modified
            : new Date().getTime(),
        ),
      };

      await this.writeScratchpad(scratchpad, order);
    }
  }

  protected async writeTableColor(
    characteristicUuid: string,
    nvmIndex: number,
    moodLight: MoodLight,
    profile?: Profile | undefined,
    tableColor?: TableColor | undefined,
    shouldWriteScratchpad: boolean | undefined = true,
  ) {
    const {
      brightness,
      speed,
      lumaAnimation,
      phaseLockNumerator,
      phaseLockDenominator,
      arrayIndices,
      colorArrayLength,
    } = tableColor ?? convertMoodLightToTableColor(moodLight, nvmIndex);

    const getProperty = () => {
      // could be lanternColor, a heat profile color, or the temp heat profile color
      if (characteristicUuid !== PATH_CONFIG.heatCycleColor.path)
        return `lanternColorApi${this.getLedApiVersion()}` as const;

      const order: HeatProfileIndex =
        (profile?.order as HeatCycleArrayIndex) ?? TEMP_HEAT_CYCLE_ARRAY_INDEX;

      if (order >= 0)
        return `heatCycle${order}ColorApi${this.getLedApiVersion()}` as const;

      return `tempHeatCycleColorApi${this.getLedApiVersion()}` as const;
    };

    const property = getProperty();

    await this.writeCommand(
      property,
      this.convertColorApiProperty([
        brightness,
        speed,
        lumaAnimation,
        Constants.LED_API_TYPE_CODE.TABLE_COLOR,
        phaseLockNumerator,
        phaseLockDenominator,
        arrayIndices,
        colorArrayLength,
      ]),
    );

    if (shouldWriteScratchpad)
      await this.writeMoodLightScratchpad(moodLight, profile);
  }

  public async readDabbingValues(): Promise<{
    state: OperatingState;
    settings: DeviceSettings;
  }> {
    const op = await this.readCommand('operatingState');

    let elapsedTime = 0;
    let totalTime = 0;

    const currentTemp = await this.readCommand('userHeaterTemp');
    const targetTemp = await this.readCommand('userHeaterTempCommand');

    // Prevent reading preheat elapsed/total times
    if (op === OperatingState.HEAT_CYCLE_ACTIVE) {
      totalTime = await this.readCommand('stateTotalTime');
      const elapsedTimeResponse = await this.readCommand('stateElapsedTime');

      if (Number.isFinite(elapsedTimeResponse)) {
        elapsedTime = elapsedTimeResponse;
      }
    }

    const activeLedColors = await this.readCommand(
      `activeLedColorApi${this.getLedApiVersion()}`,
    );

    const activeLedArrayBuffer =
      this.convertColorToBuffer(
        isLed3ColorPointer(activeLedColors)
          ? RgbtColor.fromRgb(activeLedColors)
          : activeLedColors,
      ) ?? [];

    const activeLedArray = Array.from(new Uint8Array(activeLedArrayBuffer));

    const settings: DeviceSettings = {
      currentTemp,
      stateElapsedTime: elapsedTime,
      stateTotalTime: totalTime,
      targetTemp,
      isDabbingDiscoMode:
        !isNil(activeLedArrayBuffer) &&
        // Compare with all party mode LED API 2 values except luma animation
        activeLedArray[TABLE_COLOR_BYTES.BRIGHTNESS] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.BRIGHTNESS] &&
        activeLedArray[TABLE_COLOR_BYTES.SPEED] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.SPEED] &&
        activeLedArray[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.LED_API_TYPE_CODE] &&
        activeLedArray[TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR] &&
        activeLedArray[TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR] &&
        activeLedArray[TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES] ===
          Constants.PARTY_MODE.T[
            TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
          ] &&
        activeLedArray[TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH] ===
          Constants.PARTY_MODE.T[TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH],
    };

    return {
      state: op,
      settings,
    };
  }

  public async writeMoodLight(
    characteristicUuid: string,
    nvmIndex: NvmIndex<LoraxProperties>,
    moodLight: MoodLight,
    profile?: Profile | undefined,
  ): Promise<void> {
    if (!this.modelNumber)
      throw new Error(
        "Couldn't write mood light because model number is missing.",
      );

    if (!this.serialNumber)
      throw new Error(
        "Couldn't write mood light because serial number is missing.",
      );

    if (isCustomMoodLight(moodLight)) {
      moodLight = {
        ...moodLight,
        tempo: Number(moodLight.tempo),
        type: Number(moodLight.type),
      };
    }
    nvmIndex = await this.alternateLanternNvmIndex(nvmIndex);
    const {tableColor, colorArray, offsetArray, animationArray} =
      convertMoodLightToRaw(
        moodLight,
        getDeviceModelType(this.product.name ?? 'OG'),
        nvmIndex,
      );

    const newColorArray = chunk(colorArray, 4)
      .map(bytes => RgbtColor.from4Byte(Buffer.from(bytes)).asRgb)
      .filter(isDefined);

    const colorProperty = `userColorArray${nvmIndex}` as const;
    await this.writeCommand(colorProperty, newColorArray);

    const offsetProperty = `userOffsetArray${nvmIndex}` as const;
    await this.writeCommand(offsetProperty, offsetArray);

    if (animationArray) {
      const animationBuffer = writeAnimNumArrayToAnimFrame(animationArray);
      // TODO is this right? should this correspond to the heat profile index?
      // looking at the flat implementation, it always seems to write to 0
      const aaProperty = `userAnimArray0`;

      await this.writeCommand(aaProperty, [animationBuffer]);
    }

    await this.writeTableColor(
      characteristicUuid,
      nvmIndex,
      moodLight,
      profile,
      tableColor,
    );
  }

  public async readLanternColorArrayIndex() {
    const lanternColorProperty =
      `lanternColorApi${this.getLedApiVersion()}` as const;

    const buffer = await this.readCommand(lanternColorProperty);

    const lanternColorArray = isRgbtColor(buffer)
      ? Array.from(Buffer.from(buffer.bytes))
      : Array.from(Buffer.from(buffer));

    if (
      lanternColorArray.length >
        Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES &&
      lanternColorArray[Constants.TABLE_COLOR_BYTES.LED_API_TYPE_CODE] ===
        Constants.LED_API_TYPE_CODE.TABLE_COLOR
    ) {
      const {colorIndex} = splitIndices(
        lanternColorArray[
          Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
        ],
      );

      return colorIndex;
    }
    return Constants.NVM_ARRAY_INDICES.DEFAULT_LANTERN_ARRAY_INDEX;
  }

  protected isSingleColorSolidProjector(projector: Led3MoodLight): boolean {
    if (projector.lamp.name === 'solid' && projector.lamp.param.color) {
      return true;
    }
    return false;
  }

  protected isSingleColorPikaProjector(projector: Led3MoodLight): boolean {
    if (
      projector.lamp.name === 'pikaled2' &&
      projector.meta?.userColors?.length === 1 &&
      projector.lamp.param.anim &&
      [
        LumaAnimation.STEADY,
        LumaAnimation.BREATHING,
        LumaAnimation.CIRCLING_SLOW,
      ].includes(projector.lamp.param.anim) &&
      projector.meta?.moodUlid === undefined
    ) {
      return true;
    }
    return false;
  }

  protected isPartyModeProjector(projector: Led3MoodLight): boolean {
    if (
      projector.meta?.tag === 'pikaled2-classic-disco-mood-light' &&
      projector.meta?.name === 'Party Mode'
    ) {
      return true;
    }
    return false;
  }

  protected unbinary(meta: Record<string, any>): Record<string, any> {
    const ulidKeys = ['moodUlid', 'originalMoodUlid', 'heatProfileUlid'];
    for (const key of ulidKeys) {
      if (meta[key]) {
        meta[key] = Ulid.construct(meta[key]).toCanonical();
      }
    }

    if (meta.userColors) {
      meta.colors = meta.userColors.map((color: number[]) =>
        convertRgbArrayToHex(color),
      );
    }

    return meta;
  }

  protected getMoodLightFromPeakMeta(
    meta: Record<string, any>,
  ): MoodLight | undefined {
    // Peak Meta = scratchpad data + moodlight UI/param data
    // some conversion necesssary:
    // - led3meta.userColors to scratchpad.colors
    // - led3meta.tempoFrac to scratchpad.tempo
    if (meta.userColors?.length && typeof meta.userColors[0] !== 'string') {
      meta.colors = meta.userColors.map((color: number[]) =>
        convertRgbArrayToHex(color),
      );
    }
    meta.tempo = meta.tempoFrac;

    const scratchpad = meta as Scratchpad;
    const moodLight = this.getMoodLightFromScratchpad(scratchpad as Scratchpad);

    // clean up led3Meta, only UI params
    if (moodLight) {
      const led3Config =
        LED3Data[moodLight.type as keyof typeof LED3Data] ||
        LED3Data[MoodType.NO_ANIMATION];

      const keysArr = led3Config.ui.map(ui => ui.key);
      moodLight.led3Meta = pick(
        {
          ...scratchpad,
          userColors: meta.colors, // use converted hex colors
        },
        keysArr,
      ) as Led3Meta;
    }

    return moodLight;
  }

  protected getLedApiVersion() {
    const version = LED_API_VERSIONS.find(a =>
      this.pikaparam.devInfo.info?.features.includes(a),
    );

    switch (version) {
      case 'led-api-2':
        return 2;
      case 'led-api-3':
        return 3;
      default:
        throw new Error(
          `Unsupported led api version '${version}' for PeakLoraxDevice`,
        );
    }
  }

  protected addNvmIndices(i1: number, i2: number) {
    const result = i1 + i2;

    if (!isNvmIndex(result))
      throw new Error(`Nvm indices operation result is a non-nvm index`);

    return result;
  }

  protected convertColorToBuffer(value: RgbtColor | ArrayBuffer) {
    const ledApiVersion = this.getLedApiVersion();

    switch (ledApiVersion) {
      case 2:
        return codecs.rgbtApi2.encode(value as RgbtColor);
      case 3:
        return codecs.raw.encode(value as ArrayBuffer);
    }
  }

  protected convertColorApiProperty(array: number[]) {
    const ledApiVersion = this.getLedApiVersion();

    switch (ledApiVersion) {
      case 2:
        return codecs.rgbtApi2.decode(Buffer.from(array));
      case 3:
        return codecs.raw.decode(Buffer.from(array));
    }
  }
}
