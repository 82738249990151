import React from 'react';
import {StyleSheet, Text, TextProps} from 'react-native';

import {Messages} from '../constants/Strings';
import {Vapor} from '../lib/types';
import {colors} from '../styles';

interface Props extends TextProps {
  vapor: Vapor;
}

export const VaporModeText = ({vapor, style, ...rest}: Props) => {
  return (
    <Text style={[styles.root, style]} {...rest}>
      {Messages.VAPOR[vapor]}
    </Text>
  );
};

const styles = StyleSheet.create({
  root: {
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    fontSize: 10,
    color: colors.white,
  },
});
