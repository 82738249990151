import {ProductName} from 'pikaparam';
import React from 'react';
import {
  ImageStyle,
  Platform,
  ImageBackground as ReactNativeImageBackground,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {useSelector} from 'react-redux';

import {Constants} from '../constants';
import {currentDeviceSelector} from '../lib/redux/slices/bleSlice';
import {
  Background,
  isColoredBackground,
  isColoredGradientBackground,
  isColoredImageBackground,
  isGradientBackground,
  isImageBackground,
} from '../lib/types';
import {getProductName} from '../lib/utils/getProductName';
import {LinearGradientBackground} from './LinearGradientBackground';

interface Props extends ViewProps {
  background?: Background;
  imageStyle?: StyleProp<ImageStyle>;
}

export const SwitchableBackground = ({
  background,
  children,
  imageStyle,
  style,
  ...rest
}: React.PropsWithChildren<Props>) => {
  const device = useSelector(currentDeviceSelector);
  const productName = device ? getProductName(device) : undefined;

  if (background && isColoredGradientBackground(background)) {
    return (
      <View
        style={[
          {backgroundColor: background.color},
          styles.background,
          StyleSheet.flatten(style),
        ]}
        {...rest}>
        <LinearGradientBackground
          style={{
            width: '100%',
            height: '63.5%',
            position: 'absolute',
            bottom: 0,
            ...(productName && gradientStyle[productName]),
          }}
          colors={background.gradient}
        />
        {children}
      </View>
    );
  }

  if (background && isGradientBackground(background)) {
    return (
      <LinearGradientBackground
        colors={background.gradient}
        style={[styles.background, StyleSheet.flatten(style)]}
        {...rest}>
        <View style={{width: '100%', height: '100%'}}>{children}</View>
      </LinearGradientBackground>
    );
  }

  if (background && isColoredImageBackground(background)) {
    return (
      <ReactNativeImageBackground
        source={background.image}
        style={[
          styles.background,
          {backgroundColor: background.color},
          StyleSheet.flatten(style),
        ]}
        imageStyle={[
          imageStyle,
          Platform.OS === 'web' ? {width: '100%', height: '100%'} : {},
        ]}>
        {children}
      </ReactNativeImageBackground>
    );
  }

  if (background && isImageBackground(background)) {
    return (
      <ReactNativeImageBackground
        source={background.image}
        style={[styles.background, StyleSheet.flatten(style)]}
        imageStyle={[
          imageStyle,
          Platform.OS === 'web' ? {width: '100%', height: '100%'} : {},
        ]}>
        {children}
      </ReactNativeImageBackground>
    );
  }

  return (
    <View
      style={[
        styles.background,
        background &&
          isColoredBackground(background) && {
            backgroundColor: background.color,
          },
        StyleSheet.flatten(style),
      ]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
});

const gradientStyle: {[K in ProductName]?: ViewStyle} = {
  Desert: {
    opacity: 0.7,
  },
  Flourish: {
    opacity: Constants.IS_WEB ? 0.85 : 1,
  },
};
