import {ReferralDto} from 'puffco-api-axios-client';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {rewardsApi} from '../api/apis';
import {
  newReferralCodeSelector,
  referralsActions,
} from '../redux/slices/referralsSlice';
import {userIdSelector} from '../redux/slices/userSlice';
import {useImmutableRemoteData} from './useRemoteData';

interface Accumulator {
  used: ReferralDto[];
  unused: ReferralDto[];
}

export const useReferrals = () => {
  const userId = useSelector(userIdSelector);
  const loggedIn = !!userId;
  const code = useSelector(newReferralCodeSelector);
  const dispatch = useDispatch();

  const {data, isLoading, error, mutate} = useImmutableRemoteData(
    {key: `getReferrals`, userId},
    async () => {
      if (!loggedIn) return;

      return rewardsApi.getReferrals().then(r => r.data);
    },
    {keepPreviousData: false},
  );

  const all = React.useMemo(() => data ?? [], [data]);

  const {unused, used} = React.useMemo(() => {
    return all.reduce<Accumulator>(
      (acc, referral) => {
        if (referral.isUsed) {
          acc.used.push(referral);
        } else {
          acc.unused.push(referral);
        }
        return acc;
      },
      {used: [], unused: []},
    );
  }, [all]);

  React.useEffect(() => {
    if (isLoading) return;

    dispatch(referralsActions.add({all}));
  }, [isLoading, loggedIn, all]);

  return React.useMemo(
    () => ({
      error,
      isLoading,
      unused,
      used,
      code,
      reload: () => mutate().then(() => void 0),
    }),
    [error, isLoading, code, unused, used, mutate],
  );
};
