import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Text, View} from 'react-native';
import {useDispatch} from 'react-redux';

import {backgroundMarketOptIn} from '../../../assets/images';
import {ImgBackground} from '../../../components/ImgBackground';
import {Modal} from '../../../components/Modal';
import {StyledButton} from '../../../components/StyledButton';
import {Messages} from '../../../constants/Strings';
import {Navigators, Screens} from '../../../constants/navigation';
import {updateAppFlags} from '../../../lib/redux/slices/appFlagsSlice';
import {StaticModalInterstitial} from '../../../lib/types/Interstitial';
import styled from '../../../lib/utils/styled';
import type {HomeEmulatedDrawerNavigatorScreenProps} from '../../../navigators/HomeDrawerNavigator';
import {colors} from '../../../styles';

type Navigation = HomeEmulatedDrawerNavigatorScreenProps<any>['navigation'];

const MarketOptIn = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<Navigation>();

  return (
    <Window>
      <ImgBackground source={backgroundMarketOptIn} style={{flex: 1}}>
        <Container>
          <TextContainer>
            <DescriptionText>
              {Messages.MARKET_OPT_IN_HEADER_TITLE}
            </DescriptionText>
          </TextContainer>
          <ButtonContainer>
            <StyledButton
              title={Messages.MARKET_OPT_IN_NAVIGATION_TITLE}
              onPress={() => {
                Modal.close();
                navigation.navigate(Navigators.MarketOptIn, {
                  screen: Screens.MarketOptInExist,
                });
                dispatch(updateAppFlags({hasSeenMarketOptIn: true}));
              }}
            />
            <DismissText
              onPress={() => {
                dispatch(updateAppFlags({hasSeenMarketOptIn: true}));
                Modal.close();
              }}>
              {Messages.MARKET_OPT_IN_DISMISS_TITLE}
            </DismissText>
          </ButtonContainer>
        </Container>
      </ImgBackground>
    </Window>
  );
};

export const MarketOptInInterstitial: StaticModalInterstitial = {
  key: 'market-opt-in',
  display: {as: 'modal', if: ({user}) => !!user},
  type: 'static',
  options: {
    modal: MarketOptIn,
  },
  startDate: new Date('2023-01-01'),
};

const Window = styled(View)({
  width: '90%',
  height: '85%',
  borderRadius: 18,
  overflow: 'hidden',
});

const Container = styled(View)({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  marginHorizontal: 11,
});

const TextContainer = styled(View)({
  marginBottom: 50,
  flex: 1,
  justifyContent: 'flex-end',
});

const ButtonContainer = styled(View)({
  marginBottom: 50,
  alignItems: 'center',
});

const DescriptionText = styled(Text)({
  color: colors.white,
  fontSize: 40,
  fontFamily: 'BigShouldersDisplay-Medium',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: '400',
});

const DismissText = styled(Text)({
  color: colors.white,
  fontSize: 14,
  marginTop: 20,
  fontFamily: 'Roboto-Medium',
  textTransform: 'uppercase',
  fontWeight: '400',
});
