import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ChevronLeftIcon: React.FC<IconProps> = props => (
  <Icon containerSize={40} size={32} {...props}>
    <Path d="m15 18-6-6 6-6" />
  </Icon>
);
