import {AxiosInstance} from 'axios';
import {
  AgreementApi,
  AgreementTrackingApi,
  AppLogsApi,
  ContextApi,
  DeviceTokensApi,
  GeolocationApi,
  HeatProfileApi,
  InterstitialApi,
  MarketingOptInApi,
  MoodLightApi,
  OtaApi,
  PeakDevicesApi,
  RehashApi,
  RewardsApi,
  ShareApi,
  SupportApi,
  UsersApi,
} from 'puffco-api-axios-client';
import {Linking, Platform} from 'react-native';

import {Environment} from '../../constants';
import {apiClient} from '../network/api-client';
import {authorizedApiClient} from '../network/authorized-api-client';
import {showForceUpdateAlert} from '../utils';

export {isHttpError} from '../../lib/network/http-client';

const onForceUpdate = async () => {
  showForceUpdateAlert(() => {
    switch (Platform.OS) {
      case 'web':
        window?.location?.reload();
        return;
      case 'android':
        Linking.openURL('market://details?id=com.puffco.android');
        return;
    }
  });
};

apiClient.configure({onForceUpdate});
authorizedApiClient.configure({onForceUpdate});

export const authApi = new UsersApi(
  undefined,
  Environment.apiUrl,
  apiClient.instance,
);

export const publicUserApi = new UsersApi(
  undefined,
  Environment.apiUrl,
  apiClient.instance,
);

const params: [undefined, string, AxiosInstance] = [
  undefined,
  Environment.apiUrl,
  authorizedApiClient.instance,
];

export const agreementApi = new AgreementApi(...params);
export const agreementTrackingApi = new AgreementTrackingApi(...params);
export const appLogsApi = new AppLogsApi(...params);
export const contextApi = new ContextApi(...params);
export const geolocationApi = new GeolocationApi(...params);
export const interstitialApi = new InterstitialApi(...params);
export const otaApi = new OtaApi(...params);
export const peakDeviceApi = new PeakDevicesApi(...params);
export const userApi = new UsersApi(...params);
export const deviceTokenApi = new DeviceTokensApi(...params);
export const marketOptInApi = new MarketingOptInApi(...params);
export const moodlightApi = new MoodLightApi(...params);
export const heatprofileApi = new HeatProfileApi(...params);
export const supportApi = new SupportApi(...params);
export const shareApi = new ShareApi(...params);
export const rewardsApi = new RewardsApi(...params);
export const rehashApi = new RehashApi(...params);
