import React from 'react';
import {StyleProp, StyleSheet, Text, ViewStyle} from 'react-native';

import {PressableOpacity} from '../../../components/PressableOpacity';
import {colors} from '../../../styles';

export interface Props {
  tag: string;
  selected?: boolean;
  onPress: () => void;
  style: StyleProp<ViewStyle>;
}

export const Tag = ({tag, selected, style, onPress}: Props) => (
  <PressableOpacity
    style={[
      styles.container,
      {
        borderColor: selected ? colors.white : 'transparent',
        borderWidth: 1,
      },
      style,
    ]}
    onPress={onPress}>
    <Text style={styles.tag}>{tag}</Text>
  </PressableOpacity>
);

const styles = StyleSheet.create({
  tag: {
    flex: 1,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: '400',
    color: colors.white,
    opacity: 0.75,
  },
  container: {
    backgroundColor: colors.darkGray,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
  },
});
