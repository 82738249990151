import React from 'react';
import {Image, Text, View} from 'react-native';

import {firstViewAdvancedMetrics} from '../../../../assets/images';
import {Modal} from '../../../../components/Modal';
import {Messages} from '../../../../constants/Strings';
import {CloseIcon} from '../../../../icons';
import styled from '../../../../lib/utils/styled';
import {colors} from '../../../../styles';

const {FIRST_VIEW_ADVANCED_METRIC_TITLE, FIRST_VIEW_ADVANCED_METRIC_BODY} =
  Messages;

export const FirstViewAdvancedMetrics = () => {
  return (
    <>
      <Container />
      <FirstViewContainer>
        <CloseIcon
          onPress={() => Modal.close()}
          size={30}
          style={{position: 'absolute', right: 25, top: 20, padding: 5}}
          color={colors.black}
        />
        <FirstViewImageContainer>
          <FirstViewImage source={firstViewAdvancedMetrics} />
        </FirstViewImageContainer>
        <View>
          <Title>{FIRST_VIEW_ADVANCED_METRIC_TITLE}</Title>
          <Message>{FIRST_VIEW_ADVANCED_METRIC_BODY}</Message>
        </View>
      </FirstViewContainer>
    </>
  );
};

const Container = styled(View)({
  position: 'absolute',
  backgroundColor: colors.black,
  opacity: 0.2,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const FirstViewImageContainer = styled(View)({
  marginHorizontal: 'auto',
  display: 'flex',
  alignSelf: 'center',
});

const FirstViewImage = styled(Image)({
  height: 60,
  resizeMode: 'contain',
  marginBottom: 15,
});

const FirstViewContainer = styled(View)({
  backgroundColor: colors.white,
  position: 'absolute',
  bottom: 0,
  height: 277,
  width: '100%',
  paddingTop: 48,
  paddingBottom: 52,
  paddingHorizontal: 32,
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
});

const Title = styled(Text)({
  textAlign: 'center',
  marginBottom: 4,
  fontFamily: 'Roboto-Bold',
  fontSize: 16,
  fontWeight: '400',
  lineHeight: 16,
  textTransform: 'uppercase',
  color: colors.black,
});

const Message = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  lineHeight: 21,
  fontWeight: '400',
  color: colors.black,
});
