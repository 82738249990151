import {Text} from 'react-native';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

export const MoodLightFadedText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  lineHeight: 16,
  letterSpacing: 0.5,
  opacity: 0.6,
  textTransform: 'uppercase',
  color: colors.white,
  fontWeight: '400',
});
