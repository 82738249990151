import {StyleSheet} from 'react-native';

import {colors} from '../../../styles';

export const connectStyles = StyleSheet.create({
  info: {
    color: colors.white,
    marginHorizontal: 16,
  },
  title: {
    color: colors.white,
    marginHorizontal: 16,
    marginTop: 25,
  },
});
