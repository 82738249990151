import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {useSelector} from 'react-redux';

import {appSettingsSelector} from '../redux/slices/appSettingsSlice';
import {Store} from '../redux/types';
import {Temperature} from './temperature';

export const useTemperature = (
  value: number,
  unit: TemperatureUnit,
): number => {
  const preference = useSelector(
    state => appSettingsSelector(state as Store)?.tempPreference,
  );

  return React.useMemo(
    () => Math.round(Temperature.convert(value, {from: unit, to: preference})),
    [unit, value, preference],
  );
};
