import {ProductName} from 'pikaparam';

import {
  DEVICE_MODEL_TYPE,
  LIGHTING_PATTERN_DICTIONARY,
  LUMA_ANIMATION_DICTIONARY,
  MIDDLEWARE_VALUE_BYTE_SIZE,
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY,
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY,
} from '../../constants';
import {
  DeviceModelType,
  LightingPattern,
  LumaAnimation,
  MiddlewareValue,
} from '../types';

export const getDeviceModelType = (product: ProductName) =>
  DEVICE_MODEL_TYPE[product] ?? DeviceModelType.OG;

export const getLightingPattern = (lumaAnimation: LumaAnimation) =>
  LIGHTING_PATTERN_DICTIONARY[lumaAnimation] ?? LightingPattern.STEADY;

export const getLumaAnimation = (lightingPattern: LightingPattern) =>
  LUMA_ANIMATION_DICTIONARY[lightingPattern] ?? LumaAnimation.STEADY;

export const getMiddlewareValueByteSize = (middlewareValue: MiddlewareValue) =>
  MIDDLEWARE_VALUE_BYTE_SIZE[middlewareValue] ?? 0;

export const getPeakImageColorOffsetsDictionary = (
  deviceModelType: DeviceModelType,
) =>
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY[deviceModelType] ??
  PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY[DeviceModelType.OG];

export const getPeakImageOpacityOffsetsDictionary = (
  deviceModelType: DeviceModelType,
) =>
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY[deviceModelType] ??
  PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY[DeviceModelType.OG];
