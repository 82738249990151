import React from 'react';
import {useSelector} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';

import {Constants} from '../../constants';
import {connectedPeakSelector} from '../redux/slices/bleSlice';
import {MoodLight} from '../types';

export const useDebouncedWriteLanternMoodLight = () => {
  const peak = useSelector(connectedPeakSelector);

  const callback = React.useCallback(
    (moodLight: MoodLight) => {
      peak?.writeLanternMoodLight(moodLight);
    },
    [peak],
  );

  return useDebouncedCallback(
    callback,
    Constants.MOOD_LIGHT_WRITING_DELAY.DEBOUNCE,
  );
};
