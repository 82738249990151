import {Linking} from 'react-native';

import {analytics} from '../../../../../services/analytics';

export const onItemPress = (onPress?: () => void, link?: string) =>
  onPress
    ? onPress()
    : link
      ? Linking.openURL(link).then(() =>
          analytics.trackEvent('click', {
            content_type: 'social_link',
            link_url: link,
          }),
        )
      : undefined;
