import {configureStore} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
  persistReducer,
  persistStore,
} from 'redux-persist';

import {PersistStorage} from './PersistStorage';
import {loggerMiddleware} from './middleware/loggerMiddleware';
import {migrationVersion, migrations} from './migrations';
import {rootReducer} from './rootReducer';

const reducer = persistReducer(
  {
    key: 'root',
    version: migrationVersion,
    storage: PersistStorage,
    migrate: createMigrate(migrations, {debug: false}),
    blacklist: ['ble'],
  },
  rootReducer,
);

export const ProviderStore = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loggerMiddleware),
});

export type AppDispatch = typeof ProviderStore.dispatch;

export const PersistedProviderStore = persistStore(ProviderStore);
