import {TransitionPresets, createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Strings} from '../constants/Strings';
import {Screens} from '../constants/navigation';
import {
  DeviceDocumentScreen,
  DeviceSupportScreen,
  FaqScreen,
  UserManualScreen,
} from '../screens';
import type {DeviceDocumentScreenProps} from '../screens/Education/screens/DeviceDocumentScreen';
import type {FaqScreenProps} from '../screens/Education/screens/FaqScreen';
import type {UserManualScreenProps} from '../screens/support/UserManualScreen';
import {colors} from '../styles';
import {withBackButton, withHamburgerMenu} from './common';
import {fadeStackOptions} from './common';

export type DeviceEducationStackParamList = {
  [Screens.DeviceSupport]: undefined;
  [Screens.Faq]: FaqScreenProps;
  [Screens.DeviceDocument]: DeviceDocumentScreenProps;
  [Screens.UserManual]: UserManualScreenProps;
};

const DeviceEducationStackNavigator =
  createStackNavigator<DeviceEducationStackParamList>();

export const DeviceEducationStack = () => {
  return (
    <DeviceEducationStackNavigator.Navigator
      initialRouteName={Screens.DeviceSupport}
      screenOptions={() => ({
        ...fadeStackOptions,
        ...TransitionPresets.SlideFromRightIOS,
        headerTransparent: false,
        headerTitle: '',
        headerStyle: {
          backgroundColor: colors.black,
          elevation: 0,
          shadowColor: 'transparent',
          borderBottomWidth: 0,
        },
        headerTitleContainerStyle: {
          flexGrow: 1,
          alignItems: 'center',
        },
        headerTitleStyle: {
          textTransform: 'uppercase',
          fontFamily: 'Roboto-Regular',
          fontSize: 16,
          fontWeight: '700',
          color: colors.white,
        },
        ...withBackButton(),
      })}>
      <DeviceEducationStackNavigator.Screen
        name={Screens.DeviceSupport}
        component={DeviceSupportScreen}
        options={{
          headerTitle: Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.TITLE,
          ...withHamburgerMenu(),
        }}
      />

      <DeviceEducationStackNavigator.Screen
        name={Screens.Faq}
        component={FaqScreen}
        options={{
          headerTitle: Strings.DEVICE_EDUCATION.FAQ.TITLE,
        }}
      />

      <DeviceEducationStackNavigator.Screen
        name={Screens.DeviceDocument}
        component={DeviceDocumentScreen}
        options={{
          headerTitle: Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.TITLE,
        }}
      />

      <DeviceEducationStackNavigator.Screen
        name={Screens.UserManual}
        component={UserManualScreen}
        options={{
          headerTitle: Strings.USER_MANUAL,
        }}
      />
    </DeviceEducationStackNavigator.Navigator>
  );
};
