import {Environment} from '../../constants';
import {appVersion} from '../../util/AppVersion';
import {
  ApiCallback,
  ApiCallbackOptions,
  ApiClient,
  getPlatformHeader,
} from './api-client';
import {
  CreateHttpDefaults,
  HttpClientConfig,
  HttpError,
  HttpRequestConfig,
  HttpResponse,
} from './http-client';

interface AuthorizedApiClientConfig extends HttpClientConfig {
  refreshRetries?: number;
}

interface AuthorizedApiCallbackOptions extends ApiCallbackOptions {
  onUnauthorized?: ApiCallback;
  onAccessTokenExpired?: ApiCallback;
}

export class AuthorizedApiClient<
  C extends AuthorizedApiClientConfig = AuthorizedApiClientConfig,
> extends ApiClient<C, AuthorizedApiCallbackOptions> {
  private promise: Promise<any> | null = null;
  public token: Promise<string | null> | string | null = null;

  constructor(options: CreateHttpDefaults<C>) {
    super(options);

    this.client.interceptors.request.use(async config => {
      if (!this.token) return config;

      config.headers.set('Authorization', `Bearer ${await this.token}`);

      return config;
    });

    this.client.interceptors.response.use(undefined, this.retryAfterRefresh);
  }

  // TODO: Third template literas is the error object shape as returned in the api response
  private doesRequireRefresh = ({config, status}: HttpResponse<C>) => {
    if (!config.refreshRetries || config.refreshRetries < 2) return false;

    return status === 401 || status === 403;
    // return (
    //   status === 401 ||
    //   (status === 403 && data.message === 'Forbidden' && !data.errorMessage)
    // );
  };

  private retryAfterRefresh = (error: HttpError<C>) => {
    if (!error.isAxiosError || !error.response) throw error;

    if (!this.doesRequireRefresh(error.response)) throw error;
    return this.refresh()
      .catch(error => {
        this.options?.onUnauthorized?.();

        throw error;
      })
      .then(() =>
        this.client({
          ...error.config,
          refreshRetries: (error.config?.refreshRetries ?? 0) - 1,
        } as HttpRequestConfig<C>),
      );
  };

  private refresh(): Promise<any> {
    if (this.promise) return this.promise;

    if (!this.options?.onAccessTokenExpired)
      throw new Error(`Token expiration strategy is not set.`);

    this.promise = this.options
      .onAccessTokenExpired()
      .then(data => {
        this.promise = null;
        return data;
      })
      .catch(error => {
        this.promise = null;
        throw error;
      });

    return this.promise;
  }
}

export const authorizedApiClient = new AuthorizedApiClient({
  baseURL: Environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'x-app-version': appVersion,
    'x-app-platform': getPlatformHeader(),
    'x-app-type': 'puffco-app',
  },
  refreshRetries: 2,
});
