import React from 'react';
import {Line, Path, Polyline} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const DownloadIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <Polyline points="7 10 12 15 17 10" />
    <Line x1={12} x2={12} y1={15} y2={3} />
  </Icon>
);
