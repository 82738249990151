import axios from 'axios';
import {Buffer} from 'buffer';

import {isOtaValid} from '../utils';
import {otaLog} from '../utils/Logger';

const MAX_DOWNLOAD_ATTEMPTS = 3;

const download = async (path: string, attempt = 1): Promise<Buffer> => {
  otaLog.info('Start firmware download.', {path, attempt});

  try {
    const buffer = await axios
      .get(path, {responseType: 'arraybuffer'})
      .then(r => Buffer.from(r.data, 'binary'));

    if (isOtaValid(buffer)) return buffer;

    throw new Error('The firmware data is invalid.');
  } catch (error) {
    otaLog.error('Firmware download failed.', {path, error, attempt});

    if (attempt < MAX_DOWNLOAD_ATTEMPTS) return download(path, attempt + 1);

    throw error;
  }
};

export const downloadFirmware = (path: string) => download(path);
