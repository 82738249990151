export const Screens = {
  Welcome: 'WelcomeScreen',
  LegalIntro: 'LegalIntroScreen',
  PrivacyPolicy: 'PrivacyPolicyScreen',
  TermsConditions: 'TermsConditionsScreen',
  Carousel: 'CarouselScreen',
  AccountCreated: 'AccountCreatedScreen',
  BluetoothStartPairing: 'BluetoothStartPairingScreen',
  AssignToAHeatProfile: 'AssignToAHeatProfileScreen',
  AdvancedMetrics: 'AdvancedMetricsScreen',
  CleaningReminder: 'CleaningReminderScreen',
  ChangeEmail: 'ChangeEmailScreen',
  CleaningManual: 'CleaningManualScreen',
  ChangePassword: 'ChangePasswordScreen',
  ChangeUsername: 'ChangeUsernameScreen',
  Connect: 'ConnectScreen',
  ControlCenter: 'ControlCenterScreen',
  ControlCenterBoost: 'ControlCenterBoostScreen',
  Dabbing: 'DabbingScreen',
  DevicesList: 'DevicesListScreen',
  DownloadSharedHeatProfile: 'DownloadSharedHeatProfileScreen',
  EmailConfirmed: 'EmailConfirmedScreen',
  EmailVerify: 'EmailVerifyScreen',
  EpilepsyWarning: 'EpilepsyWarningScreen',
  ExclusiveMood: 'ExclusiveMoodScreen',
  FirmwareInfo: 'FirmwareInfoScreen',
  FirmwareUpdating: 'FirmwareUpdatingScreen',
  HeatProfileEdit: 'HeatProfileEditScreen',
  HeatProfileCreate: 'HeatProfileCreateScreen',
  HeatProfileList: 'HeatProfileListScreen',
  HeatProfileSelect: 'HeatProfileSelectScreen',
  Home: 'HomeScreen',
  LanternMode: 'LanternModeScreen',
  Login: 'LoginScreen',
  Loading: 'LoadingScreen',
  MarketOptInConfirmed: 'MarketOptInConfirmedScreen',
  ForgotPassword: 'ForgotPasswordScreen',
  Register: 'RegisterScreen',
  MoodLightCreate: 'MoodLightCreateScreen',
  MoodLightEdit: 'MoodLightEditScreen',
  MoodLightLibrary: 'MoodLightLibraryScreen',
  PasswordReset: 'PasswordResetScreen',
  PasswordResetSent: 'PasswordResetSentScreen',
  PasswordConfirmed: 'PasswordConfirmedScreen',
  UserManual: 'UserManualScreen',
  SessionReady: 'SessionReadyScreen',
  SharedMood: 'SharedMoodScreen',
  SharedHeatProfile: 'SharedHeatProfileScreen',
  SnoozeFrequency: 'SnoozeFrequencyScreen',
  ReminderFrequency: 'ReminderFrequencyScreen',
  PairInstructions: 'PairInstructionsScreen',
  Account: 'AccountScreen',
  Preferences: 'PreferencesScreen',
  TemperatureSettings: 'TemperatureSettingsScreen',
  FactoryReset: 'FactoryResetScreen',
  MarketOptInExist: 'MarketOptInExistScreen',
  LimitedEditionModal: 'LimitedEditionModalScreen',
  LimitedEditionVideo: 'LimitedEditionVideoScreen',
  SaveToDevice: 'SaveToDeviceScreen',
  MoodLightAnimation: 'MoodLightAnimationScreen',
  StaticScreenInterstitial: 'StaticScreenInterstitial',
  XLUpdateRequired: 'XLUpdateRequiredScreen',
  WarrantyClaim: 'WarrantyClaimScreen',
  WarrantyClaimScreenLogs: 'WarrantyClaimScreenLogs',
  WarrantyClaimScreenSuccess: 'WarrantyClaimScreenSuccess',
  WarrantyClaimScreenUserInfo: 'WarrantyClaimScreenUserInfo',
  WarrantyClaimScreenMessage: 'WarrantyClaimScreenMessage',
  WarrantyClaimScreenTwo: 'WarrantyClaimScreenTwo',
  Guide: 'GuideScreen',
  GuideStep: 'GuideStepScreen',
  DeviceSupport: 'DeviceSupportScreen',
  Faq: 'FAQScreen',
  DeviceDocument: 'DeviceDocumentScreen',
  ReferAFriend: 'ReferAFriendScreen',
  MyRewards: 'MyRewardsScreen',
  ReferralWelcome: 'ReferralWelcomeScreen',
  RehashIntro: 'RehashIntroScreen',
  RehashEmpty: 'RehashEmptyScreen',
  RehashUserDabs: 'RehashUserDabsScreen',
  RehashFavoriteDevice: 'RehashFavoriteDeviceScreen',
  RehashFavoriteMoodlight: 'RehashFavoriteMoodlightScreen',
  RehashFavoriteDabTime: 'RehashFavoriteDabTimeScreen',
  RehashCommunityIntro: 'RehashCommunityIntroScreen',
  RehashCommunityDabs: 'RehashCommunityDabsScreen',
  RehashCommunityMoodlightRuntime: 'RehashCommunityMoodlightRuntimeScreen',
  RehashViralMoodlight: 'RehashViralMoodlightScreen',
  RehashOutro: 'RehashOutroScreen',
  RehashCreateAccount: 'RehashCreateAccountScreen',
} as const;

export type ScreenNames = (typeof Screens)[keyof typeof Screens];

export const isScreen = (value: string): value is ScreenNames => {
  return Object.values(Screens).includes(value as ScreenNames);
};

export const Navigators = {
  MainNavigator: 'MainNavigator',
  HomeTabNavigator: 'HomeTabNavigator',
  HomeDrawerNavigator: 'HomeDrawerNavigator',
  MarketOptIn: 'MarketOptInStack',
  Account: 'AccountStack',
  SharedHeatProfile: 'SharedHeatProfileStack',
  HeatProfileEdit: 'HeatProfileEditStack',
  ControlCenter: 'ControlCenterStack',
  DevicesList: 'DevicesListStack',
  HomeEmulatedDrawer: 'HomeEmulatedDrawerStack',
  DownloadSharedHeatProfile: 'DownloadSharedHeatProfileStack',
  MoodLightController: 'MoodLightControllerStack',
  Warranty: 'WarrantyStack',
  DeviceEducation: 'DeviceEducationStack',
  Guide: 'GuideStack',
  ReferralProgram: 'ReferralProgramStack',
  ReferralProgramTab: 'ReferralProgramTabNavigator',
  Community: 'CommunityStack',
  CommunityTab: 'CommunityTabNavigator',
  RehashStory: 'RehashStoryNavigator',
} as const;

export type NavigatorNames = (typeof Navigators)[keyof typeof Navigators];

export const isNavigator = (value: string): value is NavigatorNames => {
  return Object.values(Navigators).includes(value as NavigatorNames);
};
