import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import {colors} from '../styles';

interface BadgeProps extends ViewProps {
  appearance?: 'active';
}

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  appearance = 'active',
  style,
  ...props
}) => <View {...props} style={[styles.container, styles[appearance], style]} />;

const styles = StyleSheet.create({
  container: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: colors.readyGreen,
  },
});
