import {ViralMoodlightDto} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Image, StyleSheet, View} from 'react-native';

import {crown} from '../../../assets/images';
import {LinearGradient} from '../../../components/LinearGradient';
import {ResponsiveText} from '../../../components/ResponsiveText';
import {SwitchableBackground} from '../../../components/SwitchableBackground';
import {Screens} from '../../../constants/navigation';
import {responsive} from '../../../lib/utils';
import type {RehashStoryScreenProps} from '../../../navigators/RehashStoryNavigator';
import {colors} from '../../../styles';
import {DownloadButton} from '../components/DownloadButton';
import {RehashScreen} from '../components/RehashScreen';

export type Props = ViralMoodlightDto;

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashViralMoodlight> {}

const {height} = Dimensions.get('window');

const squareSize = height / 3;

const theme = {
  background: {color: colors.white},
};

export const ViralMoodlight: React.FC<ScreenProps> = ({navigation, route}) => {
  const {title, moodlightName, username, moodlightColors, moodLightShareId} =
    route.params;

  return (
    <RehashScreen
      as={SwitchableBackground}
      style={styles.container}
      background={theme.background}>
      <LinearGradient
        colors={moodlightColors ?? []}
        angle={135}
        useAngle={true}
        style={styles.gradient}
      />

      <View style={styles.contentContainer}>
        <View style={styles.content}>
          <ResponsiveText size={24} style={styles.title}>
            {title}
          </ResponsiveText>

          <View>
            <Image source={crown} style={styles.crown} />

            <ResponsiveText size={112} style={styles.moodLightName}>
              {moodlightName}
            </ResponsiveText>
          </View>
        </View>

        <View style={styles.usernameAndDots}>
          <ResponsiveText size={18} style={styles.username}>
            @{username}
          </ResponsiveText>

          <View style={styles.dotsContainer}>
            {moodlightColors
              ?.reverse()
              .map(backgroundColor => (
                <View
                  key={backgroundColor}
                  style={[styles.dot, {backgroundColor}]}
                />
              ))}
          </View>
        </View>
      </View>

      {moodLightShareId && (
        <View style={styles.buttonContainer}>
          <DownloadButton
            onPress={() => {
              navigation.navigate(Screens.SharedMood, {
                shareId: moodLightShareId,
              });
            }}
          />
        </View>
      )}
    </RehashScreen>
  );
};

const styles = StyleSheet.create({
  container: {flex: 1, paddingHorizontal: 38, position: 'relative'},
  gradient: {
    width: squareSize,
    height: squareSize,
    transform: [{rotate: '45deg'}],
    borderRadius: 12,
    position: 'absolute',
    top: '17%',
    left: -squareSize / 4,
  },
  contentContainer: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    top: '12%',
  },
  content: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  title: {
    opacity: 0.5,
    marginBottom: '18%',
    fontFamily: 'Roboto-Medium',
    paddingRight: 24,
    color: colors.black,
  },
  crown: {
    position: 'absolute',
    top: -36,
    left: -30,
    width: 44,
    height: 41,
  },
  moodLightName: {
    position: 'relative',
    fontFamily: 'Roboto-Bold',
    lineHeight: responsive(107),
    color: colors.black,
  },
  usernameAndDots: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: 32,
  },
  username: {
    opacity: 0.5,
    marginTop: '10%',
    color: colors.black,
  },
  dotsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '12%',
    marginHorizontal: -5,
    marginBottom: -5,
  },
  dot: {
    height: responsive(28),
    width: responsive(28),
    borderRadius: 50,
    margin: 5,
  },
  buttonContainer: {
    position: 'absolute',
    width: squareSize,
    left: -squareSize / 4,
    bottom: '10%',
  },
});
