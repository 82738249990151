import {Screens} from '../constants/navigation';
import NavigationService from '../lib/utils/NavigationService';

export const waitUntilAppLoaded = () => {
  return new Promise<void>(resolve => {
    const check = () => {
      const name = NavigationService.instance()?.getCurrentRoute()?.name;

      if (name !== Screens.Loading) return resolve();

      setTimeout(check, 250);
    };

    check();
  });
};
