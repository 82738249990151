import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const CalendarIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" />
    <Path d="M16 2V6" />
    <Path d="M8 2V6" />
    <Path d="M8.05548 10.4513H16.0555" />
  </Icon>
);
