import React from 'react';
import {Dimensions, Linking, ScrollView, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {AppText} from '../../components/AppText';
import {GlowingHalo} from '../../components/GlowingHalo';
import {HeatProfileScreenLayeredBackground} from '../../components/HeatProfileScreenLayeredBackground';
import {ProfileTemperature} from '../../components/ProfileTemperature';
import {SafeAreaView} from '../../components/SafeAreaView';
import {useSpinner} from '../../components/Spinner';
import {StyledButton} from '../../components/StyledButton';
import {Constants} from '../../constants';
import {NonPathShareReminder, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {shareApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useGetAndStoreExclusiveMoodLights} from '../../lib/hooks/useGetAndStoreExclusiveMoodLights';
import {useOpenApp} from '../../lib/hooks/useOpenApp';
import {appSettingsSelector} from '../../lib/redux/slices/appSettingsSlice';
import {getExclusiveMoodLightSelector} from '../../lib/redux/slices/moodLightSlice';
import {
  isCustomMoodLightSnapshot,
  isTHeatProfileSnapshot,
} from '../../lib/types';
import {changeHexColorValues} from '../../lib/utils/colors';
import {getTimeFormat} from '../../lib/utils/getTimeFormat';
import styled from '../../lib/utils/styled';
import {Temperature} from '../../lib/utils/temperature';
import type {DownloadSharedHeatProfileNavigator} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';
import {defaultTheme} from '../../themes';

interface Props
  extends DownloadSharedHeatProfileNavigator<
    typeof Screens.DownloadSharedHeatProfile
  > {}

const {
  GOOGLE_PLAY_STORE_URL,
  IS_ANDROID_WEB,
  IS_IOS_WEB,
  IS_USING_DESKTOP_WEB,
  PATH_INSTRUCTIONS_URL,
  STD_HSL_VALUES: {saturation: stdS, lightness: stdL},
} = Constants;

const defaultProfile = {
  name: 'Heat Profile',
  duration: 15,
  color: '#808080',
};

export const DownloadSharedHeatProfileScreen = ({route}: Props) => {
  const {top} = useAdaptiveSafeArea();

  const settings = useSelector(appSettingsSelector);
  const getExclusiveMoodLight = useSelector(getExclusiveMoodLightSelector);

  const [[screenWidth, screenHeight], setScreenSize] = React.useState(() => [
    Dimensions.get('window').width,
    Dimensions.get('window').height,
  ]);

  const PROF_TEMP_COMPONENT_HEIGHT = 90;
  const RING_MAX_WD_HT = 350;
  const SHIFT_UP = 55;

  const responsiveRingWidthHeight = screenWidth * 0.88;
  const ringWidthHeight =
    responsiveRingWidthHeight > RING_MAX_WD_HT
      ? RING_MAX_WD_HT
      : responsiveRingWidthHeight;
  const ringVertPostion = screenHeight / 2 - SHIFT_UP;

  const shareId = route.params?.shareId;

  useOpenApp(`/share/${shareId}`);

  const [, getAndStoreExclusives] = useGetAndStoreExclusiveMoodLights(false);

  const {loading, value} = useAsync(async () => {
    if (!shareId) throw new Error('Share missing.');

    const profile = await shareApi
      .getShareSnapshot({shareId})
      .then(r => r.data.heatProfileSnapshot);

    if (!profile) return;

    const moodlight =
      isTHeatProfileSnapshot(profile) && profile.moodLight
        ? profile.moodLight
        : undefined;

    if (!moodlight) return {profile};

    if (isCustomMoodLightSnapshot(moodlight)) return {moodlight};

    await getAndStoreExclusives();

    const exclusive = getExclusiveMoodLight(moodlight.id);

    if (exclusive) return {moodlight: exclusive};

    return {color: colors.defaultColor};
  }, [shareId]);

  const color = React.useMemo(
    () => value?.profile?.color ?? value?.color ?? defaultProfile.color,
    [value],
  );

  React.useEffect(() => {
    if (!window?.addEventListener) return;

    const handleResize = () =>
      setScreenSize([window.innerWidth, window.innerHeight]);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [top]);

  useSpinner({
    isVisible: loading,
    text: Strings.LOADING_DATA,
    color: colors.white,
  });

  if (loading) return null;

  return (
    <HeatProfileScreenLayeredBackground
      style={{width: screenWidth, height: screenHeight}}
      theme={defaultTheme}
      {...(value?.moodlight
        ? {moodLight: value.moodlight}
        : {backgroundColor: color})}>
      <SafeAreaView style={{width: '100%', height: '100%'}}>
        <ScreenContainer>
          {
            // 170 is min possible padding needed for name to be in scrollview for small screens
          }
          <ScrollView style={{paddingTop: screenHeight < 610 ? 170 : 0}}>
            <View
              style={{
                position: 'absolute',
                alignSelf: 'center',
                marginTop:
                  ringVertPostion -
                  (IS_ANDROID_WEB ? 260 : IS_IOS_WEB ? 230 : 270),
              }}>
              <Name
                numberOfLines={2}
                style={{
                  textAlign: 'center',
                  marginBottom: 30,
                }}>
                {value?.profile?.name ??
                  value?.moodlight?.name?.toUpperCase() ??
                  defaultProfile.name.toUpperCase()}
              </Name>

              <GlowContainer>
                <GlowingHalo
                  theme={defaultTheme}
                  colors={
                    value?.moodlight
                      ? value.moodlight.colors
                      : [changeHexColorValues(color, stdS, stdL, true)]
                  }
                  glowWidthHeight={ringWidthHeight}
                  seconds={value?.profile?.duration ?? defaultProfile.duration}
                  innerComponent={
                    <DetailsContainer>
                      <ProfileTemperature
                        style={{paddingLeft: 12}}
                        temperature={
                          value?.profile?.units !== undefined &&
                          value?.profile?.temperature !== undefined
                            ? Math.round(
                                Temperature.convert(value.profile.temperature, {
                                  from: value.profile.units,
                                  to: settings.tempPreference,
                                }),
                              )
                            : 400
                        }
                        temperatureUnit={settings.tempPreference[0]}
                      />
                      <Time
                        style={{
                          color:
                            defaultTheme.heatProfileSelectScreenTheme
                              .durationColor,
                          marginTop: PROF_TEMP_COMPONENT_HEIGHT - 15,
                        }}>
                        {getTimeFormat(
                          value?.profile?.duration ?? defaultProfile.duration,
                          {padMinutes: true, padSeconds: true},
                        )}
                      </Time>
                    </DetailsContainer>
                  }
                />
              </GlowContainer>
            </View>

            <StyledButton
              title={Strings.DOWNLOAD_APP}
              disabled={IS_USING_DESKTOP_WEB}
              style={{
                marginTop: ringVertPostion + (IS_ANDROID_WEB ? 250 : 210),
                width: ringWidthHeight * 0.75,
                alignSelf: 'center',
                position: 'absolute',
              }}
              onPress={() => {
                Linking.openURL(
                  IS_ANDROID_WEB
                    ? GOOGLE_PLAY_STORE_URL
                    : PATH_INSTRUCTIONS_URL,
                );
              }}
            />

            {!IS_ANDROID_WEB && (
              <TipText
                style={{
                  marginTop: ringVertPostion + 280,
                  color: defaultTheme.dabbingScreenTheme.dabbingScreenFadedText,
                }}>
                {IS_USING_DESKTOP_WEB
                  ? Strings.MOBILE_ONLY
                  : NonPathShareReminder}
              </TipText>
            )}
          </ScrollView>
        </ScreenContainer>
      </SafeAreaView>
    </HeatProfileScreenLayeredBackground>
  );
};

const ScreenContainer = styled(View)({
  padding: 30,
  width: '100%',
  height: '100%',
  paddingVertical: 50,
  justifyContent: 'space-between',
});

const Name = styled(AppText)({
  fontSize: 24,
  fontFamily: 'Roboto-Bold',
  alignSelf: 'center',
});

const Time = styled(AppText)({
  color: colors.mediumGray,
  fontSize: 16,
  fontFamily: 'Roboto-Regular',
  position: 'absolute',
});

const GlowContainer = styled(View)({
  width: '100%',
  height: '100%',
  aspectRatio: 1,
  position: 'relative',
  alignItems: 'center',
});

const DetailsContainer = styled(View)({
  width: '100%',
  alignItems: 'center',
  alignSelf: 'center',
});

const TipText = styled(AppText)({
  fontSize: 14,
  fontFamily: 'BigShouldersDisplay-Medium',
  alignSelf: 'center',
  position: 'absolute',
});
