import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const UsersIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z" />
    <Path d="M3 17.6233C3 16.6624 3.63696 15.818 4.56084 15.554V15.554C8.11582 14.5383 11.8842 14.5383 15.4392 15.554V15.554C16.363 15.818 17 16.6624 17 17.6233V20H3V17.6233Z" />
    <Path d="M16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5" />
    <Path d="M19.4392 15.554C20.363 15.818 21 16.6624 21 17.6233V20H16.5" />
  </Icon>
);
