import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const SearchIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z" />
    <Path d="M20.35 20.35L16 16" />
  </Icon>
);
