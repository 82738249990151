import React from 'react';
import {Dimensions, StyleSheet, View} from 'react-native';

import {
  CaptureContainer,
  CaptureContainerRef,
} from '../../../components/CaptureContainer';
import {PressableOpacity} from '../../../components/PressableOpacity';
import {RowContainer} from '../../../components/RowContainer';
import {Text} from '../../../components/Typography';
import {Constants} from '../../../constants';
import {Strings, interpolate} from '../../../constants/Strings';
import {Context, Feature} from '../../../contexts/context';
import {ShareIcon} from '../../../icons';
import {useSafeArea} from '../../../lib/hooks/useSafeArea';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';
import {
  ShareHandler,
  Props as ShareHandlerProps,
} from '../../components/ShareHandler';

export interface Props
  extends Pick<
    ShareHandlerProps,
    'onShareCancel' | 'onShareEnd' | 'onShareStart'
  > {}

const {height} = Dimensions.get('window');

export const Shareable: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onShareCancel,
  onShareEnd,
  onShareStart,
}) => {
  const ref = React.useRef<CaptureContainerRef>(null);
  const insets = useSafeArea();

  const {getFeatureIfEnabled} = Context.useContainer();
  const year = getFeatureIfEnabled(Feature.Rehash)?.label ?? '';

  return (
    <>
      <CaptureContainer
        {...{ref}}
        style={styles.container}
        {...(Constants.IS_NATIVE_ANDROID && {
          contentStyle: {height},
        })}>
        {children}
      </CaptureContainer>

      {children}

      <ShareHandler
        load={async () => {
          const b64Img = await ref?.current?.capture();

          if (!b64Img) return;

          return {url: b64Img};
        }}
        {...{onShareCancel, onShareEnd, onShareStart}}
        icon={props => (
          <Footer>
            <Button
              {...props}
              style={{
                paddingBottom:
                  insets.bottom + 6 + Constants.DIMENSIONS.HEIGHT * 0.008,
              }}>
              <ButtonContent>
                <ShareIcon size={32} containerSize={24} />

                <ShareText>{Strings.SHARE_THIS_SCREEN}</ShareText>
              </ButtonContent>
            </Button>
          </Footer>
        )}
        alertTitle={interpolate(Strings.REHASH.SHARE_TITLE, {year})}
        alertMessage={interpolate(Strings.REHASH.SHARE_MESSAGE, {year})}
        loadingMessage=""
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {height: '100%', width: '100%'},
});

const Footer = styled(View)({
  backgroundColor: colors.black,
});

const Button = styled(PressableOpacity)({
  paddingHorizontal: 4,
  paddingTop: 6 + Constants.DIMENSIONS.HEIGHT * 0.008,
});

const ButtonContent = styled(RowContainer)({
  justifyContent: 'center',
  flexGrow: 0,
  gap: 7,
  alignItems: 'flex-end',
});

const ShareText = styled(Text)({
  color: colors.white,
  marginBottom: -2,
});
