import {Text} from 'react-native';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

export const Title = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  color: colors.white,
});
