import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableHighlightProps,
  View,
} from 'react-native';

import {Constants} from '../constants';
import {IconProps} from '../icons';
import styled from '../lib/utils/styled';
import {DabbingScreenTheme} from '../themes';

export type DabbingButtonProps = {
  icon: React.FC<IconProps>;
  onPress: () => void;
  diameter?: number;
  iconWidth?: number;
  plusPadding?: number;
  theme: DabbingScreenTheme;
} & TouchableHighlightProps;

export const DabbingButton = ({
  icon: Icon,
  onPress,
  diameter = 50,
  iconWidth = 20,
  plusPadding = 22,
  theme,
  style,
  disabled,
  ...rest
}: DabbingButtonProps) => {
  const {
    dabbingButtonBackground,
    dabbingButtonBackgroundPressed,
    dabbingButtonForeground,
    dabbingButtonForegroundPressed,
  } = theme;
  const [isPressed, setIsPressed] = React.useState(false);
  const flattenedStyle = StyleSheet.flatten(style);

  return (
    <TouchableHighlight
      onPress={() => {
        onPress();
      }}
      onHideUnderlay={() => setIsPressed(false)}
      onShowUnderlay={() => setIsPressed(true)}
      disabled={disabled}
      style={{
        ...flattenedStyle,
        height: diameter,
        width: diameter,
        borderRadius: diameter / 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: disabled
          ? Constants.INACTIVE_OPACITY
          : Constants.ACTIVE_OPACITY,
      }}
      {...rest}>
      <Container
        style={{
          height: diameter,
          width: diameter,
          borderRadius: diameter,
          backgroundColor: isPressed
            ? dabbingButtonBackgroundPressed
            : dabbingButtonBackground,
        }}>
        <PlusSign
          style={{
            color: isPressed
              ? dabbingButtonForegroundPressed
              : dabbingButtonForeground,
            paddingRight: plusPadding,
          }}>
          +
        </PlusSign>

        <Icon
          size={iconWidth}
          color={
            isPressed ? dabbingButtonForegroundPressed : dabbingButtonForeground
          }
        />
      </Container>
    </TouchableHighlight>
  );
};

const Container = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const PlusSign = styled(Text)({
  position: 'absolute',
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
});
