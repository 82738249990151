import React from 'react';
import {View} from 'react-native';

import {Props as MaskedImageProps} from './MaskedImage';

export const MaskedImage = ({
  maskSource,
  srcTransform,
  dstImage,
  ...rest
}: MaskedImageProps) => {
  const maskSize =
    typeof srcTransform?.scale === 'string'
      ? srcTransform.scale.toLowerCase()
      : 'contain';

  return (
    <View {...rest}>
      <div
        style={{
          width: '100%',
          height: '100%',
          maskImage: `url(${maskSource})`,
          WebkitMaskImage: `url(${maskSource})`,
          maskSize,
          WebkitMaskSize: maskSize,
          maskRepeat: 'no-repeat',
          WebkitMaskRepeat: 'no-repeat',
        }}>
        {dstImage}
      </div>
    </View>
  );
};
