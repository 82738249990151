import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import {Constants} from '../constants';
import {isWhiteColor} from '../lib/utils/colors';
import {shadeColor} from '../lib/utils/colors/shadeColor';
import {fillStyle} from '../styles';
import {HeatProfileCoreImageTheme} from '../themes';
import {ConicGradientView} from './ConicGradientView';
import {Image} from './ImageWithFilter';
import {MaskedImage} from './MaskedImage';

const {IOS_CONIC_START_ANGLE, IS_NATIVE_ANDROID} = Constants;

export type HeatProfileCoreImageProps = {
  colors: string[];
  theme: HeatProfileCoreImageTheme;
} & ViewProps;

export const HeatProfileCoreImage = ({
  colors,
  theme,
  style,
  ...rest
}: HeatProfileCoreImageProps) => {
  const flattenedStyle = StyleSheet.flatten(style);
  const {baseImageSource, whiteColorShadePercentage} = theme;

  const displayColors =
    whiteColorShadePercentage && colors
      ? colors.map(color =>
          isWhiteColor(color)
            ? shadeColor(color, whiteColorShadePercentage)
            : color,
        )
      : colors;

  return (
    <View style={style} {...rest}>
      <MaskedImage
        /* Flip horizontally on Android to match web implementation. Style props do
         * not seem to affect dstImage so the transforms in ConicGradientView.tsx must
         * be applied here */
        {...(IS_NATIVE_ANDROID && {dstTransform: {scale: {x: -1, y: 1}}})}
        dstImage={ConicGradientView({
          colors: displayColors,
          startAngle:
            IOS_CONIC_START_ANGLE[displayColors ? displayColors.length : 0],
          style: {
            ...fillStyle,
            borderRadius: flattenedStyle.borderRadius,
          },
        })}
        style={{...fillStyle, borderRadius: flattenedStyle.borderRadius}}
      />
      <Image
        resizeMode={'cover'}
        source={baseImageSource}
        style={{
          ...fillStyle,
          position: 'absolute',
          borderRadius: flattenedStyle.borderRadius,
        }}
      />
    </View>
  );
};
