import {TransitionPresets, createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {Platform} from 'react-native';

import {Screens} from '../constants/navigation';
import {DownloadSharedHeatProfileScreen} from '../screens';
import {defaultNavigationOptions} from './common';

export type DownloadSharedHeatProfileStackParamList = {
  [Screens.DownloadSharedHeatProfile]: {shareId: string} | undefined;
};

const DownloadSharedHeatProfileStack =
  createStackNavigator<DownloadSharedHeatProfileStackParamList>();

export const DownloadSharedHeatProfileStackNavigator = () => {
  return (
    <DownloadSharedHeatProfileStack.Navigator
      initialRouteName={Screens.DownloadSharedHeatProfile}
      screenOptions={{
        ...defaultNavigationOptions,
        ...TransitionPresets.ModalSlideFromBottomIOS,
        ...TransitionPresets.ModalPresentationIOS,
        cardOverlayEnabled: true,
        gestureEnabled: false,
        cardStyle: {
          backgroundColor:
            Platform.OS !== 'android' ? '#00000033' : '#000000E6',
        },
        headerTitle: '',
      }}>
      <DownloadSharedHeatProfileStack.Screen
        name={Screens.DownloadSharedHeatProfile}
        component={DownloadSharedHeatProfileScreen}
        options={{
          headerTitle: 'SHARED PROFILE',
        }}
      />
    </DownloadSharedHeatProfileStack.Navigator>
  );
};
