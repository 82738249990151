import {Text} from 'react-native';

import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';

export const BodyText = styled(Text)({
  color: colors.baseText,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  lineHeight: 15,
  marginTop: 14,
  fontWeight: '400',
});
