import React from 'react';
import {useSelector} from 'react-redux';

import {Alert} from '../components/Alert';
import {Messages} from '../constants/Strings';
import {Navigators, Screens} from '../constants/navigation';
import {bleManager} from '../lib/ble2/v2/BleManager';
import {
  ConnectablePeripheral,
  ConnectionPhase,
  OnDisconnect,
} from '../lib/ble2/v2/BleManager/BleManagerBase';
import {IPeakDevice} from '../lib/ble2/v2/PeakDevice/IPeakDevice';
import {ConnectionError} from '../lib/ble2/v2/types';
import {useAppState} from '../lib/hooks/useAppState';
import {useConnectablePeripheral} from '../lib/hooks/useConnectablePeripheral';
import {useProgress} from '../lib/hooks/useProgress';
import {
  connectedPeakSelector,
  currentDeviceFirmwarePendingSelector,
  setConnectedDeviceId,
  updateDevice,
} from '../lib/redux/slices/bleSlice';
import {bleConnect} from '../lib/redux/thunk/bleConnect';
import {useAppDispatch} from '../lib/redux/useAppDispatch';
import {appLog, bleLog} from '../lib/utils/Logger';
import NavigationService from '../lib/utils/NavigationService';
import {sleep} from '../lib/utils/sleep';
import {PermissionError} from '../services/PermissionError';
import {bluetoothService} from '../services/bluetooth';
import {locationService} from '../services/location';
import {BackgroundTimerTask} from '../util/backgroundTimerTask';
import {createFlowId} from '../util/createFlowId';
import {waitUntilAppLoaded} from '../util/waitUntilAppLoaded';
import {createContainer} from './unstated-next';

type ConnectionScreenPhase = ConnectionPhase | 'none' | 'starting';

const onBootloader = async () => {
  NavigationService.instance()?.navigate(Navigators.MainNavigator, {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {screen: Screens.FirmwareUpdating, params: {}},
    },
  });
};

const getError = (
  error: unknown,
): ConnectionError | PermissionError | string => {
  if (!error || typeof error !== 'object')
    return ConnectionError.CONNECTION_ERROR;
  if (!('message' in error)) return ConnectionError.CONNECTION_ERROR;

  switch (error.message) {
    case ConnectionError.DEVICE_NOT_FOUND:
    case ConnectionError.IOS_BLUETOOTH_DISABLED:
    case ConnectionError.IOS_CONNECTION_NOT_FOUND:
    case ConnectionError.IOS_SCAN_TIMEOUT:
    case ConnectionError.PAIRING_ERROR:
    case ConnectionError.IOS_DEVICE_FORGOTTEN:
    case ConnectionError.USER_CANCELLED:
    case ConnectionError.WEB_USER_CANCELLED:
    case ConnectionError.IOS_BONDING_ERROR:
    case ConnectionError.ANDROID_BONDING_ERROR:
    case ConnectionError.IN_BOOTLOADER_STATE:
    case PermissionError.LocationDisabled:
    case PermissionError.LocationCanceled:
    case PermissionError.LocationDismissed:
    case PermissionError.LocationRequiresAction:
    case PermissionError.BluetoothDisabled:
    case PermissionError.BluetoothDenied:
    case PermissionError.BluetoothCanceled:
    case PermissionError.BluetoothDismissed:
    case PermissionError.BluetoothRequiresAction:
      return error.message;
  }

  return ConnectionError.CONNECTION_ERROR;
};

interface FlowOptions {
  flowId: string;
  attempt: number;
}

interface ConnectOptions extends FlowOptions {
  peripheral?: ConnectablePeripheral;
  timeout?: number;
}

interface ReconnectOptions extends FlowOptions {
  peripheral: ConnectablePeripheral;
}

const useConnection = () => {
  const appState = useAppState();

  const peripheral = useConnectablePeripheral();
  const firmwarePending = useSelector(currentDeviceFirmwarePendingSelector);
  const peak = useSelector(connectedPeakSelector);

  const canReconnect = React.useRef(true);
  const [error, setError] = React.useState<Error>();

  const [progress, setProgress, resetProgress] =
    useProgress<ConnectionScreenPhase>({value: 0, data: 'none', duration: 0});

  const dispatch = useAppDispatch();

  const [connecting, setConnecting] = React.useState<
    ConnectablePeripheral | boolean
  >(false);

  const [disconnecting, setDisconnecting] = React.useState<string | boolean>(
    false,
  );

  const promise = React.useRef<Promise<IPeakDevice>>();

  const tryConnect = React.useCallback(
    async (
      {peripheral, flowId, attempt}: ConnectOptions,
      type: 'manual' | 'automatic',
    ) => {
      try {
        await locationService.request();
        await bluetoothService.request();
      } catch (error) {
        setError(error as Error);
        throw error;
      }

      const now = performance.now();

      try {
        setError(undefined);
        setConnecting(peripheral ?? true);
        resetProgress({data: 'starting'});

        if (bleManager.peak) await bleManager.peak.disconnect();
        else if (bleManager.otaDevice) await bleManager.otaDevice?.disconnect();

        const onDisconnect: OnDisconnect = async unit => {
          await unit.disconnect();

          dispatch(setConnectedDeviceId(undefined));

          dispatch(
            updateDevice({
              id: unit.peripheralId,
              // TODO: do not clear these if still broadcasting
              battery: undefined,
              batteryChargeState: undefined,
              approxDabsRemainingCount: undefined,
              lastAdvertisement: Date.now(),
            }),
          );
        };

        const {peak} = await bleManager.connect({
          peripheral,
          onDisconnect,
          onProgress: setProgress,
        });

        canReconnect.current = true;

        if (!peak) {
          await onBootloader();
          throw new Error(ConnectionError.IN_BOOTLOADER_STATE);
        }

        await dispatch(bleConnect({peak})).unwrap();

        setProgress({data: 'done'});

        bleLog.info('Connect succeeded.', {
          peripheral,
          type,
          duration: performance.now() - now,
          attempt,
          flowId,
        });

        return peak;
      } catch (error) {
        bleLog.error('Connect failed.', {
          error,
          peripheral,
          type,
          duration: performance.now() - now,
          attempt,
          flowId,
        });

        setError(error as Error);

        throw error;
      }
    },
    [dispatch],
  );

  const executeOnce = (callback: () => Promise<IPeakDevice>) => {
    if (promise.current) return promise.current;

    promise.current = callback().finally(() => {
      promise.current = undefined;
    });

    return promise.current;
  };

  const connect = React.useCallback(
    (o: ConnectOptions) =>
      executeOnce(async () => {
        return await tryConnect(o, 'manual')
          .finally(() => setConnecting(false))
          .catch(async error => {
            // Wait ~1s to make sure the app came back to foreground if/when the user
            // canceled/accepted the pairing prompt. Otherwise the reconnect will be initiated again.
            await sleep(1000);
            throw error;
          });
      }),
    [tryConnect],
  );

  const reconnect = React.useCallback(
    async ({
      peripheral,
      flowId,
      attempt = 1,
    }: ReconnectOptions): Promise<IPeakDevice> => {
      try {
        return await tryConnect({peripheral, flowId, attempt}, 'automatic');
      } catch (error: any) {
        if (Object.values(PermissionError).includes(error.message)) {
          appLog.error('Reconnect failed with permission error.', {
            error,
            flowId,
            attempt,
          });
          throw error;
        }

        if (
          [
            ConnectionError.IOS_BONDING_ERROR,
            ConnectionError.ANDROID_BONDING_ERROR,
            ConnectionError.USER_CANCELLED,
            ConnectionError.WEB_USER_CANCELLED,
            ConnectionError.IN_BOOTLOADER_STATE,
          ].includes(error.message)
        ) {
          appLog.error('Reconnect failed with unretriable error.', {
            error,
            flowId,
            attempt,
          });
          throw error;
        }

        if (attempt >= 3) {
          appLog.error('Reconnect failed after several retries.', {
            error,
            flowId,
            attempt,
          });
          throw error;
        }

        appLog.error('Reconnect failed. Retrying...', {
          error,
          flowId,
          attempt,
        });

        return reconnect({peripheral, flowId, attempt: attempt + 1});
      }
    },
    [tryConnect],
  );

  const preventReconnect = React.useCallback(() => {
    canReconnect.current = false;
  }, []);

  const disconnect = React.useCallback(async () => {
    preventReconnect();
    setDisconnecting(peak?.peripheralId ?? false);

    await peak?.disconnect().finally(() => setDisconnecting(false));
  }, [peak, preventReconnect]);

  React.useEffect(() => {
    switch (appState) {
      case 'active': {
        // We shouldn't reconnect while a connection is in progress, because the new
        // connection might force the app into background and back (e.g. pairing prompt on iOS)
        if (promise.current) return;

        if (firmwarePending) return;
        if (!canReconnect.current || peak) return;
        if (!peripheral) return;

        executeOnce(async () => {
          return await reconnect({
            peripheral,
            flowId: createFlowId(),
            attempt: 1,
          })
            .finally(() => setConnecting(false))
            // Wait ~1s to make sure the app came back to foreground if/when the user
            // canceled/accepted the pairing prompt. Otherwise the reconnect will be initiated again.
            .catch(async error => {
              await sleep(1000);
              throw error;
            });
        }).catch(() => void 0);

        break;
      }
      case 'background': {
        if (!peak) return;

        // Disconnect when the app stays in the background for a long period
        const timer = BackgroundTimerTask.setTimeout(
          () => peak.disconnect().catch(() => void 0),
          600000,
        );

        return () => BackgroundTimerTask.clearTimeout(timer);
      }
    }
  }, [appState, peak, peripheral]);

  React.useEffect(() => {
    if (!firmwarePending) return;

    // We need to wait, otherwise the initial screen will overwrite this navigation
    waitUntilAppLoaded()
      .then(() => {
        Alert.alert(Messages.OTA.PENDING.title, Messages.OTA.PENDING.body, [
          {text: 'Cancel'},
          {
            text: 'Continue',
            onPress: async () => {
              await onBootloader();
            },
          },
        ]);
      })
      .catch(() => void 0);
  }, []);

  return {
    peak,
    progress,
    resetProgress,
    connect,
    connecting,
    disconnect,
    disconnecting,
    preventReconnect,
    error: error ? getError(error) : undefined,
  };
};

export const Connection = createContainer(useConnection);
