import {useAsyncFn} from 'react-use';

import {userApi} from '../api/apis';
import {CustomMoodLight} from '../types';

export function useGetMoodLights() {
  return useAsyncFn(async () => {
    const response = await userApi.getMoodLights({id: 'me'});

    return response.data.map(
      light =>
        ({
          ...light,
          led3Meta: light.led3Meta ?? {
            userColors: [...light.colors],
            tempoFrac: light.tempo,
          },
        }) as unknown as CustomMoodLight,
    );
  }, []);
}
