import React from 'react';
import {StyleSheet} from 'react-native';

import {
  StyledButton,
  StyledButtonProps,
} from '../../../../../components/StyledButton';
import {Text} from '../../../../../components/Typography';
import {ChevronLeftIcon} from '../../../../../icons';
import {ChevronRightIcon} from '../../../../../icons';
import {addOpacityToColorHex} from '../../../../../lib/utils/colors';
import {colors} from '../../../../../styles';

interface Props extends StyledButtonProps {
  previous?: boolean;
}

export const GalleryNavigationButton: React.FC<
  React.PropsWithChildren<Props>
> = ({previous, title, ...rest}) => (
  <StyledButton {...rest} style={styles.buttonContainer}>
    {!!previous && (
      <ChevronLeftIcon
        strokeWidth={2}
        opacity={0.5}
        style={styles.icon}
        containerSize={24}
        size={24}
      />
    )}

    <Text
      variant="lead"
      style={[
        styles.buttonText,
        {
          color: previous
            ? addOpacityToColorHex(colors.white, 0.5)
            : colors.black,
        },
      ]}>
      {title}
    </Text>

    {!previous && (
      <ChevronRightIcon
        strokeWidth={2}
        style={styles.icon}
        color={colors.black}
      />
    )}
  </StyledButton>
);

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    flex: 1,
  },
  buttonText: {
    padding: 0,
    paddingTop: 0,
    textTransform: 'uppercase',
  },
  icon: {
    padding: 0,
    margin: -6,
  },
});
