import {AgreementType} from 'puffco-api-axios-client';
import React from 'react';
import {ActivityIndicator, ScrollView, StyleSheet, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useDispatch, useSelector} from 'react-redux';

import {StyledButton} from '../../components/StyledButton';
import {StyledCheckBox} from '../../components/StyledCheckBox';
import {Text} from '../../components/Typography';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {ChevronRightIcon} from '../../icons';
import {agreementApi, agreementTrackingApi} from '../../lib/api/apis';
import {useAsyncFn} from '../../lib/hooks/useAsyncFn';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../../lib/redux/slices/appSettingsSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';
import {closeApp} from '../../util/closeApp';
import {LogoHeader} from '../components';

interface ScreenProps extends RootStackScreenProps<typeof Screens.LegalIntro> {}

export const LegalIntroScreen: React.FC<ScreenProps> = ({navigation}) => {
  const {top, bottom} = useSafeArea();
  const [accepted, setAccepted] = React.useState(false);

  const user = useSelector(userSelector);
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const dispatch = useDispatch();

  useBackPress(() => true);

  // Start loading agreements as soon as user opens the screen
  const promise = React.useMemo(async () => {
    const [pp, tc] = await Promise.all([
      agreementApi
        .getLatestAgreement({type: AgreementType.Pp})
        .then(r => r.data),
      agreementApi
        .getLatestAgreement({type: AgreementType.Tc})
        .then(r => r.data),
    ]);

    return {
      [AgreementType.Pp]: pp,
      [AgreementType.Tc]: tc,
    };
  }, []);

  const [{loading}, onAccept] = useAsyncFn(
    async (userId?: number): Promise<void> => {
      const agreement = await promise;

      const generatedUserId = agreeAgreement?.generatedUserId;

      if (!generatedUserId) throw new Error('No user id found.');

      await Promise.all(
        [AgreementType.Pp, AgreementType.Tc].map(type =>
          agreementTrackingApi
            .addNewAgreementTracking({
              agreementTrackingCreateDto: {
                type,
                version: agreement[type].version,
                generatedUserId,
                userId,
              },
            })
            .then(r => r.data),
        ),
      );

      dispatch(
        updateAppSettings({
          agreeAgreement: {...agreeAgreement, PP: true, TC: true},
        }),
      );

      navigation.navigate(Screens.Carousel);
    },
    [promise, agreeAgreement, navigation],
  );

  return (
    <View style={{...styles.container, paddingTop: top}}>
      <ScrollView contentContainerStyle={styles.scroller}>
        <LogoHeader>
          <Text variant="h2" style={styles.title}>
            {Messages.LEGAL_INTRO_TITLE}
          </Text>
        </LogoHeader>

        <View style={styles.content}>
          <Text>{Messages.LEGAL_INTRO_MESSAGE}</Text>

          <View>
            <LinkTouchable
              style={{borderBottomWidth: 1}}
              onPress={() => navigation.navigate(Screens.TermsConditions, {})}>
              <Text variant="lead">{Messages.TOS_SECTION_TITLE}</Text>

              <ChevronRightIcon
                size={28}
                containerSize={28}
                color={colors.black}
              />
            </LinkTouchable>

            <LinkTouchable
              onPress={() => navigation.navigate(Screens.PrivacyPolicy, {})}>
              <Text variant="lead">{Messages.PRIVACY_SECTION_TITLE}</Text>

              <ChevronRightIcon
                size={28}
                containerSize={28}
                color={colors.black}
              />
            </LinkTouchable>
          </View>

          <StyledCheckBox
            checked={accepted}
            onChange={accepted => {
              if (loading) return;
              return setAccepted(accepted);
            }}
            style={{alignItems: 'flex-start'}}
            checkboxColor={colors.coal}>
            <Text style={styles.checkBoxText} variant="s">
              {Messages.LEGAL_INTRO_AGREEMENT}
            </Text>
          </StyledCheckBox>
        </View>
      </ScrollView>

      <View style={{...styles.buttons, paddingBottom: 24 + bottom}}>
        <PrimaryButton
          title={!loading ? Strings.ACCEPT : undefined}
          disabled={!accepted || loading}
          onPress={() => onAccept(user?.id).catch(() => void 0)}
          textStyle={{color: colors.white}}>
          {loading && <ActivityIndicator size={24} color={colors.white} />}
        </PrimaryButton>

        <SecondaryButton
          title={Strings.DECLINE_AND_CLOSE_APP}
          disabled={loading}
          onPress={() => closeApp()}
          textStyle={{color: colors.black}}
        />
      </View>
    </View>
  );
};

const LinkTouchable = styled(TouchableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 48,
  borderColor: colors.lightestGrey,
});

const PrimaryButton = styled(StyledButton)({
  backgroundColor: colors.black,
  width: '100%',
});

const SecondaryButton = styled(StyledButton)({
  backgroundColor: colors.white,
  borderWidth: 1,
  width: '100%',
  borderColor: colors.lightMediumGray,
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    flexBasis: 0,
  },
  scroller: {
    display: 'flex',
    gap: 32,
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  content: {
    gap: 24,
  },
  title: {marginTop: 24},
  checkBoxText: {
    transform: [{translateY: -2}],
  },
  buttons: {
    display: 'flex',
    gap: 8,
    paddingHorizontal: 24,
    backgroundColor: colors.white,
  },
});
