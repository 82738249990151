import {Formik, FormikHelpers, FormikProps} from 'formik';
import {UsernameUpdateDto} from 'puffco-api-axios-client';
import React from 'react';
import {KeyboardAvoidingView, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';
import * as Yup from 'yup';

import {backgroundUpdateUsernameNew} from '../../assets/images';
import {Alert} from '../../components/Alert';
import {
  StyledField,
  displayRightIconMessage,
  getRightIcon,
} from '../../components/StyledField';
import {Alerts, ErrorMessages, Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {UserIcon} from '../../icons';
import {userApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useWindowHeight} from '../../lib/hooks/useWindowHeight';
import {updateAppFlags} from '../../lib/redux/slices/appFlagsSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import styled from '../../lib/utils/styled';
import {updateUser} from '../../lib/utils/updateUser';
import type {HomeEmulatedDrawerNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {toHome} from '../../navigators/util';
import {analytics} from '../../services/analytics';
import {colors} from '../../styles';
import {ImageAndContentContainer} from '../components';

interface ChangeUsernameValues {
  username: string;
}

interface Props
  extends HomeEmulatedDrawerNavigatorScreenProps<
    typeof Screens.ChangeUsername
  > {}

export const ChangeUsernameScreen = ({route, navigation}: Props) => {
  const ref = React.useRef<FormikProps<{username: string}>>(null);

  const redirect = route.params?.redirect;

  useAdaptiveSafeArea();

  const dispatch = useDispatch();

  const [, changeUsername] = useAsyncFn(
    async (dto: UsernameUpdateDto) => {
      await updateUser(dispatch, dto);

      analytics.trackEvent('username update', {});
      Alert.alert(Alerts.SUCCESS, Alerts.USERNAME_UPDATED_MESSAGE);

      navigation.replace(...(redirect ?? toHome));
    },
    [navigation, redirect],
  );

  const currentUser = useSelector(userSelector);
  const {isMedium} = useWindowHeight();

  const validateUser = React.useCallback(
    async (value: string) => {
      if (value === currentUser?.username) return;

      const {data} = await userApi.validateFields({
        userValidateFieldsDto: {username: value},
      });

      if (data.username === 'ok') return;

      const message =
        data.username === 'taken'
          ? 'Username is taken'
          : data.username === 'offensive'
            ? 'Offensive usernames are prohibited'
            : 'Username is invalid';

      if (ref.current?.values.username === value)
        ref.current?.setFieldError('username', message);
    },
    [currentUser],
  );

  const ChangeUsernameSchema = Yup.object().shape({
    username: Yup.string()
      .required(ErrorMessages.REQUIRED_USERNAME)
      .min(5, ErrorMessages.REQUIRE_USERNAME_MIN_LENGTH)
      .max(18, ErrorMessages.REQUIRE_USERNAME_MAX_LENGTH)
      .test({
        name: 'checkUsername',
        test: function (value) {
          if (value && value.length >= 5 && value.length <= 18) {
            validateUser(value);
          }
          return true;
        },
      }),
  });

  const handleSave = async (
    values: ChangeUsernameValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    await changeUsername({username: values.username});
    dispatch(updateAppFlags({hasSeenNewUsername: true}));
    actions.setSubmitting(false);
  };

  return (
    <ImageAndContentContainer
      image={backgroundUpdateUsernameNew}
      header={{content: Messages.UPDATE_USERNAME_TITLE}}
      body={{content: Messages.UPDATE_USERNAME_MSG, style: {textAlign: 'left'}}}
      primaryButton={{
        title: Strings.UPDATE_USERNAME,
        onClick: () => ref?.current?.handleSubmit(),
      }}
      secondaryButton={{
        onClick: () => {
          dispatch(updateAppFlags({hasSeenNewUsername: true}));
          navigation.replace(...(redirect ?? toHome));
        },
      }}>
      <Formik
        innerRef={ref}
        initialValues={{username: currentUser?.username ?? ''}}
        onSubmit={handleSave}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={ChangeUsernameSchema}>
        {({handleBlur, handleChange, values, errors, setFieldValue}) => (
          <KeyboardAvoidingView behavior={'height'}>
            <FieldsContainer>
              <StyledField
                icon={UserIcon}
                isBordered={true}
                height={isMedium ? 45 : 56}
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
                placeholder={Alerts.NEW_USERNAME_TITLE}
                textColor={colors.black50}
                borderColor={colors.lightMediumGray}
                value={values.username}
                leftIconColor={colors.black30}
                iconPress={() =>
                  !!errors.username && setFieldValue('username', '')
                }
                rightIcon={getRightIcon(
                  values.username,
                  currentUser?.username === values.username
                    ? undefined
                    : errors.username,
                  currentUser?.username !== values.username,
                )}
                rightIconColor={errors.username ? colors.red : colors.green}
                rightIconMessage={displayRightIconMessage(
                  values.username,
                  currentUser?.username === values.username
                    ? undefined
                    : errors.username,
                  currentUser?.username !== values.username,
                )}
              />
            </FieldsContainer>
          </KeyboardAvoidingView>
        )}
      </Formik>
    </ImageAndContentContainer>
  );
};

const FieldsContainer = styled(View)({
  width: '100%',
  paddingTop: 24,
});
