import React from 'react';
import {Animated, TouchableOpacity} from 'react-native';

import {DeleteIcon} from '../../../icons';
import styled from '../../../lib/utils/styled';

export const SwipeRightActions = ({
  dragX,
  onPress,
  width,
  opacity,
}: {
  dragX: Animated.AnimatedInterpolation<number>;
  onPress: () => void;
  width?: Animated.AnimatedInterpolation<number>;
  opacity?: Animated.AnimatedInterpolation<number>;
}) => {
  const opacityInterpolate = dragX.interpolate({
    inputRange: [-150, 0.5],
    outputRange: [1, 0.1],
  });

  return (
    <Animated.View
      style={{
        position: 'relative',
        marginLeft: -5,
        marginTop: 11.5,
        marginBottom: 1.5,
        width: width ?? '50%',
        opacity,
      }}>
      <SwipeView
        style={{
          opacity: opacityInterpolate,
        }}>
        <TouchableOpacity onPress={onPress}>
          <DeleteIcon size={22} />
        </TouchableOpacity>
      </SwipeView>
    </Animated.View>
  );
};

const SwipeView = styled(Animated.View)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  backgroundColor: 'red',
});
