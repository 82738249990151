import {Asset} from 'contentful';
import React from 'react';
import {Linking, SafeAreaView, View} from 'react-native';

import {CloseButton} from '../../../../components/CloseButton';
import {ImgBackground} from '../../../../components/ImgBackground';
import {StyledButton} from '../../../../components/StyledButton';
import {Text} from '../../../../components/Typography';
import {Constants} from '../../../../constants';
import {Strings} from '../../../../constants/Strings';
import {Screens} from '../../../../constants/navigation';
import {isResolvedEntryLink, resolveAssetUrl} from '../../../../lib/api';
import {TypeGuide} from '../../../../lib/api/content-access/types';
import {useAdaptiveSafeArea} from '../../../../lib/hooks/useAdaptiveSafeArea';
import {useExitStack} from '../../../../lib/hooks/useExitStack';
import styled from '../../../../lib/utils/styled';
import type {GuideNavigatorScreenProps} from '../../../../navigators/HomeDrawerNavigator';
import {colors} from '../../../../styles';

export interface GuideScreenProps {
  guide: TypeGuide;
  onExit?: () => void;
}

interface ScreenProps extends GuideNavigatorScreenProps<typeof Screens.Guide> {}

export const GuideScreen: React.FC<ScreenProps> = ({route, navigation}) => {
  const guide = route.params.guide;
  const onExit = route.params.onExit;

  const exitStack = useExitStack();
  const steps = guide.fields.steps?.filter(isResolvedEntryLink) ?? [];
  const manual = guide.fields.manual;

  useAdaptiveSafeArea();

  const onDismiss = React.useCallback(
    () => (onExit ? onExit() : exitStack()),
    [navigation, onExit],
  );

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <CloseButton onPress={() => onDismiss()} color={colors.black} />
      ),
    });
  }, [navigation, onDismiss]);

  const onCompletion = () => {
    if (!manual) return;

    if (Constants.IS_WEB) {
      Linking.openURL(resolveAssetUrl(manual));
    } else {
      navigation.navigate(Screens.UserManual, {
        source: {uri: resolveAssetUrl(manual)},
      });
    }
  };

  const onNext = (currentIndex: number) => {
    if (currentIndex === steps.length) {
      onDismiss();
      return;
    }

    navigation.push(Screens.GuideStep, {
      stepNumber: currentIndex + 1,
      step: steps[currentIndex],
      totalSteps: steps.length,
      onNext,
      onDismiss,
      onCompletion,
      title: guide.fields.name.toUpperCase(),
    });
  };

  return (
    <ImgBackground
      source={{
        uri: resolveAssetUrl(guide.fields.image as Asset),
      }}>
      <Container>
        <TextContainer>
          <GuideSubtitle variant="s">{guide.fields.name}</GuideSubtitle>

          <GuideTitle variant="h1">{guide.fields.title}</GuideTitle>
        </TextContainer>

        <ButtonContainer>
          <ViewGuideButton
            title={Strings.DEVICE_EDUCATION.GUIDESCREEN.VIEW_GUIDE}
            textStyle={{
              color: colors.white,
              textTransform: 'uppercase',
            }}
            onPress={() => onNext(0)}
          />

          <CancelButton
            title={Strings.CANCEL}
            textStyle={{
              color: colors.black,
            }}
            onPress={() => onDismiss()}
          />
        </ButtonContainer>
      </Container>
    </ImgBackground>
  );
};

const Container = styled(SafeAreaView)({
  flex: 1,
  alignItems: 'center',
});

const TextContainer = styled(View)({
  alignItems: 'center',
  flex: 1,
  marginTop: 96,
});

const GuideTitle = styled(Text)({
  textAlign: 'center',
  marginTop: 0,
  paddingHorizontal: 24,
  textTransform: 'uppercase',
});

const GuideSubtitle = styled(Text)({
  letterSpacing: 2,
  textAlign: 'center',
  alignSelf: 'center',
  paddingBottom: 16,
  textTransform: 'uppercase',
});

const ButtonContainer = styled(View)({
  width: '100%',
  paddingHorizontal: 24,
  paddingBottom: 36,
  flexDirection: 'column',
});

const ViewGuideButton = styled(StyledButton)({
  width: '100%',
  alignSelf: 'center',
  backgroundColor: colors.black,
});

const CancelButton = styled(StyledButton)({
  width: '100%',
  alignSelf: 'center',
  backgroundColor: colors.white,
  marginTop: 8,
});
