import React from 'react';
import {
  Dimensions,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';

import {Constants} from '../constants';
import styled from '../lib/utils/styled';
import {StyledButtonTheme, defaultTheme} from '../themes';
import {SideButtonIcon} from './SideButtonIcon';

const {height} = Dimensions.get('window');
const {PX_785} = Constants.SCREEN_HEIGHT;
const isMedium = height <= PX_785;

export const STYLED_BUTTON_DEFAULT_HEIGHT = isMedium ? 45 : 56;

export type StyledButtonProps = TouchableOpacityProps & {
  /*
   * Name to be rendered on the button
   */
  title?: string;
  /*
   * Style of button text
   */
  textStyle?: TextStyle;
  /*
   * Theme of the button
   */
  theme?: StyledButtonTheme;
  buttonIcon?: ImageSourcePropType;
};

const Button = styled(TouchableOpacity)({
  borderRadius: 80,
  height: STYLED_BUTTON_DEFAULT_HEIGHT,
  width: 260,
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

export const ButtonText = styled(Text)({
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  letterSpacing: 0.75,
  textTransform: 'uppercase',
  includeFontPadding: false,
  fontWeight: '400',
  ...(Platform.OS === 'web' && {paddingTop: 2}),
});

export const StyledButton: React.FC<
  React.PropsWithChildren<StyledButtonProps>
> = ({
  title,
  textStyle,
  disabled,
  theme = defaultTheme.styledButtonTheme,
  buttonIcon,
  style,
  children,
  ...rest
}) => (
  <Button
    disabled={disabled}
    style={{
      backgroundColor: theme.buttonColor,
      opacity: disabled ? Constants.INACTIVE_OPACITY : Constants.ACTIVE_OPACITY,
      flexDirection: buttonIcon ? 'row' : undefined,
      ...StyleSheet.flatten(style),
    }}
    {...rest}>
    {buttonIcon && <SideButtonIcon imageSrc={buttonIcon} />}
    {!!title && (
      <ButtonText style={{color: theme.buttonTextColor, ...textStyle}}>
        {title}
      </ButtonText>
    )}
    {children}
  </Button>
);
