import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {Navigators, Screens} from '../../constants/navigation';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigators/RootStackNavigator';
import {userSelector} from '../redux/slices/userSlice';

type Navigate = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const useUserVerifiedGuard = () => {
  const user = useSelector(userSelector);
  const navigation = useNavigation<Navigate>();

  const isUserVerified = React.useCallback(() => {
    if (!user) return false;

    if (!user.verified) {
      navigation.navigate(Navigators.MainNavigator, {
        screen: Navigators.HomeDrawerNavigator,
        params: {
          screen: Navigators.HomeEmulatedDrawer,
          params: {
            screen: Screens.EmailVerify,
          },
        },
      });

      return false;
    }

    return true;
  }, [user, navigation]);

  return React.useMemo(
    () => ({
      isUserVerified,
    }),
    [isUserVerified],
  );
};
