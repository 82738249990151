import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Text, View} from 'react-native';

import {Strings} from '../../../constants/Strings';
import {Screens} from '../../../constants/navigation';
import {CloseIcon} from '../../../icons';
import styled from '../../../lib/utils/styled';
import type {RootStackScreenProps} from '../../../navigators/RootStackNavigator';
import {colors} from '../../../styles';

const {WARNING, ACCOUNT_LOCKED} = Strings;

export type UserAttemptData = {
  countAttempt?: number;
  accountStatus?: boolean;
  invalidPass?: boolean;
};

type AccountStatusProps = {
  setAttemptData: React.Dispatch<React.SetStateAction<UserAttemptData>>;
  attemptData: UserAttemptData;
};

type Navigation = RootStackScreenProps<typeof Screens.Login>['navigation'];

export const AccountStatus = ({
  setAttemptData,
  attemptData,
}: AccountStatusProps) => {
  const navigation = useNavigation<Navigation>();
  const handleClick = () => {
    setAttemptData({
      ...attemptData,
      accountStatus: false,
    });
  };
  return (
    <View>
      <Container>
        <CloseIcon
          onPress={handleClick}
          size={22}
          containerSize={32}
          color={colors.white}
          style={{position: 'absolute', right: 7, top: 7, padding: 5}}
        />
        <View>
          {attemptData.countAttempt === 0 ? (
            <>
              <Title>{ACCOUNT_LOCKED}</Title>
              <Message>
                Please try again in <BoldText>15</BoldText> min, or reset your
                password{' '}
                <TextLink
                  onPress={() => navigation.navigate(Screens.ForgotPassword)}>
                  here
                </TextLink>
              </Message>
            </>
          ) : (
            <>
              <Title>{WARNING}</Title>
              <Message>
                For security reasons, you have{' '}
                <BoldText>{attemptData.countAttempt}</BoldText> remaining
                attempts until your account is temporarily locked for{' '}
                <BoldText>15</BoldText> min
              </Message>
            </>
          )}
        </View>
      </Container>
    </View>
  );
};

const Container = styled(View)({
  backgroundColor: colors.red,
  position: 'absolute',
  bottom: 0,
  width: '100%',
  paddingVertical: 14,
  paddingHorizontal: 45,
  zIndex: 5,
});

const Title = styled(Text)({
  textAlign: 'center',
  marginBottom: 2,
  color: colors.white,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 16,
  letterSpacing: 0.49,
});

const Message = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  color: colors.white,
  textAlign: 'center',
  fontSize: 14,
  lineHeight: 16,
  letterSpacing: 0.43,
});

const BoldText = styled(Text)({
  fontWeight: '700',
});

const TextLink = styled(Text)({
  textDecorationLine: 'underline',
});
