import React from 'react';
import {Image, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {paintBrushUnderline} from '../../../assets/images';
import {ResponsiveText} from '../../../components/ResponsiveText';
import {colors} from '../../../styles';

interface Props {
  text: string;
  size?: number;
  lineHeight?: number;
  underlineHeight?: number;
  style?: StyleProp<ViewStyle>;
}

export const Underlined: React.FC<Props> = ({
  size = 16,
  text,
  lineHeight,
  underlineHeight,
}) => {
  const [textWidth, setTextWidth] = React.useState(0);

  return (
    <View style={styles.container}>
      <ResponsiveText
        {...{size}}
        numberOfLines={1}
        style={[styles.text, {lineHeight}]}
        onLayout={event => setTextWidth(event.nativeEvent.layout.width)}>
        {text}
      </ResponsiveText>

      {textWidth > 0 && (
        <Image
          source={paintBrushUnderline}
          style={[
            styles.underline,
            {width: textWidth + 8, height: underlineHeight},
          ]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    alignItems: 'center',
  },
  underline: {
    position: 'absolute',
    bottom: 6,
    resizeMode: 'stretch',
  },
  text: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
  },
});
