import {FavoriteMoodlightDto} from 'puffco-api-axios-client';
import React from 'react';
import {View} from 'react-native';

import {ResponsiveText} from '../../../components/ResponsiveText';
import {RowContainer} from '../../../components/RowContainer';
import {SwitchableBackground} from '../../../components/SwitchableBackground';
import {Screens} from '../../../constants/navigation';
import {responsive} from '../../../lib/utils';
import {addOpacityToColorHex} from '../../../lib/utils/colors';
import {formatNumberWithCommas} from '../../../lib/utils/formatNumber';
import styled from '../../../lib/utils/styled';
import type {RehashStoryScreenProps} from '../../../navigators/RehashStoryNavigator';
import {colors} from '../../../styles';
import {
  BackgroundGrid,
  BoldText,
  FavoriteMoodlightGradient,
  MediumText,
} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends FavoriteMoodlightDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashFavoriteMoodlight> {}

const theme = {background: {gradient: ['#292828', colors.black]}};

export const FavoriteMoodlight: React.FC<ScreenProps> = ({route}) => {
  const {
    title,
    subtitle,
    dabCount,
    moodlightColors: colors = [],
    moodlightName,
  } = route.params;

  return (
    <RehashScreen as={Container} background={theme.background}>
      <BackgroundGrid />

      <DataContainer>
        <DotsContainer>
          {colors.map((color, index) => (
            <ColorDot key={index} style={{backgroundColor: color}} />
          ))}
        </DotsContainer>

        <TitleContainer>
          <Title size={24}>{title}</Title>

          <BoldText numberOfLines={5} textBreakStrategy="balanced" size={56}>
            {moodlightName}
          </BoldText>
        </TitleContainer>
      </DataContainer>

      <Gradient {...{colors}} />

      <InfoBox>
        <InfoText>
          <BoldText>{formatNumberWithCommas(dabCount)} </BoldText>
          {subtitle}
        </InfoText>
      </InfoBox>
    </RehashScreen>
  );
};

const Container = styled(SwitchableBackground)({
  flex: 1,
});

const Gradient = styled(FavoriteMoodlightGradient)({
  flex: 1,
});

const TitleContainer = styled(View)({
  flex: 1,
});

const DataContainer = styled(RowContainer)({
  paddingHorizontal: '12%',
  paddingTop: '15%',
  paddingBottom: '13%',
  justifyContent: 'flex-start',
});

const DotsContainer = styled(View)({
  marginRight: 30,
});

const ColorDot = styled(View)({
  width: responsive(24),
  height: responsive(24),
  borderRadius: 12,
  marginBottom: 10,
});

const Title = styled(MediumText)({
  color: addOpacityToColorHex(colors.white, 0.5),
});

const InfoText = styled(ResponsiveText)({
  color: colors.white,
});

const InfoBox = styled(View)({
  position: 'absolute',
  bottom: '5%',
  alignSelf: 'center',
  backgroundColor: addOpacityToColorHex(colors.black, 0.5),
  paddingHorizontal: 30,
  paddingVertical: 12,
  borderRadius: 50,
});
