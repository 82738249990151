import React from 'react';
import {Path, Rect} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const PhoneIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Rect x={6} y={1} width={12} height={22} rx={2} />
    <Path d="M10 4H14" />
  </Icon>
);
