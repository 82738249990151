import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const HomeIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M5 12V18.7692C5 19.0957 5.16389 19.4087 5.45561 19.6395C5.74733 19.8703 6.143 20 6.55556 20H17.4444C17.857 20 18.2527 19.8703 18.5444 19.6395C18.8361 19.4087 19 19.0957 19 18.7692V12" />
    <Path d="M2 10L12 4L22 10" />
    <Path d="M14 20V15C14 14.4477 13.5523 14 13 14H11C10.4477 14 10 14.4477 10 15V20" />
  </Icon>
);
