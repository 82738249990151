import {Constants} from '../../constants';
import {EmitterEvent, Handler} from '../types';

export class MultiEmitter<T> {
  private handlers: Handler<T>[] = [];

  emit(t: T | EmitterEvent) {
    this.handlers.forEach(handler => handler(t));
  }

  subscribe(handler: Handler<T>) {
    this.handlers.push(handler);
    return handler;
  }

  unsubscribe(handler: Handler<T>) {
    const index = this.handlers.indexOf(handler);
    if (index > Constants.INDEX_NOT_FOUND) {
      this.handlers.splice(index, 1);
    }
  }
}
