import {Constants} from '../../constants';

const {HEIGHT} = Constants.DIMENSIONS;

const isTablet = HEIGHT > 1024;

export const responsive = (
  size: number,
  designHeight = Constants.SCREEN_HEIGHT.IPHONE_14_PRO_MAX,
) => (isTablet ? size : size * (HEIGHT / designHeight));
