import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

import {IconProps} from '../icons';
import {useTheme} from '../lib/hooks/useTheme';
import styled from '../lib/utils/styled';
import {centeredRowStyle} from '../styles';

export type RoundButtonProps = {
  height: number;
  width: number;
  icon?: React.FC<IconProps>;
  text?: string;
  isActive: boolean;
  iconWidth?: number;
  iconHeight?: number;
  onPress: () => void;
  onLongPress?: () => void;
};

export const LargeRoundButton = (props: RoundButtonProps) => {
  const {
    buttonActiveBackground,
    buttonInactiveBackground,
    buttonActiveForeground,
    buttonInactiveForeground,
  } = useTheme().controlCenterTheme;
  const {
    height,
    width,
    icon: Icon,
    text,
    isActive = false,
    onPress,
    iconWidth,
    iconHeight,
  } = props;

  return (
    <TouchableOpacity
      onPress={() => {
        onPress();
      }}>
      <Container
        style={{
          height: height,
          width: width,
          borderRadius: height,
          backgroundColor: isActive
            ? buttonActiveBackground
            : buttonInactiveBackground,
        }}>
        <Container style={{flex: 0.4375}}>
          {Icon && (
            <Icon
              strokeWidth={2}
              color={
                isActive ? buttonActiveForeground : buttonInactiveForeground
              }
              style={{
                width: iconWidth,
                height: iconHeight,
                alignSelf: 'center',
              }}
            />
          )}
        </Container>
        <Container style={{flex: 0.5625}}>
          {text && (
            <ButtonText
              style={{
                color: isActive
                  ? buttonActiveForeground
                  : buttonInactiveForeground,
              }}>
              {text}
            </ButtonText>
          )}
        </Container>
      </Container>
    </TouchableOpacity>
  );
};

const Container = styled(View)(centeredRowStyle);

const ButtonText = styled(Text)({
  width: '100%',
  fontSize: 14,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  letterSpacing: 0.56,
  lineHeight: 20,
});
