import React from 'react';
import {
  Animated,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {Constants} from '../constants';
import {colors} from '../styles';

interface TabLabelProps {
  focused: boolean;
  title: string;
  badge?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const TabLabel: React.FC<TabLabelProps> = ({
  focused,
  title,
  badge,
  style,
}: TabLabelProps) => {
  const underlineAnimation = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(underlineAnimation, {
      toValue: focused ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [focused, underlineAnimation]);

  return (
    <View style={style}>
      <View style={styles.container}>
        <View>
          <Text
            style={[
              styles.labelText,
              {
                opacity: focused ? 1 : 0.5,
                fontFamily:
                  // TODO: Issue on android that breaks the labels in two rows when focused
                  focused && Constants.IS_WEB
                    ? 'Roboto-Bold'
                    : 'Roboto-Regular',
              },
            ]}>
            {title}
          </Text>

          <Animated.View
            style={[
              styles.underline,
              {
                width: underlineAnimation.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0%', '100%'],
                }),
              },
            ]}
          />
        </View>

        {badge}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  underline: {
    height: 1,
    backgroundColor: colors.white,
    marginTop: 6,
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelText: {
    fontSize: 18,
    color: colors.white,
    textTransform: 'uppercase',
  },
});
