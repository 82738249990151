import React from 'react';
import {
  StyleProp,
  Text,
  TextInput,
  TextInputProps,
  TextProps,
  View,
  ViewStyle,
} from 'react-native';

import {Constants} from '../constants';
import {CheckIcon, CloseIcon, IconProps} from '../icons';
import styled from '../lib/utils/styled';
import {colors} from '../styles';
import {AppText} from './AppText';

const SuccessIcon: React.FC<IconProps> = props => (
  <CheckIcon {...props} size={10} containerSize={16} />
);

const ErrorIcon: React.FC<IconProps> = props => (
  <CloseIcon size={10} containerSize={16} {...props} />
);

export const getRightIcon = (
  value: string,
  error: string | undefined,
  touched?: boolean | undefined,
): React.FC<IconProps> | undefined => {
  if (!touched || value === '') return undefined;

  return error ? ErrorIcon : SuccessIcon;
};

export const displayRightIconMessage = (
  valueString: string | undefined,
  errorMessage: string | undefined,
  touched?: boolean | undefined,
) => {
  if (touched || valueString !== '') {
    return errorMessage;
  } else {
    return undefined;
  }
};

export type StyledFieldProps = TextInputProps &
  TextProps & {
    /**
     * Optional icon name to be rendered to the left of the field
     */
    icon?: React.FC<IconProps>;
    /**
     * Whether the input will be secure text entry, default false
     */
    password?: boolean;
    /**
     * Determine whether there's a button to the right of the field accessible thorugh onPress
     */
    rightButton?: boolean;
    /**
     * Determine text to be rendered in the right button
     */
    rightButtonText?: string;
    height?: number;
    /**
     * top margin of icon
     */
    iconMargin?: number;
    /**
     * Icon name for StyledIcon on the right side
     */
    rightIcon?: React.FC<IconProps>;
    /**
     * color for StyledIcon on the right side
     */
    rightIconColor?: string;
    /**
     * message above Styled Icon on the right side
     */
    rightIconMessage?: string | boolean;
    /**
     * color for text input
     */
    textColor?: string;
    /**
     * color for icon on the left
     */
    leftIconColor?: string;
    /**
     * color underline below the text field
     */
    borderColor?: string;
    /**
     * show border around textInput
     */
    isBordered?: boolean;
    /**
     * rounded border for the right icon
     */
    rightIconCircled?: boolean;
    /**
     * onPress
     */
    iconPress?: () => void;
  };

const {fieldDivider, signInFieldText, signInFieldTextPlaceholder} = colors;

export const StyledField = (props: StyledFieldProps) => {
  const {
    icon: Icon,
    password,
    autoCapitalize,
    autoCorrect,
    value,
    rightButton,
    onPress,
    rightButtonText,
    height,
    maxLength = Constants.TEXT_FIELD_MAX_LENGTH,
    keyboardType,
    rightIcon: RightIcon,
    rightIconColor,
    rightIconMessage,
    rightIconCircled = true,
    textColor,
    leftIconColor,
    borderColor,
    isBordered,
    iconPress,
  } = props;
  const [color, setColor] = React.useState(signInFieldTextPlaceholder);
  const heightVal = height || 50;

  React.useEffect(() => {
    if (value === '' && color === signInFieldText)
      setColor(signInFieldTextPlaceholder);
    else if (value !== '' && color === signInFieldTextPlaceholder)
      setColor(signInFieldText);
  }, [value]);

  const roundedInputStyle = React.useMemo((): StyleProp<ViewStyle> => {
    if (isBordered) {
      return {
        height,
        borderWidth: 1,
        borderRadius: 100,
        paddingHorizontal: 23,
        alignItems: 'center',
        borderColor: borderColor || color,
      };
    }
    return {
      borderBottomWidth: 1,
      paddingTop: 13,
      paddingBottom: 3,
      height: heightVal,
      borderColor: borderColor || color,
    };
  }, [borderColor, color, heightVal, isBordered]);

  return (
    <Container style={roundedInputStyle}>
      {!!Icon && (
        <Icon
          style={{
            paddingBottom: isBordered ? undefined : 0,
            ...(isBordered && {
              marginLeft: -10,
              paddingRight: 8.5,
            }),
          }}
          size={16}
          color={leftIconColor || color}
          iconStyle={{marginTop: 2}}
        />
      )}
      <TextInput
        allowFontScaling={false}
        style={{
          paddingBottom: isBordered ? 'auto' : 2,
          color: textColor || signInFieldText,
          fontSize: 16,
          flex: 1,
          fontFamily: 'Roboto-Regular',
          fontWeight: '400',
        }}
        {...props}
        autoCapitalize={autoCapitalize || 'none'}
        autoCorrect={autoCorrect || false}
        keyboardType={keyboardType}
        maxLength={maxLength}
        placeholderTextColor={signInFieldTextPlaceholder}
        secureTextEntry={password || false}
      />

      {rightButton && (
        <AppText style={{paddingBottom: 0}} onPress={onPress}>
          {rightButtonText || 'FORGOT?'}
        </AppText>
      )}

      {rightIconMessage && rightIconMessage !== '' && (
        <View
          style={{
            backgroundColor: colors.red,
            borderRadius: 3,
            position: 'absolute',
            right: 0,
            bottom: 30,
            padding: 3,
            marginBottom: isBordered ? 14 : undefined,
          }}
          pointerEvents="none">
          <Text
            style={{
              color: colors.white,
              fontSize: 11,
              fontFamily: 'Roboto-Regular',
              letterSpacing: 0.37,
              fontWeight: '400',
            }}>
            {rightIconMessage}
          </Text>
        </View>
      )}

      {RightIcon && (
        <RightIcon
          color={rightIconColor}
          style={{
            paddingBottom: isBordered ? undefined : 0,
            ...(isBordered && {
              marginTop: isBordered ? undefined : 7,
              marginRight: -10,
            }),
            ...(rightIconCircled && {
              borderWidth: 1,
              borderColor: rightIconColor,
            }),
          }}
          onPress={iconPress}
        />
      )}
    </Container>
  );
};

const Container = styled(View)({
  flexDirection: 'row',
  alignItems: 'flex-end',
  width: '100%',
  borderColor: fieldDivider,
});
