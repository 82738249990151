import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ChevronRightIcon: React.FC<IconProps> = props => (
  <Icon containerSize={24} {...props}>
    <Path d="m9 18 6-6-6-6" />
  </Icon>
);
