import React from 'react';
import {Dimensions, View} from 'react-native';
import {useSelector} from 'react-redux';

import {AppText} from '../../../components/AppText';
import {ImgBackground} from '../../../components/ImgBackground';
import {LoadingHalo} from '../../../components/LoadingHalo';
import {PeakImageWithStaticChamber} from '../../../components/PeakImage';
import {SafeAreaView} from '../../../components/SafeAreaView';
import {StatusDisplay} from '../../../components/StatusDisplay';
import {StyledButton} from '../../../components/StyledButton';
import {Messages} from '../../../constants/Strings';
import {Strings} from '../../../constants/Strings';
import {Navigators, Screens} from '../../../constants/navigation';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import {currentDeviceFirmwareUpdateSelector} from '../../../lib/redux/slices/bleSlice';
import styled from '../../../lib/utils/styled';
import type {HomeEmulatedDrawerStackScreenProps} from '../../../navigators/HomeDrawerNavigator';
import {colors} from '../../../styles';
import {defaultTheme} from '../../../themes';
import {createFlowId} from '../../../util/createFlowId';
import {BodyContainer, BodyText, HeaderText} from '../components';
import {useUpdateFirmware} from './useUpdateFirmware';

export interface Props {}

interface ScreenProps
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.FirmwareUpdating> {}

export const FirmwareUpdatingScreen: React.FC<ScreenProps> = ({navigation}) => {
  const windowWidth = Dimensions.get('window').width;

  const firmwareUpdate = useSelector(currentDeviceFirmwareUpdateSelector);
  const [error, setError] = React.useState<Error>();
  const {loading, progress, start} = useUpdateFirmware();

  const flow = React.useRef({
    id: firmwareUpdate?.flowId ?? createFlowId(),
    attempt: firmwareUpdate?.attempt ?? 0,
  });

  useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        error ? (
          <CancelButton
            onPress={() =>
              navigation.replace(Navigators.HomeTabNavigator, {
                screen: Screens.Home,
              })
            }>
            {Strings.CANCEL}
          </CancelButton>
        ) : null,
    });
  }, [navigation, error]);

  const update = React.useCallback(() => {
    setError(undefined);

    return start({
      flowId: flow.current.id,
      attempt: ++flow.current.attempt,
    }).catch(setError);
  }, [start]);

  React.useEffect(() => {
    update();
  }, [update]);

  const getTitleAndBody = () => {
    if (error) return {title: error.name, body: error.message};

    if (loading) return Messages.OTA.UPDATING;

    if (progress.data === 'done') return Messages.OTA.UPDATED;

    return {title: ' ', body: ' '};
  };

  const getLoadingHaloColor = () => {
    if (progress.data === 'done') return colors.connectedGreen;

    return '#D8D8D8';
  };

  const getStatusBarStatus = () => {
    switch (progress.data) {
      case 'not_started':
        return '';
      case 'downloading_firmware':
        return 'DOWNLOADING';
      case 'checking_device':
        return 'CHECKING DEVICE';
      case 'updating':
        return 'UPDATING';
      case 'rebooting_to_apploader':
        return 'REBOOTING';
      case 'reconnect':
        return 'RECONNECTING TO DEVICE';
      case 'done':
        return 'COMPLETE';
      default:
        return 'CONNECTING';
    }
  };

  const {title, body} = getTitleAndBody();

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <BodyContainer>
          <HeaderText>{title}</HeaderText>

          <BodyText style={{color: colors.white50}}>{body}</BodyText>
        </BodyContainer>

        <DownloadContainer>
          <PeakImageWithStaticChamber
            colorSource={colors.white}
            style={{
              flexDirection: 'column',
              width: '100%',
              height: '80%',
              marginTop: '20%',
            }}
            peakImageContainerProps={{
              style: {
                width: '100%',
                height: '110%',
              },
            }}
            darkScreen={true}>
            <LoadingHalo
              percentage={progress.value}
              color={getLoadingHaloColor()}
              style={{
                marginTop: -windowWidth * 0.2,
                height: '100%',
                width: '100%',
                padding: 15,
              }}
            />
          </PeakImageWithStaticChamber>
        </DownloadContainer>

        <LowerContainer>
          <StatusDisplay
            status={getStatusBarStatus()}
            error={error?.message}
            theme={defaultTheme}
            percentage={
              ['not_started', 'done'].includes(progress.data)
                ? undefined
                : progress.value
            }
          />

          {progress.data === 'done' && (
            <StyledButton
              style={{marginBottom: 25, marginTop: 25}}
              disabled={loading}
              onPress={() =>
                navigation.replace(Navigators.HomeTabNavigator, {
                  screen: Screens.Home,
                })
              }
              title={Strings.DONE}
            />
          )}

          {error && (
            <StyledButton
              style={{marginBottom: 25, marginTop: 25}}
              onPress={() => update()}
              title={Strings.TRY_AGAIN}
            />
          )}
        </LowerContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const DownloadContainer = styled(View)({
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 3,
});

const LowerContainer = styled(View)({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flex: 1,
  paddingVertical: 10,
});

const CancelButton = styled(AppText)({
  color: colors.gray,
  fontSize: 14,
  textAlignVertical: 'center',
  marginRight: 16,
});
