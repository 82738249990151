import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const MinusIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M3 11.9697C3 11.5555 3.33579 11.2197 3.75 11.2197L20.25 11.2197C20.6642 11.2197 21 11.5555 21 11.9697C21 12.3839 20.6642 12.7197 20.25 12.7197H3.75C3.33579 12.7197 3 12.3839 3 11.9697Z" />
  </Icon>
);
