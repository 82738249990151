import React from 'react';
import {StyleSheet} from 'react-native';

import {LinearGradientProps as LGProps, LinearGradient} from './LinearGradient';

export const LinearGradientBackground = ({
  children,
  style,
  ...rest
}: React.PropsWithChildren<LGProps>) => {
  return (
    <LinearGradient
      style={{width: '100%', height: '100%', ...StyleSheet.flatten(style)}}
      {...rest}>
      {children}
    </LinearGradient>
  );
};

export type LinearGradientProps = LGProps;
