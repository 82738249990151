import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View, ViewProps} from 'react-native';

import {Text} from '../../components/Typography';
import {Screens} from '../../constants/navigation';
import styled from '../../lib/utils/styled';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigators/RootStackNavigator';

interface TermsAndPrivacyProps extends ViewProps {}

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const TermsAndPrivacy = ({style}: TermsAndPrivacyProps) => {
  const navigation = useNavigation<Navigation>();

  return (
    <Container style={style}>
      <Text variant="s">
        <UnderlinedText
          variant="s"
          onPress={() => {
            navigation.navigate(Screens.TermsConditions, {});
          }}>
          Terms of Service
        </UnderlinedText>
        {' & '}
        <UnderlinedText
          variant="s"
          onPress={() => {
            navigation.navigate(Screens.PrivacyPolicy, {});
          }}>
          Privacy Policy
        </UnderlinedText>
      </Text>
    </Container>
  );
};

const Container = styled(View)({
  flex: 1,
  alignItems: 'center',
});

const UnderlinedText = styled(Text)({
  textDecorationLine: 'underline',
});
