import React from 'react';
import {Path, Rect} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const DuplicateIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Rect x={8} y={3} width={11} height={15} rx={1} />
    <Path d="M5 6V18C5 19.6569 6.34315 21 8 21H16" />
  </Icon>
);
