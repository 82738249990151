import {createAsyncThunk} from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import {ulid} from 'ulid';

import {Constants} from '../../../constants';
import {IPeakDevice} from '../../ble2/v2/PeakDevice/IPeakDevice';
import {HEAT_CYCLE_ARRAY_INDICES} from '../../ble2/v2/pikaparam';
import {Store} from '../../redux/types';
import {
  Device,
  Dictionary,
  MoodLight,
  OperatingState,
  Profile,
  ProfileT,
  isPreTHeatProfile,
} from '../../types';
import {meetsMinimumFirmware} from '../../utils';
import {getDeviceFromPeak} from '../../utils/getDeviceFromPeak';
import {migrateProfileToT, profilesMatch} from '../../utils/profileFunctions';
import {
  connectedPeakSelector,
  currentDeviceSWRevisionSelector,
  currentDeviceSelector,
  updateDevice,
  updateDeviceSettings,
} from '../slices/bleSlice';
import {
  exclusiveMoodLightsByIdSelector,
  setLanternMoodLightId,
  setPeakMoodLights,
} from '../slices/moodLightSlice';
import {
  setActiveProfiles,
  setArchiveProfiles,
  updateArchiveProfile,
  updateTempProfile,
} from '../slices/profilesSlice';

const {MINIMUM_FIRMWARE_VERSION, TEMP_HEAT_PROFILE_INDEX} = Constants;

export const bleReadDeviceSettings = createAsyncThunk<void>(
  'ble/readDeviceSettings',
  async (_, thunkAPI) => {
    const {dispatch, getState} = thunkAPI;
    const state = getState() as Store;
    const peak = connectedPeakSelector(state);
    const device = currentDeviceSelector(state);

    if (!device || !peak) return;

    const migrateActive = async (active: Profile, peak: IPeakDevice) => {
      if (active.id || isPreTHeatProfile(active)) {
        return active;
      }

      const matchingArchive = state.profile.profiles.find(archive =>
        profilesMatch(archive, active),
      );
      let migratedProfile: ProfileT;
      if (matchingArchive) {
        migratedProfile = migrateProfileToT(matchingArchive);
        if (migratedProfile.wasSyncedWithActive) {
          migratedProfile = {
            ...omit(migratedProfile, 'wasSyncedWithActive'),
            id: ulid(),
            modified: new Date().getTime(),
            order: active.order,
          } as ProfileT;
        } else {
          dispatch(
            updateArchiveProfile({
              ...migratedProfile,
              wasSyncedWithActive: true,
            }),
          );
          migratedProfile = cloneDeep({
            ...migratedProfile,
            order: active.order,
          });
        }
      } else {
        migratedProfile = {
          ...active,
          id: ulid(),
          modified: new Date().getTime(),
        };
      }
      await peak.writeHeatProfile(migratedProfile);
      return migratedProfile;
    };

    const readHeatProfiles = async (peak: IPeakDevice) => {
      const profiles: Profile[] = [];
      const moodLights: MoodLight[] = [];
      const profileDictionary: Dictionary<string, Profile> = {};
      for (const i of HEAT_CYCLE_ARRAY_INDICES) {
        const profileInfo = await peak.readHeatProfile(i);
        if (profileInfo) {
          const {profile, moodLight} = profileInfo;
          let currentProfile = await migrateActive(profile, peak);

          // Ensure profiles have unique ids
          if (profileDictionary[currentProfile.id]) {
            currentProfile = {...currentProfile, id: ulid()};
            await peak.writeHeatProfile(currentProfile);
          }
          profileDictionary[currentProfile.id] = currentProfile;
          profiles.push(currentProfile);
          moodLight && moodLights.push(moodLight);
        }
      }
      return {profiles, moodLights};
    };

    const attributes = await peak.readDeviceAttributes();
    const dabbingValues = await peak.readDabbingValues();

    // Corrects the base heat profile duration when loading the app in the
    // middle of a dab
    if (
      peak.supports('TEMP_PROFILE') &&
      attributes.operatingState === OperatingState.HEAT_CYCLE_ACTIVE
    ) {
      const profile = (await peak.readHeatProfile(TEMP_HEAT_PROFILE_INDEX))
        ?.profile;

      if (!profile) return;

      dispatch(
        updateTempProfile({
          ...profile,
          duration: attributes.stateTotalTime,
        }),
      );
    }

    // Migrate profiles to T if necessary
    if (peak.supports('MOOD_LIGHTING')) {
      // If previous firmware version was pre-T, migrate profiles to T
      if (
        !meetsMinimumFirmware(
          currentDeviceSWRevisionSelector(state),
          MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
        )
      ) {
        dispatch(
          setArchiveProfiles(state.profile.profiles.map(migrateProfileToT)),
        );
        const tempProfile = state.profile.tempProfile;
        tempProfile &&
          dispatch(updateTempProfile(migrateProfileToT(tempProfile)));
      }
    }

    // Read lantern settings
    const lanternMode = attributes.lanternTime > 0;
    const exclusiveMoodLights = exclusiveMoodLightsByIdSelector(state);
    const {lColor, lPattern, partyMode, lanternMoodLight} =
      await peak.readLanternSettings(exclusiveMoodLights);
    if (lanternMode && lanternMoodLight) {
      dispatch(setLanternMoodLightId(lanternMoodLight.id));
    }

    const profileInfo = await readHeatProfiles(peak);
    if (profileInfo) {
      const {profiles, moodLights} = profileInfo;
      lanternMoodLight && moodLights.push(lanternMoodLight);
      dispatch(setPeakMoodLights(moodLights));
      dispatch(setActiveProfiles(profiles));
    }

    const deviceAttributes = getDeviceFromPeak(peak);

    const newDevice: Device = {
      ...device,
      ...deviceAttributes,
      settings: {
        ...dabbingValues.settings,
        ...deviceAttributes.settings,
        lanternMode,
        lanternColor: lColor,
        lanternPattern: lPattern,
        partyMode,
        boostTemp: attributes.boostTemperature,
        boostDuration: attributes.boostTime,
        readyMode: attributes.mode >= 0 && attributes.mode < 4,
        readyProfile: attributes.mode < 4 ? attributes.mode : undefined,
        brightness: attributes.devBrightness[2],
      },
    };

    dispatch(updateDevice(newDevice));
    dispatch(
      updateDeviceSettings({
        ...newDevice,
        syncUserLanternPreference: !lanternMode,
      }),
    );
  },
);
