import React from 'react';
import {
  Dimensions,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';

import {Constants} from '../constants';
import {Strings} from '../constants/Strings';
import {MoreIcon} from '../icons';
import {useUseMoodLight} from '../lib/hooks/useUseMoodLight';
import {MoodLight, isCustomMoodLight, isExclusiveMoodLight} from '../lib/types';
import styled from '../lib/utils/styled';
import {colors} from '../styles';
import {Theme} from '../themes';
import {CustomSwitch, CustomSwitchProps} from './CustomSwitch';
import {ElevationView} from './ElevationView';
import {MoodLightCardBackground} from './MoodLightCardBackground';
import {MoodLightFadedText} from './MoodLightFadedText';

const BORDER_RADIUS = 10;

type RequiredOnPress = Required<Pick<TouchableOpacityProps, 'onPress'>>;

export type MoodLightCardProps = {
  moodLight: MoodLight;
  isActive: boolean;
  setActiveProfile: (id: string) => void;
  theme: Theme;
  moreActionsSelection: () => void;
  username?: string;
} & RequiredOnPress;

export const MoodLightCard = ({
  moodLight,
  setActiveProfile,
  isActive,
  theme,
  onPress,
  moreActionsSelection,
  username,
}: MoodLightCardProps) => {
  const {gradientShadeFunction, textColor} = theme.moodLightCardTheme;
  const CARD_WIDTH =
    Dimensions.get('window').width - Constants.CARD_HORIZONTAL_MARGIN * 2;
  const [, use] = useUseMoodLight();

  return (
    <ElevationView
      style={{
        ...cardStyle,
        width: CARD_WIDTH,
        height: Constants.MOOD_LIGHT_CARD_HEIGHT,
        marginTop: 10,
        marginBottom: 10,
      }}>
      <MoodLightCardBackground
        width={CARD_WIDTH}
        height={Constants.MOOD_LIGHT_CARD_HEIGHT}
        moodLight={moodLight}
        gradientShadeFunction={gradientShadeFunction}>
        <CardContents
          moodLight={moodLight}
          textColor={textColor}
          switchProps={{
            value: isActive,
            onValueChange: (value: boolean) => {
              setActiveProfile(moodLight.id);
              if (value) use(moodLight.id);
            },
          }}
          onPress={onPress}
          onPressIcon={moreActionsSelection}
          username={username}
        />
      </MoodLightCardBackground>
    </ElevationView>
  );
};

const CardContents = ({
  moodLight,
  switchProps,
  onPress,
  onPressIcon,
  username,
  textColor,
}: {
  moodLight: MoodLight;
  switchProps: CustomSwitchProps;
  onPressIcon: () => void;
  username?: string;
  textColor?: string;
} & RequiredOnPress) => (
  <CardContainer>
    <CardButton onPress={onPress}>
      <CardTextContainer>
        <MoodLightName textBreakStrategy="balanced">
          {moodLight.name}
        </MoodLightName>
        {isCustomMoodLight(moodLight) && (
          <>
            {!!username && (
              <MoodLightFadedText
                style={{marginTop: 7, textTransform: 'lowercase'}}>
                {'@' + username}
              </MoodLightFadedText>
            )}
          </>
        )}
        {isExclusiveMoodLight(moodLight) && (
          <MoodLightFadedText
            style={{
              marginTop: 7,
              color: textColor || colors.white,
            }}>
            {Strings.EXCLUSIVE}
          </MoodLightFadedText>
        )}
      </CardTextContainer>
    </CardButton>
    <CustomSwitch {...switchProps} />
    <MoreIcon
      onPress={onPressIcon}
      size={32}
      color={colors.defaultColor}
      iconStyle={{
        transform: [{rotate: '90deg'}],
      }}
    />
  </CardContainer>
);

const cardStyle: ViewStyle = {
  alignSelf: 'center',
  borderRadius: BORDER_RADIUS,
  borderWidth: 0,
  overflow: 'hidden',
  width: '100%',
};

const CardTextContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 3,
});

const CardContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  paddingRight: 5,
});

const CardButton = styled(TouchableOpacity)({
  flex: 3,
  display: 'flex',
  flexDirection: 'row',
  paddingRight: 24,
  paddingLeft: 24,
  height: '100%',
  width: '100%',
  marginRight: 15,
});

const MoodLightName = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontSize: 16,
  letterSpacing: 0.5,
  lineHeight: 16,
  textTransform: 'uppercase',
  color: colors.white,
  fontWeight: '400',
});
