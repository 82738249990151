import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ExternalIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M20 12.4667V17.8667C20 18.4325 19.7752 18.9751 19.3752 19.3752C18.9751 19.7752 18.4325 20 17.8667 20H6.13333C5.56754 20 5.02492 19.7752 4.62484 19.3752C4.22476 18.9751 4 18.4325 4 17.8667V6.13333C4 5.56754 4.22476 5.02492 4.62484 4.62484C5.02492 4.22476 5.56754 4 6.13333 4H11.5333" />
    <Path d="M16 4H20V8" />
    <Path d="M12.5 11.5L20 4" />
  </Icon>
);
