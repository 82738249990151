import {convertHexToVector} from './convertHexToVector';

export const isWhiteColor = (color: string) => {
  const colors = convertHexToVector(color);
  return (
    colors != null &&
    colors.length >= 3 &&
    colors[0] > 0.9 &&
    colors[1] > 0.9 &&
    colors[2] > 0.9
  );
};
