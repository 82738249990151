import React from 'react';
import {View} from 'react-native';

import {
  backgroundGlitter,
  backgroundOpal,
  heatProfileCoreCircleMask,
  le01Gem,
  peakOpal,
  peakOpalDarkScreen,
  peakOpalLightsBaseFarLeft,
  peakOpalLightsBaseFarRight,
  peakOpalLightsBaseMidLeft,
  peakOpalLightsBaseMidRight,
  peakOpalLightsBaseRingFarLeft,
  peakOpalLightsBaseRingFarRight,
  peakOpalLightsBaseRingMidLeft,
  peakOpalLightsBaseRingMidRight,
  peakOpalLightsGlassFarLeft,
  peakOpalLightsGlassFarRight,
  peakOpalLightsGlassMidLeft,
  peakOpalLightsGlassMidRight,
} from '../assets/images';
import {Constants} from '../constants';
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
// Import from exact file to prevent cycle
import {getTransparentGradientColors} from '../lib/utils/colors/getTransparentGradientColors';
import {colors} from '../styles';
import {fillStyle} from '../styles';
// Import from exact file to prevent cycle
import {
  DarkStatusBarTheme,
  LightStatusBarTheme,
  defaultTheme,
} from './DefaultTheme';
import {
  AssignToAHeatProfileScreenTheme,
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  CustomSwitchTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileCoreImageTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LanternTypeButtonTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  SaveToDeviceScreenTheme,
  StatusBarTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from './Theme';

// General

const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: colors.black,
};

// Components

const bottomSheetTheme: BottomSheetTheme = {
  ...defaultTheme.bottomSheetTheme,
  backgroundColor: colors.lightestGrey,
  buttonBackgroundColor: colors.white,
  buttonTextColor: colors.black,
  titleColor: colors.black,
  subtitleColor: colors.black50,
  iconColor: colors.black,
  dividerColor: colors.black10,
  IconBaseBackground: () => (
    <View
      style={{
        ...fillStyle,
        backgroundColor: colors.opalWhite,
      }}
    />
  ),
};

const customSwitchTheme: CustomSwitchTheme = {
  ...defaultTheme.customSwitchTheme,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  primaryFontColor: colors.black,
  durationFontColor: colors.white,
  specularColorStart: colors.white50,
  specularColorEnd: colors.white50,
  iconColor: colors.black,
  iosShadowAlpha: 75,
  gradientShadeFunction: getTransparentGradientColors,
  overLayGradientColors: [
    addOpacityToColorHex(colors.white, 0.8),
    addOpacityToColorHex(colors.white, 0.5),
    addOpacityToColorHex(colors.white, 0),
  ],
};

const heatProfileCoreImageTheme: HeatProfileCoreImageTheme = {
  baseImageSource: le01Gem,
  maskImageSource: heatProfileCoreCircleMask,
  whiteColorShadePercentage: -21,
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  gradientShadeFunction: getTransparentGradientColors,
  background: backgroundGlitter,
};

const lanternTypeButtonTheme: LanternTypeButtonTheme = {
  ...defaultTheme.lanternTypeButtonTheme,
};

const moodLightCardTheme: MoodLightCardTheme = {
  ...defaultTheme.moodLightCardTheme,
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  ...defaultTheme.moodTypeButtonTheme,
  textColor: colors.black,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakOpal,
  lightLayers: {
    glass: {
      farLeft: peakOpalLightsGlassFarLeft,
      farRight: peakOpalLightsGlassFarRight,
      midLeft: peakOpalLightsGlassMidLeft,
      midRight: peakOpalLightsGlassMidRight,
    },
    base: {
      farLeft: peakOpalLightsBaseFarLeft,
      farRight: peakOpalLightsBaseFarRight,
      midLeft: peakOpalLightsBaseMidLeft,
      midRight: peakOpalLightsBaseMidRight,
    },
    ring: {
      farLeft: peakOpalLightsBaseRingFarLeft,
      farRight: peakOpalLightsBaseRingFarRight,
      midLeft: peakOpalLightsBaseRingMidLeft,
      midRight: peakOpalLightsBaseRingMidRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: peakOpalDarkScreen,
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: colors.black,
  buttonTextColor: colors.white,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: colors.black,
  backgroundColor: colors.opalWhite,
};

// Screens

const assignToAHeatProfileScreenTheme: AssignToAHeatProfileScreenTheme = {
  selectedBorderColor: colors.black,
  background: {color: colors.lightestGrey},
};

const connectScreenTheme: ConnectScreenTheme = {
  background: {image: backgroundOpal},
  peakNameBackgroundColor: colors.white,
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: colors.black,
  fatSliderBGColor: colors.black50,
  blurBackgroundColor: colors.transluscentLightGray,
  background: {color: colors.lightestGrey},
  boostScreenLabel: colors.black,
};

const dabbingScreenTheme = {
  ...defaultTheme.dabbingScreenTheme,
  dabbingScreenActiveText: colors.black,
  dabbingButtonBackground: colors.black,
  dabbingButtonBackgroundPressed: colors.bone,
  dabbingButtonForeground: colors.bone,
  dabbingButtonForegroundPressed: colors.black,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  ...defaultTheme.devicesListScreenTheme,
  cardColor: colors.iOSGray,
  cardText: colors.black,
  listTitleText: colors.black,
  listDivider: '#979797',
  trashCanIconColor: colors.black,
  blurBackgroundColor: colors.transluscentLightGray,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    gradient: ['#F8F8F8', '#EDEDED'],
  },
  hoverBackgroundColor: colors.gray,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  ...defaultTheme.heatProfileSelectScreenTheme,
  startTextStyle: {color: colors.black},
  backgroundLineColor: colors.lightGray,
  primaryColor: colors.black,
  durationColor: colors.black50,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,
  // General
  background: {
    image: backgroundOpal,
  },

  // Status info
  batteryBarBackgroundColor: colors.mediumGray79,
  batteryDisconnectedColor: addOpacityToColorHex(colors.black, 0.2),
  preserveIndicator: colors.black,
  statusTextStealthColor: colors.black,
  statusTitleTextColor: colors.black,
  stealthIconColor: colors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: addOpacityToColorHex(colors.white, 0.8), location: 0},
    {color: colors.invisible, location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(colors.black, 0.2),
  metricTextColor: colors.black,
  metricTitleTextColor: colors.black50,

  // Limited edition info
  limitedEditionText: {
    leftBorderGradientArray: [colors.black10, colors.black10],
    name: {color: colors.black30, value: 'Opal'},
    numberString: {color: colors.black10, value: '01'},
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {image: backgroundOpal},
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl: 'https://puffco-prod.s3-us-west-2.amazonaws.com/welcome/opal.mp4',
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: heatProfileListScreenTheme.background,
  infoLabelColor: colors.black50,
};

const statusBarTheme: StatusBarTheme = {
  ...defaultTheme.statusBarTheme,
  themed: Constants.IS_NATIVE_ANDROID
    ? DarkStatusBarTheme
    : LightStatusBarTheme,
};

export const opalTheme: Theme = {
  ...defaultTheme,

  // General
  fadedTextColor: colors.black50,
  logoTint: colors.black,
  navMenuIconColor: colors.black,
  navMenuTitleStyle,
  primaryTextColor: colors.black,
  primaryColor: colors.black,
  spinnerColor: colors.black,

  // Components
  bottomSheetTheme,
  customSwitchTheme,
  heatProfileCardTheme,
  heatProfileCoreImageTheme,
  heatProfileScreenBackgroundTheme,
  lanternTypeButtonTheme,
  moodLightCardTheme,
  moodTypeButtonTheme,
  peakImageTheme,
  sectionDividerColor: '#040404',
  sectionTitleTextColor: colors.black,
  statusDisplayTextColor: colors.black,
  styledButtonTheme,
  statusBarTheme,
  tabBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  heatProfileListScreenTheme,
  heatProfileSelectScreenTheme,
  homeScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  saveToDeviceScreenTheme,
};
