import React from 'react';
import {StyleProp, Text, View, ViewStyle} from 'react-native';

import {useDeviceStatus} from '../lib/hooks/useDeviceStatus';
import {Device} from '../lib/types';
import styled from '../lib/utils/styled';
import {colors} from '../styles';

interface Props {
  device?: Device;
  style?: StyleProp<ViewStyle>;
}

export const DeviceStatusBadge: React.FC<Props> = ({device, style}) => {
  const {getDeviceStatus} = useDeviceStatus();

  const status = getDeviceStatus(device);

  return (
    <StatusContainer {...{style}}>
      <StatusIcon style={{backgroundColor: status?.color}} />
      <StatusText adjustsFontSizeToFit={true}>{status?.text}</StatusText>
    </StatusContainer>
  );
};

const StatusContainer = styled(View)({
  alignSelf: 'flex-start',
  height: 20,
  borderRadius: 10,
  backgroundColor: colors.black,
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100%',
  gap: 6,
  paddingHorizontal: 8,
});

const StatusIcon = styled(View)({
  width: 7,
  height: 7,
  borderRadius: 100,
  backgroundColor: colors.green,
});

const StatusText = styled(Text)({
  fontSize: 10,
  color: colors.white,
  textTransform: 'uppercase',
  fontFamily: 'Roboto-Medium',
});
