import {colors} from '../../../styles';
import {isWhiteColor} from '../../utils/colors/isWhiteColor';
import {shadeColor} from './shadeColor';

// Opacity factor: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const getTransparentGradientColors = (
  color: string,
  opacityFactor = '30',
): string[] => [
  shadeColor(isWhiteColor(color) ? colors.black : color, 0) + opacityFactor,
  colors.opalWhite + '00',
];
