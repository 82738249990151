import React from 'react';
import {
  Animated,
  LayoutChangeEvent,
  LayoutRectangle,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {useAnim} from '../../../../lib/hooks/useAnim';
import styled from '../../../../lib/utils/styled';
import {XAndY} from '../util/getDabXAndYAxis';

type AvgStyle = {
  avgLineColor?: string;
};

type StyleType = {
  xLabel?: StyleProp<TextStyle>;
  yLabel?: StyleProp<TextStyle>;
  bar?: StyleProp<ViewStyle>;
  container?: StyleProp<ViewStyle>;
  lineWidth?: number;
  lineColor?: string;
} & AvgStyle;

type XType<T extends string | number> = {
  xValues: XAndY<T>[];
  formatXLabel?: (v: XAndY<T>, i: number) => string | number;
  style?: StyleType;
  gridCount?: number;
};

type YType = {
  maxY: number;
  yLabelCount?: number;
  formatYLabel?: (v: number) => string | number;
  style?: StyleType;
};

type BarGraphType<T extends string | number> = YType &
  XType<T> & {avg?: number};

const barLinePaddings = {
  min: {
    value: 1,
    padding: 8,
  },
  max: {
    value: 31,
    padding: 2,
  },
};

const getBarPadding = (number: number) => {
  if (number > barLinePaddings.max.value) {
    return barLinePaddings.max.padding;
  }

  const percentage =
    ((number - barLinePaddings.min.value) * 100) /
    (barLinePaddings.max.value - barLinePaddings.min.value);

  return Math.floor(
    (percentage / 100) *
      (barLinePaddings.max.padding - barLinePaddings.min.padding) +
      barLinePaddings.min.padding,
  );
};

export function BarGraph<T extends string | number = number>({
  xValues,
  maxY,
  yLabelCount,
  formatYLabel,
  formatXLabel,
  style,
  avg,
  gridCount,
}: BarGraphType<T>) {
  const [layout, setLayout] = React.useState<LayoutRectangle>();

  const setMemoLayout = React.useCallback((e: LayoutChangeEvent) => {
    setLayout(e?.nativeEvent?.layout);
  }, []);

  return (
    <View
      style={[
        {
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
        },
        style?.container,
      ]}>
      <YLabel
        maxY={maxY}
        yLabelCount={yLabelCount}
        formatYLabel={formatYLabel}
        style={style}
      />
      <BarContainer
        style={{
          position: 'relative',
          borderColor: style?.lineColor ?? 'black',
          borderWidth: style?.lineWidth ?? 1,
          flex: 1,
          height: '100%',
        }}
        onLayout={setMemoLayout}>
        {avg !== undefined && !!layout && (
          <View
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              position: 'absolute',
              zIndex: 999,
            }}>
            <AnimAvgLine
              style={style}
              avg={avg}
              height={layout.height}
              width={layout.width}
              max={maxY}
            />
          </View>
        )}
        <YLines
          maxY={maxY}
          yLabelCount={yLabelCount}
          formatYLabel={formatYLabel}
          style={style}
        />
        <XLabel
          height={layout?.height}
          xValues={xValues}
          formatXLabel={formatXLabel}
          maxY={maxY}
          style={style}
          gridCount={gridCount}
        />
      </BarContainer>
    </View>
  );
}

export const DashLine = ({
  width,
  bottom,
  color,
  lineHeight,
  lineWidth,
  lineGap,
  lineContainer,
}: {
  width: number;
  bottom?: ViewStyle['bottom'];
  color?: string;
  lineWidth?: number;
  lineHeight?: number;
  lineGap?: number;
  lineContainer?: StyleProp<ViewStyle>;
}) => {
  const count = React.useMemo(() => {
    let tempWidth = 0;
    let tcount = 0;
    while (tempWidth < width + (lineWidth ?? 6) + (lineGap ?? 3)) {
      tempWidth += (lineWidth ?? 6) + (lineGap ?? 3);
      tcount++;
    }
    return tcount;
  }, [width, lineWidth, lineGap]);

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          width,
          bottom,
          overflow: 'hidden',
        },
        lineContainer,
      ]}>
      {Array(count)
        .fill(null)
        .map((_, i) => (
          <View
            key={i}
            style={{
              width: lineWidth ?? 6,
              height: lineHeight ?? 0.5,
              marginLeft: i == 0 ? 0 : lineGap ?? 3,
              backgroundColor: color ?? 'white',
            }}
          />
        ))}
    </Animated.View>
  );
};

const AnimAvgLine = ({
  style,
  avg,
  height,
  max,
  width,
}: {
  style?: StyleType;
  avg: number;
  height: number;
  max: number;
  width: number;
}) => {
  const [value, anim] = useAnim();

  const percentage = (avg * 100) / max;
  const top = (percentage / 100) * height;

  React.useEffect(() => {
    anim(top, 1000, false);
  }, []);

  return (
    <DashLine
      width={width}
      color={style?.avgLineColor}
      bottom={value.interpolate({
        inputRange: [0, top],
        outputRange: [-1, top - 1],
      })}
      lineHeight={1}
      lineGap={4}
    />
  );
};

function XLabel<T extends string | number = number>({
  xValues,
  formatXLabel,
  height,
  maxY,
  style,
  gridCount,
}: {
  height?: number;
  maxY: number;
} & XType<T>) {
  const nGrids = gridCount || xValues.length;
  const gridWidth = 100 / nGrids;
  return (
    <View style={{position: 'relative', height: '100%', width: '100%'}}>
      <GridContainer>
        {Array(nGrids > 0 ? nGrids - 1 : 0)
          .fill(null)
          .map((_, i) => (
            <View
              key={`grid_${i}`}
              style={{
                height: '100%',
                width: `${gridWidth}%`,
                borderRightColor: style?.lineColor ?? 'black',
                borderRightWidth: style?.lineWidth || 1,
              }}
            />
          ))}
      </GridContainer>
      <XContainer>
        {xValues.map((v, i) => {
          const percentage = (v.y * 100) / maxY;
          const totalHeight = (percentage / 100) * (height ?? 0);
          return (
            <View
              key={i}
              style={{
                flex: 1,
                height: '100%',
                paddingHorizontal: getBarPadding(xValues.length),
              }}>
              <View
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                }}>
                <View style={{flex: 1}} />
                {height !== undefined && (
                  <BarLine height={totalHeight} style={style?.bar} />
                )}
              </View>
              <View
                style={{
                  position: 'absolute',
                  alignSelf: 'center',
                  bottom: -20,
                }}>
                <Text
                  style={[
                    {
                      width: '100%',
                    },
                    style?.xLabel,
                  ]}
                  numberOfLines={1}>
                  {formatXLabel?.(v, Number(i)) ?? v.x}
                </Text>
              </View>
            </View>
          );
        })}
      </XContainer>
    </View>
  );
}

const YLabel = ({formatYLabel, maxY, yLabelCount, style}: YType) => {
  return (
    <View
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        top: -6,
        marginRight: 4,
        zIndex: 999,
      }}>
      {Array(yLabelCount ?? 2)
        .fill(null)
        .map((_, i) => {
          const percentage =
            (((yLabelCount ?? 2) - i) * 100) / (yLabelCount ?? 2);
          const total = (percentage / 100) * maxY;
          return (
            <Text
              key={i}
              style={[
                {
                  flex: 1,
                  width: 'auto',
                  textAlign: 'right',
                },
                style?.yLabel,
              ]}>
              {formatYLabel?.(total) ?? total}
            </Text>
          );
        })}
    </View>
  );
};

const YLines = ({yLabelCount, style}: YType) => {
  return (
    <YContainer>
      {Array(yLabelCount ?? 2)
        .fill(null)
        .map((_, i) => {
          return (
            <View
              key={i}
              style={{
                position: 'relative',
                flex: 1,
                ...(i !== (yLabelCount ?? 2) - 1 && {
                  borderBottomColor: style?.lineColor ?? 'black',
                  borderBottomWidth: style?.lineWidth ?? 1,
                }),
                width: '100%',
              }}
            />
          );
        })}
    </YContainer>
  );
};

const BarLine = ({
  height,
  style,
}: {
  height: number;
  style?: StyleProp<ViewStyle>;
}) => {
  const [value, anim] = useAnim();

  React.useEffect(() => {
    anim(height, 1000, false);
  }, []);

  return (
    <Bar
      style={[
        {
          height: value.interpolate({
            inputRange: [0, height],
            outputRange: [0, height],
          }),
        },
        style,
      ]}
    />
  );
};

const BarContainer = styled(Animated.View)({
  position: 'relative',
  borderStyle: 'solid',
  width: '100%',
  height: '100%',
  flex: 1,
});

const GridContainer = styled(View)({
  flexDirection: 'row',
  height: '100%',
  width: '100%',
});

const XContainer = styled(View)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
});

const YContainer = styled(View)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Bar = styled(Animated.View)({
  overflow: 'hidden',
  borderTopLeftRadius: 100,
  borderTopRightRadius: 100,
  backgroundColor: 'purple',
});
