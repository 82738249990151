import React from 'react';
import {Image, Linking, StyleSheet, View} from 'react-native';

import {Description} from '../../../components/Description';
import {StyledButton} from '../../../components/StyledButton';
import {Title} from '../../../components/Title';
import {Strings} from '../../../constants/Strings';
import {resolveAssetUrl} from '../../../lib/api';
import {TypeLinkItem} from '../../../lib/api/content-access/types';
import {analytics} from '../../../services/analytics';
import {colors} from '../../../styles';

interface Props {
  item: TypeLinkItem;
}

export const LinkItem: React.FC<Props> = ({item}) => {
  return (
    <View
      style={[
        styles.root,
        item.fields.separator
          ? {
              borderBottomWidth: 1,
              borderBottomColor: colors.darkGreyMedium33,
            }
          : {},
      ]}>
      <View style={styles.textAndIcon}>
        {item.fields.icon && (
          <Image
            style={styles.icon}
            source={{
              uri: resolveAssetUrl(item.fields.icon),
            }}
          />
        )}

        <View style={styles.text}>
          <Title style={item.fields.description ? styles.bold : {}}>
            {item.fields.displayName}
          </Title>

          {item.fields.description && (
            <Description style={styles.description}>
              {item.fields.description}
            </Description>
          )}
        </View>
      </View>

      {item.fields.url && (
        <StyledButton
          style={styles.button}
          textStyle={styles.bold}
          title={item.fields.buttonText ?? Strings.OPEN}
          onPress={() => {
            Linking.openURL(item.fields.url);

            analytics.trackEvent('click', {
              content_type: 'social_link',
              link_url: item.fields.url,
            });
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAndIcon: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  icon: {
    width: 42,
    height: 42,
    marginRight: 14,
    resizeMode: 'contain',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bold: {fontFamily: 'Roboto-Bold'},
  description: {
    marginTop: 12,
  },
  button: {
    width: 'auto',
    height: 'auto',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
});
