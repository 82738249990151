import React from 'react';
import {StyleSheet} from 'react-native';

import {
  PressableOpacity,
  Props as PressableOpacityProps,
} from '../../../components/PressableOpacity';
import {ResponsiveText} from '../../../components/ResponsiveText';
import {Strings} from '../../../constants/Strings';
import {DownloadIcon} from '../../../icons';
import {responsive} from '../../../lib/utils';
import {colors} from '../../../styles';

interface Props extends PressableOpacityProps {}

export const DownloadButton = ({style, ...rest}: Props) => {
  return (
    <PressableOpacity style={[styles.container, style]} {...rest}>
      <DownloadIcon
        containerSize={responsive(56)}
        style={styles.icon}
        color={colors.black}
      />

      <ResponsiveText size={18}>{Strings.DOWNLOAD}</ResponsiveText>
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  icon: {
    marginBottom: 13,
    borderWidth: 1,
    borderColor: colors.goldMediumGray,
  },
});
