import React from 'react';
import {ColorValue, TouchableOpacity, ViewStyle} from 'react-native';

import {CheckIcon} from '../icons';
import styled from '../lib/utils/styled';
import {colors} from '../styles';
import {Text, TextProps} from './Typography';

interface StyledCheckBoxProps {
  checked: boolean;
  onChange(value: boolean): void;
  disabled?: boolean;
  checkboxColor?: ColorValue;
  style?: ViewStyle;
}

export const StyledCheckBox: React.FC<
  React.PropsWithChildren<StyledCheckBoxProps>
> = ({checked, disabled, style, checkboxColor, children, onChange}) => (
  <Container
    {...{style, disabled}}
    activeOpacity={0.6}
    onPress={() => onChange?.(!checked)}>
    <CheckIcon
      containerSize={20}
      style={{
        borderColor: checked ? colors.lightMediumGray : colors.transparent,
        borderWidth: checked ? 0 : 1,
        backgroundColor: checked
          ? checkboxColor || colors.readyGreen
          : colors.white,
        borderRadius: 4,
      }}
      color={!checked ? 'transparent' : colors.white}
      size={16}
    />

    {children}
  </Container>
);

const Container = styled(TouchableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
  minHeight: 28,
  gap: 8,
});

export const CheckBoxContent: React.FC<TextProps> = props => (
  <Text variant="xs" {...props} />
);
