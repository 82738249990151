import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const AddUserIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M19 8V14" />
    <Path d="M22 11H16" />
    <Path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" />
    <Path d="M2 17.6233C2 16.6624 2.63696 15.818 3.56084 15.554V15.554C7.11582 14.5383 10.8842 14.5383 14.4392 15.554V15.554C15.363 15.818 16 16.6624 16 17.6233V20H2V17.6233Z" />
  </Icon>
);
