import React from 'react';
import {Dimensions, View} from 'react-native';

import {PlusIcon} from '../../../icons';
import styled from '../../../lib/utils/styled';

const {width, height} = Dimensions.get('window');

const ICON_SIZE = 23;
const HORIZONTAL_SPACING = 17;
const VERTICAL_SPACING = 22;

const numColumns = Math.floor(width / (ICON_SIZE + HORIZONTAL_SPACING));
const numRows = Math.floor(height / (ICON_SIZE + VERTICAL_SPACING));

const gridData = Array.from({length: numColumns * numRows});

// Favorite moodlight background pluses
export const BackgroundGrid = () => (
  <Container>
    {gridData.map((_, index) => (
      <IconContainer key={index}>
        <PlusIcon opacity={0.1} />
      </IconContainer>
    ))}
  </Container>
);

const Container = styled(View)({
  position: 'absolute',
  top: '-20%',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignContent: 'center',
  zIndex: -10,
  pointerEvents: 'none',
});

const IconContainer = styled(View)({
  marginHorizontal: HORIZONTAL_SPACING / 2,
  marginVertical: VERTICAL_SPACING / 2,
});
