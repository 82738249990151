import React from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';

import {Constants} from '../constants';
import {useDimensions} from '../lib/hooks/useDimensions';
import {useTheme} from '../lib/hooks/useTheme';
import styled from '../lib/utils/styled';
import {BottomSheetComponent} from './BottomSheet';
import {BottomSheetButton, BottomSheetButtonProps} from './BottomSheetButton';
import {CloseButton} from './CloseButton';

export type BottomSheetDetailProps = {
  header: {
    icon: JSX.Element;
    title: string;
    subtitle: string;
    viewStyle?: ViewStyle;
  };
  buttonPropArr: BottomSheetButtonProps[];
  onClose?: () => void;
};

export const BottomSheetDetail = ({
  onClose = BottomSheetComponent.close,
  header: {icon, title, subtitle, viewStyle},
  buttonPropArr,
}: BottomSheetDetailProps) => {
  const {
    backgroundColor,
    titleColor,
    subtitleColor,
    lineColor,
    buttonBackgroundColor,
    dividerColor,
    iconColor,
  } = useTheme().bottomSheetTheme;
  const {
    window: {height, width},
  } = useDimensions();

  return (
    <BottomSheetContainer
      style={{
        height: Constants.IS_WEB ? height : '100%', // not ideal, revist later
        width: Constants.IS_WEB ? width : '100%',
        backgroundColor: backgroundColor,
      }}>
      <Header>
        <HeaderInfoContainer>
          <IconContainer style={viewStyle}>{icon}</IconContainer>
          <HeaderTextContainer>
            <Title style={{color: titleColor}}>{title}</Title>
            <Subtitle style={{color: subtitleColor}}>{subtitle}</Subtitle>
          </HeaderTextContainer>
        </HeaderInfoContainer>

        <CloseButton
          size={24}
          containerSize={24}
          color={iconColor}
          onPress={onClose}
        />
      </Header>
      <Line style={{backgroundColor: lineColor}} />
      <Body>
        {buttonPropArr.map(({onPress, style, ...rest}, index) => (
          <View key={index}>
            <BottomSheetButton
              onPress={event => {
                onClose();
                onPress && onPress(event);
              }}
              style={{
                backgroundColor: buttonBackgroundColor,
                ...(index === 0 && {
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }),
                ...(index === buttonPropArr.length - 1 && {
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }),
                ...StyleSheet.flatten(style),
              }}
              {...rest}
            />
            {index < buttonPropArr.length - 1 && (
              <ButtonSeparatorLine style={{backgroundColor: dividerColor}} />
            )}
          </View>
        ))}
      </Body>
    </BottomSheetContainer>
  );
};

const BottomSheetContainer = styled(View)({
  width: '100%',
  alignItems: 'center',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
});

const Header = styled(View)({
  height: Constants.BOTTOM_SHEET_HEADER_HEIGHT,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingHorizontal: 17,
});

const HeaderInfoContainer = styled(View)({
  flexDirection: 'row',
});

const IconContainer = styled(View)({
  height: 40,
  width: 40,
  marginRight: 10,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  overflow: 'hidden',
});

const HeaderTextContainer = styled(View)({
  flexDirection: 'column',
  justifyContent: 'center',
});

const Body = styled(View)({
  width: '100%',
  paddingTop: 20,
  paddingHorizontal: 17,
});

const Line = styled(View)({
  height: 0.5,
  width: '100%',
});

const ButtonSeparatorLine = styled(View)({
  height: 1,
  width: '100%',
  opacity: 0.5,
});

const Title = styled(Text)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  letterSpacing: 0.75,
  textTransform: 'uppercase',
  fontWeight: '400',
});

const Subtitle = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 12,
  letterSpacing: 0.6,
  textTransform: 'uppercase',
  fontWeight: '400',
});
