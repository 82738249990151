import {useSelector} from 'react-redux';
import {useAsync, useThrottleFn} from 'react-use';

import {peakDeviceApi} from '../api/apis';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../redux/slices/bleSlice';
import {userSelector} from '../redux/slices/userSlice';
import {useAppDispatch} from '../redux/useAppDispatch';
import {Device} from '../types';

const getPeakDtoFromDevice = (device: Device) => ({
  serialNumber: device.serialNumberString,
  name: device.name ?? '',
  model: device.modelNumberString ?? '',
  firmware: device.softwareRevisionString ?? '',
  gitHash: device.gitHashString ?? '',
  dob: new Date(device.dob ?? Date.now()).toISOString(),
  batteryCapacity: device.batteryCapacity ?? 0,
  hitCount: device.hits,
  totalRuntime: device.totalTime,
  lastCleanReminderDabCount: device.appSettings?.lastCleanReminderDabCount,
  cleaningReminderDabs: device.appSettings?.cleaningReminderDabs,
  snoozeDabs: device.appSettings?.snoozeDabs,
  delay: device.appSettings?.delay,
  cleaningReminderLastUpdated: device.appSettings?.cleaningReminderLastUpdated,
});

const compare = (d1?: Device, d2?: Device) => {
  const d1s = d1 ? getPeakDtoFromDevice(d1) : '';
  const d2s = d2 ? getPeakDtoFromDevice(d2) : '';

  return JSON.stringify(d1s) === JSON.stringify(d2s);
};

export const useSyncPeakDevice = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const device = useSelector(currentDeviceSelector, compare);
  const peak = useSelector(connectedPeakSelector);
  const userId = user?.id;

  const connected = !!peak?.attributes;

  useAsync(async () => {
    const serialNumber = device?.serialNumberString;

    if (!serialNumber || !userId) return;

    const appSettings = await peakDeviceApi
      .getDeviceBySerialNumber({serialNumber})
      .then(r => r.data.appSettings);

    const localUpdatedAt = device.appSettings?.cleaningReminderLastUpdated ?? 0;
    const remoteUpdatedAt = appSettings?.cleaningReminderLastUpdated ?? 0;

    if (remoteUpdatedAt < localUpdatedAt) return;

    dispatch(
      updateDeviceAppSettings({
        id: device.id,
        appSettings: {
          lastCleanReminderDabCount: appSettings?.lastCleanReminderDabCount,
          cleaningReminderDabs: appSettings?.cleaningReminderDabs,
          snoozeDabs: appSettings?.snoozeDabs,
          delay: appSettings?.delay,
          cleaningReminderLastUpdated: appSettings?.cleaningReminderLastUpdated,
        },
      }),
    );
  }, [device?.serialNumberString, userId]);

  useThrottleFn(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (connected, device?: Device, userId?: number) => {
      if (!connected) return;

      const serialNumber = device?.serialNumberString;

      if (!serialNumber) return;

      return peakDeviceApi.putDevice({
        serialNumber,
        peakDeviceUpsertDto: {
          ...getPeakDtoFromDevice(device),
          serialNumber,
        },
      });
    },
    5000,
    [connected, device, userId],
  );
};
