import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const CheckIcon: React.FC<IconProps> = props => (
  <Icon strokeWidth={2} {...props}>
    <Path d="M20 6L9 17L4 12" />
  </Icon>
);
