import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {IconProps} from '../../icons';
import {colors} from '../../styles';

export interface Props {
  title: string;
  icon: React.FC<IconProps>;
  onPress: () => void;
  badge?: React.ReactNode;
}

export const DrawerItem = ({onPress, title, icon: Icon, badge}: Props) => (
  <View style={styles.container}>
    <TouchableOpacity style={styles.item} {...{onPress}}>
      <Icon strokeWidth={1} opacity={0.5} />

      <Text style={styles.text}>{title}</Text>

      {badge && <View style={styles.badge}>{badge}</View>}
    </TouchableOpacity>
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
  },
  item: {
    alignItems: 'center',
    width: '100%',
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  icon: {
    opacity: 0.5,
    tintColor: colors.black,
    width: 40,
  },
  text: {
    fontSize: 16,
    color: colors.baseText,
    marginLeft: 6,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
  },
  badge: {marginLeft: 8},
});
