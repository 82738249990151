import {convertHexToRgbArray} from './convertHexToRgbArray';

export const convertHexArrayToNumArray = (colors: string[], zeroPad = true) => {
  const numArray: number[] = [];
  colors.forEach(color => {
    const colorValues = convertHexToRgbArray(color);
    !zeroPad && colorValues.pop();
    numArray.push(...colorValues);
  });
  return numArray;
};
