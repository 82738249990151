import React from 'react';
import {Animated} from 'react-native';

import {mixColor} from '../utils/colors';

export type ColorTransitionProps = {
  color: string;
  transitionDuration?: number;
};

export function useColorTransition({
  color,
  transitionDuration = 500,
}: ColorTransitionProps) {
  const animation = React.useRef(new Animated.Value(0));
  const [animationValue, setAnimationValue] = React.useState(0);
  const colorRef = React.useRef(color);
  const [currentColor, setCurrentColor] = React.useState(color);
  const [prevColor, setPrevColor] = React.useState(color);

  React.useEffect(() => {
    const currentAnimation = animation.current;
    const listener = currentAnimation.addListener(({value}) =>
      setAnimationValue(value),
    );
    return () => {
      currentAnimation.removeListener(listener);
    };
  }, []);

  React.useEffect(() => {
    Animated.timing(animation.current, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start(() =>
      Animated.timing(animation.current, {
        toValue: 1,
        duration: transitionDuration,
        useNativeDriver: false,
      }).start(),
    );
    setPrevColor(colorRef.current);
    colorRef.current = color;
  }, [color]);

  React.useEffect(() => {
    const blendedColor = mixColor({
      colorA: prevColor,
      colorB: color,
      percentage: animationValue,
    });

    setCurrentColor(blendedColor);
  }, [animationValue]);

  return currentColor;
}
