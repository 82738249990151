import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useDebounce} from 'use-debounce';

import {CustomSwitch} from '../../../components/CustomSwitch';
import {CheckboxUI} from '../../../lib/types';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';

export const Checkbox: React.FC<{
  title: string;
  ui: CheckboxUI;
  value: 1 | 0;
  setValue: (val: 1 | 0) => void;
}> = ({title, ui, value, setValue}) => {
  const [switchVal, setSwitchVal] = React.useState(Boolean(value));
  const [debouncedSwitchVal] = useDebounce(switchVal, 1000);

  React.useEffect(() => {
    setSwitchVal(Boolean(value));
  }, [value]);

  React.useEffect(() => {
    setValue(debouncedSwitchVal ? 1 : 0);
  }, [debouncedSwitchVal]);
  return (
    <ControlPanelButtonContainer
      onPress={() => setSwitchVal(v => !v)}
      style={{marginBottom: 7}}>
      <ButtonTitle numberOfLines={1}>{title}</ButtonTitle>
      <ButtonEnd>
        <CustomSwitch
          leftBackgroundColor={colors.slideSwitchGreen}
          value={switchVal || Boolean(ui.default)} // TODO seems lke we won't be able to set to false?
          style={{zIndex: -1}}
          onValueChange={v => setSwitchVal(v)}
        />
      </ButtonEnd>
    </ControlPanelButtonContainer>
  );
};
const ControlPanelButtonContainer = styled(TouchableOpacity)({
  height: 56,
  paddingLeft: 24,
  paddingRight: 18,
  paddingVertical: 20,
  backgroundColor: colors.black30,
  display: 'flex',
  flexDirection: 'row',
  marginTop: 16,
  marginBottom: 22,
  borderRadius: 8,
  alignItems: 'center',
});

const ButtonTitle = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  lineHeight: 16,
  color: colors.white,
  flex: 1,
});
const ButtonEnd = styled(View)({
  flex: 1,
  alignItems: 'flex-end',
});
