import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Text} from 'react-native';

import {Screens} from '../../constants/navigation';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {RedirectOptions, RedirectionParameter} from '../../navigators/params';
import {colors} from '../../styles';

export type RightHeaderButtonProps = {
  redirection?: RedirectOptions;
  name?: string;
  textColor?: string;
  onPress?: () => void;
};

type Navigation = RootStackScreenProps<typeof Screens.Register>['navigation'];

export const RightHeaderButton = ({
  redirection,
  name,
  textColor,
  onPress,
}: RightHeaderButtonProps) => {
  const navigation = useNavigation<Navigation>();

  return (
    <Header
      style={{color: textColor || colors.white}}
      onPress={
        onPress ??
        (() => {
          // TODO: Check if redirection includes account screen
          if (!redirection) return;

          navigation.navigate(...RedirectionParameter.decode(redirection));
        })
      }>
      {name}
    </Header>
  );
};

const Header = styled(Text)({
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  marginRight: 10,
  fontWeight: '400',
});
