import {ImageSourcePropType} from 'react-native';

import {
  tintableHeatGlow1Color1,
  tintableHeatGlow2Color1,
  tintableHeatGlow2Color2,
  tintableHeatGlow3Color1,
  tintableHeatGlow3Color2,
  tintableHeatGlow3Color3,
  tintableHeatGlow4Color1,
  tintableHeatGlow4Color2,
  tintableHeatGlow4Color3,
  tintableHeatGlow4Color4,
  tintableHeatGlow5Color1,
  tintableHeatGlow5Color2,
  tintableHeatGlow5Color3,
  tintableHeatGlow5Color4,
  tintableHeatGlow5Color5,
  tintableHeatGlow6Color1,
  tintableHeatGlow6Color2,
  tintableHeatGlow6Color3,
  tintableHeatGlow6Color4,
  tintableHeatGlow6Color5,
  tintableHeatGlow6Color6,
} from '../../assets/images';

export const TINTABLE_HEAT_GLOW_IMAGE_SOURCES: ImageSourcePropType[][] = [
  [tintableHeatGlow1Color1],
  [tintableHeatGlow2Color1, tintableHeatGlow2Color2],
  [tintableHeatGlow3Color1, tintableHeatGlow3Color2, tintableHeatGlow3Color3],
  [
    tintableHeatGlow4Color1,
    tintableHeatGlow4Color2,
    tintableHeatGlow4Color3,
    tintableHeatGlow4Color4,
  ],
  [
    tintableHeatGlow5Color1,
    tintableHeatGlow5Color2,
    tintableHeatGlow5Color3,
    tintableHeatGlow5Color4,
    tintableHeatGlow5Color5,
  ],
  [
    tintableHeatGlow6Color1,
    tintableHeatGlow6Color2,
    tintableHeatGlow6Color3,
    tintableHeatGlow6Color4,
    tintableHeatGlow6Color5,
    tintableHeatGlow6Color6,
  ],
];
