import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import {colors} from '../../../styles';

export interface Props {
  style?: StyleProp<ViewStyle>;
}

export const LoadingContainer = ({style}: Props) => (
  <View style={[styles.container, style]}>
    <ActivityIndicator size="large" color={colors.gray} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colors.black,
  },
});
