import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {ChevronLeftIcon, IconProps} from '../icons';
import {currentDeviceIdSelector} from '../lib/redux/slices/bleSlice';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../navigators/RootStackNavigator';
import {RedirectOptions} from '../navigators/params';
import {toHome, toStartPairing} from '../navigators/util';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

interface BackButtonProps extends IconProps {
  fallback?: RedirectOptions;
  icon?: React.FC<IconProps>;
  onPress?(): void;
}

export const BackButton: React.FC<BackButtonProps> = ({
  icon: Icon = ChevronLeftIcon,
  fallback,
  onPress: onCallback,
  ...props
}) => {
  const deviceId = useSelector(currentDeviceIdSelector);
  const navigation = useNavigation<Navigation>();

  const onPress = React.useCallback(() => {
    if (onCallback) return onCallback();

    return navigation.replace(
      ...(fallback ?? (deviceId ? toHome : toStartPairing())),
    );
  }, [onCallback, fallback, deviceId, navigation]);

  return <Icon {...props} {...{onPress}} />;
};
