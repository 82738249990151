import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Animated, Dimensions, StyleSheet, Vibration, View} from 'react-native';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {useSelector} from 'react-redux';

import {Constants} from '../../constants';
import {Messages} from '../../constants/Strings';
import {useAppHeaderHeight} from '../../lib/hooks/useAppHeaderHeight';
import {useGetVaporSetting} from '../../lib/hooks/useGetVaporSetting';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useTheme} from '../../lib/hooks/useTheme';
import {useWatchDevice} from '../../lib/hooks/useWatchDevice';
import {appSettingsSelector} from '../../lib/redux/slices/appSettingsSlice';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDevice,
  updateDeviceSettings,
} from '../../lib/redux/slices/bleSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import {
  MoodLight,
  OperatingState,
  Profile,
  isCustomMoodLight,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {
  elapsedSecondsSince,
  getProfileAnimatedHaloWHSize,
  getProfileTempFontSize,
} from '../../lib/utils';
import {appLog} from '../../lib/utils/Logger';
import {changeHexColorValues} from '../../lib/utils/colors';
import {useTemperature} from '../../lib/utils/convertTemperature';
import {getTimeFormat} from '../../lib/utils/getTimeFormat';
import {Temperature} from '../../lib/utils/temperature';
import {analytics} from '../../services/analytics';
import {AnimatedHalo, calculateInnerContainerDiameter} from '../AnimatedHalo';
import {DabberFooter} from './DabberFooter';
import {DabberHeader} from './DabberHeader';
import {DabberInner} from './DabberInner';

const BLE_READ_LATENCY = 500; // Estimate in milliseconds
const MAX_PERCENTAGE = 100;
const WINDOW_HEIGHT = Dimensions.get('window').height;

export interface Props {
  profile: Profile;
  profileBaseDuration: number;
  profileBaseTemp: number;
  isResumingDab: boolean;
  isIdle?: boolean;
  resetDab?: boolean;
  onResetDabCompletion?: () => void;
  idleInnerComponent?: () => JSX.Element;
  dabInnerComponent?: () => JSX.Element;
  idleFooter?: () => JSX.Element;
  dabMessagesTitle?: string;
  dabMessages?: string[];
  moodLight?: MoodLight;
  headerTopPadding?: number;
  titleTopPadding?: number;
  haloPositionShift?: number;
  tipTextMarginBottom?: number;
  isVaporEnabled?: boolean;
  onCoreImageLayout?: (layout: {height: number; y: number}) => void;
}

const getProfileDynamicDisplayValues = (height: number) => {
  const animHaloSize = getProfileAnimatedHaloWHSize(height);
  const innerHaloDiameterSize = calculateInnerContainerDiameter(animHaloSize);
  const tempFontSize = getProfileTempFontSize(height);

  return {
    animHaloSize,
    innerHaloDiameterSize,
    tempFontSize,
  };
};

export const Dabber = (props: Props) => {
  const {
    profile,
    profileBaseDuration,
    profileBaseTemp,
    isResumingDab,
    isIdle = false,
    resetDab = false,
    onResetDabCompletion,
    idleInnerComponent,
    dabInnerComponent,
    idleFooter,
    dabMessagesTitle,
    dabMessages,
    moodLight,
    headerTopPadding,
    titleTopPadding,
    haloPositionShift = 0,
    tipTextMarginBottom = 15,
    onCoreImageLayout,
    isVaporEnabled,
  } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const timingAnim = React.useRef(new Animated.Value(0)).current;
  const warmUpAnim = React.useRef(new Animated.Value(0)).current;

  const [animatedPercentage, setAnimatedPercentage] = React.useState(0);
  const [startTimer, setStartTimer] = React.useState(false);
  const [warmUpVal, setWarmUpVal] = React.useState(0);
  const [warmUpTime, setWarmUpTime] = React.useState(10000);
  const [previousDuration, setPreviousDuration] =
    React.useState(profileBaseDuration);
  const [backgroundHeight, setBackgroundHeight] = React.useState(WINDOW_HEIGHT);

  const peak = useSelector(connectedPeakSelector);
  const device = useSelector(currentDeviceSelector);
  const settings = useSelector(appSettingsSelector);

  const deviceState = useWatchDevice('state');
  const deviceSettings = device?.settings;
  const tempPreference = settings?.tempPreference;
  const rawCurrentTemp = useWatchDevice('currentTemp', true, 250) || 0;
  const rawTargetTemp = useWatchDevice('targetTemp') || 0;

  const currentTemp = useTemperature(rawCurrentTemp, TemperatureUnit.Celsius);

  const targetTemp = useTemperature(rawTargetTemp, TemperatureUnit.Celsius);

  const currentDuration = useWatchDevice('stateElapsedTime') || 0;
  const targetDuration = useWatchDevice('stateTotalTime') || 0;

  const insets = useSafeArea();
  const HEADER_HEIGHT = useAppHeaderHeight();

  const maxTemp = useTemperature(
    Constants.TEMPERATURE_MAX_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const selectedTemperature = useTemperature(
    profile.temperature,
    profile.units,
  );

  const intensity = React.useMemo(() => {
    if (!isIdle) return warmUpVal;
    return Math.round(selectedTemperature / maxTemp);
  }, [isIdle, warmUpVal, selectedTemperature, maxTemp]);

  const canAddTemp =
    deviceState === OperatingState.HEAT_CYCLE_ACTIVE && targetTemp < maxTemp;
  const canAddTime =
    deviceState === OperatingState.HEAT_CYCLE_ACTIVE &&
    targetDuration < Constants.DURATION_MAX;
  const vibratePattern = Constants.IS_NATIVE_ANDROID
    ? [0, 400, 500, 400]
    : [0, 500];
  const isMoodLight = isTHeatProfileMoodLight(profile);
  const profileColor = isMoodLight ? '#FFFFFF' : profile.color;

  const haloCenterScreenPosition =
    (backgroundHeight - Constants.PROFILE_HALO_CONTAINER_HEIGHT) / 2;
  const haloPosition = haloCenterScreenPosition + haloPositionShift;

  const {animHaloSize, innerHaloDiameterSize, tempFontSize} = React.useMemo(
    () => getProfileDynamicDisplayValues(backgroundHeight),
    [backgroundHeight],
  );

  React.useEffect(() => {
    if (isIdle && warmUpTime !== 15000) {
      return;
    }
    getWarmUpTime();
    const listener = timingAnim.addListener(({value}) => {
      setAnimatedPercentage(value);
    });

    const warmUpListener = warmUpAnim.addListener(({value}) => {
      setWarmUpVal(value);
    });

    const toValue = targetTemp / maxTemp;
    const minToValue = 0.46;
    const midToValue = 0.73;

    Animated.timing(warmUpAnim, {
      toValue: toValue < minToValue ? midToValue : toValue,
      duration: warmUpTime,
      useNativeDriver: false,
    }).start();

    return () => {
      timingAnim.removeListener(listener);
      warmUpAnim.removeListener(warmUpListener);
      setWarmUpVal(0);

      dispatch(
        updateDeviceSettings({
          id: device?.id ?? '',
          settings: {
            isDabbingDiscoMode: false,
          },
        }),
      );
    };
  }, [isIdle]);

  const vapor = useGetVaporSetting(profile);

  const startProgressAnimation = (seconds: number) => {
    Animated.timing(timingAnim, {
      toValue: MAX_PERCENTAGE,
      duration:
        seconds * Constants.UNIT_CONVERSION.SECONDS_TO_MILLISECONDS -
        BLE_READ_LATENCY, // Subtracted to account for latency
      useNativeDriver: false,
    }).start();
  };

  React.useEffect(() => {
    if (isIdle) {
      return;
    }
    if (!device) {
      return;
    }
    if (!startTimer && Number.isFinite(targetDuration)) {
      if (deviceState === OperatingState.HEAT_CYCLE_ACTIVE) {
        Vibration.vibrate(vibratePattern);
        setStartTimer(true);
        if (isResumingDab) {
          // Set starting percentage for resuming progress when loading app after
          // dabbing session has already started
          const currentPercentage =
            (currentDuration / profileBaseDuration) * 100;
          timingAnim.setValue(currentPercentage);
        }
        previousDuration !== profileBaseDuration &&
          setPreviousDuration(profileBaseDuration);
        startProgressAnimation(profileBaseDuration - currentDuration);
      }
    }
  }, [device, isIdle]);

  React.useEffect(() => {
    if (isIdle) return;

    if (
      (profileBaseDuration >= targetDuration ||
        !Number.isFinite(targetDuration)) &&
      Number.isFinite(profileBaseDuration)
    ) {
      previousDuration !== profileBaseDuration &&
        setPreviousDuration(profileBaseDuration);

      // If tempProfile updates after heat cycle is active, this will
      // correct the animation
      deviceState === OperatingState.HEAT_CYCLE_ACTIVE &&
        startProgressAnimation(profileBaseDuration - currentDuration);
    }
  }, [profileBaseDuration, isIdle]);

  React.useEffect(() => {
    if (isIdle) {
      return;
    }
    if (startTimer) {
      switch (deviceState) {
        case OperatingState.HEAT_CYCLE_FADE:
          setStartTimer(false);
          break;

        case OperatingState.HEAT_CYCLE_ACTIVE:
          if (currentDuration <= targetDuration) {
            const currentPercentage = (currentDuration / targetDuration) * 100;
            timingAnim.setValue(currentPercentage);
            startProgressAnimation(targetDuration - currentDuration);
          }
          break;
      }
    }
  }, [targetDuration, targetTemp, deviceState, currentDuration]);

  React.useEffect(() => {
    if (resetDab) {
      resetDabbing();
    }
  }, [resetDab]);

  const resetDabbing = () => {
    timingAnim.removeAllListeners();
    warmUpAnim.removeAllListeners();
    timingAnim.setValue(0);
    warmUpAnim.setValue(0);

    setStartTimer(false);
    setWarmUpVal(0);
    setWarmUpTime(15000);

    // delay to wait for the interval execution to stop
    // otherwise it overwrites the device state saved below
    setTimeout(async () => {
      if (!peak) return;

      if (device) {
        appLog.info('Heat Cycle Completed', {
          chamberType: device.chamberType,
          intensity: Messages.VAPOR[vapor],
        });

        analytics.trackEvent(
          'heat cycle end',
          {},
          undefined,
          ({timestamp, ...properties}) => ({
            ...properties,
            ...(timestamp && {
              duration: elapsedSecondsSince(timestamp),
            }),
          }),
        );
      }

      dispatch(
        updateDevice({
          id: peak.peripheralId,
          state: OperatingState.HEAT_CYCLE_FADE,
        }),
      );

      const {dabsPerDay, totalHeatCycles, approxDabsRemaining} =
        await peak.readDabSummary();

      dispatch(
        updateDevice({
          id: peak.peripheralId,
          dailyDabs: dabsPerDay,
          hits: totalHeatCycles,
          approxDabsRemainingCount: approxDabsRemaining,
        }),
      );
    }, 600);
    onResetDabCompletion && onResetDabCompletion();
  };

  const onTemperatureButtonPress = React.useCallback(
    (addTempIncrement: number) => {
      const temperatureInCelsius = Temperature.convertDifference(
        addTempIncrement,
        {
          from: settings.tempPreference,
          to: TemperatureUnit.Celsius,
        },
      );

      peak
        ?.addDabbingTemperature(addTempIncrement, settings.tempPreference)
        .then(() => {
          analytics.trackEvent(
            'heat cycle add temperature',
            {
              value: temperatureInCelsius,
            },
            undefined,
            (properties, {value}) => ({
              ...properties,
              ...(properties.temperature && {
                temperature: properties.temperature + value,
              }),
            }),
          );
        });
    },
    [peak, settings],
  );

  const onTimeButtonPress = React.useCallback(
    (addTimeIncrement: number) => {
      const timeDiff = targetDuration - profileBaseDuration;

      peak
        ?.addDabbingTime((timeDiff > 0 ? timeDiff : 0) + addTimeIncrement)
        .then(() => {
          analytics.trackEvent(
            'heat cycle add time',
            {value: addTimeIncrement},
            undefined,
            (properties, {value, ...values}) => ({
              ...properties,
              ...values,
              ...(properties.duration && {
                duration: properties.duration + value,
              }),
            }),
          );
        });
    },
    [peak, targetDuration, profileBaseDuration],
  );

  const getWarmUpTime = () => {
    const hotThreshold = 200;
    const delta = targetTemp - currentTemp;
    if (currentTemp > hotThreshold && delta > 0) {
      const delta = targetTemp - currentTemp;
      switch (true) {
        case delta < 100:
          setWarmUpTime(2000);
          break;
        case delta < 200:
          setWarmUpTime(4000);
          break;
        case delta < 300:
          setWarmUpTime(6000);
          break;
        case delta < 400:
          setWarmUpTime(7000);
          break;
        case delta <= maxTemp - hotThreshold:
          setWarmUpTime(8000);
          break;
        default:
          setWarmUpTime(6000);
      }
    } else {
      switch (true) {
        case targetTemp < 400:
          setWarmUpTime(16000);
          break;
        case targetTemp < 500:
          setWarmUpTime(17000);
          break;
        case targetTemp < 600:
          setWarmUpTime(18000);
          break;
        case targetTemp <= maxTemp:
          setWarmUpTime(19000);
          break;
        default:
          setWarmUpTime(14000);
      }
    }
  };

  const timeTextPrompt = () => {
    switch (deviceState) {
      case OperatingState.HEAT_CYCLE_PREHEAT:
        return currentTemp < profileBaseTemp ? 'HEATING UP' : 'COOLING DOWN';
      case OperatingState.HEAT_CYCLE_ACTIVE:
        if (targetDuration && currentDuration) {
          if (currentDuration < 1) {
            return 'READY';
          }
          const timeLeft = Math.round(targetDuration - currentDuration);
          if (Number.isFinite(timeLeft) && !isNaN(timeLeft) && timeLeft >= 0) {
            return getTimeFormat(timeLeft, {
              padMinutes: true,
              padSeconds: true,
            });
          }
        }
        return null;
      case OperatingState.HEAT_CYCLE_FADE:
        return 'COOLING DOWN';
      case OperatingState.IDLE:
        return ' ';
    }
    return null;
  };

  return (
    <View
      style={styles.container}
      onLayout={e => {
        setBackgroundHeight(e.nativeEvent.layout.height);
      }}>
      <DabberHeader
        paddingTop={headerTopPadding ?? insets.top + HEADER_HEIGHT}
        primaryTextColor={
          theme.dabbingScreenTheme.textColor ?? theme.primaryTextColor
        }
        titlePaddingTop={
          titleTopPadding ??
          backgroundHeight * Constants.PROFILE_TITLE_TOP_PADDING_BG_RATIO
        }
        profileName={profile.name}
        fadedTextColor={
          theme.dabbingScreenTheme.fadedTextColor ?? theme.fadedTextColor
        }
        targetTemperatureStyle={theme.dabbingScreenTheme.targetTemperatureStyle}
        targetTempTextPrompt={isIdle ? '' : `${profileBaseTemp}°`}
      />

      <View style={[styles.halo, {marginTop: haloPosition}]}>
        <AnimatedHalo
          haloDiameter={animHaloSize}
          theme={theme}
          colors={
            isMoodLight && moodLight
              ? moodLight.colors
              : [
                  changeHexColorValues(
                    profileColor,
                    Constants.STD_HSL_VALUES.saturation,
                    Constants.STD_HSL_VALUES.lightness,
                    true,
                  ),
                ]
          }
          isMoodAnimating={
            moodLight &&
            isCustomMoodLight(moodLight) &&
            Number(moodLight?.type) !== 0
          }
          isMoodLight={isMoodLight}
          warmUp={currentTemp < targetTemp * 0.97}
          seconds={
            deviceState === OperatingState.HEAT_CYCLE_PREHEAT
              ? profileBaseDuration
              : previousDuration
          }
          {...{intensity}}
          innerComponent={
            isIdle && idleInnerComponent ? (
              idleInnerComponent()
            ) : (
              <AnimatedCircularProgress
                size={innerHaloDiameterSize - 8}
                width={4}
                fill={Math.round(animatedPercentage)}
                rotation={0}
                tintColor={
                  theme.dabbingScreenTheme.textColor ?? theme.primaryColor
                }
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {() =>
                  dabInnerComponent ? (
                    dabInnerComponent()
                  ) : (
                    <DabberInner
                      currentTemp={currentTemp}
                      temperatureUnit={tempPreference[0]}
                      tempFontSize={tempFontSize}
                      dabbingScreenActiveText={
                        theme.dabbingScreenTheme.dabbingScreenActiveText
                      }
                      temperatureBigStyle={theme.temperatureBigStyle}
                      durationColor={
                        theme.heatProfileSelectScreenTheme.durationColor
                      }
                      timeStatusStyle={theme.dabbingScreenTheme.timeStatusStyle}
                      primaryTextColor={
                        theme.dabbingScreenTheme.textColor ??
                        theme.primaryTextColor
                      }
                      timeTextPrompt={timeTextPrompt()}
                      isVaporEnabled={!!isVaporEnabled}
                      {...{vapor}}
                    />
                  )
                }
              </AnimatedCircularProgress>
            )
          }
          isDisco={deviceSettings?.isDabbingDiscoMode}
          onCoreImageLayout={onCoreImageLayout}
        />
      </View>

      {isIdle && idleFooter && idleFooter()}

      {!isIdle && (
        <DabberFooter
          targetTemperature={rawTargetTemp}
          message={
            dabMessagesTitle && dabMessages
              ? dabMessagesTitle + ': ' + dabMessages[0]
              : undefined
          }
          style={{
            boostContainer: {width: innerHaloDiameterSize / 2},
            message: {marginBottom: tipTextMarginBottom},
          }}
          {...{backgroundHeight}}
          {...(canAddTemp && {onTemperatureButtonPress})}
          {...(canAddTime && {onTimeButtonPress})}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    overflow: 'visible',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  halo: {
    alignItems: 'center',
    justifyContent: 'center',
    height: Constants.PROFILE_HALO_CONTAINER_HEIGHT,
    position: 'absolute',
    overflow: 'visible',
  },
});
