import {ChamberType, MoodType} from '../lib/types';

export const CHAMBER_NAME_RECORD = {
  [ChamberType.None]: '',
  [ChamberType.Classic]: '',
  [ChamberType.XL]: 'XL',
  [ChamberType.Performance]: '3D CHAMBER',
};

export const MOOD_TYPE_NAME_RECORD = {
  [MoodType.NO_ANIMATION]: 'Static',
  [MoodType.DISCO]: 'Disco',
  [MoodType.FADE]: 'Fade',
  [MoodType.SPIN]: 'Spin',
  [MoodType.SPLIT_GRADIENT]: 'Split',
  [MoodType.VERTICAL_SLIDESHOW]: 'Fill',
  [MoodType.TORNADO]: 'Tornado',
  [MoodType.BREATHING]: 'Breathing',
  [MoodType.CIRCLING_SLOW]: 'Circling Slow',
  [MoodType.LAVA_LAMP]: 'Lava Lamp',
  [MoodType.CONFETTI]: 'Confetti',
};
