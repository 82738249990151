import {format, parse} from 'date-fns';
import {TimeFilterPeriod} from 'puffco-api-axios-client';
import React from 'react';
import {View} from 'react-native';

import {DabHistorySummary} from '../../../../lib/types';
import {formatNumber} from '../../../../lib/utils/formatNumber';
import {colors} from '../../../../styles';
import {XAndY, getDabXAndYAxis} from '../util/getDabXAndYAxis';
import {getMaximumXAndYDomain} from '../util/getMaximumXAndYDomain';
import {BarGraph} from './BarGraph';

interface DabHistoryGraphContainerProps {
  timeFilter: TimeFilterPeriod;
  dabHistorySummary: DabHistorySummary[];
  startDate: Date;
  allTimeDabs: number;
  children: (data: {
    data: XAndY[];
    avg: number;
    totalDabs: number;
    maximum: XAndY<number>;
  }) => React.ReactNode;
}

interface DabHistoryGraphProps {
  timeFilter: TimeFilterPeriod;
  avg: number;
  data: XAndY[];
  maximum: XAndY<number>;
}

export const DabHistoryContainer = React.memo(function DabHistoryContainer({
  timeFilter,
  dabHistorySummary,
  children,
  startDate,
  allTimeDabs,
}: DabHistoryGraphContainerProps) {
  const {data, avg, totalDabs, maximum} = React.useMemo(() => {
    const count = {x: 0, y: 0};

    const findAndCheckValue = (
      dabHistorySummary: DabHistorySummary[],
      value: number,
      timeFilter: TimeFilterPeriod,
    ) => {
      let parseString = '';

      switch (timeFilter) {
        case 'd':
          parseString = 'yyyy-MM-dd HH';
          break;
        case 'y':
          parseString = 'yyyy-MM';
          break;
        default:
          parseString = 'yyyy-MM-dd';
      }

      return dabHistorySummary.find(v => {
        const formatMatch = format(value, parseString);
        return v.dateTimeStr === formatMatch;
      });
    };

    const getAll = (): XAndY<string>[] => {
      const yearObj: Record<string, number> = {};

      const startYear = (
        startDate ? new Date(startDate) : new Date()
      ).getFullYear();
      Array(new Date().getFullYear() - startYear + 1)
        .fill(null)
        .forEach((_, i) => {
          count.x += 1;
          yearObj[(i + startYear).toString()] = 0;
        });

      dabHistorySummary.forEach(v => {
        if (v.count > count.y) {
          count.y = v.count;
        }
        const x = parse(v.dateTimeStr, 'yyyy', new Date())
          .getFullYear()
          .toString();
        if (!(x in yearObj)) {
          count.x += 1;
          yearObj[x] = 0;
        }
        yearObj[x] += v.count;
      });

      return Object.keys(yearObj)
        .map(v => ({x: v, y: yearObj[v]}))
        .sort((a, b) => Number(a.x) - Number(b.x));
    };

    const data =
      timeFilter === 'all'
        ? getAll()
        : getDabXAndYAxis(
            timeFilter,
            v => {
              const d = findAndCheckValue(dabHistorySummary, v, timeFilter);
              if (!!d?.count && d.count > count.y) {
                count.y = d.count;
              }
              count.x += 1;
              return d?.count ?? 0;
            },
            startDate,
          );

    return {
      data,
      avg: data.reduce((acc, cur) => acc + cur.y, 0) / data.length,
      totalDabs:
        timeFilter === 'all'
          ? allTimeDabs
          : dabHistorySummary?.reduce((acc, cur) => acc + cur.count, 0) ?? 0,

      maximum: getMaximumXAndYDomain(count),
    };
  }, [dabHistorySummary, timeFilter, startDate, allTimeDabs]);
  return (
    <>
      {typeof children === 'function'
        ? children({data, avg, totalDabs, maximum})
        : children}
    </>
  );
});

export const DabHistoryGraph = React.memo(function DabHistoryGraph({
  timeFilter,
  data,
  avg,
  maximum,
}: DabHistoryGraphProps) {
  const getGridCount = () => {
    switch (timeFilter) {
      case 'y':
      case 'm':
      case 'd':
        return 4;
      default:
        return undefined;
    }
  };
  return (
    <View style={{top: 15, height: 160, width: '100%'}}>
      <BarGraph<string>
        avg={avg}
        style={{
          yLabel: {
            fontFamily: 'Roboto-Regular',
            color: colors.mediumGray50,
            fontWeight: '400',
            fontSize: 11,
          },
          xLabel: {
            fontFamily: 'Roboto-Regular',
            color: colors.mediumGray50,
            fontWeight: '400',
            fontSize: 11,
          },
          lineColor: '#4F4F4F',
          bar: {
            backgroundColor: colors.medianPurple,
            width: '100%',
            maxWidth: 30,
            alignSelf: 'center',
          },
          avgLineColor: colors.white50,
        }}
        formatYLabel={v => formatNumber(Math.floor(v))}
        gridCount={getGridCount()}
        xValues={data as XAndY<string>[]}
        maxY={maximum.y}
        formatXLabel={({x}, i) => {
          if (timeFilter === 'y') return x[0];
          if (
            timeFilter === 'w' ||
            (timeFilter === 'all' && (i === 0 || i === data.length - 1)) ||
            (timeFilter === 'm' &&
              (i === 0 ||
                i === Math.floor(data.length / 2) - 1 ||
                i === data.length - 1)) ||
            (timeFilter === 'd' && i % 7 === 0)
          ) {
            return x;
          }

          return '';
        }}
      />
    </View>
  );
});
