import {
  peakBaseShadow,
  peakPeachBlackLightsBaseLeft,
  peakPeachBlackLightsBaseRight,
  peakStorm,
  peakStormBase,
  peakStormChamberRegular,
  peakStormChamberXl,
  peakStormGlass,
} from '../assets/images';
// Import from exact file to prevent cycle
// Import from exact file to prevent cycle
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
import {colors} from '../styles';
import {defaultTheme} from './DefaultTheme';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  PeakImageTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from './Theme';
import {whitePeachTheme} from './WhitePeachTheme';

const BACKGROUND_COLOR = '#64687F';
const LINEAR_GRADIENT_ARRAY = ['rgba(49, 54, 78, 0)', 'rgba(49, 54, 78, 1)'];

const {
  lightLayers: {glass},
} = whitePeachTheme.peakImageTheme;

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: peakStorm,
  peakLayers: {
    glass: peakStormGlass,
    base: peakStormBase,
    chamber: {
      regular: peakStormChamberRegular,
      xl: peakStormChamberXl,
    },
    shadow: peakBaseShadow,
  },
  lightLayers: {
    glass,
    base: {
      left: peakPeachBlackLightsBaseLeft,
      right: peakPeachBlackLightsBaseRight,
    },
    ring: {},
  },
  peakDarkScreen: peakStorm,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: colors.white,
  inactiveColor: 'rgba(255, 255, 255, 0.5)',
  backgroundColor: 'transparent',
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: colors.white,
  buttonTextColor: colors.black,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: colors.black,
  durationFontColor: colors.white,
  specularColorStart: colors.clear,
  specularColorEnd: colors.clear,
};
/* endregion: Components */

/* region: Screens */
const connectScreenTheme: ConnectScreenTheme = {
  ...defaultTheme.connectScreenTheme,
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl: 'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/storm.mp4',
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  hoverBackgroundColor: colors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },

  // Status info
  batteryBarBackgroundColor: colors.mediumGray50,
  batteryDisconnectedColor: addOpacityToColorHex(colors.white, 0.5),
  preserveIndicator: colors.black,
  statusTextStealthColor: colors.black,
  statusTitleTextColor: colors.white,
  stealthIconColor: colors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: '#35384FFF', location: 0.15},
    {color: '#5B5F76FF', location: 0.75},
    {color: '#00000000', location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(colors.white, 0.5),
  metricTextColor: colors.white,
  metricTitleTextColor: addOpacityToColorHex(colors.white, 0.5),
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: colors.white,
  dabbingScreenFadedText: colors.mediumGray50,
  dabbingButtonBackground: colors.bone,
  dabbingButtonBackgroundPressed: colors.black,
  dabbingButtonForeground: colors.black,
  dabbingButtonForegroundPressed: colors.bone,
  iconColor: colors.white,
  textColor: colors.white,
  fadedTextColor: colors.mediumGray50,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: colors.white,
  iconColor: colors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: colors.white,
  },
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: colors.white,
  durationColor: colors.white50,
  startTextStyle: {color: colors.white, fontWeight: '400'},
  backgroundLineColor: colors.gray,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: colors.white,
  infoText: colors.white,
  fatSliderColor: colors.white,
  fatSliderBGColor: colors.black,
  buttonActiveForeground: colors.black,
  buttonInactiveForeground: colors.white,
  buttonActiveBackground: colors.white,
  buttonInactiveBackground: colors.black50,
  blurBackgroundColor: 'rgba(51, 51, 51, 0.8)',
  background: {
    color: 'rgba(51, 51, 51, 0.8)',
  },
  boostScreenLabel: colors.baseText,
};
/* endregion: Screens */

export const stormTheme: Theme = {
  ...defaultTheme,

  // Components
  primaryColor: colors.white,
  primaryTextColor: colors.white,
  logoTint: colors.invisible,
  peakImageTheme,
  heatProfileCardTheme,
  sectionDividerColor: colors.white20,
  sectionTitleTextColor: colors.white,
  statusDisplayTextColor: colors.white,
  spinnerColor: colors.black,
  tabBarTheme,
  styledButtonTheme,

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: BACKGROUND_COLOR, gradient: LINEAR_GRADIENT_ARRAY},
  },
  connectScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  homeScreenTheme,
  heatProfileListScreenTheme,
  dabbingScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileSelectScreenTheme,
  controlCenterTheme,

  dabTickTheme: {
    color: colors.white,
  },
};
