import React from 'react';

import {Screens} from '../../constants/navigation';
import type {HeatProfileEditNavigator} from '../../navigators/RootStackNavigator';
import {analytics} from '../../services/analytics';
import {HeatProfileForm} from './HeatProfileForm';

export type Props = {
  profileId?: string;
  headerTitle?: string;
  showHeader?: boolean;
  openMoodLight?: boolean;
};

type ScreenProps = HeatProfileEditNavigator<typeof Screens.HeatProfileEdit>;

export const HeatProfileEditScreen = ({route}: ScreenProps) => {
  const onSave = React.useCallback(() => {
    analytics.trackEvent('heat profile edit', {});
  }, []);

  return <HeatProfileForm {...{onSave}} {...(route.params ?? {})} />;
};
