import React from 'react';
import {Path, Rect} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ShopIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Rect x={4} y={6} width={16} height={16} rx={2} />
    <Path d="M16 9V6C16 3.79086 14.2091 2 12 2V2C9.79086 2 8 3.79086 8 6V9" />
  </Icon>
);
