import React from 'react';
import {Linking, StyleSheet} from 'react-native';

import {StyledButton} from '../../../components/StyledButton';

interface LinkButtonConfig {
  text: string;
  url: string; // Could also be a deeplink
}

interface Props {
  config: LinkButtonConfig;
}

export const LinkButton: React.FC<Props> = ({config}) => (
  <StyledButton
    style={styles.button}
    title={config.text}
    textStyle={{fontFamily: 'Roboto-Bold'}}
    onPress={() => Linking.openURL(config.url)}
  />
);

const styles = StyleSheet.create({
  button: {
    width: '100%',
  },
});
