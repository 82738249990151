import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const PuffcoIcon: React.FC<IconProps> = ({
  baseHeight = 25,
  baseWidth = 16,
  containerSize = 40,
  ...props
}) => (
  <Icon {...props} {...{baseHeight, baseWidth, containerSize}}>
    <Path d="M14.8173 15.8838V4.87386L9.24438 1V19.8709L14.8173 15.8838Z" />
    <Path d="M0.999959 19.8386V8.82869L6.57288 4.95483V23.8257L0.999959 19.8386Z" />
  </Icon>
);
