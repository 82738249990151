import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const InfoIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <Path d="M12 16V12" />
    <Path d="M12 8H12.01" />
  </Icon>
);
