import {
  background,
  logoClose,
  peak,
  peakLightsBaseLeft,
  peakLightsBaseRight,
  peakLightsGlassLeft,
  peakLightsGlassRight,
} from '../assets/images';
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
// Import from exact file to prevent cycle
import {shadeColor} from '../lib/utils/colors/shadeColor';
import {colors} from '../styles';
import {
  AssignToAHeatProfileScreenTheme,
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  CustomSwitchTheme,
  DabbingScreenTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LanternTypeButtonTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  SaveToDeviceScreenTheme,
  SharedMoodScreenTheme,
  StatusBarStyle,
  StatusBarTheme,
  StyledButtonTheme,
  TabBarTheme,
  Theme,
} from './Theme';

// Import from exact file to prevent cycle

// General

const navMenuTitleStyle: NavMenuTitleStyle = {
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 14,
  color: colors.white,
};

// Components

const bottomSheetTheme: BottomSheetTheme = {
  buttonBackgroundColor: '#353535',
  buttonTextColor: colors.white,
  backgroundColor: colors.bottomSheetGray,
  dividerColor: '#BCBBC1',
  lineColor: '#BCBBC1',
  titleColor: colors.white,
  subtitleColor: colors.white50,
  iconColor: colors.white,
  gradientShadeFunction: (color: string) =>
    [-50, -80].map(shade => shadeColor(color, shade)),
};

const customSwitchTheme: CustomSwitchTheme = {
  backgroundColorOn: colors.white,
  backgroundColorOff: colors.black,
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  primaryFontColor: colors.white,
  durationFontColor: colors.white,
  durationBubbleColor: colors.black,
  specularColorStart: colors.black,
  specularColorEnd: '#FFFFFF26',
  iconColor: colors.white,
  gradientShadeFunction: (color: string) =>
    [-75, -90].map(shade => shadeColor(color, shade)),
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  gradientShadeFunction: (color: string) =>
    [-60, -90].map(shade => shadeColor(color, shade)),
};

const lanternTypeButtonTheme: LanternTypeButtonTheme = {
  activeForeground: colors.black,
  inactiveForeground: colors.white,
  activeBackground: '#D4D4D4',
  inactiveBackground: colors.black50,
};

const moodLightCardTheme: MoodLightCardTheme = {
  gradientShadeFunction: (color: string) =>
    [-75, -90].map(shade => shadeColor(color, shade)),
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  activeForeground: colors.black,
  inactiveForeground: colors.white,
  activeBackground: colors.white,
  inactiveBackground: colors.black,
  textColor: colors.white,
};

const peakImageTheme: PeakImageTheme = {
  peak,
  lightLayers: {
    glass: {
      left: peakLightsGlassLeft,
      right: peakLightsGlassRight,
    },
    base: {
      left: peakLightsBaseLeft,
      right: peakLightsBaseRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDisconnectedColor: colors.red,
  peakDarkScreen: peak,
};

const styledButtonTheme: StyledButtonTheme = {
  buttonColor: colors.bone,
  buttonTextColor: colors.black,
};

const tabBarTheme: TabBarTheme = {
  activeColor: colors.white,
  inactiveColor: colors.mediumGray,
  backgroundColor: colors.background,
  height: 50,
};

// Screens

const assignToAHeatProfileScreenTheme: AssignToAHeatProfileScreenTheme = {
  selectedBorderColor: colors.white,
  background: {
    color: colors.bottomSheetGray,
  },
};

const connectScreenTheme: ConnectScreenTheme = {
  background: {image: background},
  peakNameBackgroundColor: colors.black,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: colors.white,
  infoText: colors.white50,
  fatSliderColor: colors.white,
  fatSliderBGColor: colors.black,
  buttonActiveForeground: colors.black,
  buttonInactiveForeground: colors.white,
  buttonActiveBackground: colors.white,
  buttonInactiveBackground: colors.black50,
  blurBackgroundColor: colors.transluscentLightGray,
  background: {
    color: colors.bottomSheetGray,
  },
  boostScreenLabel: colors.baseText,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: colors.white,
  dabbingScreenFadedText: colors.mediumGray50,
  dabbingButtonBackground: colors.bone,
  dabbingButtonBackgroundPressed: colors.black,
  dabbingButtonForeground: colors.black,
  dabbingButtonForegroundPressed: colors.bone,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  cardColor: colors.black50,
  cardText: colors.white,
  listTitleText: colors.white,
  listDivider: '#888888',
  trashCanIconColor: colors.white,
  activeOpacity: 1,
  inactiveOpacity: 0.2,
  connectedIconColor: colors.connectedGreen,
  disconnectedIconColor: '#D0021B',
  blurBackgroundColor: colors.clear,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  editTextColor: colors.lightSmoke,
  sliderTitleColor: colors.white,
  sliderThumbColor: colors.white,
  sliderTrackFillColor: colors.white,
  sliderTrackBackgroundColor: colors.white30,
  sliderIconTintColor: colors.white,
  moodLightHeaderBackgroundColor: colors.darkGreyMedium,
  durationColor: colors.white50,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {image: background},
  hoverBackgroundColor: colors.white,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: colors.white,
  durationColor: colors.white50,
  startTextStyle: {color: colors.white, fontWeight: '400'},
  backgroundLineColor: colors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  // General
  background: {image: background},
  peakDisconnectedColor: 'transparent',

  // Status info
  batteryBarBackgroundColor: colors.progressBarGray,
  batteryBarForegroundColor: colors.batteryGreen,
  batteryDisconnectedColor: addOpacityToColorHex(colors.white, 0.2),
  preserveIndicator: colors.white,
  statusTextConnectedColor: colors.darkGreen,
  statusTextDisconnectedColor: colors.red,
  statusTextStealthColor: colors.white,
  statusTitleTextColor: colors.white,
  stealthIconColor: colors.white,

  // Metric info
  dataBackgroundGradientArray: [
    {color: addOpacityToColorHex(colors.black, 0.3), location: 0},
    {color: addOpacityToColorHex(colors.black, 0), location: 1},
  ],
  bowlWarningColor: colors.red,
  metricDisconnectedTextColor: addOpacityToColorHex(colors.white, 0.2),
  metricTextColor: colors.white,
  metricTitleTextColor: colors.white50,
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: heatProfileListScreenTheme.background,
  infoLabelColor: colors.white50,
};

const sharedMoodScreenTheme: SharedMoodScreenTheme = {
  gradientShadeFunction: (color: string) =>
    [-30, -30].map(shade => shadeColor(color, shade)),
};

export const LightStatusBarTheme: StatusBarStyle = {
  backgroundColor: colors.white,
  barStyle: 'dark-content',
};
export const DarkStatusBarTheme: StatusBarStyle = {
  backgroundColor: '#090909',
  barStyle: 'light-content',
};

const statusBarTheme: StatusBarTheme = {
  dark: DarkStatusBarTheme,
  light: LightStatusBarTheme,
  themed: DarkStatusBarTheme,

  // These screens' background is always light
  WelcomeScreenStatusBar: LightStatusBarTheme,
  LegalIntroScreenStatusBar: LightStatusBarTheme,
  PrivacyPolicyScreenStatusBar: LightStatusBarTheme,
  TermsConditionsScreenStatusBar: LightStatusBarTheme,
  AccountCreatedScreenStatusBar: LightStatusBarTheme,
  CarouselScreenStatusBar: LightStatusBarTheme,
  EmailConfirmedScreenStatusBar: LightStatusBarTheme,
  ForgotPasswordScreenStatusBar: LightStatusBarTheme,
  LoginScreenStatusBar: LightStatusBarTheme,
  PasswordConfirmedScreenStatusBar: LightStatusBarTheme,
  PasswordResetScreenStatusBar: LightStatusBarTheme,
  PasswordResetSentScreenStatusBar: LightStatusBarTheme,
  RegisterScreenStatusBar: LightStatusBarTheme,
  XLUpdateRequiredScreenStatusBar: LightStatusBarTheme,
  GuideScreenStatusBar: LightStatusBarTheme,
  GuideStepScreenStatusBar: LightStatusBarTheme,
  RehashUserDabsScreenStatusBar: LightStatusBarTheme,
  RehashCommunityDabsScreenStatusBar: LightStatusBarTheme,
  RehashCommunityMoodlightRuntimeScreenStatusBar: LightStatusBarTheme,
  RehashViralMoodlightScreenStatusBar: LightStatusBarTheme,

  // These screens' background is always dark
  CleaningReminderScreenStatusBar: DarkStatusBarTheme,
  AccountScreenStatusBar: DarkStatusBarTheme,
  DevicesListScreenStatusBar: DarkStatusBarTheme,
  PreferencesScreenStatusBar: DarkStatusBarTheme,
  HeatProfileSelectScreenStatusBar: DarkStatusBarTheme,
  HeatProfileEditScreenStatusBar: DarkStatusBarTheme,
  DabbingScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenMessageStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenSuccessStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenTwoStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenUserInfoStatusBar: DarkStatusBarTheme,
  ConnectScreenStatusBar: DarkStatusBarTheme,
  PairInstructionsScreenStatusBar: DarkStatusBarTheme,
  LimitedEditionModalScreenStatusBar: DarkStatusBarTheme,
  AdvancedMetricsScreenStatusBar: DarkStatusBarTheme,
  MoodLightCreateScreenStatusBar: DarkStatusBarTheme,
  MoodLightEditScreenStatusBar: DarkStatusBarTheme,
  MoodLightAnimationScreenStatusBar: DarkStatusBarTheme,
  MoodLightLibraryScreenStatusBar: DarkStatusBarTheme,
  AssignToAHeatProfileScreenStatusBar: DarkStatusBarTheme,
  BluetoothStartPairingScreenStatusBar: DarkStatusBarTheme,
  ChangeEmailScreenStatusBar: DarkStatusBarTheme,
  CleaningManualScreenStatusBar: DarkStatusBarTheme,
  ChangePasswordScreenStatusBar: DarkStatusBarTheme,
  ChangeUsernameScreenStatusBar: DarkStatusBarTheme,
  ControlCenterBoostScreenStatusBar: DarkStatusBarTheme,
  DownloadSharedHeatProfileScreenStatusBar: DarkStatusBarTheme,
  EmailVerifyScreenStatusBar: DarkStatusBarTheme,
  EpilepsyWarningScreenStatusBar: DarkStatusBarTheme,
  ExclusiveMoodScreenStatusBar: DarkStatusBarTheme,
  FirmwareInfoScreenStatusBar: DarkStatusBarTheme,
  FirmwareUpdatingScreenStatusBar: DarkStatusBarTheme,
  LanternModeScreenStatusBar: DarkStatusBarTheme,
  MarketOptInConfirmedScreenStatusBar: DarkStatusBarTheme,
  UserManualScreenStatusBar: DarkStatusBarTheme,
  SessionReadyScreenStatusBar: DarkStatusBarTheme,
  SharedMoodScreenStatusBar: DarkStatusBarTheme,
  SharedHeatProfileScreenStatusBar: DarkStatusBarTheme,
  SnoozeFrequencyScreenStatusBar: DarkStatusBarTheme,
  ReminderFrequencyScreenStatusBar: DarkStatusBarTheme,
  TemperatureSettingsScreenStatusBar: DarkStatusBarTheme,
  FactoryResetScreenStatusBar: DarkStatusBarTheme,
  MarketOptInExistScreenStatusBar: DarkStatusBarTheme,
  LimitedEditionVideoScreenStatusBar: DarkStatusBarTheme,
  SaveToDeviceScreenStatusBar: DarkStatusBarTheme,
  WarrantyClaimScreenLogsStatusBar: DarkStatusBarTheme,
  DeviceSupportScreenStatusBar: DarkStatusBarTheme,
  FAQScreenStatusBar: DarkStatusBarTheme,
  DeviceDocumentScreenStatusBar: DarkStatusBarTheme,
  ReferAFriendScreenStatusBar: DarkStatusBarTheme,
  MyRewardsScreenStatusBar: DarkStatusBarTheme,
  ReferralWelcomeScreenStatusBar: DarkStatusBarTheme,
  RehashIntroScreenStatusBar: DarkStatusBarTheme,
  RehashEmptyScreenStatusBar: DarkStatusBarTheme,
  RehashFavoriteMoodlightScreenStatusBar: DarkStatusBarTheme,
  RehashFavoriteDabTimeScreenStatusBar: DarkStatusBarTheme,
  RehashCommunityIntroScreenStatusBar: DarkStatusBarTheme,
  RehashOutroScreenStatusBar: DarkStatusBarTheme,
  RehashCreateAccountScreenStatusBar: DarkStatusBarTheme,
};

export const defaultTheme: Theme = {
  // General
  fadedTextColor: colors.white,
  logoIcon: logoClose,
  navMenuIconColor: colors.white,
  navMenuTitleStyle,
  primaryColor: colors.white,
  primaryTextColor: colors.white,
  spinnerColor: colors.white,

  // Components
  bottomSheetTheme,
  customSwitchTheme,
  heatProfileCardTheme,
  heatProfileScreenBackgroundTheme,
  lanternTypeButtonTheme,
  moodLightCardTheme,
  moodTypeButtonTheme,
  peakImageTheme,
  sectionDividerColor: colors.gray,
  sectionTitleTextColor: colors.white,
  statusDisplayTextColor: colors.white,
  styledButtonTheme,
  tabBarTheme,
  statusBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileListScreenTheme,
  heatProfileSelectScreenTheme,
  homeScreenTheme,
  saveToDeviceScreenTheme,
  sharedMoodScreenTheme,
};
