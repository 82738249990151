import React from 'react';
import {Text, View} from 'react-native';
import {useDispatch} from 'react-redux';

import {brainSignal} from '../../assets/images';
import {Image} from '../../components/ImageWithFilter';
import {LinearGradientBackground} from '../../components/LinearGradientBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StyledButton} from '../../components/StyledButton';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {updateAppFlags} from '../../lib/redux/slices/appFlagsSlice';
import styled from '../../lib/utils/styled';
import type {ControlCenterNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import type {HeatProfileEditNavigator} from '../../navigators/RootStackNavigator';
import {colors, fillStyle} from '../../styles';

type Props =
  | HeatProfileEditNavigator<typeof Screens.EpilepsyWarning>
  | ControlCenterNavigatorScreenProps<typeof Screens.EpilepsyWarning>;

export const EpilepsyWarningScreen = ({route, navigation}: Props) => {
  const dispatch = useDispatch();

  const {redirect} = route.params;

  const onUnderstandButtonPress = React.useCallback(() => {
    dispatch(updateAppFlags({hasSeenEpilepsyWarning: true}));

    navigation.replace(...redirect);
  }, [navigation, redirect]);

  return (
    <LinearGradientBackground colors={['#7A24F4', '#5F12F5']}>
      <ScreenContainer>
        <TopContainer>
          <Image
            source={brainSignal}
            resizeMode={'contain'}
            style={fillStyle}
          />
        </TopContainer>

        <BottomContainer>
          <View>
            <TitleText>{Messages.EPILEPSY_WARNING_TITLE}</TitleText>

            <DescriptionText>
              {Messages.EPILEPSY_WARNING_DESCRIPTION}
            </DescriptionText>
          </View>

          <StyledButton
            title={Strings.I_UNDERSTAND}
            onPress={onUnderstandButtonPress}
          />
        </BottomContainer>
      </ScreenContainer>
    </LinearGradientBackground>
  );
};

const ScreenContainer = styled(SafeAreaView)({
  ...fillStyle,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const InnerContainer = styled(View)({
  ...fillStyle,
  display: 'flex',
  flexDirection: 'column',
});

const TopContainer = styled(InnerContainer)({justifyContent: 'flex-end'});

const BottomContainer = styled(InnerContainer)({
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 40,
  paddingHorizontal: 30,
});

const TitleText = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 44,
  color: colors.white,
  letterSpacing: 1,
  marginBottom: 29,
  textTransform: 'uppercase',
  includeFontPadding: false,
  textAlign: 'center',
  fontWeight: '400',
});

const DescriptionText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 18,
  lineHeight: 28,
  color: colors.white,
  letterSpacing: 0.5,
  includeFontPadding: false,
  textAlign: 'center',
  fontWeight: '400',
});
