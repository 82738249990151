import {MarketOptIn, UserUpdateDto} from 'puffco-api-axios-client';

import {CustomAlert} from '../../components/CustomAlert';
import {ErrorMessages} from '../../constants/Strings';
import {userApi} from '../api/apis';
import {setUser} from '../redux/slices/userSlice';
import type {AppDispatch} from '../redux/store';

export type ChangeMarketOptInDTO = {
  marketOptIn?: MarketOptIn;
};

export const updateUser = async (
  dispatch: AppDispatch,
  dto: UserUpdateDto,
  badRequestErrorMessage = ErrorMessages.BAD_REQUEST,
) => {
  try {
    const {data} = await userApi.updateUserById({id: 'me', userUpdateDto: dto});

    dispatch(setUser(data));

    return data;
  } catch (error) {
    if (!(error instanceof Error)) throw new Error(`Unknown error: ${error}`);

    if (error?.name === 'Unauthorized') {
      CustomAlert.alert('Error', ErrorMessages.INVALID_USER_AUTHORIZATION);
    } else if (error?.name === 'Forbidden') {
      CustomAlert.alert('Error', ErrorMessages.EMAIL_NOT_VERIFIED);
    } else if (error?.name === 'Bad Request') {
      CustomAlert.alert('Error', badRequestErrorMessage);
    } else {
      CustomAlert.alert('Error', ErrorMessages.NETWORK_ERROR);
    }

    throw error;
  }
};
