import {useFocusEffect, useNavigation} from '@react-navigation/core';
import React from 'react';

import {Alert} from '../../components/Alert';
import {Alerts, Strings} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {Connection} from '../../contexts/connection';
import {useConnectablePeripheral} from '../../lib/hooks/useConnectablePeripheral';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigators/RootStackNavigator';
import {
  getNavigationRoute,
  routeToOptions,
  toHome,
  toStartPairing,
} from '../../navigators/util';
import {LoadingContainer} from '../../screens/Education/components/LoadingContainer';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const withConnectedGuard = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const ComponentWithHOC: React.FC<P> = (props: P) => {
    const navigation = useNavigation<Navigation>();
    const peripheral = useConnectablePeripheral();

    const {peak, connecting} = Connection.useContainer();

    const connected = !!peak;

    useFocusEffect(
      React.useCallback(() => {
        const redirect = routeToOptions(getNavigationRoute(navigation));

        if (!peripheral)
          // If there is no current device, navigate to the pairing flow
          return navigation.navigate(...toStartPairing({redirect}));

        // If peak is connected, we can skip as we will render the screen
        // If peak is connecting, we can skip as we will render a loading indicator until it becomes false
        if (connected || connecting) return;

        // We need this wrapper promise, because on iOS the onDismiss is always called after onPress
        new Promise<boolean>(resolve => {
          Alert.alert(
            Alerts.DISCONNECTED_TITLE,
            Alerts.CONNECT_TO_DEVICE,
            [
              {
                text: Strings.CONTINUE,
                onPress: () => resolve(true),
              },
              {
                text: Strings.NOT_NOW,
                onPress: () => resolve(false),
              },
            ],
            {onDismiss: () => setTimeout(resolve, 100, false)},
          );
        }).then(ok => {
          if (!ok) return navigation.navigate(...toHome);

          // Navigate to the device connection screen that will redirect back to this screen
          return navigation.replace(Navigators.MainNavigator, {
            screen: Screens.Connect,
            params: {
              deviceId: peripheral.id,
              deviceName: peripheral.name,
              redirect,
            },
          });
        });
      }, [peripheral, connected, connecting]),
    );

    if (!peripheral || !connected) return <LoadingContainer />;

    return <Component {...props} />;
  };

  ComponentWithHOC.displayName = `withConnectedGuard(${Component.displayName ?? ''})`;

  return ComponentWithHOC;
};
