import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';

import {Alert} from '../../components/Alert';
import {SafeAreaView} from '../../components/SafeAreaView';
import {
  StyledField,
  displayRightIconMessage,
  getRightIcon,
} from '../../components/StyledField';
import {ErrorMessages} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {MailIcon} from '../../icons';
import {userApi} from '../../lib/api/apis';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import styled from '../../lib/utils/styled';
import {updateUser} from '../../lib/utils/updateUser';
import type {AccountNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors} from '../../styles';

interface ChangeEmailValues {
  email: string;
}

interface Props
  extends AccountNavigatorScreenProps<typeof Screens.ChangeEmail> {}

export const ChangeEmailScreen = ({route, navigation}: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);

  useAdaptiveSafeArea();

  const setAccountFieldValue = route.params?.setAccountFieldValue;

  const ChangeEmailSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(ErrorMessages.REQUIRED_EMAIL)
      .email(ErrorMessages.INVALID_EMAIL)
      .test({
        name: 'checkEmail',
        test: async function (value) {
          if (
            value &&
            value.includes('@') &&
            value.includes('.') &&
            value !== user?.email
          ) {
            const {data} = await userApi.validateFields({
              userValidateFieldsDto: {email: value},
            });

            if (data.email === 'ok') return true;

            const message =
              data.email === 'taken'
                ? 'Email already in use'
                : 'Invalid email address';

            return this.createError({message, path: 'email'});
          }

          return true;
        },
      }),
  });

  const handleSave = async (
    values: ChangeEmailValues,
    actions: FormikHelpers<typeof values>,
  ) => {
    actions.setSubmitting(true);
    const updateData: {
      email?: string;
    } = {};
    if (values.email && values.email !== '' && values.email !== user?.email) {
      updateData.email = values.email.trim();
    }
    const {email} = await updateUser(dispatch, updateData);

    if (email !== user?.email) {
      setAccountFieldValue?.('email', email);

      Alert.alert('Success', 'Your email has been changed!');
    }

    navigation.goBack();

    actions.setSubmitting(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{email: user ? user.email : ''}}
        onSubmit={handleSave}
        validationSchema={ChangeEmailSchema}>
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          submitForm,
          values,
          errors,
          touched,
        }) => (
          <View style={{alignItems: 'center'}}>
            <ChangeContainer>
              <FieldLabel>EMAIL</FieldLabel>
              <StyledField
                icon={MailIcon}
                borderColor={colors.mediumGray}
                height={35}
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                keyboardType="email-address"
                leftIconColor={colors.mediumGray}
                rightIcon={getRightIcon(
                  values.email,
                  errors.email,
                  user?.email !== values.email,
                )}
                rightIconColor={errors.email ? colors.red : colors.green}
                rightIconMessage={displayRightIconMessage(
                  values.email,
                  errors.email,
                  touched.email,
                )}
                textColor={colors.white}
              />
            </ChangeContainer>
            <ButtonContainer>
              <Button disabled={!isValid || isSubmitting} onPress={submitForm}>
                <ButtonText>SAVE</ButtonText>
              </Button>
            </ButtonContainer>
          </View>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled(SafeAreaView)({
  alignItems: 'center',
  backgroundColor: '#333333',
  flex: 1,
  justifyContent: 'center',
});

const ChangeContainer = styled(View)({
  width: 250,
});

const FieldLabel = styled(Text)({
  color: colors.white,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 16,
});

const ButtonContainer = styled(View)({
  marginTop: 20,
});

const Button = styled(TouchableOpacity)({
  backgroundColor: colors.mediumGray,
  borderRadius: 20,
  paddingHorizontal: 30,
  paddingVertical: 8,
});

const ButtonText = styled(Text)({
  color: colors.white,
  fontFamily: 'Roboto-Medium',
  fontWeight: '400',
  fontSize: 12,
});
