import {combineReducers} from 'redux';
import {createTransform, persistReducer} from 'redux-persist';

import {PersistStorage} from './PersistStorage';
import {appFlagsReducer} from './slices/appFlagsSlice';
import {appSettingsReducer} from './slices/appSettingsSlice';
import {bleReducer} from './slices/bleSlice';
import {interstitialsReducer} from './slices/interstitialsSlice';
import {moodLightReducer} from './slices/moodLightSlice';
import {profileReducer} from './slices/profilesSlice';
import {referralsReducer} from './slices/referralsSlice';
import {userReducer} from './slices/userSlice';
import {viewedChamberTypesReducer} from './slices/viewedChamberTypesSlice';
import {warrantyReducer} from './slices/warrantySlice';
import {Store} from './types';

export const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  ble: persistReducer<Store['ble']>(
    {
      key: 'ble',
      storage: PersistStorage,
      transforms: [
        createTransform(
          (state: Store['ble']['devices'], key) => {
            if (key !== 'devices') return state;

            return state.map(
              ({battery, batteryChargeState, approxDabsRemainingCount, ...d}) =>
                d,
            );
          },
          undefined,
          {},
        ),
      ],
      blacklist: ['connectedDevice'] as (keyof Store['ble'])[],
    },
    bleReducer,
  ),
  appFlags: appFlagsReducer,
  appSettings: appSettingsReducer,
  moodLight: moodLightReducer,
  warranty: warrantyReducer,
  referrals: referralsReducer,
  viewedChamberTypes: viewedChamberTypesReducer,
  interstitials: interstitialsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const stateSelector = (state: Store) => state;
