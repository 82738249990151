import {Middleware} from '@reduxjs/toolkit';

import {appLog} from '../../utils/Logger';

const spammyActions = new Set(['ble/updateDevice']);

export const loggerMiddleware: Middleware = () => next => action => {
  if (spammyActions.has(action.type)) return next(action);

  appLog.info(`redux: ${action.type}`, {type: action.type});

  return next(action);
};
