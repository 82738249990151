import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {StyleProp} from 'react-native';
import {TextStyle} from 'react-native';

import {Constants} from '../../../constants';
import {ExternalIcon} from '../../../icons/ExternalIcon';
import {useLink} from '../../../lib/hooks/useLink';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';

interface Props {
  uri: string;
  style?: StyleProp<TextStyle>;
  color?: string;
  appearance?: 'primary' | 'default';
}

export const Hyperlink = ({
  uri,
  children,
  style,
  color = colors.azureBlue,
  appearance = 'default',
}: React.PropsWithChildren<Props>) => {
  const handleLink = useLink();

  return (
    <Container onPress={() => handleLink(uri)} {...{style}}>
      <LinkText style={{color}}>{children}</LinkText>

      {appearance === 'primary' && (
        <Icon size={16} containerSize={16} {...{color}} />
      )}
    </Container>
  );
};

const Container = styled(TouchableOpacity)({
  flexDirection: 'row',
  alignItems: 'center',
  ...(Constants.IS_NATIVE_ANDROID && {
    transform: [{translateY: 4}],
  }),
});

const Icon = styled(ExternalIcon)({
  marginLeft: 4,
});

export const LinkText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  fontWeight: '400',
  textDecorationLine: 'underline',
});
