import React from 'react';
import {useSelector} from 'react-redux';

import {userIdSelector} from '../../lib/redux/slices/userSlice';
import {createContainer} from '../unstated-next';
import {Feature} from './features';
import {useLoadContext} from './useLoadContext';

const useContext = () => {
  const userId = useSelector(userIdSelector);
  const {data} = useLoadContext({userId});

  const getFeatureIfEnabled = React.useCallback(
    (feature: Feature) => {
      return data?.features[feature];
    },
    [data],
  );

  return React.useMemo(() => ({getFeatureIfEnabled}), [getFeatureIfEnabled]);
};

export const Context = createContainer(useContext);
