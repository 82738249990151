import React from 'react';
import {FlatList, Linking, StyleSheet, View} from 'react-native';

import {BackToTopButton} from '../../../../components/BackToTopButton';
import {Constants} from '../../../../constants';
import {Strings} from '../../../../constants/Strings';
import {Screens} from '../../../../constants/navigation';
import {isResolvedEntryLink, resolveAssetUrl} from '../../../../lib/api';
import {TypeDevice, TypeTag} from '../../../../lib/api/content-access/types';
import {useAdaptiveSafeArea} from '../../../../lib/hooks/useAdaptiveSafeArea';
import {useDebouncedState} from '../../../../lib/hooks/useDebouncedState';
import type {DeviceEducationNavigatorScreenProps} from '../../../../navigators/HomeDrawerNavigator';
import {colors} from '../../../../styles';
import {useContactSupport} from '../../../support/useContactSupport';
import {FaqDisclosure} from '../../components/FaqDisclosure';
import {Footer} from '../../components/Footer';
import {LoadingContainer} from '../../components/LoadingContainer';
import {NoResults} from '../../components/NoResults';
import {SearchBar} from '../../components/SearchBar';
import {TagFilter} from '../../components/TagFilter';
import {useLoadFaqs} from './useLoadFaqs';

export interface FaqScreenProps {
  referenceId: string;
  selected: TypeDevice;
}

interface ScreenProps
  extends DeviceEducationNavigatorScreenProps<typeof Screens.Faq> {}

export const FaqScreen: React.FC<ScreenProps> = ({route, navigation}) => {
  const contactSupport = useContactSupport();
  useAdaptiveSafeArea();

  const listRef = React.useRef<FlatList>(null);

  const [selectedTag, setSelectedTag] = React.useState<TypeTag>();
  const {
    debouncedState: query,
    state: inputValue,
    setDebouncedState: setDebouncedQuery,
    setDebouncedInstantly: setQuery,
    setState: setInputValue,
    setValues,
  } = useDebouncedState('');
  const [selectedFaq, setSelectedFaq] = React.useState<string>();

  const referenceId = route.params.referenceId;
  const selected = route.params.selected;

  const tags = selected?.fields.tags?.filter(isResolvedEntryLink);

  const options = {
    referenceId,
    query,
    tag: selectedTag?.sys.id,
  };

  const faqs = useLoadFaqs(options);

  const faqItems = faqs.faqs;

  const manual = selected?.fields.manual;

  const onTagPress = React.useCallback(
    (tag: TypeTag) => {
      setValues('');
      setSelectedTag(tag !== selectedTag ? tag : undefined);
    },
    [selectedTag],
  );

  const selectFaq = (id: string) => setSelectedFaq(id);

  return (
    <FlatList
      ref={listRef}
      data={faqItems}
      style={styles.layout}
      contentContainerStyle={styles.container}
      keyExtractor={item => item.sys.id}
      showsVerticalScrollIndicator={false}
      renderItem={({item: faq, index}) => (
        <FaqDisclosure
          key={index}
          {...{faq, index, listRef}}
          selected={selectedFaq}
          select={selectFaq}
        />
      )}
      ItemSeparatorComponent={Separator}
      ListHeaderComponent={
        <>
          <SearchBar
            value={inputValue}
            onChangeText={text => {
              setSelectedTag(undefined);
              setInputValue(text);

              if (text === '') {
                setQuery(text);
                return;
              }

              setDebouncedQuery(text);
            }}
          />

          {tags?.length && !inputValue && !!faqItems.length && (
            <>
              <TagFilter
                title={Strings.DEVICE_EDUCATION.FAQ.TAGS}
                onPress={onTagPress}
                {...{tags, selectedTag}}
              />

              {!faqs.loading && <Separator />}
            </>
          )}

          {faqs.loading && <LoadingContainer style={{minHeight: 200}} />}

          {!faqItems.length && !faqs.loading && (
            <>
              <NoResults style={styles.noResults} />

              {tags?.length && (
                <>
                  <Separator />

                  <TagFilter
                    style={styles.noResultsTagFilter}
                    title={Strings.DEVICE_EDUCATION.FAQ.POPULAR_TOPICS}
                    titleStyle={styles.noResultsTagFilterTitle}
                    onPress={onTagPress}
                    {...{tags, selectedTag}}
                  />
                </>
              )}
            </>
          )}
        </>
      }
      ListFooterComponent={
        !faqItems.length && !faqs.loading ? undefined : (
          <View style={styles.footerContainer}>
            {!faqs.loading && (
              <>
                <Separator />

                <Footer
                  title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.FOOTER}
                  onInstructionManualPress={() => {
                    if (!manual) return;

                    if (Constants.IS_WEB) {
                      Linking.openURL(resolveAssetUrl(manual));
                    } else {
                      navigation.navigate(Screens.UserManual, {
                        source: {uri: resolveAssetUrl(manual)},
                      });
                    }
                  }}
                  onContactSupportPress={contactSupport}
                  style={styles.footer}
                />

                {faqItems.length > 3 && (
                  <BackToTopButton
                    onPress={() => {
                      listRef.current?.scrollToOffset({
                        animated: true,
                        offset: 0,
                      });
                    }}
                  />
                )}
              </>
            )}
          </View>
        )
      }
    />
  );
};

const Separator = () => <View style={styles.separator} />;

const styles = StyleSheet.create({
  noResults: {marginBottom: 54},
  noResultsTagFilter: {marginTop: 48},
  noResultsTagFilterTitle: {textTransform: 'uppercase'},
  container: {
    flexGrow: 1,
    height: Constants.IS_WEB ? 0 : undefined,
  },
  footerContainer: {marginBottom: 78},
  footer: {marginTop: 72},
  separator: {
    height: 1.2,
    backgroundColor: colors.darkGray,
    marginHorizontal: 20,
  },
  layout: {
    flex: 1,
    backgroundColor: colors.black,
  },
});
