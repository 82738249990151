import {AgreementType} from 'puffco-api-axios-client';
import React from 'react';
import {Linking} from 'react-native';
import {useSelector} from 'react-redux';

import {Screens} from '../../constants/navigation';
import {optionsToRoute, toHome, toStartPairing} from '../../navigators/util';
import {pushService} from '../../services/push';
import {appFlagsSelector, updateAppFlags} from '../redux/slices/appFlagsSlice';
import {currentDeviceSelector} from '../redux/slices/bleSlice';
import {useAppDispatch} from '../redux/useAppDispatch';
import {appLog} from '../utils/Logger';
import NavigationService from '../utils/NavigationService';
import {openDeeplink} from '../utils/openDeeplink';
import {useAgreement} from './useAgreement';

export const useDeeplinking = () => {
  const dispatch = useAppDispatch();
  const hasCurrentDevice = !!useSelector(currentDeviceSelector);
  const {launchCount = 0} = useSelector(appFlagsSelector);
  const {getStatus} = useAgreement();

  React.useEffect(() => {
    const getInitialUrl = async () => {
      const url = await pushService.getInitialUrl();

      if (url) return url;

      return Linking.getInitialURL().then(url => url ?? undefined);
    };

    const onAppOpen = async () => {
      const status = await getStatus();

      if (!status[AgreementType.Tc] || !status[AgreementType.Pp])
        return NavigationService.instance()?.resetRoot({
          index: 0,
          routes: [optionsToRoute([Screens.Welcome])],
        });

      if (!hasCurrentDevice)
        return NavigationService.instance()?.resetRoot({
          index: 0,
          routes: [optionsToRoute(toStartPairing())],
        });

      return NavigationService.instance()?.resetRoot({
        index: 0,
        routes: [optionsToRoute(toHome)],
      });
    };

    const onOpen = async (url: string, initial?: boolean) =>
      openDeeplink(url, initial, onAppOpen);

    dispatch(updateAppFlags({launchCount: launchCount + 1}));

    const promise = getInitialUrl()
      .then(url => {
        appLog.info('Initial url resolved.', {url});

        if (!url) return onAppOpen();

        return onOpen(url, true);
      })
      .then(() => [
        pushService.onAppOpen(onOpen),
        Linking.addEventListener('url', ({url}) => onOpen(url)).remove.bind(
          Linking,
        ),
      ])
      .catch(() => []);

    return () => {
      promise.then(callbacks => callbacks.forEach(callback => callback()));
    };
  }, []);
};
