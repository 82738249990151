import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {Badge} from '../components/Badge';
import {useSpinner} from '../components/Spinner';
import {TabLabel} from '../components/TabLabel';
import {Strings} from '../constants/Strings';
import {Screens} from '../constants/navigation';
import {useAdaptiveSafeArea} from '../lib/hooks/useAdaptiveSafeArea';
import {useReferrals} from '../lib/hooks/useReferrals';
import {useTabBarAnimation} from '../lib/hooks/useTabBarAnimation';
import {MyRewardsScreen} from '../screens/ReferralProgram/MyRewardsScreen';
import type {Props as MyRewardsScreenProps} from '../screens/ReferralProgram/MyRewardsScreen';
import {ReferAFriendScreen} from '../screens/ReferralProgram/ReferAFriendScreen';
import {colors} from '../styles';

export type ReferralTabParamList = {
  [Screens.ReferAFriend]: undefined;
  [Screens.MyRewards]: MyRewardsScreenProps;
};

const ReferralProgramTabNavigator =
  createMaterialTopTabNavigator<ReferralTabParamList>();

export const ReferralProgramTab = () => {
  const {isLoading, unused} = useReferrals();

  const {animation, screenOptions} = useTabBarAnimation();

  const {onTouchStart, onTouchMove} = animation;

  useAdaptiveSafeArea();

  useSpinner({
    isVisible: isLoading,
    text: Strings.LOADING_DATA,
    color: colors.white,
  });

  return (
    <View {...{onTouchMove, onTouchStart}} style={styles.layout}>
      {!isLoading && (
        <ReferralProgramTabNavigator.Navigator {...{screenOptions}}>
          <ReferralProgramTabNavigator.Screen
            name={Screens.ReferAFriend}
            component={ReferAFriendScreen}
            options={{
              tabBarLabel: ({focused}) => (
                <TabLabel
                  {...{focused}}
                  title={Strings.REFERRAL_PROGRAM.REFER_A_FRIEND}
                />
              ),
            }}
          />

          <ReferralProgramTabNavigator.Screen
            name={Screens.MyRewards}
            component={MyRewardsScreen}
            options={{
              tabBarLabel: ({focused}) => (
                <TabLabel
                  title={Strings.REFERRAL_PROGRAM.MY_REWARDS}
                  {...{focused}}
                  badge={
                    unused.length > 0 ? (
                      <Badge style={styles.badge}>
                        <Text style={styles.badgeText}>{unused.length}</Text>
                      </Badge>
                    ) : undefined
                  }
                />
              ),
            }}
          />
        </ReferralProgramTabNavigator.Navigator>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: colors.black,
  },
  badge: {marginLeft: 6, marginBottom: 6, height: 25, width: 25},
  badgeText: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
  },
});
