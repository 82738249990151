import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import {backgroundFlourishMoodlightInterstitial} from '../../../assets/images';
import {flourishMoodlightInterstitialText} from '../../../assets/images';
import {ImgBackground} from '../../../components/ImgBackground';
import {Modal} from '../../../components/Modal';
import {
  StyledButton,
  StyledButtonProps,
} from '../../../components/StyledButton';
import {Messages} from '../../../constants/Strings';
import {CloseIcon} from '../../../icons';
import {useMoodLight} from '../../../lib/hooks/useMoodLight';
import {StaticModalInterstitial} from '../../../lib/types/Interstitial';
import {analytics} from '../../../services/analytics';
import {colors} from '../../../styles';

interface Props {}

const KEY = 'flourish-moodlight';

export const FlourishMoodlightInterstitial: React.FC<Props> = () => {
  const {accessMoodLight} = useMoodLight();

  React.useEffect(() => {
    analytics.trackEvent('interstitial view', {content_id: KEY});
  }, []);

  const onCloseButtonPress = () => {
    Modal.close();
  };

  const onCheckItOutButtonPress = () => {
    analytics.trackEvent('interstitial click', {
      content_id: KEY,
    });

    Modal.close();

    accessMoodLight({});
  };

  return (
    <View style={styles.window}>
      <ImgBackground
        source={backgroundFlourishMoodlightInterstitial}
        style={styles.background}
        resizeMode="cover">
        <CloseButton onPress={onCloseButtonPress} />

        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{Messages.NEW_EXCLUSIVE_MOODLIGHT}</Text>

            <Image
              source={flourishMoodlightInterstitialText}
              style={styles.flourishText}
            />
          </View>

          <StyledButton
            title={Messages.CHECK_IT_OUT}
            textStyle={{color: colors.white}}
            style={styles.checkItOutButton}
            onPress={onCheckItOutButtonPress}
          />
        </View>
      </ImgBackground>
    </View>
  );
};

export const flourishMoodlightInterstitial: StaticModalInterstitial = {
  key: KEY,
  startDate: new Date('2024-05-01'),
  type: 'static',
  display: {
    as: 'modal',
    if: ({currentDevice, connectedDevice}) => {
      if (!currentDevice || !connectedDevice) return false;

      const isFlourish = currentDevice.product?.name === 'Flourish';

      if (!isFlourish) return false;

      return true;
    },
  },
  options: {
    modal: FlourishMoodlightInterstitial,
  },
};

const CloseButton: React.FC<Pick<StyledButtonProps, 'onPress'>> = props => (
  <CloseIcon
    containerSize={30}
    size={20}
    color={colors.black}
    style={styles.closeButton}
    {...props}
  />
);

const styles = StyleSheet.create({
  window: {
    width: '90%',
    height: '85%',
    borderRadius: 18,
    overflow: 'hidden',
  },
  closeButton: {
    backgroundColor: colors.white,
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: 1,
  },
  background: {flex: 1},
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    marginHorizontal: 11,
    paddingVertical: 20,
  },
  textContainer: {marginTop: '5%'},
  text: {
    textTransform: 'uppercase',
    color: colors.white,
    fontFamily: 'BigShouldersDisplay-Bold',
    fontSize: 14,
    letterSpacing: 0.2,
    fontWeight: '700',
  },
  flourishText: {width: 201, height: 59, marginTop: 14},
  checkItOutButton: {
    width: 211,
    backgroundColor: '#6D997F',
  },
});
