import React from 'react';
import {Path} from 'react-native-svg';

import {colors} from '../styles';
import {Icon, IconProps} from './Icon';

export const ThermometerIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z" />
    <Path
      fill={props.color ?? colors.white}
      d="M14 9.5V14.76C14.8027 15.2963 15.4117 16.0766 15.7369 16.9856C16.0622 17.8946 16.0865 18.8841 15.8062 19.8079C15.526 20.7317 14.9561 21.541 14.1807 22.1161C13.4052 22.6912 12.4654 23.0017 11.5 23.0017C10.5346 23.0017 9.59476 22.6912 8.81934 22.1161C8.04391 21.541 7.47399 20.7317 7.19376 19.8079C6.91352 18.8841 6.93782 17.8946 7.26307 16.9856C7.58832 16.0766 8.19728 15.2963 9 14.76V9.5H14Z"
    />
  </Icon>
);
