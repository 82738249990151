import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

import type {RootStackParamList} from '../../navigators/RootStackNavigator';

export const useExitStack = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  return () => {
    const routesLength = navigation.getState().routes.length;

    if (routesLength > 1) navigation.popToTop();

    navigation.pop();
  };
};
