import React from 'react';
import {Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {AnswerItemContainer, Circle} from '..';
import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {SegmentedProgress} from '../../components/SegmentedProgress';
import {Navigators, Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {addAnswer} from '../../lib/redux/slices/warrantySlice';
import styled from '../../lib/utils/styled';
import type {WarrantyNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigators/params';
import {colors} from '../../styles';

interface ScreenProps
  extends WarrantyNavigatorScreenProps<typeof Screens.WarrantyClaimScreenTwo> {}

const toWarrantyClaimScreenTwoRedirect = new RedirectionParameter(
  Navigators.MainNavigator,
  {
    screen: Navigators.HomeDrawerNavigator,
    params: {
      screen: Navigators.HomeEmulatedDrawer,
      params: {
        screen: Navigators.Warranty,
        params: {
          screen: Screens.WarrantyClaimScreenTwo,
        },
      },
    },
  },
);

export const WarrantyClaimScreenTwo: React.FC<ScreenProps> = ({navigation}) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();
  const userInfo = useSelector(userSelector);

  const initialData = [
    {id: 1, question: 'Usb charger', selected: false},
    {id: 2, question: 'Wireless charging', selected: false},
  ];
  const [data, setData] = React.useState(initialData);
  const SelectQuestion = (id: number) => {
    const newTest = initialData.slice();
    newTest[id - 1].selected = true;
    setData(newTest);
    const result = newTest.filter(item => item.selected === true);
    dispatch(addAnswer({questionTwo: result[0].question}));

    if (userInfo) {
      dispatch(
        addAnswer({
          userInfo: {
            name: `${userInfo?.firstName} ${userInfo?.lastName}`,
            email: userInfo?.email,
          },
        }),
      );
      navigation.navigate(Screens.WarrantyClaimScreenMessage);
    } else {
      navigation.navigate(Screens.WarrantyClaimScreenUserInfo, {
        headerLeft: toWarrantyClaimScreenTwoRedirect.encode(),
      });
    }
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <QuestionContainer>
          <QuestionText>
            Are you using the included usb charger or wireless charging?
          </QuestionText>
        </QuestionContainer>
        <SegmentedProgress progress={2} />
        <AnswersContainer>
          {data.map(({id, question, selected}) => {
            const questionRowValue = question;
            const selectedValue = selected;
            const idValue = id;
            return (
              <AnswerItemContainer
                key={id}
                onPress={() => {
                  SelectQuestion(idValue);
                }}>
                <Circle style={{borderWidth: selectedValue ? 4 : 2}} />
                <AnswerText>{questionRowValue}</AnswerText>
              </AnswerItemContainer>
            );
          })}
        </AnswersContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const QuestionContainer = styled(View)({
  flex: 1,
  marginHorizontal: '7%',
  marginTop: 64,
});

const QuestionText = styled(Text)({
  color: colors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 28,
  lineHeight: 48,
  fontWeight: '400',
});

const AnswersContainer = styled(View)({
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  flex: 3.3,
  marginHorizontal: '7%',
  marginTop: '5%',
});

const AnswerText = styled(Text)({
  alignSelf: 'center',
  color: colors.white,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
});
