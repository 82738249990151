import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Messages} from '../constants/Strings';
import {Strings} from '../constants/Strings';
import {Screens} from '../constants/navigation';
import {MoodType} from '../lib/types';
import {MoodLightAnimationScreen} from '../screens';
import {
  MoodLightCreateScreen,
  Props as MoodLightCreateScreenProps,
} from '../screens/controlCenter/MoodLightCreateScreen';
import {
  MoodLightEditScreen,
  Props as MoodLightEditScreenProps,
} from '../screens/controlCenter/MoodLightEditScreen';
import {fadeStackOptions, withBackButton} from './common';

export type MoodLightControllerStackParamList = {
  [Screens.MoodLightCreate]?: MoodLightCreateScreenProps;
  [Screens.MoodLightEdit]?: MoodLightEditScreenProps;
  [Screens.MoodLightAnimation]: {
    onAnimationValueChange: (m: MoodType) => void;
    initialAnimationValue: MoodType;
  };
};

const MoodLightControllerStack =
  createStackNavigator<MoodLightControllerStackParamList>();

export const MoodLightControllerStackNavigator = () => {
  return (
    <MoodLightControllerStack.Navigator
      initialRouteName={Screens.MoodLightCreate}
      screenOptions={() => fadeStackOptions}>
      <MoodLightControllerStack.Screen
        name={Screens.MoodLightCreate}
        component={MoodLightCreateScreen}
        options={{
          headerTitle: Messages.MOOD_LIGHT_EDITOR_TITLE,
          ...withBackButton(),
        }}
      />

      <MoodLightControllerStack.Screen
        name={Screens.MoodLightEdit}
        component={MoodLightEditScreen}
        options={{
          headerTitle: Messages.MOOD_LIGHT_EDITOR_TITLE,
          ...withBackButton(),
        }}
      />

      <MoodLightControllerStack.Screen
        name={Screens.MoodLightAnimation}
        component={MoodLightAnimationScreen}
        options={{
          headerTitle: Strings.ANIMATIONS,
          ...withBackButton(),
        }}
      />
    </MoodLightControllerStack.Navigator>
  );
};
