import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {drawnCircle} from '../../../assets/images';
import {ResponsiveImage} from '../../../components/ResponsiveImage';
import {ResponsiveText} from '../../../components/ResponsiveText';
import {responsive} from '../../../lib/utils';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';

interface CircledTextProps {
  text: string;
  style?: StyleProp<ViewStyle>;
}

export const CircledText: React.FC<CircledTextProps> = ({text, style}) => (
  <Container {...{style}}>
    <DrawnCircle source={drawnCircle} />
    <InnerText size={24}>{text}</InnerText>
  </Container>
);

const Container = styled(View)({
  width: responsive(166),
  height: responsive(166),
  justifyContent: 'center',
  alignItems: 'center',
});

const DrawnCircle = styled(ResponsiveImage)({
  width: '100%',
});

const InnerText = styled(ResponsiveText)({
  position: 'absolute',
  color: colors.black,
  fontFamily: 'Roboto-Bold',
  letterSpacing: responsive(7.2),
});
