import {useSelector} from 'react-redux';

import {rewardsApi} from '../api/apis';
import {userIdSelector} from '../redux/slices/userSlice';
import {useImmutableRemoteData} from './useRemoteData';

export const useReferralLink = () => {
  const userId = useSelector(userIdSelector);

  return useImmutableRemoteData(
    {key: 'useReferralLink', userId},
    async ({userId}) => {
      if (!userId) return;
      return rewardsApi.getReferralLink().then(r => r.data.referralLink);
    },
  );
};
