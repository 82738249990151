import React from 'react';
import {StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import {Modal} from '../../../../components/Modal';
import {
  GalleryElement,
  StrictTypeGallery,
  isStrictTypeGallery,
  resolveAssetUrl,
} from '../../../../lib/api';
import {
  TypeStyledAsset,
  isTypeStyledAsset,
} from '../../../../lib/api/content-access/types';
import {analytics} from '../../../../services/analytics';
import {convertName} from '../../../../services/analytics/converters';
import {ContestGalleryViewer} from './GalleryViewer';
import {GridGalleryItem} from './GridGalleryItem';

interface Props {
  gallery: StrictTypeGallery;
}

const thumbnailSize = 480;

export const ContestGallery: React.FC<Props> = ({gallery}) => {
  const elements = gallery.fields.elements;

  if (!elements) return null;

  const renderItem = ({item, index}: {item: GalleryElement; index: number}) => {
    if (!isStrictTypeGallery(item)) return null;

    const innerElements = item.fields.elements as TypeStyledAsset[];

    if (!innerElements) return null;

    const firstAsset = innerElements[0];

    if (!isTypeStyledAsset(firstAsset)) return null;

    const thumbnail = resolveAssetUrl(
      firstAsset.fields.asset,
      // For bigger resolutions, not all thumbnails load on Android
      {w: thumbnailSize},
    );

    return (
      <GridGalleryItem
        {...{asset: item, index, thumbnail}}
        key={index}
        onPress={() => {
          Modal.display({
            element: (
              <ContestGalleryViewer
                {...{index}}
                elements={elements as StrictTypeGallery[]}
                onClose={Modal.close}
                guide={gallery.fields.guide}
                onItemChange={asset => {
                  if (!asset?.fields.title) return;

                  analytics.trackEvent('select content', {
                    content_id: convertName(asset?.fields.title),
                  });
                }}
              />
            ),
          });
        }}
      />
    );
  };

  return (
    <View style={styles.layout}>
      <FlatList
        data={elements}
        renderItem={renderItem}
        keyExtractor={item => item.sys.id}
        numColumns={3}
        columnWrapperStyle={styles.columnWrapper}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
  },
  columnWrapper: {
    gap: 14,
    marginBottom: 14,
  },
});
