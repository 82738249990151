import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {Constants} from '../constants';
import {Strings} from '../constants/Strings';
import {DuplicateIcon} from '../icons';
import {useCopy} from '../lib/hooks/useCopy';
import {colors} from '../styles';

interface Props {
  text: string;
}

export const CopyBox = ({text}: Props) => {
  const {onCopy, copied} = useCopy();

  return (
    <View
      style={[
        styles.container,
        copied
          ? {
              borderColor: colors.transparentLight,
            }
          : {
              backgroundColor: colors.transparentLighter,
              borderColor: colors.invisible,
            },
      ]}>
      <Text style={styles.text}>{copied ? Strings.COPIED : text}</Text>

      <TouchableOpacity onPress={() => onCopy({text})}>
        <DuplicateIcon color={colors.black} opacity={0.5} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:
      Constants.DIMENSIONS.HEIGHT < Constants.SCREEN_HEIGHT.PX_785 ? 12 : 20,
    borderRadius: 8,
    width: '100%',
    borderWidth: 1,
  },
  text: {
    marginRight: 10,
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: colors.black,
  },
  icon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
});
