import {BaseRehashDto} from 'puffco-api-axios-client';
import React from 'react';
import {View} from 'react-native';

import {rehashIntro} from '../../../assets/images';
import {ResponsiveImage} from '../../../components/ResponsiveImage';
import {SwitchableBackground} from '../../../components/SwitchableBackground';
import {Screens} from '../../../constants/navigation';
import styled from '../../../lib/utils/styled';
import type {RehashStoryScreenProps} from '../../../navigators/RehashStoryNavigator';
import {colors} from '../../../styles';
import {IntroBanner} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends BaseRehashDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashIntro> {}

const theme = {
  background: {color: '#62A8A5'},
};

export const Intro: React.FC<ScreenProps> = ({route}) => {
  const subtitle = route.params?.subtitle;

  return (
    <RehashScreen as={SwitchableBackground} background={theme.background}>
      <ImageContainer>
        <Square />

        <HeroImage source={rehashIntro} />
      </ImageContainer>

      {subtitle && <Banner text={subtitle} />}
    </RehashScreen>
  );
};

const ImageContainer = styled(View)({
  marginTop: '10%',
  height: '52%',
  aspectRatio: 0.91,
  alignSelf: 'flex-end',
});

const Square = styled(View)({
  position: 'absolute',
  top: '-5%',
  left: '-10%',
  height: '22%',
  aspectRatio: 1,
  backgroundColor: colors.darkYellow,
  zIndex: -1,
});

const HeroImage = styled(ResponsiveImage)({width: '100%'});

const Banner = styled(IntroBanner)({
  position: 'absolute',
  top: '50%',
  left: '0%',
  right: '0%',
  height: '70%',
});
