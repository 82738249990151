import {ProductName} from 'pikaparam';

import {PUFFCO_PRODUCTS} from '../../constants';
import {Device} from '../types';

const getLegacyProductName = (
  device: Pick<Device, 'product' | 'modelNumberString' | 'serialNumberString'>,
): ProductName => {
  const productByModel = device.modelNumberString
    ? PUFFCO_PRODUCTS[device.modelNumberString]
    : undefined;

  const [productCode] =
    device.serialNumberString?.match(/^((?:[\dA-Z]*[A-Z]+|\d)\d)/) ?? [];

  const productBySerial = productCode
    ? PUFFCO_PRODUCTS[productCode]
    : undefined;

  // Some LE Desert Peak Pros has the same model code as Peach Black - Onyx (model code: 13)
  if (productByModel === 'Onyx' && productBySerial === 'Desert') {
    return 'Desert';
  }

  return productByModel ?? productBySerial ?? 'OG';
};

export const getProductName = (
  device: Pick<Device, 'product' | 'modelNumberString' | 'serialNumberString'>,
): ProductName => {
  if (device.product?.name) return device.product.name;

  // TODO: remove this once users updated to the latest version
  return getLegacyProductName(device);
};
