import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {AppText} from '../../components/AppText';
import {CleaningReminderAlertPrompt} from '../../components/CleaningReminderAlertPrompt';
import {CustomMenuList} from '../../components/CustomMenuList';
import {ImgBackground} from '../../components/ImgBackground';
import {Modal} from '../../components/Modal';
import {SafeAreaView} from '../../components/SafeAreaView';
import {Constants} from '../../constants';
import {Messages, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {CheckIcon} from '../../icons';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  currentDeviceSelector,
  updateDeviceAppSettings,
} from '../../lib/redux/slices/bleSlice';
import styled from '../../lib/utils/styled';
import type {HomeEmulatedDrawerStackScreenProps} from '../../navigators/HomeDrawerNavigator';
import {analytics} from '../../services/analytics';
import {convertProduct} from '../../services/analytics/converters';
import {colors} from '../../styles';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.ReminderFrequency
>;

export const ReminderFrequencyScreen = (_props: ScreenProps) => {
  useAdaptiveSafeArea();
  const dispatch = useDispatch();
  const device = useSelector(currentDeviceSelector);

  const handleNumberSelection = (switchValue: number) => {
    if (!device) return;
    if (device.appSettings?.cleaningReminderDabs !== switchValue) {
      dispatch(
        updateDeviceAppSettings({
          id: device.id,
          cleaningReminderDabs: switchValue,
        }),
      );
      analytics.trackEvent('clean rem freq update', {
        value: switchValue,
        ...(device.product && {
          product: convertProduct(device.product),
        }),
      });
    }
  };

  const renderButton = ({
    item,
  }: {
    item: 0 | -1 | (typeof Constants.DEFAULT_CLEANING_FREQUENCY_VALUES)[number];
  }) => {
    const checked =
      (device?.appSettings?.cleaningReminderDabs &&
        !Constants.DEFAULT_CLEANING_FREQUENCY_VALUES.includes(
          device?.appSettings
            .cleaningReminderDabs as (typeof Constants.DEFAULT_CLEANING_FREQUENCY_VALUES)[number],
        ) &&
        device?.appSettings.cleaningReminderDabs !== 0 &&
        item === -1) ||
      (device?.appSettings &&
        item === device?.appSettings.cleaningReminderDabs);
    return (
      <PickerButton
        onPress={() =>
          item !== -1
            ? handleNumberSelection(item)
            : Modal.display({element: <CleaningReminderAlertPrompt />})
        }>
        <ButtonText>
          {item === 0
            ? Strings.NO_REMINDER
            : item === -1
              ? !checked
                ? Strings.CUSTOM
                : `${Strings.CUSTOM} (${device?.appSettings?.cleaningReminderDabs})`
              : item}
        </ButtonText>
        {checked && <CheckIcon color={colors.green} />}
      </PickerButton>
    );
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, backgroundColor: colors.black}}>
        <SectionContainer>
          <SectionContainerText>{Messages.NUMBER_DABS}</SectionContainerText>
          <CustomMenuList
            menuItemValues={[
              0,
              ...Constants.DEFAULT_CLEANING_FREQUENCY_VALUES,
              -1,
            ]}
            renderItem={renderButton}
          />
        </SectionContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const SectionContainer = styled(View)({
  flexDirection: 'column',
  marginTop: 64,
});

const SectionContainerText = styled(AppText)({
  marginLeft: Constants.IS_NATIVE_ANDROID ? 22 : 24,
  lineHeight: 12,
  color: colors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: '400',
});

const ButtonText = styled(Text)({
  marginLeft: 15,
  paddingTop: 10,
  color: colors.white,
  fontSize: 12,
  fontFamily: 'Roboto-Regular',
  letterSpacing: 0.47,
  fontWeight: '400',
});

const PickerButton = styled(TouchableOpacity)({
  height: 40,
  borderRadius: 45,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
