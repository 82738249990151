import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ChevronDownIcon: React.FC<IconProps> = props => (
  <Icon containerSize={40} size={32} {...props}>
    <Path d="M6 9L12 15L18 9" />
  </Icon>
);
