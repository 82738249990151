import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {backgroundReferAFriend} from '../../assets/images';
import {HeadingText} from '../../components/HeadingText';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import type {ReferralNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {RedirectionParameter} from '../../navigators/params';
import {colors} from '../../styles';
import {CtaButton, ImageHeader, ScrollLayout} from './components';

interface ScreenProps
  extends ReferralNavigatorScreenProps<typeof Screens.ReferralWelcome> {}

export const ReferralWelcomeScreen: React.FC<ScreenProps> = ({navigation}) => {
  useAdaptiveSafeArea();

  return (
    <ScrollLayout contentContainerStyle={{flexGrow: 1}} style={{paddingTop: 0}}>
      <ImageHeader source={backgroundReferAFriend} style={styles.header} />

      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <HeadingText style={styles.title}>
            {Messages.REWARDS.LOGIN}
          </HeadingText>

          <Text style={styles.subtitle}>{Messages.REWARDS.CREAT_ACCOUNT}</Text>
        </View>

        <View style={styles.footer}>
          <CtaButton
            title={Strings.LOGIN}
            style={styles.ctaButton}
            onPress={() =>
              navigation.navigate(Screens.Login, {
                redirect: new RedirectionParameter(Navigators.MainNavigator, {
                  screen: Navigators.HomeDrawerNavigator,
                  params: {
                    screen: Navigators.HomeEmulatedDrawer,
                    params: {
                      screen: Navigators.ReferralProgram,
                    },
                  },
                }).encode(),
              })
            }
          />

          <CtaButton
            title={Strings.CREATE_ACCOUNT}
            style={styles.secondaryCtaButton}
            textStyle={{
              color: colors.white,
              fontSize: 16,
              fontFamily: 'Roboto-Bold',
              letterSpacing: 0.48,
            }}
            onPress={() =>
              navigation.navigate(Screens.Register, {
                redirect: new RedirectionParameter(Navigators.MainNavigator, {
                  screen: Navigators.HomeDrawerNavigator,
                  params: {
                    screen: Navigators.HomeEmulatedDrawer,
                    params: {
                      screen: Navigators.ReferralProgram,
                    },
                  },
                }).encode(),
              })
            }
          />
        </View>
      </View>
    </ScrollLayout>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  header: {
    marginBottom: 30,
  },
  title: {
    marginBottom: 30,
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.textOnSurface,
    lineHeight: 26,
    fontSize: 16,
    marginBottom: 30,
  },
  ctaButton: {
    marginBottom: 8,
    width: '100%',
  },
  secondaryCtaButton: {
    backgroundColor: colors.black,
    borderWidth: 1,
    borderColor: colors.textOnSurfaceLight,
    width: '100%',
  },
  footer: {
    width: '100%',
    marginTop: 12,
    marginBottom: 8,
  },
});
