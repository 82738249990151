import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {Alert} from '../../components/Alert';
import {AppText} from '../../components/AppText';
import {DeviceStatusBadge} from '../../components/DeviceStatusBadge';
import {Image} from '../../components/ImageWithFilter';
import {LinearGradient} from '../../components/LinearGradient';
import {Logo} from '../../components/Logo';
import {Modal} from '../../components/Modal';
import {PeakImage} from '../../components/PeakImage';
import {ProgressBar} from '../../components/ProgressBar';
import {SwitchableBackground} from '../../components/SwitchableBackground';
import {Constants} from '../../constants';
import {Alerts} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {Connection} from '../../contexts/connection';
import {DevicesSwitchIcon} from '../../icons';
import {GuideType} from '../../lib/api';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useAppHeaderHeight} from '../../lib/hooks/useAppHeaderHeight';
import {useChamberType} from '../../lib/hooks/useChamberType';
import {useGetAndStoreExclusiveMoodLights} from '../../lib/hooks/useGetAndStoreExclusiveMoodLights';
import {useGetLatestOtaFile} from '../../lib/hooks/useGetLatestOtaFile';
import {useLogSync} from '../../lib/hooks/useLogSync';
import {useReferrals} from '../../lib/hooks/useReferrals';
import {useTheme} from '../../lib/hooks/useTheme';
import {useUpdateDabbingReduxValues} from '../../lib/hooks/useUpdateDabbingReduxValues';
import {useWatchDevice} from '../../lib/hooks/useWatchDevice';
import {appFlagsSelector} from '../../lib/redux/slices/appFlagsSlice';
import {appSettingsSelector} from '../../lib/redux/slices/appSettingsSlice';
import {currentDeviceSelector} from '../../lib/redux/slices/bleSlice';
import {getMoodLightSelector} from '../../lib/redux/slices/moodLightSlice';
import {
  activeProfilesSelector,
  currentProfileSelector,
  tempProfileSelector,
} from '../../lib/redux/slices/profilesSlice';
import {referralsActions} from '../../lib/redux/slices/referralsSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {
  BatteryChargeState,
  ChamberType,
  HeatProfileType,
  OperatingState,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {meetsMinimumFirmware} from '../../lib/utils';
import NavigationService from '../../lib/utils/NavigationService';
import {changeHexColorValues} from '../../lib/utils/colors';
import {useTemperature} from '../../lib/utils/convertTemperature';
import {
  getMoodLightColor,
  profilesMatch,
} from '../../lib/utils/profileFunctions';
import styled from '../../lib/utils/styled';
import type {HomeTabNavigatorScreenProps} from '../../navigators/HomeTabNavigator';
import {RedirectionParameter} from '../../navigators/params';
import {colors} from '../../styles';
import {HomeScreenTheme} from '../../themes';
import {useGuide} from '../Education/screens/GuideScreen';
import {NewRewardModal} from '../ReferralProgram/NewRewardModal';
import {CleaningReminder} from './components/CleaningReminder';
import {useCanDab} from './useCanDab';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('screen').width;

const limitedEditionLeftBorderWidth = 3;
const {IPHONE_8} = Constants.SCREEN_HEIGHT;

interface Props extends HomeTabNavigatorScreenProps<typeof Screens.Home> {}

export const HomeScreen = ({navigation}: Props) => {
  const {navigate, replace, setOptions} = navigation;

  const appDispatch = useDispatch();
  const HEADER_HEIGHT = useAppHeaderHeight();
  const {bottom} = useAdaptiveSafeArea();
  const isFocused = useIsFocused();
  const updateDabbingReduxValues = useUpdateDabbingReduxValues();
  const activeProfiles = useSelector(activeProfilesSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const deviceCurrentTemp = useWatchDevice('currentTemp', isFocused);
  const deviceBattery = useWatchDevice('battery', isFocused);
  const deviceState = useWatchDevice('state');
  const {isXlChamberKit, isPossibleXlChamberKit} = useChamberType();

  const lastKnownChamberType = currentDevice?.chamberType;

  const chamberType = useWatchDevice('chamberType', isFocused);

  const normalisedChamberType =
    chamberType ||
    (lastKnownChamberType ??
      (isXlChamberKit || isPossibleXlChamberKit
        ? ChamberType.XL
        : ChamberType.Performance));

  const deviceHits = useWatchDevice('hits', isFocused);
  const deviceBatteryChargeEstTimeToFull = useWatchDevice(
    'batteryChargeEstTimeToFull',
    isFocused,
  );
  const deviceApproxDabsRemainingCount = useWatchDevice(
    'approxDabsRemainingCount',
    isFocused,
  );
  const deviceDailyDabs = useWatchDevice('dailyDabs', isFocused);
  const deviceBatteryChargeState = useWatchDevice(
    'batteryChargeState',
    isFocused,
  );

  const currentProfile = useSelector(currentProfileSelector);
  const currentProfileIndex = useWatchDevice('heatCycleSelect', isFocused, 0);
  const tempProfile = useSelector(tempProfileSelector);
  const getMoodLight = useSelector(getMoodLightSelector);
  const {hasSeenNewUsername, launchCount} = useSelector(appFlagsSelector);
  const currentUser = useSelector(userSelector);

  const {peak} = Connection.useContainer();
  const connected = !!peak;

  const {tempPreference, batteryPreservationOn} =
    useSelector(appSettingsSelector);

  const guide = useGuide({
    type: GuideType.NEW_USER,
  });

  const referrals = useReferrals();

  useLogSync();

  const [, getOta] = useGetLatestOtaFile();

  React.useEffect(() => {
    if (!currentDevice?.serialNumberString) return;
    getOta(currentDevice?.serialNumberString).catch(() => void 0);
  }, [currentDevice?.serialNumberString, getOta]);

  const [peakImageSize, setPeakImageSize] = React.useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [spacingToReduce, setSpacingToReduce] = React.useState(0);
  const {verifyCanDab} = useCanDab();

  const currentTheme = useTheme();
  const {
    homeScreenTheme,
    logoTint,
    navMenuIconColor,
    primaryColor,
    tabBarTheme,
  } = currentTheme;
  const {
    batteryBarBackgroundColor,
    batteryBarForegroundColor,
    batteryDisconnectedColor,
    bowlWarningColor,
    groundLayerImage,
    limitedEditionText,
    peakDisconnectedColor,
    statusTitleTextColor,
  } = homeScreenTheme;
  const deviceId = currentDevice?.id;

  const threshold = useTemperature(
    Constants.TEMPERATURE_WARNING_THRESHOLD,
    TemperatureUnit.Fahrenheit,
  );

  const temperature = useTemperature(
    deviceCurrentTemp ?? 0,
    TemperatureUnit.Celsius,
  );

  const bowlTempColor = React.useMemo(
    () => (temperature > threshold ? bowlWarningColor : undefined),
    [threshold, temperature, bowlWarningColor],
  );

  useGetAndStoreExclusiveMoodLights();

  const peakStatus = connected
    ? currentDevice?.settings?.stealth
      ? 'stealth'
      : 'connected'
    : 'disconnected';

  useFocusEffect(
    React.useCallback(() => {
      if (referrals.isLoading) return;
      if (!referrals.code) return;

      Modal.display({element: <NewRewardModal code={referrals.code} />});

      appDispatch(referralsActions.clearNew());
    }, [referrals.isLoading, referrals.code, appDispatch]),
  );

  React.useEffect(() => {
    // Updates targetTemp and tempProfile when profile changes to prevent stale
    // targetTemp and tempProfile values in redux from triggering false
    // positives for changes in targetTemp and targetDuration
    const profile =
      currentProfileIndex === Constants.TEMP_HEAT_PROFILE_INDEX
        ? tempProfile
        : currentProfile;

    if (profile) {
      // when doing a clean install we won't have a tempProfile or a currentProfile
      // to test, delete Path browser, and reinstall and re-pair
      updateDabbingReduxValues(profile);
    }
  }, [currentProfileIndex, currentProfile?.id, tempProfile?.id, deviceId]);

  React.useEffect(() => {
    const currentActiveRouteName = NavigationService.getCurrentRouteName();
    switch (deviceState) {
      case OperatingState.HEAT_CYCLE_ACTIVE:
        if (currentActiveRouteName === Screens.SharedHeatProfile) {
          break;
        }
        navigate(Screens.Dabbing, {
          isResumingDab: true,
        });
        break;
      case OperatingState.HEAT_CYCLE_PREHEAT || OperatingState.HEAT_CYCLE_FADE:
        if (currentActiveRouteName === Screens.SharedHeatProfile) {
          break;
        }
        verifyCanDab(() => {
          navigate(Screens.Dabbing);
        });
    }
  }, [deviceState]);

  React.useEffect(() => {
    if (
      !hasSeenNewUsername &&
      launchCount >= 2 &&
      currentUser?.defaultUsername &&
      currentUser?.username &&
      isFocused
    ) {
      navigate(Screens.ChangeUsername);
    }
  }, [currentUser, hasSeenNewUsername, isFocused]);

  useFocusEffect(
    React.useCallback(() => {
      setOptions({
        headerRight: () => (
          <DevicesSwitchIcon
            onPress={() => {
              if (!connected)
                return Alert.alert(
                  Alerts.NOT_CONNECTED,
                  Alerts.CONNECT_TO_DEVICE,
                );

              navigate(Navigators.ControlCenter, {
                screen: Screens.ControlCenter,
              });
            }}
            color={navMenuIconColor}
          />
        ),
        headerTitle: () =>
          logoTint === colors.invisible && <Logo size={25} color={logoTint} />,
      });
    }, [setOptions, navigate, connected, navMenuIconColor, logoTint]),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (
        !currentDevice?.appSettings ||
        currentDevice?.appSettings.cleaningReminderDabs === 0
      ) {
        // don't do anything if set to "no reminder"
        return;
      }

      const lastCleanReminderDabCount =
        currentDevice?.appSettings.lastCleanReminderDabCount || 0;

      const cleaningReminderDabs =
        currentDevice?.appSettings.cleaningReminderDabs || 100;
      const delay = currentDevice?.appSettings.delay || 0;
      const currentDabs = deviceHits
        ? deviceHits - lastCleanReminderDabCount
        : 0;

      if (connected && currentDabs >= cleaningReminderDabs + delay) {
        Modal.display({
          element: <CleaningReminder />,
        });
      }
    }, [
      connected,
      currentDevice?.appSettings?.lastCleanReminderDabCount,
      currentDevice?.appSettings?.cleaningReminderDabs,
      currentDevice?.appSettings?.delay,
      deviceHits,
    ]),
  );

  const isChargingState = () => {
    return (
      deviceBatteryChargeState === BatteryChargeState.BULK ||
      deviceBatteryChargeState === BatteryChargeState.TOPUP ||
      deviceBatteryChargeState === BatteryChargeState.FULL
    );
  };

  const calculateNameSize = () => {
    if (!currentDevice?.name) {
      return 26;
    }
    if (screenWidth <= 360) {
      if (currentDevice?.name?.length < 15) {
        return 24;
      } else {
        return 16;
      }
    } else if (currentDevice?.name?.length > 15) {
      return 20;
    }
    return 30;
  };

  const getPeakColorSource = () => {
    switch (peakStatus) {
      case 'connected':
        // TODO: HomeScreen behavior ticket task
        return currentProfile
          ? isTHeatProfileMoodLight(currentProfile)
            ? getMoodLight(currentProfile.moodLightId) ?? peakDisconnectedColor
            : changeHexColorValues(
                getMoodLightColor(currentProfile) ===
                  Constants.FACTORY_HEX_COLORS.high
                  ? colors.red
                  : getMoodLightColor(currentProfile),
                1,
                0.5,
                true,
              )
          : peakDisconnectedColor;
      case 'stealth':
        return colors.black;
      case 'disconnected':
        return peakDisconnectedColor;
    }
  };

  const setImageDimensions = (e: LayoutChangeEvent) => {
    const {PEAK_IMAGE_ORIGINAL_DIMENSIONS} = Constants;
    const aspectRatio =
      PEAK_IMAGE_ORIGINAL_DIMENSIONS.width /
      PEAK_IMAGE_ORIGINAL_DIMENSIONS.height; // current dimensions of the peak images
    const {
      width: containerWidth,
      height,
      x: containerX,
      y,
    } = e.nativeEvent.layout;

    const width = aspectRatio * height;

    // The container may be narrower (or wider) than the image
    // But the horizontal centers are aligned
    // So use that knowledge to calculate the X position the image
    const imageX = containerX - (width - containerWidth) / 2;

    setPeakImageSize({height, width, x: imageX, y});
  };

  const makeGroundLayerStyle = () => {
    const {groundLayer} = homeScreenTheme;
    if (!groundLayer) {
      // use old logic if no groundLayer theme values are defined
      return {bottom: -(tabBarTheme.height + bottom)};
    }
    if (peakImageSize.width === 0) {
      // hide Ground Layer until Peak Image is rendered
      return {width: 0, height: 0};
    }
    const scaledGroundLayerImageWidth =
      peakImageSize.width *
      groundLayer.groundLayerImageWidthToPeakImageWidthRatio;
    const scaledGroundLayerImageHeight =
      scaledGroundLayerImageWidth *
      (groundLayer.groundLayerImageOriginalHeight /
        groundLayer.groundLayerImageOriginalWidth);
    const groundLayerImageTop =
      peakImageSize.y +
      peakImageSize.height * groundLayer.normalizedPeakImageYOffset -
      scaledGroundLayerImageHeight * groundLayer.normalizedGroundLayerYOffset;
    const groundLayerImageLeft =
      peakImageSize.x +
      peakImageSize.width * groundLayer.normalizedPeakImageXOffset -
      scaledGroundLayerImageWidth * groundLayer.normalizedGroundLayerXOffset;

    return {
      top: groundLayerImageTop,
      width: scaledGroundLayerImageWidth,
      height: scaledGroundLayerImageHeight,
      left: groundLayerImageLeft,
    };
  };

  const setProgressBarText = () => {
    if (!currentDevice || !connected) return '';
    const isCharging = isChargingState();
    if (isCharging) {
      if (
        deviceBatteryChargeEstTimeToFull &&
        deviceBatteryChargeEstTimeToFull !== Infinity
      ) {
        const {batteryChargeEstTimeToFull, battery, softwareRevisionString} =
          currentDevice;
        if (!battery || !batteryChargeEstTimeToFull) return '';
        const estTime =
          batteryPreservationOn &&
          meetsMinimumFirmware(
            softwareRevisionString,
            Constants.MINIMUM_FIRMWARE_VERSION.BATTERY_PRESERVATION,
          )
            ? Math.max(
                (batteryChargeEstTimeToFull *
                  (Constants.BATTERY_PRESERVATION_PCT - battery)) /
                  (100 - battery),
                0,
              )
            : batteryChargeEstTimeToFull;

        const remainMin = Math.round(estTime / 60);
        return `${remainMin} min until fully charged`;
      }
      if (deviceBattery && deviceBattery >= 95) return '0 min remaining';

      return '';
    } else if (deviceApproxDabsRemainingCount) {
      const minDabs = Math.floor(deviceApproxDabsRemainingCount / 5) * 5;
      return `${minDabs}-${minDabs + 5} dabs remaining`;
    }
    return '';
  };

  const progressBarText = setProgressBarText();

  const getDeviceBatteryText = () => {
    return connected && typeof deviceBattery === 'number'
      ? `${deviceBattery}%`
      : '- -%';
  };

  return (
    <SwitchableBackground background={homeScreenTheme.background}>
      <SafeAreaContainer
        style={{
          paddingTop: HEADER_HEIGHT,
          paddingBottom: bottom + tabBarTheme.height,
        }}>
        <ScreenContainer>
          <PeakInfoContainer>
            <TopContainer
              style={{
                marginBottom:
                  windowHeight <= IPHONE_8 ? 0 : 20 - spacingToReduce,
              }}>
              <DeviceName
                numberOfLines={4}
                style={{
                  fontSize: calculateNameSize(),
                  color: primaryColor,
                }}
                onTextLayout={e => {
                  setSpacingToReduce((e.nativeEvent.lines.length - 1) * 2);
                }}>
                {currentDevice?.name}
              </DeviceName>
            </TopContainer>

            <AnalyticsTextContainer>
              <AnalyticsTitle
                style={{
                  fontFamily: 'BigShouldersDisplay-Regular',
                  fontSize: 20,
                  fontWeight: '400',
                  letterSpacing: 0.5,
                  color: connected
                    ? statusTitleTextColor
                    : batteryDisconnectedColor,
                }}>
                {getDeviceBatteryText()}
              </AnalyticsTitle>
            </AnalyticsTextContainer>

            <AnalyticsContainer style={{marginVertical: 5}}>
              {/* TODO: need to parse battery charge to full information */}
              <ProgressBar
                width={connected ? `${deviceBattery || 0}%` : '0%'}
                wrapperStyle={{backgroundColor: batteryBarBackgroundColor}}
                verticalBar={
                  connected && batteryPreservationOn ? 0.8 : undefined
                }
                innerStyle={{backgroundColor: batteryBarForegroundColor}}
                containerStyle={{height: 40 - spacingToReduce}}
                textStyle={{
                  fontSize: 12,
                  marginTop: 3,
                  color: homeScreenTheme.metricTextColor,
                  fontFamily: 'Roboto-Regular',
                  fontWeight: '400',
                }}
                text={progressBarText}
              />
            </AnalyticsContainer>

            <AnalyticsContainer
              style={{
                marginBottom:
                  windowHeight <= IPHONE_8 ? 10 : 40 - spacingToReduce,
              }}>
              <DeviceStatusBadge
                device={
                  currentDevice && {
                    ...currentDevice,
                    batteryChargeState: peak?.attributes.chargeState,
                  }
                }
              />
            </AnalyticsContainer>
          </PeakInfoContainer>

          <AnalyticsContainer style={styles.metricsContainer}>
            <DataSetContainer
              title="ACTIVE PROFILE:"
              data={connected && currentProfile ? currentProfile.name : '- -'}
              connected={connected}
              homeScreenTheme={homeScreenTheme}
            />

            <DataSetContainer
              title="TEMPERATURE"
              color={bowlTempColor}
              data={
                connected && temperature
                  ? temperature + '°' + tempPreference[0]
                  : '- - °'
              }
              connected={connected}
              homeScreenTheme={homeScreenTheme}
            />

            <DataSetContainer
              title="DAILY DABS"
              data={connected ? (deviceDailyDabs || 0).toFixed(1) : '- -'}
              connected={connected}
              homeScreenTheme={homeScreenTheme}
            />

            <DataSetContainer
              title="TOTAL DABS"
              data={connected && deviceHits ? deviceHits : '- -'}
              connected={connected}
              homeScreenTheme={homeScreenTheme}
            />

            {limitedEditionText && (
              <View
                style={[
                  styles.limitedEditionWrapper,
                  {marginTop: 30 - spacingToReduce},
                ]}>
                {limitedEditionText.leftBorderGradientArray && (
                  <View>
                    <LinearGradient
                      colors={limitedEditionText.leftBorderGradientArray}
                      style={{
                        flex: 1,
                        width: limitedEditionLeftBorderWidth,
                      }}
                    />
                  </View>
                )}

                <AnalyticsContainer style={styles.limitedEditionTextContainer}>
                  <Text
                    style={[
                      styles.limitedEditionTextFaded,
                      {
                        color: connected
                          ? limitedEditionText.numberString.color
                          : homeScreenTheme.metricDisconnectedTextColor,
                      },
                    ]}>
                    {`LE - ${limitedEditionText.numberString.value}`}
                  </Text>

                  <Text
                    style={[
                      styles.limitedEditionTextFaded,
                      styles.limitedEditionText,
                      {
                        color: connected
                          ? limitedEditionText.name.color
                          : homeScreenTheme.metricDisconnectedTextColor,
                      },
                    ]}>
                    {limitedEditionText.name.value}
                  </Text>
                </AnalyticsContainer>
              </View>
            )}
          </AnalyticsContainer>

          <PeakContainer
            activeOpacity={0.9}
            onPress={() => {
              if (!connected)
                return navigate(Navigators.MainNavigator, {
                  screen: Screens.Connect,
                  params: {
                    deviceId: currentDevice?.id,
                    deviceName: currentDevice?.name,
                    redirect: new RedirectionParameter(
                      Navigators.MainNavigator,
                      {
                        screen: Navigators.HomeDrawerNavigator,
                        params: {
                          screen: Navigators.HomeEmulatedDrawer,
                          params: {
                            screen: Navigators.HomeTabNavigator,
                            params: {screen: Screens.Home},
                          },
                        },
                      },
                    ).encode(),
                  },
                });

              const findProfile = () => {
                if (currentProfileIndex !== Constants.TEMP_HEAT_PROFILE_INDEX)
                  return activeProfiles[currentProfileIndex];
                return tempProfile
                  ? activeProfiles.find(active =>
                      profilesMatch(tempProfile, active),
                    ) ?? activeProfiles[0]
                  : activeProfiles[0];
              };
              const profile = findProfile();
              // Can't handle this logic after navigating, as the heat profile activates before the guide opens
              // we don't want that
              if (!guide.shouldDisplay) {
                navigate(Screens.HeatProfileSelect, {
                  profile,
                  type: HeatProfileType.Active,
                });
                return;
              }
              guide.display({
                onExit: () =>
                  replace(Screens.HeatProfileSelect, {
                    profile,
                    type: HeatProfileType.Active,
                  }),
              });
            }}
            onLayout={setImageDimensions}>
            <PeakImage
              colorSource={getPeakColorSource()}
              style={{
                height: peakImageSize.height,
                width: peakImageSize.width,
                position: 'absolute',
              }}
              chamberType={normalisedChamberType}
            />
          </PeakContainer>

          {groundLayerImage && (
            <GroundLayerContainer style={makeGroundLayerStyle()}>
              <Image
                source={groundLayerImage}
                resizeMode="cover"
                style={{flex: 1, width: '100%'}}
              />
            </GroundLayerContainer>
          )}
        </ScreenContainer>
      </SafeAreaContainer>
    </SwitchableBackground>
  );
};

type DataSetProps = {
  title: string;
  data: any;
  connected: boolean;
  color?: string;
  homeScreenTheme: HomeScreenTheme;
};

const DataSetContainer = ({
  title,
  data,
  connected,
  color,
  homeScreenTheme: {
    dataBackgroundGradientArray,
    metricDisconnectedTextColor,
    metricTextColor,
    metricTitleTextColor,
  },
}: DataSetProps) => {
  const options = dataBackgroundGradientArray.reduce<{
    colors: string[];
    locations: number[];
  }>(
    (obj, item) => {
      obj.colors.push(item.color);
      obj.locations.push(item.location);

      return obj;
    },
    {colors: [], locations: []},
  );

  return (
    <LinearGradient
      {...options}
      angle={90}
      useAngle={true}
      style={styles.dataSetGradient}>
      <View
        style={{
          flexDirection: 'column',
          width: '100%',
          paddingLeft: 40,
        }}>
        <AnalyticsTitle
          style={{
            color: connected
              ? metricTitleTextColor
              : metricDisconnectedTextColor,
            marginBottom: -2,
          }}>
          {title}
        </AnalyticsTitle>

        <AnalyticsText
          numberOfLines={2}
          style={{
            color: connected
              ? color ?? metricTextColor
              : metricDisconnectedTextColor,
          }}>
          {data?.toString()}
        </AnalyticsText>
      </View>
    </LinearGradient>
  );
};

const SafeAreaContainer = styled(View)({
  flex: 1,
  justifyContent: 'center',
});

const ScreenContainer = styled(View)({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
});

const PeakContainer = styled(TouchableOpacity)({
  position: 'absolute',
  width: '70%',
  height: '85%',
  right: 0,
  zIndex: 3,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const PeakInfoContainer = styled(View)({
  flexDirection: 'column',
  width: '50%',
  paddingLeft: 40,
  zIndex: 4,
});

const TopContainer = styled(View)({
  flexDirection: 'row',
  zIndex: 4,
});

const DeviceName = styled(Text)({
  maxWidth: windowWidth * 0.3,
  width: '50%',
  fontFamily: 'BigShouldersDisplay-Bold',
  overflow: 'hidden',
  flexWrap: 'wrap',
  textAlign: 'left',
  textTransform: 'uppercase',
  fontWeight: '400',
});

const AnalyticsTextContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const AnalyticsContainer = styled(View)({
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1,
  width: '100%',
});

const styles = StyleSheet.create({
  metricsContainer: {
    alignItems: 'flex-start',
  },
  dataSetGradient: {
    width: '80%',
    left: 0,
    marginBottom: 3,
    paddingTop: 8,
    paddingBottom: 4,
  },
  limitedEditionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 40,
  },
  limitedEditionTextContainer: {
    marginTop: 0,
    paddingLeft: 8,
  },
  limitedEditionTextFaded: {
    fontFamily: 'BigShouldersDisplay-Bold',
    alignSelf: 'flex-start',
    fontSize: 18,
    letterSpacing: 0.65,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontWeight: '400',
  },
  limitedEditionText: {
    fontFamily: 'BigShouldersDisplay-Black',
    fontWeight: '900',
  },
});

const AnalyticsTitle = styled(AppText)({
  alignSelf: 'flex-start',
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  letterSpacing: 0.47,
  includeFontPadding: false,
  textTransform: 'uppercase',
});

const AnalyticsText = styled(AppText)({
  fontFamily: 'BigShouldersDisplay-Bold',
  alignSelf: 'flex-start',
  fontSize: 24,
  letterSpacing: 0.38,
  maxWidth: '43%',
  textTransform: 'uppercase',
  includeFontPadding: false,
});

const GroundLayerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  top: '80%',
});
