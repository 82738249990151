import html2canvas from 'html2canvas';
import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const CaptureContainer = React.forwardRef(function CaptureContainer(
  {children, style}: React.PropsWithChildren<Props>,
  ref,
) {
  const capRef = React.useRef<HTMLDivElement>(null);

  const capture = async () => {
    const element = capRef.current;
    const canvas = await html2canvas(element as HTMLElement, {
      useCORS: true,
    });
    const data = canvas.toDataURL('png');
    return data;
  };

  React.useImperativeHandle(ref, () => ({capture}), []);

  return (
    <View style={[styles.root, style]}>
      <div ref={capRef} style={styles.content}>
        {children}
      </div>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    display: 'flex',
    flex: 1,
    zIndex: -1,
    pointerEvents: 'none',
  },
  content: {display: 'flex', flex: 1},
});
