import {TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Text, View} from 'react-native';

import styled from '../../../../lib/utils/styled';
import {colors} from '../../../../styles';
import {Odometer} from './Odometer';

const windowWidth = Dimensions.get('window').width;

const TOTAL = 29;

type TemperatureGraphProp = {
  scale: TemperatureUnit;
  temperature: number;
  lowest: number;
  highest: number;
};

//Create min and max to auto adjust odometer radius and to avoid overlapping
const minAndMax = {
  min: {
    width: 375,
    radius: 2,
  },
  max: {
    width: 450,
    radius: 23,
  },
};

const getAdditionalRadius = () => {
  if (windowWidth > minAndMax.max.width) {
    return minAndMax.max.radius;
  }
  const percentage =
    ((windowWidth - minAndMax.min.width) * 100) /
    (minAndMax.max.width - minAndMax.min.width);

  return Math.floor(
    (percentage / 100) * (minAndMax.max.radius - minAndMax.min.radius) +
      minAndMax.min.radius,
  );
};
const getData = (index: number) => [
  ...Array(TOTAL)
    .fill(null)
    .map((_, i) => ({
      x: i,
      y:
        index - 4 <= i && i <= index + 4
          ? i === index
            ? 16
            : i - 1 === index || i + 1 === index
              ? 8
              : 1
          : 0,
    })),
];

export const TemperatureGraph = React.memo(function TemperatureGraph({
  scale,
  temperature,
  lowest,
  highest,
}: TemperatureGraphProp) {
  let percentage = 0;

  if (temperature < lowest) percentage = 0;
  else if (temperature > highest) percentage = 100;
  else percentage = ((temperature - lowest) * 100) / (highest - lowest);

  const percentageToBar = Math.floor((percentage / 100) * (TOTAL - 1));

  return (
    <View
      style={{
        width: Math.round(windowWidth / 2),
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}>
      <Odometer
        style={{
          top: -26 - getAdditionalRadius(),
          left: -15,
        }}
        animateAll={true}
        activeLines={{color: colors.medianPurple, radius: 100}}
        baseLines={{color: '#424242'}}
        xAndY={getData(percentageToBar)}
        innerColor={'#1C1C1C'}
        lineSize={2.2}
        innerRadius={88 + getAdditionalRadius()}
        size={118 + getAdditionalRadius()}
        value={percentageToBar}
        animationSpeed={1000}
        innerComp={
          <TemperatureContainer>
            <Temperature>{Math.round(temperature)}°</Temperature>
            <Scale>{scale === TemperatureUnit.Fahrenheit ? 'F' : 'C'}</Scale>
          </TemperatureContainer>
        }
        leftCompStyle={{left: -8}}
        rightCompStyle={{right: -8}}
        leftComp={<AxisText>{Math.round(lowest)}°</AxisText>}
        rightComp={<AxisText>{Math.round(highest)}°</AxisText>}
      />
    </View>
  );
});

const AxisText = styled(Text)({
  color: colors.mediumGray50,
  fontFamily: 'Roboto-Regular',
  fontSize: 12,
  fontWeight: '400',
  lineHeight: 20,
  textTransform: 'uppercase',
  letterSpacing: 0.05,
});

const TemperatureContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  top: -8,
  left: 6,
});

const Temperature = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  fontSize: 32,
  letterSpacing: 0.05,
  color: colors.white,
});

const Scale = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 8,
  position: 'relative',
  top: 24,
  left: -6,
  color: colors.white50,
});
