import {Text} from 'react-native';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

export const AppText = styled(Text)({
  marginVertical: 2,
  color: colors.baseText,
  fontSize: 20,
  fontFamily: 'BigShouldersDisplay-Medium',
  letterSpacing: 0.6,
  fontWeight: '400',
});
