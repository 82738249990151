import React from 'react';
import {View} from 'react-native';
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';

import {CloseIcon, PauseIcon, PlayIcon} from '../icons';
import {addOpacityToColorHex} from '../lib/utils/colors';
import styled from '../lib/utils/styled';
import {colors} from '../styles';

export interface Props {
  index: number;
  length: number;
  progress: SharedValue<number>;
  isDark?: boolean;
  isPaused?: boolean;
  visible?: boolean;
  onCloseButtonPress: () => void;
  onPlaybackButtonPress: () => void;
}

export const StoriesHeader: React.FC<Props> = ({
  index,
  length,
  progress,
  isDark,
  isPaused,
  visible = true,
  onCloseButtonPress,
  onPlaybackButtonPress,
}) => {
  const completedColor = isDark ? colors.black : colors.white;
  const uncompletedColor = addOpacityToColorHex(completedColor, 0.5);

  const activeStyle = useAnimatedStyle(() => ({
    width: `${interpolate(progress.value, [0, 1], [0, 100])}%`,
    backgroundColor: completedColor,
  }));

  const ControlIcon = isPaused ? PlayIcon : PauseIcon;

  return (
    <View style={!visible && {opacity: 0}}>
      <Segments>
        {Array.from({length}, (_, segmentIndex) => (
          <Segment
            key={segmentIndex}
            style={{
              backgroundColor:
                segmentIndex < index ? completedColor : uncompletedColor,
            }}>
            {segmentIndex === index && <ActiveSegment style={activeStyle} />}
          </Segment>
        ))}
      </Segments>

      <Header>
        <ControlIcon
          color={completedColor}
          containerSize={44}
          strokeWidth={2}
          size={26}
          style={{
            borderColor: uncompletedColor,
            borderWidth: 1,
          }}
          onPress={onPlaybackButtonPress}
        />

        <CloseIcon
          color={completedColor}
          containerSize={44}
          size={26}
          strokeWidth={2}
          style={{
            borderColor: uncompletedColor,
            borderWidth: 1,
            padding: 5,
          }}
          onPress={onCloseButtonPress}
        />
      </Header>
    </View>
  );
};

const Header = styled(View)({
  marginTop: 12,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8,
});

const Segments = styled(View)({
  flexDirection: 'row',
  height: 5,
  gap: 5,
  marginBottom: 12,
});

const Segment = styled(View)({
  flex: 1,
  borderRadius: 100,
  overflow: 'hidden',
});

const ActiveSegment = styled(Animated.View)({
  height: '100%',
});
