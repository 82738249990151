import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';

import {Logo} from '../../components/Logo';
import {Text, TextProps} from '../../components/Typography';
import {colors} from '../../styles';

interface Props extends ViewProps {}

export const LogoHeader: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  children,
}) => (
  <View style={[styles.container, style]}>
    <Logo color={colors.black} size={39} />
    {children}
  </View>
);

export const LogoTitle: React.FC<TextProps> = ({style, ...props}) => (
  <Text style={[styles.title, style]} variant="h2" {...props} />
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: 24,
  },
});
