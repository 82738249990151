import {DeviceModelType, MoodType} from '../lib/types';
import type {PeakImageLayerKey, PeakSection} from '../themes';

export const PEAK_IMAGE_COLOR_OFFSETS_DICTIONARY = {
  [DeviceModelType.OG]: {
    [MoodType.DISCO]: {
      glass: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.FADE]: {
      glass: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
      base: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.NO_ANIMATION]: {
      glass: {
        left: [0, 0, 1, 1, 1, 1, 5],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        left: [0, 0, 0, 0, 0, 4, 4],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.SPIN]: {
      glass: {
        left: [0, 0, 1, 1, 1, 1, 1],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        left: [0, 0, 1, 1, 1, 1, 1],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.SPLIT_GRADIENT]: {
      glass: {
        left: [0, 0, 1, 2, 2, 3, 3],
        right: [0, 0, 1, 2, 2, 3, 3],
      },
      base: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.VERTICAL_SLIDESHOW]: {
      glass: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
      base: {
        left: [0, 0, 1, 1, 1, 1, 1],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    // Advanced Mood Light offsets
    [MoodType.LAVA_LAMP]: {
      glass: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.CONFETTI]: {
      glass: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
    },
  },
  [DeviceModelType.LIGHTNING]: {
    [MoodType.DISCO]: {
      glass: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.FADE]: {
      glass: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.NO_ANIMATION]: {
      glass: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 1, 1, 1, 1, 1],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 5],
        midLeft: [0, 0, 0, 0, 0, 4, 4],
        farRight: [0, 0, 0, 0, 0, 0, 5],
        midRight: [0, 0, 0, 0, 0, 4, 4],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 4, 4],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.SPIN]: {
      glass: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 1, 1, 1, 1, 1],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 1, 1, 1, 1, 1],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
        // Peach Black Specific
        left: [0, 0, 1, 1, 1, 1, 1],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      ring: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 1, 1, 1, 1, 1],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.SPLIT_GRADIENT]: {
      glass: {
        farLeft: [0, 0, 1, 2, 2, 3, 3],
        midLeft: [0, 0, 1, 2, 2, 3, 3],
        farRight: [0, 0, 1, 2, 2, 3, 3],
        midRight: [0, 0, 1, 2, 2, 3, 3],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 1, 2, 2, 3, 3],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 1, 2, 2, 3, 3],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 0, 0, 0, 0, 0],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
      },
    },
    [MoodType.VERTICAL_SLIDESHOW]: {
      glass: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 0, 0, 0, 0, 0],
        midRight: [0, 0, 0, 0, 0, 0, 0],
      },
      base: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 0, 0, 0, 0, 0],
        // Peach Black Specific
        left: [0, 0, 1, 1, 1, 1, 1],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      ring: {
        farLeft: [0, 0, 1, 1, 1, 1, 1],
        midLeft: [0, 0, 1, 1, 1, 1, 1],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    // Advanced Mood Light offsets
    [MoodType.LAVA_LAMP]: {
      glass: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
    },
    [MoodType.CONFETTI]: {
      glass: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
      base: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
        // Peach Black Specific
        left: [0, 0, 0, 0, 0, 0, 0],
        right: [0, 0, 1, 1, 1, 1, 1],
      },
      ring: {
        farLeft: [0, 0, 0, 0, 0, 0, 0],
        midLeft: [0, 0, 0, 0, 0, 0, 0],
        farRight: [0, 0, 1, 1, 1, 1, 1],
        midRight: [0, 0, 1, 1, 1, 1, 1],
      },
    },
  },
} as Record<
  DeviceModelType,
  Record<MoodType, Record<PeakSection, Record<PeakImageLayerKey, number[]>>>
>;

export const PEAK_IMAGE_OPACITY_OFFSETS_DICTIONARY = {
  [DeviceModelType.OG]: {
    [MoodType.DISCO]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
    [MoodType.FADE]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
    [MoodType.NO_ANIMATION]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
    [MoodType.SPIN]: {
      glass: {
        left: 0,
        right: 0,
      },
      base: {
        left: 0,
        right: 0.5,
      },
    },
    [MoodType.VERTICAL_SLIDESHOW]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
    // Advanced Mood Light offsets
    [MoodType.LAVA_LAMP]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
    [MoodType.CONFETTI]: {
      glass: {
        left: 1,
        right: 1,
      },
      base: {
        left: 1,
        right: 1,
      },
    },
  },
  [DeviceModelType.LIGHTNING]: {
    [MoodType.DISCO]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
    [MoodType.FADE]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
    [MoodType.NO_ANIMATION]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
    [MoodType.SPIN]: {
      glass: {
        farLeft: 0,
        midLeft: 0,
        farRight: 0,
        midRight: 0,
      },
      base: {
        farLeft: 0,
        midLeft: 0,
        farRight: 0.5,
        midRight: 0.5,
        // Peach Black Specific
        left: 0,
        right: 0.5,
      },
      ring: {
        farLeft: 0,
        midLeft: 0,
        farRight: 0.5,
        midRight: 0.5,
      },
    },
    [MoodType.VERTICAL_SLIDESHOW]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
    // Advanced Mood Light offsets
    [MoodType.LAVA_LAMP]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
    [MoodType.CONFETTI]: {
      glass: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
      base: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
        // Peach Black Specific
        left: 1,
        right: 1,
      },
      ring: {
        farLeft: 1,
        midLeft: 1,
        farRight: 1,
        midRight: 1,
      },
    },
  },
} as Record<
  DeviceModelType,
  Record<MoodType, Record<PeakSection, Record<PeakImageLayerKey, number>>>
>;
