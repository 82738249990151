import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useSelector} from 'react-redux';

import {Alert} from '../../components/Alert';
import {Constants} from '../../constants';
import {Alerts, Strings} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {
  toEpilepsyWarning,
  toHeatProfileEdit,
  toHome,
  toMoodLightLibrary,
} from '../../navigators/util';
import {appFlagsSelector} from '../redux/slices/appFlagsSlice';
import {currentDeviceSWRevisionSelector} from '../redux/slices/bleSlice';
import {userSelector} from '../redux/slices/userSlice';
import {meetsMinimumFirmware} from '../utils';

type Navigation = RootStackScreenProps<
  typeof Navigators.MainNavigator | typeof Navigators.HeatProfileEdit
>['navigation'];

export const useMoodLight = () => {
  const navigation = useNavigation<Navigation>();
  const loggedIn = !!useSelector(userSelector);
  const {hasSeenEpilepsyWarning} = useSelector(appFlagsSelector);
  const deviceSWRevision = useSelector(currentDeviceSWRevisionSelector);

  const canUseMoodLighting = meetsMinimumFirmware(
    deviceSWRevision,
    Constants.MINIMUM_FIRMWARE_VERSION.MOOD_LIGHTING,
  );

  const accessMoodLight = React.useCallback(
    ({profileId, redirect}: {profileId?: string} & WithOptionalRedirect) => {
      if (!canUseMoodLighting || !loggedIn) {
        return Alert.alert(
          Alerts.ACCESS_DENIED,
          canUseMoodLighting
            ? Alerts.FEATURE_ACCESS_MESSAGE
            : Alerts.MOOD_LIGHT_UPDATE_MESSAGE,
          [
            {
              text: canUseMoodLighting ? Strings.LOG_IN : Strings.UPDATE,
              onPress: () => {
                if (canUseMoodLighting) {
                  return navigation.navigate(Screens.Login, {
                    redirect: profileId
                      ? toHeatProfileEdit({profileId}).value
                      : hasSeenEpilepsyWarning
                        ? redirect ?? toMoodLightLibrary.value
                        : toEpilepsyWarning(
                            redirect ?? toMoodLightLibrary.value,
                          ).value,
                  });
                }

                navigation.navigate(...toHome);

                navigation.navigate(Navigators.MainNavigator, {
                  screen: Navigators.HomeDrawerNavigator,
                  params: {
                    screen: Navigators.HomeEmulatedDrawer,
                    params: {
                      screen: Screens.FirmwareInfo,
                    },
                  },
                });
              },
              style: 'default',
            },
            {text: Strings.CANCEL, style: 'cancel'},
          ],
          {cancelable: false},
        );
      }

      if (hasSeenEpilepsyWarning) {
        return navigation.navigate(...(redirect ?? toMoodLightLibrary.value));
      }

      if (profileId) {
        return navigation.navigate(Navigators.HeatProfileEdit, {
          screen: Screens.EpilepsyWarning,
          params: {
            redirect: toHeatProfileEdit({profileId}).value,
          },
        });
      }

      return navigation.navigate(
        ...toEpilepsyWarning(redirect ?? toMoodLightLibrary.value).value,
      );
    },
    [canUseMoodLighting, hasSeenEpilepsyWarning, loggedIn, navigation],
  );

  return {canUseMoodLighting, hasSeenEpilepsyWarning, accessMoodLight};
};
