import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const PlayIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M9.41826 4.8722L17.418 11.0655C18.2089 11.6778 18.1903 12.878 17.3808 13.4656L9.38109 19.2718C8.38948 19.9915 7 19.2831 7 18.0578V6.05829C7 4.81162 8.43249 4.10903 9.41826 4.8722Z" />
  </Icon>
);
