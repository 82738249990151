import React from 'react';
import {
  Image,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {useDebouncedCallback} from 'use-debounce';

import {Constants} from '../../../../../constants';
import {resolveAssetUrl} from '../../../../../lib/api';
import {
  TypeStyledAsset,
  isTypeStyledAsset,
} from '../../../../../lib/api/content-access/types';
import {colors} from '../../../../../styles';
import {ErrorContainer} from '../../ErrorContainer';

const SCREEN_WIDTH = Constants.DIMENSIONS.WIDTH;

interface Props {
  assets: TypeStyledAsset[];
  onIndexChange?: (newIndex: number) => void;
  index?: number;
  withDots?: boolean;
  animated?: boolean;
  aspectRatio?: number;
}

export const AssetSlider = React.forwardRef(function AssetSlider(
  {assets, index = 0, withDots, aspectRatio = 0.667, onIndexChange}: Props,
  ref,
) {
  const [currentIndex, setCurrentIndex] = React.useState(index);

  const flatListRef = React.useRef<FlatList>(null);

  const handleScrollEnd = React.useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const offsetX = event.nativeEvent?.contentOffset?.x;

      if (typeof offsetX !== 'number') return;

      const newIndex = Math.round(offsetX / SCREEN_WIDTH);

      if (newIndex === currentIndex) return;

      setCurrentIndex(newIndex);

      if (!onIndexChange) return;

      onIndexChange(newIndex);
    },
    [currentIndex, onIndexChange],
  );

  React.useImperativeHandle(ref, () => ({
    scrollToIndex: ({
      index,
      animated = true,
    }: {
      index: number;
      animated?: boolean;
    }) => {
      if (!flatListRef.current) return;

      flatListRef.current.scrollToIndex({
        index,
        animated,
      });

      const offset = index * SCREEN_WIDTH;
      const event = {
        nativeEvent: {
          contentOffset: {x: offset},
        },
      } as NativeSyntheticEvent<NativeScrollEvent>;

      handleScrollEnd(event);
    },
    ...flatListRef.current,
  }));

  React.useEffect(
    () => flatListRef.current?.scrollToIndex({index, animated: false}),
    [],
  );

  // On the web the animation must finish before the current index is updated
  const debouncedHandleScrollEnd = useDebouncedCallback(handleScrollEnd, 70);

  const renderItem = ({item}: {item: TypeStyledAsset}) => {
    const uri = resolveAssetUrl(item.fields.asset);
    const imageStyle = item.fields.style;

    return (
      <View style={styles.imageContainer}>
        {isTypeStyledAsset(item) ? (
          <Image
            source={{uri}}
            style={[styles.image, {aspectRatio}, imageStyle]}
          />
        ) : (
          <ErrorContainer />
        )}
      </View>
    );
  };

  const getItemLayout = (_: unknown, index: number) => ({
    length: SCREEN_WIDTH,
    offset: SCREEN_WIDTH * index,
    index,
  });

  return (
    <View style={styles.sliderContainer}>
      <FlatList
        ref={flatListRef} // Attach the FlatList ref to the internal ref
        {...{getItemLayout}}
        data={assets}
        renderItem={renderItem}
        keyExtractor={item => item.sys.id}
        horizontal
        showsHorizontalScrollIndicator={false}
        pagingEnabled
        {...(Constants.IS_NATIVE_ANDROID && {
          onMomentumScrollEnd: handleScrollEnd,
        })}
        {...(!Constants.IS_NATIVE_ANDROID && {
          onScroll: debouncedHandleScrollEnd,
        })}
      />

      {withDots && (
        <View style={styles.dotsContainer}>
          {assets.map((_, idx) => (
            <View
              key={idx}
              style={[
                styles.dot,
                currentIndex === idx ? styles.activeDot : styles.inactiveDot,
              ]}
            />
          ))}
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  sliderContainer: {
    flex: 1,
  },
  image: {
    resizeMode: 'contain',
    width: SCREEN_WIDTH,
    maxHeight: '100%',
  },
  imageContainer: {
    flex: 1,
    width: SCREEN_WIDTH,
    alignItems: 'center',
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 11,
    height: 11,
    borderRadius: 10,
    marginHorizontal: 4,
    borderWidth: 1,
    borderColor: colors.white,
  },
  activeDot: {
    backgroundColor: colors.white,
  },
  inactiveDot: {
    backgroundColor: colors.greySettings10,
  },
});
