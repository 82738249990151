import React from 'react';
import {useSelector} from 'react-redux';

import {Constants} from '../../constants';
import {Strings} from '../../constants/Strings';
import {Connection} from '../../contexts/connection';
import {colors} from '../../styles';
import {appSettingsSelector} from '../redux/slices/appSettingsSlice';
import {currentDeviceSelector} from '../redux/slices/bleSlice';
import {BatteryChargeState, Device} from '../types';
import {meetsMinimumFirmware} from '../utils';

const deviceState = {
  SEARCHING: {
    color: colors.progressBlue,
    text: `${Strings.SEARCHING}...`,
  },
  CONNECTING: {
    color: colors.progressBlue,
    text: Strings.CONNECTING,
  },
  DISCONNECTED: {
    color: colors.red,
    text: Strings.DISCONNECTED,
  },
  DISCONNECTING: {
    color: colors.red,
    text: `${Strings.DISCONNECTING}...`,
  },
  INSUFFICIENT_BATTERY: {
    color: colors.red,
    text: Strings.INSUFFICIENT_BATTERY,
  },
  CHAMBER_DISCONNECTED: {
    color: colors.red,
    text: Strings.CHAMBER_DISCONNECTED,
  },
  CHARGE_COMPLETE: {
    color: colors.readyGreen,
    text: Strings.CHARGE_COMPLETE,
  },
  FULLY_CHARGED: {
    color: colors.readyGreen,
    text: Strings.FULLY_CHARGED,
  },
  CHARGING_TO_EIGHTY: {
    color: colors.readyGreen,
    text: Strings.CHARGING_TO_EIGHTY,
  },
  CHARGING: {
    color: colors.readyGreen,
    text: Strings.CHARGING,
  },
  READY_TO_RIP: {
    color: colors.readyGreen,
    text: Strings.READY_TO_RIP,
  },
} as const;

export const useDeviceStatus = () => {
  const {peak, connecting, progress, disconnecting} = Connection.useContainer();
  const currentDevice = useSelector(currentDeviceSelector);
  const connected = !!peak;
  const {batteryPreservationOn} = useSelector(appSettingsSelector);

  const isCharging = (state?: BatteryChargeState) =>
    state === BatteryChargeState.BULK ||
    state === BatteryChargeState.TOPUP ||
    state === BatteryChargeState.FULL;

  const getDeviceStatus = React.useCallback(
    (device?: Device) => {
      if (!device) return deviceState.DISCONNECTED;

      if (
        typeof connecting === 'object' &&
        (device?.id === connecting?.id || device?.name === connecting?.name)
      ) {
        if (['starting', 'scanning'].includes(progress.data))
          return deviceState.SEARCHING;

        return deviceState.CONNECTING;
      }

      if (device.id === disconnecting) return deviceState.DISCONNECTING;

      // if not connected, and not advertising (ie pre-Peach)
      if (!connected && !device.broadcastingCounter)
        return deviceState.DISCONNECTED;

      if (!connected || device.id !== currentDevice?.id)
        return deviceState.DISCONNECTED;

      const chargeState = device.batteryChargeState;

      const battery = device.battery ?? 0;

      if (isCharging(chargeState)) {
        if (
          batteryPreservationOn &&
          meetsMinimumFirmware(
            device.softwareRevisionString,
            Constants.MINIMUM_FIRMWARE_VERSION.BATTERY_PRESERVATION,
          )
        ) {
          // If battery preservation is on
          if (battery >= 80) return deviceState.CHARGE_COMPLETE;

          return deviceState.CHARGING_TO_EIGHTY;
        }

        if (battery === 100) return deviceState.FULLY_CHARGED;

        return deviceState.CHARGING;
      }

      if (device.battery === 0) return deviceState.INSUFFICIENT_BATTERY;

      if (!device.chamberType) return deviceState.CHAMBER_DISCONNECTED;

      return deviceState.READY_TO_RIP;
    },
    [peak, connecting, progress, disconnecting, batteryPreservationOn],
  );

  return {getDeviceStatus};
};
