import React from 'react';
import {StyleSheet, View} from 'react-native';
import {
  SharedValue,
  runOnJS,
  useAnimatedReaction,
} from 'react-native-reanimated';

import {colors} from '../styles';
import {Theme, defaultTheme} from '../themes';
import {AppText} from './AppText';

export interface Props {
  status?: 'SEARCHING' | string;
  percentage?: SharedValue<number>;
  error?: string;
  theme?: Theme;
}

const getText = (value: number, status: Props['status']) => {
  if (!status || value === 0) return status ?? '';

  return `${status}... ${value}%`;
};

const getValue = (value: number) => {
  'worklet';
  return Math.round(value * 100);
};

export const StatusDisplay = ({
  error,
  percentage,
  status,
  theme = defaultTheme,
}: Props) => {
  const [value, setValue] = React.useState(() =>
    getValue(percentage?.value ?? 0),
  );

  useAnimatedReaction(
    () => getValue(percentage?.value ?? 0),
    value => runOnJS(setValue)(value),
  );

  return (
    <View style={styles.container}>
      {error ? (
        <AppText style={styles.error}>{error}</AppText>
      ) : (
        <AppText style={{color: theme.statusDisplayTextColor}}>
          {getText(value, status)}
        </AppText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {color: colors.red},
});
