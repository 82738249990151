import React from 'react';
import {StyleSheet, Text, TextInput, TextInputProps, View} from 'react-native';

import {Constants} from '../../constants';
import {ErrorMessages} from '../../constants/Strings';
import {PencilIcon} from '../../icons';
import {checkStringBytes} from '../../lib/utils/checkStringBytes';
import {colors, profileTitleStyle} from '../../styles';

interface Props
  extends Pick<TextInputProps, 'value' | 'onChangeText' | 'style'> {}

export const isValid = (value?: string) => {
  if (!value) return false;

  return value.trim().length !== 0 && checkStringBytes(value, 'profile');
};

export const HeatProfileNameTextInput = ({
  value,
  onChangeText,
  style,
}: Props) => {
  const nameRef = React.useRef<TextInput>(null);

  return (
    <>
      <View style={styles.container}>
        <TextInput
          allowFontScaling={false}
          autoCapitalize="characters"
          ref={nameRef}
          {...{value, onChangeText}}
          keyboardType={
            Constants.IS_NATIVE_ANDROID ? 'visible-password' : 'default'
          }
          maxLength={Constants.PROFILE_NAME_MAX_LENGTH}
          style={[profileTitleStyle, styles.input, style]}
        />

        <PencilIcon
          solid
          style={styles.icon}
          color={colors.white}
          size={12}
          onPress={() => nameRef.current?.focus()}
        />
      </View>

      {!isValid(value) && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>
            {value?.trim().length === 0
              ? ErrorMessages.PROFILE_NAME_EMPTY
              : ErrorMessages.PROFILE_NAME_LONG}
          </Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  input: {
    fontSize: 24,
    fontWeight: '500',
    letterSpacing: 0.18,
    color: colors.white,
    maxWidth: '80%',
    textTransform: 'uppercase',
  },
  icon: {width: 10},
  errorContainer: {
    height: 30,
  },
  errorText: {
    color: colors.errorColor,
    fontSize: 14,
    fontFamily: 'BigShouldersDisplay-Medium',
    fontWeight: '400',
  },
});
