import React from 'react';
import {View, ViewProps} from 'react-native';

import {Constants} from '../constants';
import {MoodLight, Profile, isTHeatProfileMoodLight} from '../lib/types';
import {colors, fillStyle} from '../styles';
import {HeatProfileCardTheme} from '../themes';
import {Image} from './ImageWithFilter';
import {LinearGradientBackground} from './LinearGradientBackground';
import {MultiGradientBackground} from './MultiGradientBackground';

export type HeatProfileCardBackgroundProps = {
  isOpal: boolean;
  editMode?: boolean;
  profile: Profile;
  moodLight?: MoodLight;
  heatProfileCardTheme: HeatProfileCardTheme;
} & ViewProps;

export const HeatProfileCardBackground = ({
  profile,
  moodLight,
  isOpal,
  editMode,
  heatProfileCardTheme: {
    backgroundImage,
    editBackgroundImage,
    gradientShadeFunction,
    overLayGradientColors,
  },
  style,
  ...rest
}: HeatProfileCardBackgroundProps) => {
  let profileColor = isTHeatProfileMoodLight(profile)
    ? colors.defaultColor
    : profile.color;
  profileColor =
    profileColor === Constants.FACTORY_HEX_COLORS.high
      ? colors.red
      : profileColor;
  const hasBackgroundImage = editMode ? editBackgroundImage : backgroundImage;
  return (
    <View style={[fillStyle, style]} {...rest}>
      {moodLight && moodLight.colors && moodLight.colors.length > 1 ? (
        <MultiGradientBackground
          gradientShadeFunction={gradientShadeFunction}
          colors={moodLight.colors}
          angles={
            moodLight.colors.length === 2
              ? [...Constants.HORIZONTAL_GRADIENT_ANGLES]
              : undefined
          }
          gradientLayerOpacity={moodLight.colors.length === 2 ? 1 : undefined}
          style={style}
          {...(isOpal && {BaseBackground: OpalBaseBackground})}
        />
      ) : (
        <LinearGradientBackground
          colors={gradientShadeFunction(
            moodLight && moodLight.colors ? moodLight.colors[0] : profileColor,
          )}
          useAngle={true}
          angle={270}
          angleCenter={{x: 0.5, y: 0.5}}
          style={{backgroundColor: colors.white}}
        />
      )}
      {overLayGradientColors &&
        moodLight &&
        moodLight.colors &&
        moodLight.colors.length > 1 && (
          <LinearGradientBackground
            colors={overLayGradientColors}
            locations={[0, 0.5, 1]}
            useAngle={true}
            angle={90}
            angleCenter={{x: 0.5, y: 0.5}}
            style={{position: 'absolute'}}
          />
        )}
      {hasBackgroundImage && (
        <Image
          source={hasBackgroundImage}
          resizeMode={'cover'}
          style={{
            ...fillStyle,
            position: 'absolute',
            opacity:
              moodLight && moodLight.colors && moodLight.colors.length > 1
                ? 0.5
                : 1,
          }}
        />
      )}
    </View>
  );
};

const OpalBaseBackground: React.FC<ViewProps> = ({
  children,
  style,
  ...rest
}: React.PropsWithChildren<ViewProps>) => (
  <LinearGradientBackground
    start={{x: 0, y: 0.5}}
    end={{x: 1, y: 0.5}}
    colors={[colors.white, '#555555']}
    useAngle={true}
    angle={90}
    angleCenter={{x: 0.5, y: 0.5}}
    style={style}
    {...rest}>
    {children}
  </LinearGradientBackground>
);
