import {Platform} from 'react-native';

import {Environment} from '../../constants';
import {appVersion} from '../../util/AppVersion';
import {CreateHttpDefaults, HttpClientConfig, HttpError} from './http-client';
import {HttpClient} from './http-client';

export interface ApiClientConfig extends HttpClientConfig {}

export type ApiCallback = () => Promise<any>;

export interface ApiCallbackOptions {
  onForceUpdate?: ApiCallback;
}

export const getPlatformHeader = () =>
  Platform.OS === 'android' ? 'Android' : Platform.OS === 'ios' ? 'iOS' : 'Web';

export class ApiClient<
  C extends ApiClientConfig = ApiClientConfig,
  O extends ApiCallbackOptions = ApiCallbackOptions,
> extends HttpClient<C> {
  protected options?: Partial<O>;

  constructor(options: CreateHttpDefaults<C>) {
    super(options);

    this.client.interceptors.response.use(undefined, this.checkForceUpdate);
  }

  configure(options: Partial<O>) {
    this.options = {...this.options, ...options};
  }

  private checkForceUpdate = (error: HttpError<C>) => {
    if (!this.options?.onForceUpdate) throw error;

    if (!error.isAxiosError) throw error;

    const data = error.response?.data as any;

    const errors = data?.errors ?? [];

    if (errors[0]?.code !== 'client_version_not_supported') throw error;

    this.options.onForceUpdate();

    return new Promise<void>(() => void 0);
  };
}

export const apiClient = new ApiClient({
  baseURL: Environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'x-app-version': appVersion,
    'x-app-platform': getPlatformHeader(),
    'x-app-type': 'puffco-app',
  },
});
