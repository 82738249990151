import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const SettingsIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M21 19L14 19" />
    <Path d="M10 19L3 19" />
    <Path d="M21 12L12 12" />
    <Path d="M8 12L3 12" />
    <Path d="M21 5L16 5" />
    <Path d="M12 5L3 5" />
    <Path d="M14 22L14 16" />
    <Path d="M8 15L8 9" />
    <Path d="M16 8L16 2" />
  </Icon>
);
