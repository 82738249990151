import {
  DrawerContentComponentProps,
  useDrawerStatus,
} from '@react-navigation/drawer';
import {DrawerActions} from '@react-navigation/native';
import React from 'react';
import {Helmet} from 'react-helmet';
import {StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';

import {Constants} from '../../constants';
import {Alerts, Strings, interpolate} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {Connection} from '../../contexts/connection';
import {Context, Feature} from '../../contexts/context';
import {Interstitial} from '../../contexts/interstitial';
import {
  AddUserIcon,
  BluetoothIcon,
  GearIcon,
  HomeIcon,
  MailIcon,
  MenuTripleIcon,
  PuffcoIcon,
  QuestionIcon,
  ShopIcon,
  StatsIcon,
  UserIcon,
  UsersIcon,
} from '../../icons';
import {useReferralConfig} from '../../lib/hooks/useReferralConfig';
import {useReferrals} from '../../lib/hooks/useReferrals';
import {useTheme} from '../../lib/hooks/useTheme';
import {appFlagsSelector} from '../../lib/redux/slices/appFlagsSlice';
import {isOtaAvailableSelector} from '../../lib/redux/slices/bleSlice';
import {Store} from '../../lib/redux/types';
import {navigateToWebsite} from '../../lib/utils/navigateToWebsite';
import styled from '../../lib/utils/styled';
import {toAdvancedMetrics, toHome} from '../../navigators/util';
import {colors} from '../../styles';
import {flourishTheme} from '../../themes';
import {Badge} from '../Badge';
import {CustomAlert} from '../CustomAlert';
import {SafeAreaView} from '../SafeAreaView';
import {DrawerItem} from './DrawerItem';

export enum DrawerStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export const DrawerContent: React.FC<DrawerContentComponentProps> = ({
  navigation,
}) => {
  const {getFeatureIfEnabled} = Context.useContainer();
  const {remoteInterstitial: interstitial, display} =
    Interstitial.useContainer();
  const user = useSelector((store: Store) => store.user);
  const drawerStatus = useDrawerStatus();

  const {hasSeenRehash} = useSelector(appFlagsSelector);

  const isOtaAvailable = useSelector(isOtaAvailableSelector);
  const {peak} = Connection.useContainer();
  const connected = !!peak;

  const hasFWUpdate = connected && isOtaAvailable;

  const theme = useTheme();
  const isFlourish = theme === flourishTheme;

  const {unused} = useReferrals();

  const {data: config} = useReferralConfig();

  const amount = config?.amount ?? '';

  const rehash = getFeatureIfEnabled(Feature.Rehash);

  return (
    <SafeAreaView style={styles.layout}>
      {drawerStatus === DrawerStatus.OPEN && Constants.IS_IOS_WEB && (
        <Helmet>
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="default"
          />
        </Helmet>
      )}

      <MenuButton
        size={32}
        onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
        {...(isFlourish && {color: flourishTheme.navMenuIconColor})}
      />

      <DrawerItem
        onPress={() =>
          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          })
        }
        icon={HomeIcon}
        title="Home"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.DevicesList)}
        icon={BluetoothIcon}
        title="Devices"
      />

      <DrawerItem
        onPress={() => navigateToWebsite(Constants.SHOP_URL_PATH)}
        icon={ShopIcon}
        title="Shop"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Screens.Preferences)}
        icon={GearIcon}
        title="Settings"
        badge={
          !!hasFWUpdate && (
            <Badge>
              <Text style={styles.badgeText}>{1}</Text>
            </Badge>
          )
        }
      />

      <DrawerItem
        onPress={() => {
          if (user) return navigation.navigate(Screens.AdvancedMetrics);

          CustomAlert.alert(
            Alerts.ACCESS_DENIED,
            Alerts.FEATURE_ACCESS_MESSAGE_2,
            [
              {
                text: Strings.SIGN_IN,
                onPress: () => {
                  navigation.navigate(Screens.Login, {
                    redirect: toAdvancedMetrics.encode(),
                  });
                },
              },
              {
                text: Strings.CREATE_ACCOUNT,
                onPress: () => {
                  navigation.navigate(Screens.Register, {
                    redirect: toAdvancedMetrics.encode(),
                  });
                },
              },
              {
                text: Strings.CANCEL,
                style: 'cancel',
              },
            ],
          );
        }}
        icon={StatsIcon}
        title={Strings.ADVANCED_METRICS}
      />

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.Community)}
        icon={UsersIcon}
        title={Strings.COMMUNITY.TITLE}
      />

      <DrawerItem
        onPress={() => {
          if (user) return navigation.navigate(Navigators.Account);

          navigation.navigate(Screens.Login, {redirect: toHome});

          navigation.dispatch(DrawerActions.closeDrawer());
        }}
        icon={UserIcon}
        title="Account"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.DeviceEducation)}
        icon={QuestionIcon}
        title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.TITLE}
      />

      {!!interstitial && (
        <DrawerItem
          onPress={() => {
            navigation.dispatch(DrawerActions.closeDrawer());
            display(interstitial);
          }}
          icon={MailIcon}
          title="Notifications"
        />
      )}

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.ReferralProgram)}
        icon={AddUserIcon}
        title={Strings.REFERRAL_PROGRAM.REFER_A_FRIEND}
        badge={
          <Badge>
            <Text
              numberOfLines={1}
              style={[
                styles.badgeText,
                unused.length === 0 && styles.badgeSpacing,
              ]}>
              {unused.length > 0
                ? unused.length
                : interpolate(Strings.REFERRAL_PROGRAM.EARN_30, {amount})}
            </Text>
          </Badge>
        }
      />

      {rehash?.label && (
        <DrawerItem
          onPress={() => navigation.navigate(Navigators.RehashStory)}
          icon={PuffcoIcon}
          title={`${Strings.REHASH.PUFFCO_REHASH} ${rehash.label}`}
          badge={
            !hasSeenRehash?.[rehash.label] && (
              <Badge>
                <Text style={styles.badgeText}>1</Text>
              </Badge>
            )
          }
        />
      )}
    </SafeAreaView>
  );
};

const MenuButton = styled(MenuTripleIcon)({
  marginTop: 8,
  marginBottom: 18,
  marginLeft: 14,
});

const styles = StyleSheet.create({
  layout: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.drawerBackground,
  },
  badgeText: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  badgeSpacing: {paddingHorizontal: 10, paddingVertical: 6},
  flexContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
