import React from 'react';
import {StyleProp, StyleSheet, Text, View, ViewStyle} from 'react-native';

import {StyledButton} from '../../../components/StyledButton';
import {Strings} from '../../../constants/Strings';
import {colors} from '../../../styles';

export interface Props {
  title: string;
  onInstructionManualPress?: () => void;
  onContactSupportPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const Footer = ({
  title,
  onInstructionManualPress,
  onContactSupportPress,
  style,
}: Props) => (
  <View style={[styles.container, style]}>
    <Text style={styles.title}>{title}</Text>

    {!!onInstructionManualPress && (
      <StyledButton
        title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.INSTRUCTIONS_MANUAL}
        textStyle={{
          color: colors.white,
          textTransform: 'uppercase',
        }}
        style={styles.button}
        onPress={onInstructionManualPress}
      />
    )}

    {!!onContactSupportPress && (
      <StyledButton
        title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.CONTACT_SUPPORT}
        textStyle={{
          color: colors.white,
          textTransform: 'uppercase',
        }}
        style={styles.button}
        onPress={onContactSupportPress}
      />
    )}
  </View>
);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginHorizontal: 40,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    textTransform: 'uppercase',
    fontSize: 22,
    fontWeight: '700',
    color: colors.white,
    textAlign: 'center',
    marginBottom: 30,
  },
  button: {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: colors.black,
    color: colors.white,
    marginHorizontal: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colors.white50,
    marginBottom: 12,
  },
});
