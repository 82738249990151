import React from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';

import {BlurredBackgroundSafeArea} from '../../components/BlurredBackgroundSafeArea';
import {CloseButton} from '../../components/CloseButton';
import {HeatProfileCard} from '../../components/HeatProfileCard';
import {SectionTitle} from '../../components/SectionTitle';
import {
  SwappableSectionList,
  SwappableSectionListRenderItem,
} from '../../components/SwappableSectionList';
import {Messages} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useDisconnectGuard} from '../../lib/hooks/useDisconnectGuard';
import {useTheme} from '../../lib/hooks/useTheme';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDeviceSettings,
} from '../../lib/redux/slices/bleSlice';
import {getPeakMoodLightSelector} from '../../lib/redux/slices/moodLightSlice';
import {activeProfilesSelector} from '../../lib/redux/slices/profilesSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import {
  Profile,
  isColoredBackground,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import {recursiveDeepCopy} from '../../lib/utils/recursiveDeepCopy';
import styled from '../../lib/utils/styled';
import type {ControlCenterNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors, fillStyle} from '../../styles';
import {desertTheme, flourishTheme} from '../../themes';
import {InfoText} from './components/InfoText';

interface Props
  extends ControlCenterNavigatorScreenProps<typeof Screens.SessionReady> {}

export const SessionReadyScreen = ({navigation}: Props) => {
  useDisconnectGuard();
  const theme = useTheme();
  const {
    controlCenterTheme: {background},
    navMenuIconColor,
    navMenuTitleStyle,
  } = theme;
  const peak = useSelector(connectedPeakSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const dispatch = useAppDispatch();
  const activeProfiles = useSelector(activeProfilesSelector);
  const getPeakMoodLight = useSelector(getPeakMoodLightSelector);
  useAdaptiveSafeArea();
  const isDesert = theme === desertTheme;
  const isFlourish = theme === flourishTheme;

  const saveSettings = async (selectedProfile: number) => {
    if (!peak) return;

    await peak.writeDeviceReadyProfile(selectedProfile);

    dispatch(
      updateDeviceSettings({
        id: peak?.peripheralId,
        ...currentDevice,
        settings: {
          ...currentDevice?.settings,
          readyProfile: selectedProfile,
          //NAN or out of range disables ready mode
          readyMode: selectedProfile < 4,
        },
      }),
    );
  };

  const renderItem: SwappableSectionListRenderItem<Profile> = ({info}) => {
    const {item: profile} = info;
    const moodLightId = isTHeatProfileMoodLight(profile) && profile.moodLightId;
    const moodLight = getPeakMoodLight(moodLightId || '');

    return (
      <HeatProfileCard
        moodLight={moodLight}
        theme={theme}
        profile={profile}
        active={true}
        onPress={() => {
          saveSettings(profile.order);
          navigation.replace(Screens.ControlCenter);
        }}
      />
    );
  };

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'READY MODE',
      headerTitleStyle: {
        ...navMenuTitleStyle,
        ...(isDesert && {color: colors.white}),
      },
      headerLeft: () => null,
      headerRight: () => (
        <CloseButton
          onPress={() => navigation.replace(Screens.ControlCenter)}
          color={isDesert ? colors.white : navMenuIconColor}
        />
      ),
    });
  }, [isDesert, navMenuTitleStyle, navMenuIconColor, navigation]);

  useBackPress(
    React.useCallback(() => {
      navigation.replace(Screens.ControlCenter);
      return true;
    }, [navigation]),
  );

  return (
    <BlurredBackgroundSafeArea
      style={{
        ...fillStyle,
        ...(isColoredBackground(background) && {
          backgroundColor: background.color,
        }),
      }}>
      <Container>
        <InfoLabel
          {...(isFlourish && {
            style: {color: addOpacityToColorHex(colors.white, 0.8)},
          })}>
          {Messages.DEVICE_SETTINGS_READY_MODE}
        </InfoLabel>
        <ListContainer>
          <ListHeader>
            <SectionTitle
              title={'ACTIVE'}
              titleStyle={isDesert ? {color: colors.white} : undefined}
              fillerStyle={
                isDesert ? {backgroundColor: colors.gray} : undefined
              }
            />
          </ListHeader>
          <SwappableSectionList
            editingList={false}
            sections={[
              {
                data: recursiveDeepCopy(activeProfiles).sort(
                  (a: Profile, b: Profile) => a.order - b.order,
                ),
              },
            ]}
            keyExtractor={item => item.id + item.order}
            renderItem={renderItem}
            stickySectionHeadersEnabled={false}
          />
        </ListContainer>
      </Container>
    </BlurredBackgroundSafeArea>
  );
};

const Container = styled(View)({
  width: '100%',
  height: '100%',
});

const ListContainer = styled(View)({flex: 1});

const ListHeader = styled(View)({
  paddingHorizontal: 10,
});

const InfoLabel = styled(InfoText)({
  width: '100%',
  marginTop: 60,
  marginBottom: 20,
  paddingHorizontal: 20,
});
