import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const DeleteIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M3 6H5H21" />
    <Path d="M19 6V19.125C19 19.6223 18.7893 20.0992 18.4142 20.4508C18.0391 20.8025 17.5304 21 17 21H7C6.46957 21 5.96086 20.8025 5.58579 20.4508C5.21071 20.0992 5 19.6223 5 19.125V6" />
    <Path d="M8 6V4C8 3.44772 8.44772 3 9 3H15C15.5523 3 16 3.44772 16 4V6" />
    <Path d="M10 11V16" />
    <Path d="M14 11V16" />
  </Icon>
);
