import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewProps} from 'react-native';

import {Constants} from '../constants';
import styled from '../lib/utils/styled';
import {centerStyle} from '../styles';
import {defaultTheme} from '../themes';

const DEFAULT_TEMP_FONT_SIZE = 59;

export type ProfileTemperatureProps = {
  temperature: number;
  temperatureUnit: string;
  textStyle?: TextStyle;
} & ViewProps;

export const ProfileTemperature = (props: ProfileTemperatureProps) => {
  const {temperature, temperatureUnit, style, textStyle} = props;
  const restStyle = StyleSheet.flatten(style) || {};
  const {
    color,
    fontSize = DEFAULT_TEMP_FONT_SIZE,
    ...temperatureStyle
  } = StyleSheet.flatten(textStyle) || {};
  const scaleRatio = fontSize / DEFAULT_TEMP_FONT_SIZE;

  return (
    <TemperatureContainer style={restStyle}>
      <TemperatureText
        style={{
          ...temperatureStyle,
          fontSize,
          lineHeight: fontSize,
          height: fontSize,
          paddingTop: fontSize * (Constants.IS_NATIVE_ANDROID ? 0.12 : 0.01),
          ...(color && {color}),
        }}>
        {Math.round(temperature)}
      </TemperatureText>

      <UnitsContainer
        style={{
          height: fontSize,
          paddingTop: fontSize * 0.08,
          paddingBottom: fontSize * 0.03,
          marginLeft: scaleRatio * 7.26,
        }}>
        <DegreeSymbolContainer
          style={{
            width: scaleRatio * 10.89,
            height: scaleRatio * 10.89,
            paddingTop: scaleRatio * 15,
          }}>
          <TempUnits
            style={{
              ...temperatureStyle,
              ...(color && {color}),
              fontFamily: 'Roboto-Bold',
              fontSize: scaleRatio * 38,
              fontWeight: '400',
              lineHeight: undefined,
            }}>
            °
          </TempUnits>
        </DegreeSymbolContainer>

        <TempUnits
          style={{
            ...temperatureStyle,
            ...(color && {color}),
            fontFamily: 'Roboto-Bold',
            fontWeight: '400',
            fontSize: scaleRatio * 15.43,
            lineHeight: undefined,
          }}>
          {temperatureUnit}
        </TempUnits>
      </UnitsContainer>
    </TemperatureContainer>
  );
};

const defaultTextColor =
  defaultTheme.dabbingScreenTheme.dabbingScreenActiveText;

const UnitsContainer = styled(View)({
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 70,
});

const DegreeSymbolContainer = styled(View)({
  ...centerStyle,
});

const TemperatureText = styled(Text)({
  fontSize: DEFAULT_TEMP_FONT_SIZE,
  lineHeight: DEFAULT_TEMP_FONT_SIZE + 2,
  height: DEFAULT_TEMP_FONT_SIZE - 5,
  fontFamily: 'BigShouldersDisplay-Bold',
  letterSpacing: 0.6,
  color: defaultTextColor,
  includeFontPadding: false,
  fontWeight: '400',
});

const TempUnits = styled(Text)({
  color: defaultTextColor,
  includeFontPadding: false,
  textAlign: 'center',
  fontWeight: '400',
});

const TemperatureContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
});
