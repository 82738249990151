// TODO: remove file when we have real data
import {Led2Config, Led3Config, MoodType} from '../types';
import BREATHING from './breathing.json';
import CIRCLING_SLOW from './circling_slow.json';
import CONFETTI from './confetti.json';
import DISCO from './disco.json';
import FADE from './fade.json';
import LAVA_LAMP from './lava_lamp.json';
import LED2 from './led2.json';
import LED2_NO_ANIMATION from './led2_no_animation.json';
import NO_ANIMATION from './no_animation.json';
import PARTY_MODE from './party_mode.json';
import SPIN from './spin.json';
import SPLIT_GRADIENT from './split_gradient.json';
import VERTICAL_SLIDESHOW from './vertical_slideshow.json';

export const LED3Data = {
  [MoodType.NO_ANIMATION]: NO_ANIMATION as Led3Config,
  [MoodType.DISCO]: DISCO as Led3Config,
  [MoodType.FADE]: FADE as Led3Config,
  [MoodType.SPIN]: SPIN as Led3Config,
  [MoodType.SPLIT_GRADIENT]: SPLIT_GRADIENT as Led3Config,
  [MoodType.VERTICAL_SLIDESHOW]: VERTICAL_SLIDESHOW as Led3Config,
  [MoodType.BREATHING]: BREATHING as Led3Config,
  [MoodType.CIRCLING_SLOW]: CIRCLING_SLOW as Led3Config,
  [MoodType.LAVA_LAMP]: LAVA_LAMP as Led3Config,
  [MoodType.CONFETTI]: CONFETTI as Led3Config,
};

export const LED3PartyModeData = PARTY_MODE as Led3Config;

export const LED2Data = LED2 as Led2Config;

export const LED2NoAnimationData = LED2_NO_ANIMATION as Led2Config;
