import {HttpClient} from '../network/http-client';

class ZendeskApi {
  private readonly url = 'https://puffco.zendesk.com/api';
  private readonly httpClient = new HttpClient({baseURL: this.url});

  public async uploadFile({
    deviceId,
    body,
  }: {
    deviceId?: string;
    body: ArrayBuffer;
  }): Promise<{upload: {token: string}}> {
    return this.httpClient.post<{upload: {token: string}}>(
      // TODO: Double check parameters here
      `/v2/uploads.json?filename=warranty-picture-device-id=${deviceId}`,
      body,
      {
        // TODO: application/binary is not a valid mime type. perhaps application/octet-stream
        headers: {
          'Content-Type': 'application/binary',
        },
      },
    );
  }
}

export const zendeskApi = new ZendeskApi();
