import {ProductName} from 'pikaparam';
import React from 'react';
import {useSelector} from 'react-redux';

import {THEME_RECORD} from '../../themes';
import {currentDeviceSelector} from '../redux/slices/bleSlice';
import {Store} from '../redux/types';
import {getProductName} from '../utils/getProductName';

export const getTheme = (productName: ProductName) => {
  return THEME_RECORD[productName] ?? THEME_RECORD.OG;
};

export const useTheme = () => {
  const modelNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.modelNumberString,
  );
  const serialNumberString = useSelector(
    (state: Store) => currentDeviceSelector(state)?.serialNumberString,
  );
  const product = useSelector(
    (state: Store) => currentDeviceSelector(state)?.product,
  );

  return React.useMemo(
    () =>
      getTheme(
        getProductName({modelNumberString, serialNumberString, product}),
      ),
    [modelNumberString, serialNumberString, product],
  );
};

export const isProductLimitedEdition = (productName: ProductName) => {
  return !!getTheme(productName).limitedEditionModalScreenTheme;
};
