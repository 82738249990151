import React from 'react';
import {ClipPath, Path, Rect} from 'react-native-svg';

import {colors} from '../styles';
import {Icon, IconProps} from './Icon';

export const SunIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" />
    <Path d="M12 1V3" />
    <Path d="M12 21V23" />
    <Path d="M4.21997 4.22L5.63997 5.64" />
    <Path d="M18.36 18.36L19.78 19.78" />
    <Path d="M1 12H3" />
    <Path d="M21 12H23" />
    <Path d="M4.21997 19.78L5.63997 18.36" />
    <Path d="M18.36 5.64L19.78 4.22" />

    <ClipPath id="clip0_96_680">
      <Rect width={24} height={24} fill={props.color ?? colors.white} />
    </ClipPath>
  </Icon>
);
