import React from 'react';
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {useSelector} from 'react-redux';

import {glow} from '../assets/images';
import {Constants} from '../constants';
import {Messages} from '../constants/Strings';
import {DeleteIcon} from '../icons';
import {ReorderIcon} from '../icons/ReorderIcon';
import {useCardScaleFactor} from '../lib/hooks/useCardScaleFactor';
import {useCardScaleStyle} from '../lib/hooks/useCardScaleStyle';
import {useGetVaporSetting} from '../lib/hooks/useGetVaporSetting';
import {appSettingsSelector} from '../lib/redux/slices/appSettingsSlice';
import {
  MoodLight,
  Profile,
  isPreTHeatProfile,
  isTHeatProfileMoodLight,
  isTHeatProfileNoMoodLight,
} from '../lib/types';
import {changeHexColorValues} from '../lib/utils/colors';
import {useTemperature} from '../lib/utils/convertTemperature';
import {getTimeFormat} from '../lib/utils/getTimeFormat';
import styled from '../lib/utils/styled';
import {centerStyle, colors, fillStyle} from '../styles';
import {Theme} from '../themes';
import {
  blackPeachTheme,
  desertTheme,
  flourishTheme,
  opalTheme,
  stormTheme,
  whitePeachTheme,
} from '../themes';
import {
  DEFAULT_HALO_DIAMETER,
  calculateInnerContainerDiameter,
} from './AnimatedHalo';
import {AppText} from './AppText';
import {ElevationView} from './ElevationView';
import {HeatProfileCardBackground} from './HeatProfileCardBackground';
import {HeatProfileCardRing} from './HeatProfileCardRing';
import {HeatProfileCoreImage} from './HeatProfileCoreImage';
import {Image} from './ImageWithFilter';
import {LinearGradientBackground} from './LinearGradientBackground';
import {ProfileTemperature} from './ProfileTemperature';

export type HeatProfileCardProps = TouchableWithoutFeedbackProps & {
  profile: Profile;
  moodLight?: MoodLight;
  active: boolean;
  editMode?: boolean;
  borderWidth?: number;
  borderColor?: string;
  profileDelete?: (id: string) => void;
  theme: Theme;
  isVaporEnabled?: boolean;
};

const HEAT_PROFILE_CORE_DIAMETER = 180;
const {black50, invisible, white} = colors;

export const HeatProfileCard = (props: HeatProfileCardProps) => {
  const {
    profile,
    moodLight,
    active,
    editMode,
    onLongPress,
    onPressOut,
    onPress,
    borderWidth = 0,
    borderColor,
    profileDelete,
    theme,
    isVaporEnabled = false,
  } = props;
  const {heatProfileCardTheme, heatProfileCoreImageTheme} = theme;
  const {
    primaryFontColor,
    durationFontColor,
    specularColorStart,
    specularColorEnd,
    iconColor,
    iosShadowAlpha,
    durationBubbleColor,
  } = heatProfileCardTheme;
  const isOpal = theme === opalTheme;
  const isPeach = theme === whitePeachTheme || theme === blackPeachTheme;
  const isDesert = theme === desertTheme;
  const isFlourish = theme === flourishTheme;
  const isStorm = theme === stormTheme;
  const shadowOpacity = Constants.IS_WEB ? iosShadowAlpha : undefined;

  const settings = useSelector(appSettingsSelector);
  const temperature = useTemperature(profile.temperature, profile.units);
  const [isPressed, setIsPressed] = React.useState(false);
  const cardScaleFactor = useCardScaleFactor();
  const cardScaleStyle = useCardScaleStyle(cardScaleFactor);
  const cardContainerStyle = {
    height: cardScaleFactor * Constants.CARD_HEIGHT,
    marginVertical: cardScaleFactor * Constants.CARD_VERTICAL_MARGIN,
  };

  const vapor = useGetVaporSetting(profile);

  const TextBubble = styled(View)({
    borderRadius: 8.5,
    height: 20,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const CardElevate = React.useCallback(
    ({style, children, ...rest}: ViewProps & {children: React.ReactNode}) => {
      return (
        <>
          {isPeach || isDesert || isFlourish || isStorm ? (
            <View style={[{...cardStyle, ...cardScaleStyle}, style]} {...rest}>
              {children}
            </View>
          ) : (
            <ElevationView
              shadowOpacity={shadowOpacity}
              style={[{...cardStyle, ...cardScaleStyle}, style]}
              {...rest}>
              {children}
            </ElevationView>
          )}
        </>
      );
    },
    [cardScaleStyle, cardStyle],
  );

  const renderCard = ({style, ...rest}: ViewProps) => {
    const getGlowTintColor = () => {
      if (isPreTHeatProfile(profile) || isTHeatProfileNoMoodLight(profile)) {
        return Constants.IS_WEB
          ? changeHexColorValues(profile.color, 1, 0.5, true)
          : profile.color;
      } else if (moodLight && moodLight.colors) {
        return moodLight.colors.length > 1 ? white : moodLight.colors[0];
      } else {
        return changeHexColorValues(white, 1, 0.5, true);
      }
    };

    return (
      <CardElevate style={[{...cardStyle, ...cardScaleStyle}, style]} {...rest}>
        <LinearGradientBackground // specular highlight
          colors={[specularColorStart, specularColorEnd]}
          useAngle={true}
          angle={350}
          angleCenter={{x: 0.5, y: 0.5}}
          style={{padding: isOpal ? 0 : 1, borderRadius: 10}}>
          <Card>
            <HeatProfileCardBackground
              profile={profile}
              moodLight={moodLight}
              isOpal={isOpal}
              editMode={editMode}
              heatProfileCardTheme={theme.heatProfileCardTheme}
              style={{
                width: Constants.CARD_WIDTH,
                height: Constants.CARD_HEIGHT,
              }}
            />
            <CardForeground>
              <VerticalContainer style={{alignItems: 'flex-start'}}>
                <ProfileName
                  numberOfLines={1}
                  style={{color: primaryFontColor}}>
                  {profile.name.toUpperCase()}
                </ProfileName>
                <ProfileTemperature
                  temperature={Math.round(temperature)}
                  temperatureUnit={
                    (settings && settings.tempPreference[0]) || 'F'
                  }
                  textStyle={{
                    fontSize: 45,
                    color: primaryFontColor,
                  }}
                />
              </VerticalContainer>
              {!editMode ? (
                <VerticalContainer
                  style={{alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                  <Image
                    source={glow}
                    style={{
                      height: 358,
                      width: 398,
                      bottom: -210,
                      right: -(Constants.IS_WEB ? 147 : 150),
                      zIndex: 0,
                      position: 'absolute',
                      opacity:
                        !isOpal && moodLight && moodLight.colors.length > 1
                          ? 0.5
                          : 1,
                      tintColor: getGlowTintColor(),
                    }}
                  />
                  {heatProfileCoreImageTheme && (
                    <HeatProfileCoreImage
                      theme={heatProfileCoreImageTheme}
                      colors={
                        isTHeatProfileMoodLight(profile)
                          ? moodLight && moodLight.colors
                            ? moodLight.colors
                            : [colors.defaultColor]
                          : [profile.color]
                      }
                      style={{
                        borderRadius: calculateInnerContainerDiameter(
                          DEFAULT_HALO_DIAMETER,
                        ),
                        height: HEAT_PROFILE_CORE_DIAMETER,
                        width: HEAT_PROFILE_CORE_DIAMETER,
                        bottom: -124,
                        right: -(Constants.IS_WEB ? 52 : 54),
                        position: 'absolute',
                      }}
                    />
                  )}
                  <HeatProfileCardRing
                    colors={
                      !isOpal && moodLight && moodLight.colors.length > 1
                        ? moodLight.colors
                        : [colors.white]
                    }
                    width={
                      HEAT_PROFILE_CORE_DIAMETER * (Constants.IS_WEB ? 1.08 : 1)
                    }
                    height={
                      HEAT_PROFILE_CORE_DIAMETER * (Constants.IS_WEB ? 1.08 : 1)
                    }
                    offsetBottom={-115 * (Constants.IS_WEB ? 1.11 : 1)}
                    offsetRight={-45 * (Constants.IS_WEB ? 1.3 : 1)}
                  />

                  <BubbleContainer>
                    {isVaporEnabled && (
                      <TextBubble
                        style={{
                          backgroundColor: durationBubbleColor,
                          marginRight: 5,
                        }}>
                        <DurationText style={{color: durationFontColor}}>
                          {Messages.VAPOR[vapor]}
                        </DurationText>
                      </TextBubble>
                    )}
                    <DurationBubble
                      style={{
                        backgroundColor: durationBubbleColor,
                      }}>
                      <DurationText style={{color: durationFontColor}}>
                        {getTimeFormat(profile.duration, {
                          padMinutes: false,
                          padSeconds: true,
                        })}
                      </DurationText>
                    </DurationBubble>
                  </BubbleContainer>
                </VerticalContainer>
              ) : (
                <HorizontalContainer>
                  {!active && (
                    <DeleteIcon
                      color={iconColor}
                      onPress={() => profileDelete && profileDelete(profile.id)}
                    />
                  )}
                  <ReorderIcon
                    color={iconColor}
                    onLongPress={onLongPress}
                    onPressOut={onPressOut}
                  />
                </HorizontalContainer>
              )}
            </CardForeground>
            {borderWidth > 0 && (
              <Card style={{borderColor, borderWidth, position: 'absolute'}} />
            )}
          </Card>
        </LinearGradientBackground>
      </CardElevate>
    );
  };

  if (Constants.IS_WEB) {
    // Workaround for an issue with TouchableOpacity causing the list to snap while dragging
    return (
      <TouchableWithoutFeedback
        disabled={editMode}
        onPress={onPress}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}>
        <CardContainer style={cardContainerStyle}>
          {!editMode && (
            <OpacityLayer
              style={{
                backgroundColor: isPressed ? black50 : invisible,
                borderWidth: borderWidth,
                ...cardScaleStyle,
              }}
            />
          )}
          {renderCard({style: {position: 'absolute'}})}
        </CardContainer>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <TouchableOpacity disabled={editMode} onPress={onPress}>
      <CardContainer style={cardContainerStyle}>{renderCard({})}</CardContainer>
    </TouchableOpacity>
  );
};

const cardStyle: ViewStyle = {
  alignSelf: 'center',
  borderColor: colors.invisible,
  borderRadius: 10,
  borderWidth: 0,
  height: Constants.CARD_HEIGHT,
  overflow: 'hidden',
  width: '100%',
};

const OpacityLayer = styled(View)({
  ...cardStyle,
  alignSelf: 'center',
  overflow: 'hidden',
  position: 'absolute',
  zIndex: 9,
});

const CardContainer = styled(View)({...centerStyle, width: '100%'});

const Card = styled(View)(cardStyle);

const CardForeground = styled(View)({
  ...fillStyle,
  position: 'absolute',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflow: 'hidden',
  paddingHorizontal: 10,
  paddingVertical: 10,
});

const VerticalContainer = styled(View)({
  flex: 1,
  justifyContent: 'space-between',
  zIndex: 1,
});

const ProfileName = styled(AppText)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  width: 200,
});

const DurationText = styled(AppText)({
  fontFamily: 'Roboto-Medium',
  letterSpacing: 0,
  fontSize: 12,
  includeFontPadding: false,
  fontWeight: '400',
});

const HorizontalContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const DurationBubble = styled(View)({
  borderRadius: 8.5,
  width: 40,
  height: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const BubbleContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
});
