import {Text} from 'react-native';

import styled from '../lib/utils/styled';
import {colors} from '../styles';

export const Description = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  color: colors.textOnSurface,
});
