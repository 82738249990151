import React from 'react';
import {Circle} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const MoreIcon: React.FC<IconProps> = ({...props}) => (
  <Icon {...props}>
    <Circle cx={12} cy={12} r={1} />
    <Circle cx={19} cy={12} r={1} />
    <Circle cx={5} cy={12} r={1} />
  </Icon>
);
