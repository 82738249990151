import React from 'react';
import {useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {Alert} from '../../components/Alert';
import {Constants} from '../../constants';
import {Alerts, interpolate} from '../../constants/Strings';
import {colors} from '../../styles';
import {moodlightApi} from '../api/apis';
import {
  connectedPeakSelector,
  currentDeviceIdSelector,
  currentDeviceSettingsSelector,
  updateDeviceSettings,
} from '../redux/slices/bleSlice';
import {
  deleteAccountMoodLight,
  lanternMoodLightSelector,
  setLanternMoodLightId,
  setPeakMoodLights,
} from '../redux/slices/moodLightSlice';
import {
  activeProfilesSelector,
  archiveProfilesSelector,
  tempProfileSelector,
  updateActiveProfile,
  updateArchiveProfiles,
  updateTempProfile,
} from '../redux/slices/profilesSlice';
import {userSelector} from '../redux/slices/userSlice';
import {useAppDispatch} from '../redux/useAppDispatch';
import {
  CustomMoodLight,
  Dictionary,
  Profile,
  ProfileT,
  isTHeatProfileMoodLight,
} from '../types';
import {setProfileToWhite} from '../utils/profileFunctions';
import {useGetUpdatedPeakMoodLights} from './useGetUpdatedPeakMoodLights';

type DeletedInfo = {
  deletedMoodLight: CustomMoodLight;
  matchingActives: ProfileT[];
  matchingArchives: ProfileT[];
  shouldTurnOffLantern: boolean;
  matchingTemp?: ProfileT;
};

export function useDeleteMoodLight() {
  const deletedInfo = React.useRef<DeletedInfo | null>(null);
  const dispatch = useAppDispatch();
  const peak = useSelector(connectedPeakSelector);
  const currentDeviceId = useSelector(currentDeviceIdSelector);
  const lanternMoodLight = useSelector(lanternMoodLightSelector);
  const isLanternModeOn =
    useSelector(currentDeviceSettingsSelector)?.lanternMode ?? false;
  const archives = useSelector(archiveProfilesSelector);
  const actives = useSelector(activeProfilesSelector);
  const tempProfile = useSelector(tempProfileSelector);
  const user = useSelector(userSelector);
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();

  const whitenActivesTempLantern = React.useCallback(
    ({
      matchingActives,
      matchingTemp,
      deletedMoodLight,
      shouldTurnOffLantern,
    }: {
      matchingActives: DeletedInfo['matchingActives'];
      matchingTemp: DeletedInfo['matchingTemp'];
      deletedMoodLight: CustomMoodLight;
      shouldTurnOffLantern: boolean;
    }) => {
      // Whiten temp profile in Redux
      const whiteTempProfile: ProfileT | undefined = matchingTemp
        ? {
            ...setProfileToWhite(matchingTemp),
            order: Constants.TEMP_HEAT_PROFILE_INDEX,
          }
        : undefined;
      if (whiteTempProfile) {
        dispatch(updateTempProfile(whiteTempProfile));
      }

      // Whiten active profiles in Redux
      matchingActives = matchingActives.map(active => {
        const whitenedProfile = setProfileToWhite(active);
        dispatch(updateActiveProfile(whitenedProfile));
        return whitenedProfile;
      });

      // Whiten active and temp profiles on the device
      const combinedProfiles = [
        ...matchingActives,
        ...(whiteTempProfile ? [whiteTempProfile] : []),
      ];
      combinedProfiles.length > 0 &&
        peak?.writeHeatProfiles(
          combinedProfiles,
          combinedProfiles.map(() => undefined),
        );

      const attachedActivesDictionary: Dictionary<string, Profile> = {};
      matchingActives.forEach(
        active => (attachedActivesDictionary[active.id] = active),
      );

      // Whiten lantern
      const shouldWhitenLantern =
        !!lanternMoodLight && lanternMoodLight.id === deletedMoodLight.id;

      if (shouldWhitenLantern) {
        dispatch(setLanternMoodLightId());

        if (isLanternModeOn) {
          if (shouldTurnOffLantern) peak?.stopLantern();
          peak?.clearScratchpad();
          dispatch(
            updateDeviceSettings({
              syncUserLanternPreference: true,
              id: currentDeviceId ?? '',
              settings: {
                lanternMode: false,
                lanternColor: colors.defaultColor,
              },
            }),
          );
        }
      }

      // Update Peak mood lights in Redux
      dispatch(
        setPeakMoodLights(
          getUpdatedPeakMoodLights({
            actives: actives.map(
              active => attachedActivesDictionary[active.id] ?? active,
            ),
            lanternMoodLight: undefined,
          }),
        ),
      );
    },
    [
      peak,
      actives,
      currentDeviceId,
      getUpdatedPeakMoodLights,
      isLanternModeOn,
      lanternMoodLight,
    ],
  );

  const whitenMatchingArchives = React.useCallback(
    (matchingArchives: DeletedInfo['matchingArchives']) => {
      // Whiten archive profiles in Redux
      user &&
        dispatch(
          updateArchiveProfiles(
            matchingArchives.map(archive => ({
              ...setProfileToWhite(archive),
              userId: user.id,
            })),
          ),
        );
    },
    [user],
  );

  const deleteActiveMoodLight = React.useCallback(
    (deletedInfo: DeletedInfo) => {
      const {
        deletedMoodLight,
        matchingActives,
        matchingTemp,
        shouldTurnOffLantern,
      } = deletedInfo;
      whitenActivesTempLantern({
        matchingActives,
        matchingTemp,
        deletedMoodLight,
        shouldTurnOffLantern,
      });
    },
    [whitenActivesTempLantern],
  );

  const [state, action] = useAsyncFn(
    async (moodLightId: string) => {
      try {
        moodlightApi.deleteMoodLight({moodLightId});

        if (!deletedInfo.current) return;

        dispatch(deleteAccountMoodLight(deletedInfo.current.deletedMoodLight));
        whitenMatchingArchives(deletedInfo.current.matchingArchives);
        deleteActiveMoodLight(deletedInfo.current);

        deletedInfo.current = null;
      } catch (error) {
        Alert.alert(Alerts.MOOD_DELETE_ERROR_TITLE, (error as Error).message);
        deletedInfo.current = null;

        throw error;
      }
    },
    [deleteActiveMoodLight, whitenMatchingArchives],
  );

  const deleteMoodLight = React.useCallback(
    ({
      moodLight,
      isPeakMoodLight,
      shouldTurnOffLantern,
      onDelete,
    }: {
      moodLight: CustomMoodLight;
      isPeakMoodLight: boolean;
      shouldTurnOffLantern: boolean;
      onDelete: () => void;
    }) => {
      const profileMatchesMoodLight = (profile: Profile) =>
        isTHeatProfileMoodLight(profile) &&
        profile.moodLightId === moodLight.id;
      const matchingActives = actives.filter(
        profileMatchesMoodLight,
      ) as ProfileT[];
      const matchingArchives = archives.filter(
        profileMatchesMoodLight,
      ) as ProfileT[];

      Alert.alert(
        Alerts.CONFIRM_DELETION,
        matchingArchives.length > 0 || matchingActives.length > 0
          ? interpolate(Alerts.CONFIRM_WHITEN_PROFILES, {
              allNames: (() => {
                const profileMap = new Map<string, Profile>();
                [...matchingActives, ...matchingArchives].forEach(
                  profile =>
                    !profileMap.has(profile.id) &&
                    profileMap.set(profile.id, profile),
                );
                return [...profileMap.values()]
                  .map(profile => profile.name)
                  .join(', ');
              })(),
            })
          : interpolate(Alerts.CONFIRM_DELETE_MOOD_LIGHT, {
              moodLight: moodLight?.name ?? 'this mood light',
            }),
        [
          {text: 'Cancel'},
          {
            text: 'OK',
            onPress: async () => {
              const deleteInformation = {
                deletedMoodLight: moodLight,
                matchingActives,
                matchingArchives,
                shouldTurnOffLantern,
                ...(tempProfile &&
                  isTHeatProfileMoodLight(tempProfile) &&
                  tempProfile.moodLightId === moodLight.id && {
                    matchingTemp: tempProfile,
                  }),
              };
              if (isPeakMoodLight) {
                deleteActiveMoodLight(deleteInformation);
              }
              deletedInfo.current = deleteInformation;
              await action(moodLight.id);
              onDelete();
            },
          },
        ],
      );
    },
    [
      actives,
      action,
      archives,
      deleteActiveMoodLight,
      tempProfile,
      deletedInfo,
    ],
  );

  return [state, deleteMoodLight] as const;
}
