import {
  HeatProfileUpdateDto,
  MoodLightCategory,
  TemperatureUnit,
} from 'puffco-api-axios-client';

import type {UpdateHeatProfileDto} from '../hooks/useUpdateHeatProfileWithSuccessError';

export const convertUpdateHeatProfileToDto = ({
  units,
  modified,
  moodLight,
  ...dto
}: UpdateHeatProfileDto): HeatProfileUpdateDto => ({
  ...dto,
  units: TemperatureUnit[units],
  ...(modified && {modified: new Date(modified).getTime()}),
  ...(moodLight &&
    moodLight.category === MoodLightCategory.Custom && {
      moodLight: {
        ...moodLight,
        category: moodLight.category,
        userId: dto.userId,
      },
    }),
});
