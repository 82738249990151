import React from 'react';
import {useSelector} from 'react-redux';

import {userApi} from '../lib/api/apis';
import {useSyncPeakDevice} from '../lib/hooks/useSyncPeakDevice';
import {useTrackUser} from '../lib/hooks/useTrackUser';
import {setUser, userSelector} from '../lib/redux/slices/userSlice';
import {useAppDispatch} from '../lib/redux/useAppDispatch';
import {setUser as setLoggerUser} from '../lib/utils/Logger';
import {analytics} from '../services/analytics';
import {setUser as setSentryUser} from '../util/sentry';

export const AppStateSensor: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(userSelector);

  useSyncPeakDevice();
  useTrackUser();

  // TODO: remove this once session handling is fixed
  React.useEffect(() => {
    setLoggerUser(user ?? undefined);
    setSentryUser(user ?? undefined);
  }, [user]);

  React.useEffect(() => {
    if (!user?.id) return;

    analytics.setCurrentUser(user?.id.toString());
    userApi.getUserById({id: 'me'}).then(({data}) => dispatch(setUser(data)));
  }, [user?.id]);

  return null;
};
