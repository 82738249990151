import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {Constants} from '../../../constants';
import {Profile} from '../../types';
import {ProfileStore, Store} from '../types';
import {currentDeviceSelector} from './bleSlice';

const updateArchiveProf = (state: ProfileStore, updatedProfile: Profile) => {
  const index = state.profiles.findIndex(
    currentProfile => currentProfile.id === updatedProfile.id,
  );
  if (index !== Constants.INDEX_NOT_FOUND) {
    state.profiles[index] = {
      ...state.profiles[index],
      ...updatedProfile,
      modified: new Date().getTime(),
    };
  }
};

const profileSlice = createSlice({
  name: 'profiles',
  initialState: {
    currentProfileIndex: 0,
    activeProfiles: [],
    profiles: [],
    tempProfile: null,
  } as ProfileStore,
  reducers: {
    setArchiveProfiles(state: ProfileStore, action: PayloadAction<Profile[]>) {
      state.profiles = action.payload;
    },
    addArchiveProfile(state: ProfileStore, action: PayloadAction<Profile>) {
      state.profiles.push(action.payload);
    },
    addArchiveProfileFront(
      state: ProfileStore,
      action: PayloadAction<Profile>,
    ) {
      state.profiles.forEach((profile: Profile) => {
        profile.order++;
      });
      state.profiles.splice(0, 0, action.payload);
    },
    updateArchiveProfile(state: ProfileStore, action: PayloadAction<Profile>) {
      updateArchiveProf(state, action.payload);
    },
    updateArchiveProfiles(
      state: ProfileStore,
      action: PayloadAction<Profile[]>,
    ) {
      action.payload.forEach(updatedProfile => {
        updateArchiveProf(state, updatedProfile);
      });
    },
    deleteArchiveProfile(state: ProfileStore, action: PayloadAction<Profile>) {
      state.profiles.forEach((profile: Profile) => {
        if (profile.order > action.payload.order) {
          profile.order--;
        }
      });
      state.profiles.splice(
        state.profiles.findIndex(p => p.id === action.payload.id),
        1,
      );
    },
    setActiveProfiles(state: ProfileStore, action: PayloadAction<Profile[]>) {
      // TODO: Update profiles only if date modified is newer
      state.activeProfiles = action.payload;
    },
    updateTempProfile(state: ProfileStore, action: PayloadAction<Profile>) {
      state.tempProfile = {
        ...omit(state.tempProfile, 'isMoodLight', 'moodLightId', 'version'),
        ...action.payload,
      };
    },
    updateActiveProfile(state: ProfileStore, action: PayloadAction<Profile>) {
      const index = state.activeProfiles.findIndex(
        p => p.order === action.payload.order,
      );
      state.activeProfiles[index] = {
        ...state.activeProfiles[index],
        ...action.payload,
        modified: new Date().getTime(),
      };
    },
    resetProfileArchives(state: ProfileStore) {
      state.profiles = [];
      state.currentProfileIndex = 0;
    },
  },
});

export const activeProfilesSelector = (state: Store) =>
  state.profile.activeProfiles;
export const archiveProfilesSelector = (state: Store) => state.profile.profiles;
export const tempProfileSelector = (state: Store) =>
  state.profile.tempProfile ?? undefined;
export const currentProfileSelector = createSelector(
  tempProfileSelector,
  activeProfilesSelector,
  currentDeviceSelector,
  (tempProfile, activeProfiles, device) => {
    const selectedHeatCycle = device?.selectedHeatCycle;

    if (!selectedHeatCycle) return activeProfiles[0];

    if (selectedHeatCycle === Constants.TEMP_HEAT_PROFILE_INDEX) {
      return tempProfile ?? activeProfiles[0];
    }

    return [0, 1, 2, 3].includes(selectedHeatCycle)
      ? activeProfiles[selectedHeatCycle]
      : activeProfiles[0];
  },
);

export const {
  setArchiveProfiles,
  addArchiveProfile,
  addArchiveProfileFront,
  updateArchiveProfile,
  updateArchiveProfiles,
  deleteArchiveProfile,
  setActiveProfiles,
  updateTempProfile,
  updateActiveProfile,
  resetProfileArchives,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
