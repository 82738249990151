import React from 'react';

import {backgroundForgotPassword} from '../../assets/images';
import {Constants} from '../../constants';
import {Messages} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {openApp} from '../../lib/hooks/useOpenApp';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {ImageAndContentContainer, TermsAndPrivacy} from '../components';

const {IS_WEB} = Constants;
const {PASSWORD_CONFIRMED_TITLE, PASSWORD_CONFIRMED_MSG} = Messages;

export interface Props {
  email: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.PasswordConfirmed> {}

export const PasswordConfirmedScreen: React.FC<ScreenProps> = ({route}) => {
  return (
    <ImageAndContentContainer
      isNotPath={IS_WEB}
      image={backgroundForgotPassword}
      header={{content: PASSWORD_CONFIRMED_TITLE}}
      body={{content: PASSWORD_CONFIRMED_MSG}}
      primaryButton={{
        onClick: () => openApp(`/login?email=${route.params?.email ?? ''}`),
      }}
      bottom={{content: <TermsAndPrivacy />, style: {marginTop: 24}}}
    />
  );
};
