import {Constants} from '../../constants';

export const getSecondsFromMilliseconds = (milliseconds: number) =>
  Math.round(milliseconds / Constants.UNIT_CONVERSION.SECONDS_TO_MILLISECONDS);

export const convertDegreesToRadians = (degrees: number) =>
  degrees * (Math.PI / 180);

export const convertRadiansToDegrees = (radians: number) =>
  radians * (180 / Math.PI);

// Coordinate system conversion
export const convertCartesianToPolar = (x: number, y: number) => ({
  radius: Math.hypot(x, y),
  angle: Math.atan2(y, x), // In radians
});

export const convertPolarToCartesian = (radius: number, angle: number) => ({
  x: radius * Math.cos(angle),
  y: radius * Math.sin(angle),
});
