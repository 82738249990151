// AppFlags differs from AppSettings in that AppSettings are user configured,
// while AppFlags are persisted state set by the application itself.
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {GuideType} from '../../api';
import {AppFlagsStore, Store} from '../types';

const appFlagsSlice = createSlice({
  name: 'appFlags',
  initialState: {
    hasSeenEpilepsyWarning: false,
    hasSeenNewUsername: false,
    launchCount: 0,
    hasSeenMarketOptIn: false,
    hasSeenVaporUnlockedAlert: false,
    hasSeenAdvancedMetrics: false,
    hasSeenMetricsAlertScreen: false,
    hasSeenXLUnlockedAlertScreen: false,
    hasSeenXLProductReleaseAlertScreen: false,
    hasSeenXLUpsellAlertScreen: false,
    hasSeenSMSMarketOptIn: false,
    hasSeenGuide: {
      [GuideType?.NEW_USER]: false,
      [GuideType?.GALLERY]: false,
    },
    hasSeenRehash: {},
  } as AppFlagsStore,
  reducers: {
    updateAppFlags(state, action: PayloadAction<Partial<AppFlagsStore>>) {
      return {...state, ...action.payload};
    },
  },
});

export const appFlagsSelector = (state: Store) => state.appFlags;

export const {updateAppFlags} = appFlagsSlice.actions;

export const appFlagsReducer = appFlagsSlice.reducer;
