import React from 'react';
import {Image, ImageSourcePropType, StyleSheet, Text, View} from 'react-native';

import {PressableOpacity} from '../../../../components/PressableOpacity';
import {colors} from '../../../../styles';

interface Props {
  thumbnailImage: ImageSourcePropType;
  name: string;
  isSelected: boolean;
  onPress: () => void;
}

export const DeviceItem = ({
  onPress,
  thumbnailImage,
  name,
  isSelected,
}: Props) => (
  <PressableOpacity
    style={[
      styles.pressable,
      {
        opacity: isSelected ? 1 : 0.5,
      },
    ]}
    {...{onPress}}>
    <View style={styles.text}>
      <Image
        source={thumbnailImage}
        resizeMode="contain"
        style={styles.image}
      />
    </View>

    <Text style={styles.name}>{name}</Text>
  </PressableOpacity>
);

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
  },
  text: {
    borderRadius: 12,
    borderColor: colors.white,
    borderWidth: 1,
  },
  image: {
    height: 56,
    aspectRatio: 0.7,
    marginHorizontal: 20,
    marginVertical: 12,
  },
  name: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: '700',
    color: colors.white,
    marginTop: 12,
  },
});
