import {differenceInYears, isValid, parse} from 'date-fns';
import * as Yup from 'yup';

import {ErrorMessages} from '../../../constants/Strings';

const ageRequirement = 21;
const maxAge = 120;
const regex = (value: string) => (value ?? '').replace(/\D/g, '');

export const checkAgeTest = Yup.string().test({
  name: 'checkAge',
  test: function (value: string) {
    if (!value) {
      // this field could be empty
      return true;
    }
    let formatBirthday = regex(value);
    if (formatBirthday.length < 8) {
      return this.createError({
        message: ErrorMessages.INVALID_DATE,
        path: 'birthday',
      });
    } else if (formatBirthday.length > 8) {
      // formatDate on the forms will only allow 8 characters
      // so even if a user types more here we should only validate
      // against the 8 because the 9th char will be stripped off after
      // valdiation
      formatBirthday = formatBirthday.slice(0, 8);
    }
    const birthdayDate = parse(formatBirthday, 'mmddyyyy', new Date());
    if (!isValid(birthdayDate)) {
      return this.createError({
        message: ErrorMessages.INVALID_DATE,
        path: 'birthday',
      });
    }
    const age = differenceInYears(new Date(), birthdayDate);
    if (age < ageRequirement || age > maxAge) {
      return this.createError({
        message: ErrorMessages.AGE_NOT_MET,
        path: 'birthday',
      });
    }
    return true;
  },
});
