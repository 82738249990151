import React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

type ElevationViewProps = {
  shadowOpacity?: number;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> & {
    style?: Omit<React.CSSProperties, 'transform'> &
      Pick<ViewStyle, 'transform'>;
  };

export const ElevationView = ({
  shadowOpacity,
  children,
  style = {},
  ...rest
}: React.PropsWithChildren<ElevationViewProps>) => {
  const {transform, ...restStyle} = StyleSheet.flatten(style);
  const color = shadowOpacity ? `#000000${shadowOpacity}` : `black`;
  return (
    <div
      style={{
        transform: (transform as any[])
          ?.map(prop => `${Object.keys(prop)[0]}(${Object.values(prop)[0]})`)
          .reduce(
            (previousValue, currentValue) => `${previousValue} ${currentValue}`,
          ),
        ...restStyle,
        boxShadow: `0px 2px 6px ${color}`,
      }}
      {...rest}>
      {children}
    </div>
  );
};
