import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';

import {Screens} from '../constants/navigation';
import {
  AccountScreen,
  ChangeEmailScreen,
  ChangePasswordScreen,
  ChangeUsernameScreen,
} from '../screens';
import {
  defaultNavigationOptions,
  withBackButton,
  withHamburgerMenu,
} from './common';

export type AccountStackParamList = {
  [Screens.Account]:
    | {
        headerLeft: React.ReactNode;
        headerRight: React.ReactNode;
      }
    | undefined;
  [Screens.ChangePassword]: undefined;
  [Screens.ChangeUsername]: undefined;
  [Screens.ChangeEmail]:
    | {
        setAccountFieldValue: (key: 'email', value: string) => void;
      }
    | undefined;
};

const AccountStack = createStackNavigator<AccountStackParamList>();

export const AccountStackNavigator = () => {
  return (
    <AccountStack.Navigator
      initialRouteName={Screens.Account}
      screenOptions={{...defaultNavigationOptions}}>
      <AccountStack.Screen
        name={Screens.Account}
        component={AccountScreen}
        options={{
          headerTitle: 'ACCOUNT',
          ...withHamburgerMenu(),
        }}
      />

      <AccountStack.Screen
        name={Screens.ChangePassword}
        component={ChangePasswordScreen}
        options={{
          headerTitle: 'PASSWORD',
          headerBackTitleVisible: false,
          ...withBackButton(),
        }}
      />

      <AccountStack.Screen
        name={Screens.ChangeUsername}
        component={ChangeUsernameScreen}
      />

      <AccountStack.Screen
        name={Screens.ChangeEmail}
        component={ChangeEmailScreen}
        options={{
          headerBackTitleVisible: false,
          headerTitle: 'UPDATE EMAIL',
          ...withBackButton(),
        }}
      />
    </AccountStack.Navigator>
  );
};
