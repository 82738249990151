import React from 'react';
import {Animated} from 'react-native';

import {useAnim} from '../../../lib/hooks/useAnim';

type AnimationChildProps = {
  top: Animated.Value;
  height?: number;
  width?: number;
  right: Animated.Value;
};

const CARD_HEIGHT = 178;

export const AnimationSwipeContainer = ({
  onClick,
  onAfterAnimation,
  children,
}: {
  onClick: (() => boolean) | (() => Promise<boolean>);
  onAfterAnimation: (() => void) | (() => Promise<void>);
  children: (d: {
    style: AnimationChildProps;
    onPress: () => void;
    setLayout: (w: number, h: number) => void;
  }) => React.ReactNode;
}) => {
  const [right, startRight, isRightAnimDone] = useAnim();
  const [top, startTop, isTopAnimDone] = useAnim();

  const [layout, setLayout] = React.useState<{height?: number; width?: number}>(
    {
      height: undefined,
      width: undefined,
    },
  );

  const onPress = async () => {
    const result = await onClick();
    if (result && layout.width !== undefined) {
      startRight(layout.width / 2 + 10);
    }
  };

  const updateLayout = (width: number) => {
    if (layout.height !== undefined && layout.width !== undefined) {
      return;
    }
    setLayout(v => ({...v, width, height: CARD_HEIGHT}));
  };

  React.useEffect(() => {
    if (isRightAnimDone && layout.height !== undefined) startTop(layout.height);
  }, [isRightAnimDone]);

  React.useEffect(() => {
    if (isTopAnimDone) onAfterAnimation();
  }, [isTopAnimDone]);

  const style = {
    top: top,
    height: layout.height,
    width: layout.width,
    right: right,
  };

  return (
    <>
      {children({
        style: style,
        onPress,
        setLayout: updateLayout,
      })}
    </>
  );
};
