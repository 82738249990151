import {ResponsiveText} from '../../../components/ResponsiveText';
import styled from '../../../lib/utils/styled';
import {colors} from '../../../styles';

export const MediumText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Medium',
  color: colors.white,
});

export const BoldText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Bold',
  color: colors.white,
});

export const RegularText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Regular',
});
