import React from 'react';
import {Text, View} from 'react-native';
import {Circle, Line, Svg} from 'react-native-svg';

import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StyledButton} from '../../components/StyledButton';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useExitStack} from '../../lib/hooks/useExitStack';
import styled from '../../lib/utils/styled';
import type {WarrantyNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {colors} from '../../styles';

interface ScreenProps
  extends WarrantyNavigatorScreenProps<
    typeof Screens.WarrantyClaimScreenSuccess
  > {}

export const WarrantyClaimScreenSuccess: React.FC<ScreenProps> = () => {
  useAdaptiveSafeArea();

  const exitStack = useExitStack();

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <MainContainer>
          <StyledTitle>Success!</StyledTitle>

          <CheckContainer>
            <Svg height="145" width="145">
              <Circle
                cx="70"
                cy="70"
                r="68"
                stroke="#00DE50"
                fill="none"
                strokeWidth="2"
              />
              <Line
                x1="35"
                y1="75"
                x2="60"
                y2="100"
                stroke="#00DE50"
                strokeWidth="3"
              />
              <Line
                x1="59"
                y1="100"
                x2="110"
                y2="45"
                stroke="#00DE50"
                strokeWidth="3"
              />
            </Svg>
          </CheckContainer>

          <TextContainer>
            <StyledInfoText>{Messages.WARRANTY_SUCCESS_BODY}</StyledInfoText>
            <StyledInfoText>Thanks,</StyledInfoText>
            <StyledInfoText>Puffco Support</StyledInfoText>
            <StyledInfoText>{Messages.WARRANTY_SUCCESS_EMAIL}</StyledInfoText>
          </TextContainer>

          <StyledButton onPress={() => exitStack()} title={Strings.DONE} />
        </MainContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const MainContainer = styled(View)({
  marginTop: 64,
  padding: 15,
  height: '85%',
  alignItems: 'center',
});

const TextContainer = styled(View)({
  flex: 2,
  padding: 15,
  paddingBottom: 70,
  justifyContent: 'space-between',
});

const CheckContainer = styled(View)({
  flex: 2.5,
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledTitle = styled(Text)({
  flex: 1,
  alignSelf: 'flex-start',
  color: colors.white,
  fontFamily: 'BigShouldersDisplay-Bold',
  fontWeight: '400',
  fontSize: 30,
  lineHeight: 48,
  marginLeft: 15,
});

const StyledInfoText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  color: colors.white,
  fontSize: 16,
  textAlign: 'center',
  fontWeight: '400',
});
