import {Buffer} from 'buffer';
import {AnimFrame} from 'pikaparam';

import {MiddlewareValue} from '../types';
import {convertHexArrayToNumArray} from './colors';
import {getMiddlewareValueByteSize} from './getDictionaryValue';
import {decodeTrusted} from './ulidCoding';

export const writeValueToBuffer = (
  value: string | number | number[] | Buffer | string[],
  type: MiddlewareValue,
) => {
  let buf: Buffer;

  switch (type) {
    case MiddlewareValue.STRING:
      return Buffer.from(value as string);

    case MiddlewareValue.FLOAT:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeFloatLE(value as number, 0);
      return buf;

    case MiddlewareValue.U_INT_8:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeUInt8(value as number, 0);
      return buf;

    case MiddlewareValue.U_INT_16:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeUInt16LE(value as number, 0);
      return buf;

    case MiddlewareValue.U_INT_32:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeUInt32LE(value as number, 0);
      return buf;

    case MiddlewareValue.INT_8:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeInt8(value as number, 0);
      return buf;

    case MiddlewareValue.INT_32:
      buf = Buffer.allocUnsafe(getMiddlewareValueByteSize(type));
      buf.writeInt32LE(value as number, 0);
      return buf;

    case MiddlewareValue.BUFFER:
      return Buffer.from(value as Buffer);

    case MiddlewareValue.ULID:
      return Buffer.from(decodeTrusted(value as string));

    case MiddlewareValue.COLORS: //used for color array
      return Buffer.from(convertHexArrayToNumArray(value as string[], false));
  }
};

export const writeInt32NumbersToBuffer = (array: number[]) => {
  const byteSize = getMiddlewareValueByteSize(MiddlewareValue.INT_32);
  const buffer = Buffer.allocUnsafe(array.length * byteSize);
  array.forEach((num, index) => buffer.writeInt32LE(num, index * byteSize));
  return buffer;
};

export const writeAnimNumArrayToBuffer = (array: number[]) => {
  // 48 is calculated from 3 extra bytes per float x 16 floats
  const buffer = Buffer.allocUnsafe(array.length + 48);
  let offset = 0;
  array.forEach((num, index) => {
    if (index % 21 === 0) {
      // Write time float to array
      buffer.writeFloatLE(array[0], offset);
      offset += getMiddlewareValueByteSize(MiddlewareValue.FLOAT);
    } else {
      // Write luma int to array
      buffer.writeUInt8(num, offset);
      offset += getMiddlewareValueByteSize(MiddlewareValue.U_INT_8);
    }
  });
  return buffer;
};

export const writeAnimNumArrayToAnimFrame = (array: number[]) => {
  const frame: AnimFrame = {
    time: 0,
    luma: [],
  };

  array.forEach((num, index) => {
    if (index % 21 === 0) frame.time = array[0];
    else frame.luma.push(num);
  });

  return frame;
};
