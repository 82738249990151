import {ImpactFeedbackStyle, impactAsync} from 'expo-haptics';
import React from 'react';
import {ImageSourcePropType, TouchableOpacity, View} from 'react-native';

import {useTheme} from '../lib/hooks/useTheme';
import styled from '../lib/utils/styled';
import {Image} from './ImageWithFilter';

export type FatButtonProps = {
  diameter: number;
  onIconSource?: ImageSourcePropType;
  isActive?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  onPress: () => void;
  onLongPress?: () => void;
};

export const FatButton = (props: FatButtonProps) => {
  const {
    buttonActiveBackground,
    buttonInactiveBackground,
    buttonActiveForeground,
    buttonInactiveForeground,
  } = useTheme().controlCenterTheme;

  const {
    diameter,
    onIconSource,
    isActive = false,
    onPress,
    onLongPress,
    iconWidth,
    iconHeight,
  } = props;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress();
      }}
      onLongPress={() => {
        if (onLongPress) {
          impactAsync(ImpactFeedbackStyle.Heavy);
          onLongPress();
        }
      }}>
      <Container
        style={{
          height: diameter,
          width: diameter,
          borderRadius: diameter,
          backgroundColor: isActive
            ? buttonActiveBackground
            : buttonInactiveBackground,
        }}>
        {onIconSource && (
          <Image
            source={onIconSource}
            style={{
              width: iconWidth,
              height: iconHeight,
              alignSelf: 'center',
              tintColor: isActive
                ? buttonActiveForeground
                : buttonInactiveForeground,
            }}
            resizeMode={'contain'}
          />
        )}
      </Container>
    </TouchableOpacity>
  );
};

const Container = styled(View)({
  display: 'flex',
  justifyContent: 'center',
});
