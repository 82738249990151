import React from 'react';
import {
  Animated,
  PanResponder,
  ScrollView,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useWindowHeight} from '../../lib/hooks/useWindowHeight';
import styled from '../../lib/utils/styled';
import {colors} from '../../styles';

export const DraggableBottomSheet = ({
  children,
  bottomContainer,
  bottomSheetStyle,
  header,
}: {
  children: React.ReactNode;
  bottomContainer: React.ReactNode;
  bottomSheetStyle?: ViewStyle;
  header?: string;
}) => {
  return (
    <>
      {children}
      <DraggableSheet header={header} bottomSheetStyle={bottomSheetStyle}>
        {bottomContainer}
      </DraggableSheet>
    </>
  );
};

const MAX_DOWNWARD_TRANSLATE_Y = 0;
const DRAG_THRESHOLD = 50;

const DraggableSheet = ({
  children,
  bottomSheetStyle,
  header = '',
}: {
  children: React.ReactNode;
  bottomSheetStyle?: ViewStyle;
  header?: string;
}) => {
  const {bottom} = useSafeArea();
  const {isSmall, windowHeight} = useWindowHeight();
  const MAX_HEIGHT = windowHeight * (!isSmall ? 0.68 : 0.7);
  const SNAP_POINTS = [
    windowHeight * (isSmall ? 0.28 : 0.32) + bottom,
    MAX_HEIGHT,
  ];

  const MAX_UPWARD_TRANSLATE_Y = SNAP_POINTS[0] - SNAP_POINTS[1];

  const animatedValue = React.useRef(new Animated.Value(0)).current;
  const lastGestureDy = React.useRef(0);

  const panResponder = React.useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        animatedValue.setOffset(lastGestureDy.current);
      },
      onPanResponderMove: (_, gesture) => {
        animatedValue.setValue(gesture.dy);
      },
      onPanResponderRelease: (_, gesture) => {
        animatedValue.flattenOffset();

        if (gesture.dy > 0) {
          if (gesture.dy <= DRAG_THRESHOLD) {
            springAnimation('up');
          } else {
            springAnimation('down');
          }
          return;
        }
        if (gesture.dy >= -DRAG_THRESHOLD) {
          springAnimation('down');
        } else {
          springAnimation('up');
        }
      },
    }),
  ).current;

  const springAnimation = (direction: 'up' | 'down') => {
    lastGestureDy.current =
      direction === 'down' ? MAX_DOWNWARD_TRANSLATE_Y : MAX_UPWARD_TRANSLATE_Y;
    Animated.spring(animatedValue, {
      toValue: lastGestureDy.current,
      useNativeDriver: true,
    }).start();
  };

  const bottomSheetAnimation = {
    transform: [
      {
        translateY: animatedValue.interpolate({
          inputRange: [MAX_UPWARD_TRANSLATE_Y, MAX_DOWNWARD_TRANSLATE_Y],
          outputRange: [MAX_UPWARD_TRANSLATE_Y, MAX_DOWNWARD_TRANSLATE_Y],
          extrapolate: 'clamp',
        }),
      },
    ],
  };

  return (
    <Container>
      <AnimatedBottomSheet
        style={[
          {
            height: SNAP_POINTS[1],
            bottom: MAX_UPWARD_TRANSLATE_Y - bottom,
          },
          bottomSheetAnimation,
          bottomSheetStyle,
        ]}>
        <DraggableContainer {...panResponder.panHandlers}>
          <DraggableHandle />
          <HeaderPanelTitle>{header}</HeaderPanelTitle>
        </DraggableContainer>
        <ScrollView
          style={{maxHeight: MAX_HEIGHT}}
          scrollEnabled={lastGestureDy.current === MAX_UPWARD_TRANSLATE_Y}
          contentContainerStyle={{
            backgroundColor: colors.dark,
            paddingHorizontal: 20,
            paddingTop: 4,
          }}>
          {children}
        </ScrollView>
      </AnimatedBottomSheet>
    </Container>
  );
};

const Container = styled(View)({
  display: 'flex',
  flex: 1,
  width: '100%',
});

const HeaderPanelTitle = styled(Text)({
  fontFamily: 'Roboto-Medium',
  textTransform: 'uppercase',
  fontSize: 14,
  fontWeight: '400',
  lineHeight: 16,
  letterSpacing: 0.05,
  color: colors.white,
  marginTop: 14,
});

const AnimatedBottomSheet = styled(Animated.View)({
  position: 'absolute',
  width: '100%',
  backgroundColor: colors.dark,
  borderTopLeftRadius: 32,
  borderTopRightRadius: 32,
});

const DraggableContainer = styled(View)({
  marginTop: 17,
  marginBottom: 22,
  width: '100%',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.dark,
  borderTopLeftRadius: 32,
  borderTopRightRadius: 32,
});

const DraggableHandle = styled(View)({
  width: 48,
  height: 6,
  backgroundColor: colors.white20,
  borderRadius: 10,
});
