import {CustomMoodLight, User} from '../types';
import {moodlightApi} from './apis';

export type SaveMoodLightDto = Omit<CustomMoodLight, 'modified'> & {
  modified: Date;
  userId: number;
};

export const saveMoodlight = async (
  moodLight: CustomMoodLight,
  user?: User,
) => {
  if (!user) return;

  const {data} = await moodlightApi.updateMoodLight({
    moodLightUpdateDto: [
      {
        ...moodLight,
        category: moodLight.category,
        modified: new Date().toISOString(),
        userId: user.id,
      },
    ],
  });

  const response: CustomMoodLight = {
    ...data[0],
    category: data[0].category,
    modified: new Date(data[0].modified).getTime(),
  };

  return response;
};
