import {useIsFocused} from '@react-navigation/native';
import colorsys from 'colorsys';
import React from 'react';
import {Animated, Image, PanResponder, View} from 'react-native';

import {colorWheel, picker} from '../assets/images';
import {bleReadDeviceSettings} from '../lib/redux/thunk';
import {useAppDispatch} from '../lib/redux/useAppDispatch';
import styled from '../lib/utils/styled';
import {colors} from '../styles';

export type ColorPickerProps = {
  diameter: number;
  initialColor: string;
  onValueChange: (color: string) => void;
};

export const ColorPicker = (props: ColorPickerProps) => {
  const PAN_DIAMETER = 35;
  const dispatch = useAppDispatch();
  const [pan] = React.useState(new Animated.ValueXY());
  const isFocused = useIsFocused();
  const {diameter, initialColor, onValueChange} = props;
  const radius = diameter / 2;
  const [color, setColor] = React.useState(initialColor || colors.white);
  const [totalOffset, setTotalOffset] = React.useState({x: 0, y: 0});
  const pickerRef = React.useRef<Image>();

  React.useEffect(() => {
    const {x, y} = getCoordinates(initialColor);

    pan.setOffset({
      x,
      y,
    });
  }, [totalOffset]);

  React.useEffect(() => {
    if (!isFocused) dispatch(bleReadDeviceSettings());
  }, [isFocused]);

  const updateColor = (gestureStateX: number, gestureStateY: number) => {
    const polar = calcPolar(gestureStateX, gestureStateY);
    const {deg} = polar;
    let {rad} = polar;
    if (rad > 0.97) {
      rad = 1;
    }
    const hsv = {h: deg, s: 100 * rad, v: 100};
    const currentColor = colorsys.hsv2Hex(hsv);
    setColor(currentColor);
    onValueChange(currentColor);
  };

  const calcPolar = (gestureStateX: number, gestureStateY: number) => {
    const x = gestureStateX - totalOffset.x - radius;
    const y = -(gestureStateY - totalOffset.y - radius + PAN_DIAMETER / 2);
    const left = y * y + x * x;
    let deg = Math.atan2(x, y) * (-180 / Math.PI);
    if (deg < 0) deg = 360 + deg;
    const rad = Math.sqrt(left) / radius;
    return {deg, rad};
  };

  const isOutBounds = (gestureStateX: number, gestureStateY: number) => {
    const x = gestureStateX - totalOffset.x - radius;
    const y = gestureStateY - totalOffset.y - radius + PAN_DIAMETER / 2;
    const left = y * y + x * x;
    const right = radius * radius;

    return left / right > 0.97;
  };

  const getLocation = (gestureStateX: number, gestureStateY: number) => {
    const x = gestureStateX - totalOffset.x - PAN_DIAMETER / 2;
    const y = gestureStateY - totalOffset.y;
    return {x, y};
  };

  const getCoordinates = (startColor: string) => {
    const {h, s} = colorsys.hex2Hsv(startColor);
    let deg = 0;
    if (h - 90 < 0) {
      deg = h + 270;
    } else {
      deg = h - 90;
    }
    const r = (s / 100) * radius; // was normalized
    const rad = (Math.PI * deg) / 180;
    const x = -(r * Math.cos(rad));
    const y = r * Math.sin(rad);
    const textX = x + radius - PAN_DIAMETER / 2;
    const textY = y + radius - PAN_DIAMETER / 2;

    return {x: textX, y: textY};
  };

  const handleLayoutChange = () => {
    if (pickerRef?.current && pickerRef.current.measure) {
      pickerRef.current.measure(
        (
          _fx: number,
          _fy: number,
          _width: number,
          _height: number,
          px: number,
          py: number,
        ) => {
          setTotalOffset({x: px, y: py});
        },
      );
    }
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponderCapture: () => true,
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onMoveShouldSetPanResponder: () => true,

    onPanResponderGrant: (_, gestureState) => {
      if (!isOutBounds(gestureState.x0, gestureState.y0)) {
        const {x, y} = getLocation(gestureState.x0, gestureState.y0);
        updateColor(gestureState.x0, gestureState.y0);
        if (x > 0 && y > 0) {
          pan.setOffset({
            y,
            x,
          });
        }
      }
    },

    onPanResponderMove: (_, gestureState) => {
      if (!isOutBounds(gestureState.moveX, gestureState.moveY)) {
        const {x, y} = getLocation(gestureState.moveX, gestureState.moveY);
        updateColor(gestureState.moveX, gestureState.moveY);
        if (x > 0 && y > 0) {
          pan.setOffset({
            y,
            x,
          });
        }
      }
    },

    onPanResponderRelease: (_, gestureState) => {
      if (!isOutBounds(gestureState.moveX, gestureState.moveY)) {
        updateColor(gestureState.moveX, gestureState.moveY);
      }
    },
  });

  const panHandlers = (panResponder && panResponder.panHandlers) || {};

  return (
    <CircleContainer
      {...panHandlers}
      style={{
        height: diameter,
        width: diameter,
      }}>
      <Image
        source={colorWheel}
        style={{
          height: diameter,
          width: diameter,
          position: 'absolute',
          transform: [{rotate: '-90deg'}],
        }}
        ref={ref => {
          if (ref) {
            pickerRef.current = ref;
          }
        }}
        onLayout={() => handleLayoutChange()}
      />
      <Animated.View
        style={[
          {
            width: PAN_DIAMETER,
            height: PAN_DIAMETER,
            borderRadius: PAN_DIAMETER / 2,
            backgroundColor: color,
            borderColor: '#f0f1f5',
            borderWidth: 0,
            position: 'absolute',
            left: pan.x,
            top: pan.y,
          },
        ]}>
        <Image
          source={picker}
          style={{width: PAN_DIAMETER, height: PAN_DIAMETER}}
        />
      </Animated.View>
    </CircleContainer>
  );
};

const CircleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
});
