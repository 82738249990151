import {useFocusEffect} from '@react-navigation/native';
import React from 'react';

import {
  bottomSheetBackPress,
  bottomSheetEmitter,
} from '../../components/BottomSheet';
import {ModalProps, modalBackPress, modalEmitter} from '../../components/Modal';
import {BackHandler} from '../../util/BackHandler';
import {EmitterEvent} from '../types';

type Handler = Parameters<BackHandler['addEventListener']>[1];

/*
 * Hook that replaces the Android back button press with a callback function.
 * Ensure that onBackPress is either wrapped in React.useCallback() or references a
 * function outside of the component/hook to avoid unnecessary adding/removing
 * of events.
 */
export function useBackPress(onBackPress: Handler) {
  const backPress = React.useRef<Handler>();
  const [backPressQueue, setBackPressQueue] = React.useState<Handler[]>([]);

  useUpdateBackPressQueue(setBackPressQueue);

  useFocusEffect(
    React.useCallback(() => {
      if (backPress.current) {
        BackHandler.removeEventListener('hardwareBackPress', backPress.current);
      }

      backPress.current =
        backPressQueue.length > 0 ? backPressQueue[0] : onBackPress;

      backPress.current &&
        BackHandler.addEventListener('hardwareBackPress', backPress.current);

      return () =>
        backPress.current &&
        BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [backPressQueue, onBackPress]),
  );
}

const useUpdateBackPressQueue = (
  setBackPressQueue: React.Dispatch<React.SetStateAction<Handler[]>>,
) => {
  React.useEffect(() => {
    const modalEventHandler = (event: ModalProps | EmitterEvent) => {
      setBackPressQueue(backPressQueue => {
        switch (event) {
          case EmitterEvent.CLOSE: {
            return backPressQueue.slice(1);
          }
          case EmitterEvent.CLOSE_ALL: {
            return [];
          }
          default: {
            return [...backPressQueue, modalBackPress];
          }
        }
      });
    };

    const bottomSheetEventHandler = (event: JSX.Element | EmitterEvent) => {
      setBackPressQueue(backPressQueue => {
        switch (event) {
          case EmitterEvent.CLOSE: {
            return backPressQueue.slice(1);
          }
          default: {
            return [...backPressQueue, bottomSheetBackPress];
          }
        }
      });
    };

    modalEmitter.subscribe(modalEventHandler);
    bottomSheetEmitter.subscribe(bottomSheetEventHandler);

    return () => {
      modalEmitter.unsubscribe(modalEventHandler);
      bottomSheetEmitter.unsubscribe(bottomSheetEventHandler);
    };
  }, [setBackPressQueue]);
};
