import {HeaderBackButtonProps} from '@react-navigation/elements';
import {useNavigation} from '@react-navigation/native';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Pressable, ScrollView, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {ulid} from 'ulid';

import {Alert} from '../../components/Alert';
import {AppText} from '../../components/AppText';
import {AutoAdjustScreen} from '../../components/AutoAdjustScreen';
import {BackButton} from '../../components/BackButton';
import {BottomSheetScrollable} from '../../components/BottomSheetScrollable';
import {MoodLightCircle} from '../../components/MoodLightCircle';
import {MoodLightList} from '../../components/MoodLightList';
import {useSpinner} from '../../components/Spinner';
import {
  TextSwitch,
  TextSwitchHandlePosition,
} from '../../components/TextSwitch';
import {Constants} from '../../constants';
import {ErrorMessages, Messages, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {CheckIcon, ChevronRightIcon} from '../../icons';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useChamberType} from '../../lib/hooks/useChamberType';
import {useDisconnectGuard} from '../../lib/hooks/useDisconnectGuard';
import {useGetUpdatedPeakMoodLights} from '../../lib/hooks/useGetUpdatedPeakMoodLights';
import {useLantern} from '../../lib/hooks/useLantern';
import {useMoodLight} from '../../lib/hooks/useMoodLight';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useTheme} from '../../lib/hooks/useTheme';
import {useThrottle} from '../../lib/hooks/useThrottle';
import {useUpdateDabbingReduxValues} from '../../lib/hooks/useUpdateDabbingReduxValues';
import {useUpdateHeatProfiles} from '../../lib/hooks/useUpdateHeatProfiles';
import {appSettingsSelector} from '../../lib/redux/slices/appSettingsSlice';
import {connectedPeakSelector} from '../../lib/redux/slices/bleSlice';
import {
  getMoodLightSelector,
  setPeakMoodLights,
} from '../../lib/redux/slices/moodLightSlice';
import {
  activeProfilesSelector,
  addArchiveProfileFront,
  archiveProfilesSelector,
  updateActiveProfile,
  updateArchiveProfile,
} from '../../lib/redux/slices/profilesSlice';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import {
  MoodLight,
  Profile,
  Vapor,
  isTHeatProfile,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {getVaporValue} from '../../lib/utils';
import {
  changeHexColorValues,
  convertHexToHsl,
  convertHslToHex,
} from '../../lib/utils/colors';
import {useTemperature} from '../../lib/utils/convertTemperature';
import {
  getActiveProfileMoodLights,
  profilesMatch,
  setProfileToColor,
  setProfileToMoodLight,
} from '../../lib/utils/profileFunctions';
import styled from '../../lib/utils/styled';
import {Temperature} from '../../lib/utils/temperature';
import type {HeatProfileEditNavigator} from '../../navigators/RootStackNavigator';
import {toHeatProfileList, toHome} from '../../navigators/util';
import {colors} from '../../styles';
import {ColorSlider} from './ColorSlider';
import {DurationSlider} from './DurationSlider';
import {
  HeatProfileNameTextInput,
  isValid as isHeatProfileNameValid,
} from './HeatProfileNameTextInput';
import {TemperatureSlider} from './TemperatureSlider';
import {VaporControlSlider} from './VaporControlSlider';
import {VaporControlUpsellText} from './VaporControlUpsellText';

const {
  IS_WEB,
  SCREEN_HEIGHT: {GALAXY_S8},
  STD_HSL_VALUES: {saturation: stdS, lightness: stdL},
} = Constants;

const moodLightCircleSize = 16;
const inputTextMultiplier = 18;

export interface Props {
  profileId?: string;
  headerTitle?: string;
  showHeader?: boolean;
  openMoodLight?: boolean;
  onSave: () => void;
}

type Navigation = HeatProfileEditNavigator<
  typeof Screens.HeatProfileEdit | typeof Screens.HeatProfileCreate
>['navigation'];

export const HeatProfileForm = ({
  profileId,
  openMoodLight,
  headerTitle,
  showHeader,
  onSave,
}: Props) => {
  useDisconnectGuard();
  const theme = useTheme();

  const navigation = useNavigation<Navigation>();

  const {top} = useSafeArea();
  const dispatch = useAppDispatch();

  const {width, height} = Dimensions.get('screen');
  const {height: winHeight} = Dimensions.get('window');

  const isSmall = (IS_WEB ? window.innerHeight : winHeight) <= GALAXY_S8;

  const nameMaxWidth = width * 0.8;

  const user = useSelector(userSelector);
  const {tempPreference} = useSelector(appSettingsSelector);
  const peak = useSelector(connectedPeakSelector);
  const archives: Profile[] = useSelector(archiveProfilesSelector);
  const actives: Profile[] = useSelector(activeProfilesSelector);
  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();
  const updateDabbingReduxValues = useUpdateDabbingReduxValues();

  const {accessMoodLight, canUseMoodLighting, hasSeenEpilepsyWarning} =
    useMoodLight();

  const canToggleMoodLights =
    canUseMoodLighting && !!user && hasSeenEpilepsyWarning;

  const currentProfile = React.useMemo(
    () =>
      profileId
        ? actives.find(p => p.id === profileId) ??
          archives.find(p => p.id === profileId)
        : undefined,
    [actives, archives, profileId],
  );

  const isActive = React.useMemo(
    () => !!profileId && actives.some(p => p.id === profileId),
    [profileId, actives],
  );

  const isSaving = React.useRef(false);

  const [bottomSheetOpen, setBottomSheetOpen] = React.useState<boolean>(false);

  const minTemp = useTemperature(
    Constants.TEMPERATURE_MIN_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const maxTemp = useTemperature(
    Constants.TEMPERATURE_MAX_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const [{error, value, loading: updateLoading}, updateApiArchives] =
    useUpdateHeatProfiles();

  const profileParam: Profile = React.useMemo(() => {
    const profile: Profile = currentProfile ?? {
      ...(canUseMoodLighting
        ? {
            version: ProfileVersion.T,
            isMoodLight: false,
            vaporSetting: 0,
          }
        : {version: ProfileVersion.PreT}),
      modified: new Date().getTime(),
      id: ulid(),
      name: 'NEW PROFILE',
      color: colors.defaultColor,
      temperature: minTemp,
      units: tempPreference || TemperatureUnit.Fahrenheit,
      duration: Constants.DURATION_MIN,
      order: 0,
      userId: user?.id,
    };

    return {
      ...profile,
      temperature: Temperature.convert(
        profile?.temperature ?? Constants.TEMPERATURE_MIN_FAHRENHEIT,
        {
          from: profile?.units ?? TemperatureUnit.Fahrenheit,
          to: tempPreference,
        },
      ),
    };
  }, [canUseMoodLighting, currentProfile, minTemp, tempPreference, user]);

  const [profile, setProfile] = React.useState<Profile | undefined>(
    profileParam,
  ); // keep in preference units until saved

  const profileColor = React.useMemo(
    () =>
      !profile || isTHeatProfileMoodLight(profile)
        ? colors.defaultColor
        : profile.color,
    [profile],
  );

  const [moodLight, setMoodLight] = React.useState<MoodLight | undefined>(
    !!profile && isTHeatProfileMoodLight(profile)
      ? getMoodLight(profile.moodLightId)
      : undefined,
  );

  const isMoodLight = !!profile && isTHeatProfileMoodLight(profile);

  const getInitialHandlePosition = React.useCallback(() => {
    return moodLight
      ? TextSwitchHandlePosition.RIGHT
      : TextSwitchHandlePosition.LEFT;
  }, [moodLight]);

  const [switchPosition, setSwitchPosition] = React.useState(
    getInitialHandlePosition(),
  );

  const inputWidth = Math.min(
    profile?.name ? (profile?.name?.length + 1) * inputTextMultiplier : 0,
    nameMaxWidth,
  );

  const hasNameError = !isHeatProfileNameValid(profile?.name);

  const {isVaporEnabled, isMeetAC, is3dChamber, isXLChamber} = useChamberType();

  const vapors = isXLChamber
    ? [Vapor.Standard, Vapor.High, Vapor.Max, Vapor.Xl]
    : [Vapor.Standard, Vapor.High, Vapor.Max];

  useSpinner({isVisible: updateLoading});

  useBackPress(
    React.useCallback(() => {
      if (bottomSheetOpen) {
        setBottomSheetOpen(false);
      } else {
        navigation.goBack();
      }
      return true;
    }, [bottomSheetOpen, navigation]),
  );

  React.useEffect(() => {
    if (!profile) {
      let profileToSet = profileParam;
      if (isTHeatProfile(profileParam)) {
        if (isTHeatProfileMoodLight(profileParam)) {
          const {moodLightId} = profileParam;
          const profileMoodLight = getMoodLight(moodLightId);
          setMoodLight(profileMoodLight);
          profileToSet = setProfileToMoodLight(profileParam, moodLightId);
        } else {
          setMoodLight(undefined);
          profileToSet = setProfileToColor(profileParam, profileParam.color);
        }
      }
      setProfile(profileToSet);
    }
  }, [getMoodLight, profileParam]);

  React.useEffect(() => {
    if (openMoodLight) {
      setBottomSheetOpen(true);
      navigation.setParams({openMoodLight: undefined});
    }
  }, [openMoodLight, navigation]);

  const titleStyle = React.useMemo(
    () => ({
      ...(theme.heatProfileEditScreenTheme.navMenuTitleStyle ??
        theme.navMenuTitleStyle),
      color: colors.white,
    }),
    [
      theme.heatProfileEditScreenTheme.navMenuTitleStyle,
      theme.navMenuTitleStyle,
    ],
  );

  const setTemperature = React.useCallback(
    (temperature: number) => {
      setProfile(profile => (profile ? {...profile, temperature} : undefined));
    },
    [setProfile],
  );

  const setDuration = React.useCallback(
    (duration: number) =>
      setProfile(profile => (profile ? {...profile, duration} : undefined)),
    [setProfile],
  );

  const setVaporMood = React.useCallback(
    (vaporSetting: number) =>
      setProfile(profile => (profile ? {...profile, vaporSetting} : undefined)),
    [setProfile],
  );

  const setColor = React.useCallback(
    (color: number) => {
      setProfile(profile =>
        profile
          ? {
              ...profile,
              color: convertHslToHex(color, stdS, stdL, true),
            }
          : undefined,
      );
    },
    [setProfile],
  );

  const throttledSetColor = useThrottle(setColor, 1000);

  React.useEffect(() => {
    if (bottomSheetOpen) {
      navigation.setParams({showHeader: false});
    } else if (!moodLight) {
      navigation.setParams({showHeader: true});
    } else if (moodLight && !bottomSheetOpen) {
      navigation.setParams({showHeader: true});
    }
  }, [bottomSheetOpen, moodLight, navigation]);

  React.useEffect(() => {
    navigation.setParams({
      headerTitle: currentProfile?.id ? 'EDIT' : undefined,
    });
  }, [currentProfile?.id]);

  const displayColors =
    isMoodLight && moodLight
      ? moodLight.colors
      : [changeHexColorValues(profileColor, stdS, stdL, true)];

  useLantern(
    switchPosition === TextSwitchHandlePosition.RIGHT
      ? moodLight
      : displayColors[0],
  );

  const navigateToHeatProfileList = () => {
    navigation.navigate(...toHeatProfileList.encode());

    return;
  };

  const saveHeatProfile = React.useCallback(() => {
    if (profile) {
      const temperature = Temperature.convert(profile.temperature, {
        from: tempPreference,
        to: profile.units,
      });

      let save: Profile = {
        ...profile,
        temperature,
        ...(profile.version === ProfileVersion.T && {
          modified: new Date().getTime(),
        }),
        ...(moodLight && switchPosition === TextSwitchHandlePosition.RIGHT
          ? {
              isMoodLight: true,
              moodLightId: moodLight.id,
              color: colors.defaultColor,
            }
          : {moodLightId: null, isMoodLight: false, color: profileColor}),
      };

      if (!isSaving.current && !hasNameError) {
        isSaving.current = true;
        if (isActive) {
          if (save.version === ProfileVersion.T) {
            const matchingArchive = archives.find(archive =>
              profilesMatch(archive, save),
            );
            if (matchingArchive && user) {
              // Keep matching active and archive profiles in sync
              const saveWithUserId = {
                ...save,
                userId: matchingArchive.userId ?? user.id,
              };
              const updatedArchive = {
                ...saveWithUserId,
                order: matchingArchive.order,
              };
              save = saveWithUserId;
              updateApiArchives([updatedArchive]);
              dispatch(updateArchiveProfile(updatedArchive));
            }
          }
          peak?.writeHeatProfiles(
            [save],
            getActiveProfileMoodLights([save], getMoodLight),
          );
          dispatch(
            setPeakMoodLights(
              getUpdatedPeakMoodLights({
                actives: actives.map(active =>
                  active.order === save.order ? save : active,
                ),
              }),
            ),
          );
          dispatch(updateActiveProfile(save));
          updateDabbingReduxValues(save);

          if (navigation.canGoBack()) navigation.goBack();
          else navigation.replace(...toHome);
        } else if (user) {
          const moodlightsData =
            switchPosition === TextSwitchHandlePosition.RIGHT
              ? moodLight
              : undefined;

          let moodLightWithId = undefined;
          if (moodlightsData) {
            moodLightWithId = {...moodlightsData, userId: user.id};
          }

          updateApiArchives([
            {...save, userId: user.id, moodLight: moodLightWithId},
          ]);
        }
      }

      onSave();
    }
  }, [
    peak,
    actives,
    archives,
    getMoodLight,
    getUpdatedPeakMoodLights,
    hasNameError,
    onSave,
    isActive,
    moodLight,
    profile,
    profileColor,
    tempPreference,
    updateApiArchives,
    updateDabbingReduxValues,
    user,
    switchPosition,
  ]);

  React.useEffect(() => {
    const options = showHeader
      ? {
          headerLeft: ({onPress}: HeaderBackButtonProps) => (
            <BackButton {...{onPress}} color={titleStyle?.color} />
          ),
          headerRight: () => (
            <CheckIcon
              size={28}
              onPress={saveHeatProfile}
              color={titleStyle?.color}
            />
          ),
          headerTitle: () => (
            <HeaderTitle style={titleStyle}>{headerTitle}</HeaderTitle>
          ),
          headerShown: true,
        }
      : {headerShown: false};

    navigation.setOptions(options);
  }, [saveHeatProfile, titleStyle, navigation, headerTitle, showHeader]);

  React.useEffect(() => {
    if (profile) {
      const temperature = Temperature.convert(profile?.temperature ?? 0, {
        from: tempPreference,
        to: profile?.units ?? TemperatureUnit.Celsius,
      });

      if (!!value && !isActive) {
        const save: Profile = {
          ...profile,
          temperature,
          ...(moodLight && switchPosition === TextSwitchHandlePosition.RIGHT
            ? {
                isMoodLight: true,
                moodLightId: moodLight.id,
              }
            : {isMoodLight: false, color: profileColor}),
        };
        if (!currentProfile?.id) {
          dispatch(addArchiveProfileFront(save));
          navigateToHeatProfileList();
        } else {
          navigation.goBack();
        }
        dispatch(updateArchiveProfile(save));
      } else if (error) {
        Alert.alert('Error', ErrorMessages.NETWORK_ERROR);
        if (!currentProfile?.id) {
          navigateToHeatProfileList();

          return;
        }

        navigation.goBack();
      }
    }
  }, [currentProfile?.id, value, error, switchPosition]);

  const onDisabledPress = React.useCallback(() => {
    accessMoodLight({profileId: profile?.id});
  }, [accessMoodLight, profile]);

  React.useEffect(() => {
    if (canUseMoodLighting) {
      setProfile(v =>
        !!v && isTHeatProfile(v)
          ? switchPosition === TextSwitchHandlePosition.LEFT && !!moodLight
            ? setProfileToColor(v, displayColors[0])
            : v
          : undefined,
      );
    }
  }, [canUseMoodLighting, switchPosition]);

  // make sure initial position match TextSwitch
  React.useEffect(() => {
    setSwitchPosition(getInitialHandlePosition());
  }, [getInitialHandlePosition]);

  return (
    <MainContentContainer>
      <ScrollableContainer
        contentContainerStyle={{
          height: Constants.IS_WEB ? 0 : undefined,
        }}>
        {!!profile && (
          <ContentContainer
            style={{
              width: '100%',
              alignItems: 'center',
            }}>
            <HeaderContainer style={{marginTop: IS_WEB ? top + 20 : 10}}>
              <HeatProfileNameTextInput
                value={profile?.name ?? ''}
                onChangeText={text => {
                  setProfile(prof =>
                    prof
                      ? {
                          ...prof,
                          name: text?.toUpperCase() || '',
                        }
                      : undefined,
                  );
                }}
                style={{
                  width: inputWidth,
                }}
              />
            </HeaderContainer>

            <MainSliderContainer>
              <TemperatureSlider
                value={profile?.temperature ?? 0}
                min={minTemp}
                max={maxTemp}
                style={{paddingBottom: isSmall ? 24 : 40}}
                onEnd={setTemperature}
              />

              <DurationSlider
                value={profile?.duration}
                max={Constants.DURATION_MAX}
                min={Constants.DURATION_MIN}
                style={{paddingBottom: isSmall ? 24 : 40}}
                onEnd={setDuration}
              />

              <VaporControlSlider
                value={getVaporValue(profile) || 0}
                onEnd={setVaporMood}
                disabled={!isVaporEnabled}
                {...{vapors}}
              />

              <VaporControlUpsellText
                {...{isMeetAC, is3dChamber, isVaporEnabled}}
                style={{
                  marginTop: isSmall ? 5 : 10,
                  marginBottom: isSmall ? 8 : 16,
                }}
              />

              <TextSwitch
                initialHandlePosition={getInitialHandlePosition()}
                leftText={Strings.COLOR}
                rightText={Strings.MOOD}
                disabledPosition={
                  !canToggleMoodLights
                    ? TextSwitchHandlePosition.RIGHT
                    : undefined
                }
                onDisabledPress={onDisabledPress}
                onToggle={position => setSwitchPosition(position)}
              />

              <TextSwitchBottomContainer>
                {switchPosition === TextSwitchHandlePosition.LEFT ? (
                  <ColorSliderContainer>
                    <ColorSlider
                      value={displayColors}
                      onChange={v => throttledSetColor(convertHexToHsl(v[0]))}
                      onEnd={v => throttledSetColor(convertHexToHsl(v[0]))}
                    />
                  </ColorSliderContainer>
                ) : (
                  <MoodLightButtonContainer
                    onPress={() => {
                      if (!canToggleMoodLights) {
                        onDisabledPress();
                      } else {
                        setBottomSheetOpen(true);
                      }
                    }}>
                    <MoodLightButtonText>
                      {Messages.HEAT_PROFILE_EDIT_MOOD_LIGHT_BTN}
                    </MoodLightButtonText>

                    <MoodLightButtonRight>
                      {moodLight && (
                        <>
                          <GradientCircle style={{marginRight: 10}}>
                            <MoodLightCircle
                              size={moodLightCircleSize}
                              {...{theme, moodLight}}
                            />
                          </GradientCircle>

                          <MoodLightButtonRightText numberOfLines={1}>
                            {moodLight.name}
                          </MoodLightButtonRightText>
                        </>
                      )}

                      <ChevronRightIcon
                        color={colors.white}
                        size={32}
                        containerSize={36}
                      />
                    </MoodLightButtonRight>
                  </MoodLightButtonContainer>
                )}
              </TextSwitchBottomContainer>
            </MainSliderContainer>
          </ContentContainer>
        )}
      </ScrollableContainer>

      {bottomSheetOpen && (
        <BottomSheetScrollable
          addButtonAction={() => navigation.navigate(Screens.MoodLightCreate)}
          headerOnlyVisibleHeight={0}
          onOpenChange={isOpen => setBottomSheetOpen(isOpen)}
          onMinimizedPress={(enabled: boolean) => !enabled && onDisabledPress()}
          isEnabled={canToggleMoodLights}
          isOpen={bottomSheetOpen}
          innerComponent={() => (
            <MoodLightList
              setActiveMoodLightId={moodLightId =>
                moodLightId
                  ? setMoodLight(getMoodLight(moodLightId))
                  : setMoodLight(undefined)
              }
              currentActiveId={moodLight?.id}
              shouldShowPeakMoodLights={isActive}
            />
          )}
          titleHeader={
            bottomSheetOpen ? Strings.MOOD_LIGHTS : Strings.MOOD_LIGHTING
          }
          titleSubheader={moodLight?.name ?? ''}
          screenHeight={height}
        />
      )}
    </MainContentContainer>
  );
};

// Header
const HeaderContainer = styled(View)({
  maxWidth: '80%',
  height: 200,
  flexDirection: 'column',
  alignItems: 'center',
});

const MainContentContainer = styled(View)({
  width: '100%',
  flex: 1,
  backgroundColor: colors.black,
});

const ScrollableContainer = styled(ScrollView)({
  flex: 1,
});

const ContentContainer = styled(AutoAdjustScreen)({
  alignItems: 'center',
  marginBottom: 40,
});

// Footer
const MainSliderContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
});

const TextSwitchBottomContainer = styled(View)({
  height: 40, // Matches slider height
  marginTop: 24,
  marginBottom: 40,
});

const ColorSliderContainer = styled(View)({
  marginTop: 10,
});

const MoodLightButtonText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  color: colors.white,
});

const MoodLightButtonContainer = styled(Pressable)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 12,
  backgroundColor: colors.gray19,
  width: '100%',
  height: 56,
  paddingRight: 5,
  paddingLeft: 24,
});

const MoodLightButtonRight = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const MoodLightButtonRightText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  color: colors.white50,
  fontSize: 16,
});

const GradientCircle = styled(View)({
  height: moodLightCircleSize,
  width: moodLightCircleSize,
  borderRadius: 1000,
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
});

const HeaderTitle = styled(AppText)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  marginTop: 10,
});
