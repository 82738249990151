import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {moodlightApi} from '../api/apis';
import {AsyncWrapperOptions, withAsyncWrapper} from '../api/asyncWrapper';
import {upsertAccountMoodLight} from '../redux/slices/moodLightSlice';
import {userSelector} from '../redux/slices/userSlice';
import {CustomMoodLight} from '../types';

export function useSaveMoodLight({
  onSuccess,
  onError,
}: AsyncWrapperOptions<CustomMoodLight>) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return useAsyncFn(
    withAsyncWrapper(
      async (moodLight: CustomMoodLight) => {
        if (!user) return;

        const {data} = await moodlightApi.updateMoodLight({
          moodLightUpdateDto: [
            {
              ...moodLight,
              category: moodLight.category,
              modified: new Date().toISOString(),
              userId: user.id,
            },
          ],
        });

        const response: CustomMoodLight = {
          ...data[0],
          category: data[0].category,
          modified: new Date(data[0].modified).getTime(),
        };

        dispatch(upsertAccountMoodLight(moodLight));

        return response;
      },
      {onSuccess, onError},
    ),
    [user, onSuccess, onError],
  );
}
