import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ReorderIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M3 10H21" />
    <Path d="M3 15H21" />
    <Path d="M3 20H21" />
    <Path d="M3 5H21" />
  </Icon>
);
