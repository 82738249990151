import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const RefreshIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M12 20C16.4183 20 20 16.4183 20 12M4 12C4 7.58172 7.58172 4 12 4" />
    <Path d="M11.8787 1.87868L14 4L11.8787 6.12132" />
    <Path d="M12.1213 22.1213L10 20L12.1213 17.8787" />
  </Icon>
);
