import React from 'react';
import {View, ViewProps} from 'react-native';
import {interpolate} from 'react-native-reanimated';

import {Strings} from '../../constants/Strings';
import {LockIcon} from '../../icons';
import {Vapor} from '../../lib/types';
import styled from '../../lib/utils/styled';
import {colors} from '../../styles';
import {HorizontalSlider} from '../components/HorizontalSlider';
import {useSyncedSharedValue} from '../components/Slider';
import {
  SliderContainer,
  SliderTick,
  SliderValue,
  SliderValues,
} from '../components/SliderContainer';

interface Props extends ViewProps {
  value: Vapor;
  vapors: Vapor[];
  disabled?: boolean;
  onEnd(value: number): void;
}

// <0.25 snaps to 0
// <0.75 snaps to 0.5
// <1.25 snaps to 1
// <1.75 snaps to 1.5
const snapToVapor = (value: number) => Math.round(value * 2) / 2;

const fromValue = (value: number, min: number, max: number) =>
  snapToVapor(interpolate(value, [0, 1], [min, max]));
const toValue = (value: number, min: number, max: number) =>
  interpolate(snapToVapor(value), [min, max], [0, 1]);

export const VaporControlSlider = ({
  value: initialValue,
  vapors,
  style,
  disabled,
  onEnd,
}: Props) => {
  const {min, max} = React.useMemo(
    () => ({min: vapors[0], max: vapors[vapors.length - 1]}),
    [vapors],
  );

  const [endValue, setEndValue] = React.useState(() =>
    toValue(initialValue, min, max),
  );

  const value = useSyncedSharedValue(
    initialValue,
    value => toValue(value, min, max),
    [initialValue, min, max],
  );

  return (
    <SliderContainer {...{style}}>
      <SliderValues>
        <Title>
          {!!disabled && (
            <StyledLockIcon
              size={18}
              color={colors.white50}
              containerSize={18}
            />
          )}

          <SliderValue highlighted={!disabled}>
            {Strings.SLIDER.VAPOR}
          </SliderValue>
        </Title>

        <SliderValue highlighted={!disabled}>
          {Vapor[fromValue(endValue, min, max)].toUpperCase()}
        </SliderValue>
      </SliderValues>

      <View>
        <HorizontalSlider
          {...{value, disabled}}
          onChange={value => setEndValue(value)}
          onEnd={v => {
            value.value = toValue(fromValue(v, min, max), min, max);
            onEnd?.(fromValue(v, min, max));
          }}
        />

        <SliderValues>
          {vapors.map((vapor, index, array) => (
            <View key={vapor}>
              {index > 0 && index < array.length - 1 && <SliderTick />}
            </View>
          ))}
        </SliderValues>
      </View>

      <SliderValues>
        {vapors.map(vapor => (
          <SliderValue key={vapor}>{vapor}</SliderValue>
        ))}
      </SliderValues>
    </SliderContainer>
  );
};

const Title = styled(View)({
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});

const StyledLockIcon = styled(LockIcon)({
  padding: 0,
  marginLeft: -2,
  alignItems: 'center',
});
