import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {CompositeScreenProps} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';

import {Strings} from '../constants/Strings';
import {Navigators, Screens} from '../constants/navigation';
import {userSelector} from '../lib/redux/slices/userSlice';
import {ReferralWelcomeScreen} from '../screens/ReferralProgram/ReferralWelcomeScreen';
import {colors} from '../styles';
import type {ReferralNavigatorScreenProps} from './HomeDrawerNavigator';
import {
  ReferralProgramTab,
  type ReferralTabParamList,
} from './ReferralProgramTabNavigator';
import {defaultNavigationOptions} from './common';

export type ReferralStackParamList = {
  [Navigators.ReferralProgramTab]: undefined;
  [Screens.ReferralWelcome]: undefined;
};

export type ReferralTabNavigatorScreenProps<
  T extends keyof ReferralTabParamList,
> = CompositeScreenProps<
  MaterialTopTabScreenProps<ReferralTabParamList, T>,
  ReferralNavigatorScreenProps<keyof ReferralStackParamList>
>;

const ReferralProgramStackNavigator =
  createStackNavigator<ReferralStackParamList>();

export const ReferralProgramStack = () => {
  const isLoggedIn = !!useSelector(userSelector);

  return (
    <ReferralProgramStackNavigator.Navigator
      initialRouteName={
        isLoggedIn ? Navigators.ReferralProgramTab : Screens.ReferralWelcome
      }
      screenOptions={() => ({
        ...defaultNavigationOptions,
        headerTransparent: false,
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.black,
          borderWidth: 0,
        },
        title: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND,
        headerTitleStyle: styles.headerTitle,
        cardStyle: styles.card,
        headerLeft: () => null,
      })}>
      <ReferralProgramStackNavigator.Screen
        name={Navigators.ReferralProgramTab}
        component={ReferralProgramTab}
      />

      <ReferralProgramStackNavigator.Screen
        name={Screens.ReferralWelcome}
        component={ReferralWelcomeScreen}
      />
    </ReferralProgramStackNavigator.Navigator>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    fontWeight: '700',
    color: colors.white,
    textTransform: 'uppercase',
  },
  card: {
    backgroundColor: colors.black,
  },
});
