import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const UserIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" />
    <Path d="M5 17.6233C5 16.6624 5.63696 15.818 6.56084 15.554V15.554C10.1158 14.5383 13.8842 14.5383 17.4392 15.554V15.554C18.363 15.818 19 16.6624 19 17.6233V20H5V17.6233Z" />
  </Icon>
);
