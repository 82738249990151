import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {heatprofileApi} from '../api/apis';
import {AsyncWrapperOptions, withAsyncWrapper} from '../api/asyncWrapper';
import {updateArchiveProfile} from '../redux/slices/profilesSlice';
import {userSelector} from '../redux/slices/userSlice';
import {MoodLight} from '../types/MoodLight';
import {Profile} from '../types/Profile';
import {convertUpdateHeatProfileToDto} from '../utils/convertUpdateHeatProfileToDto';

export type UpdateHeatProfileDto = Profile & {
  userId: number;
  moodLight?: MoodLight & {userId: number};
};

export function useUpdateHeatProfileWithSuccessError({
  onSuccess,
  onError,
}: AsyncWrapperOptions<Profile | undefined>) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return useAsyncFn(
    withAsyncWrapper(
      async (profile: UpdateHeatProfileDto) => {
        if (!user) return;

        const heatProfileUpdateDto = [convertUpdateHeatProfileToDto(profile)];

        const data = await heatprofileApi
          .updateProfiles({
            heatProfileUpdateDto,
          })
          .then(({data}) => data[0] as unknown as Profile);

        dispatch(updateArchiveProfile(data));

        return data;
      },
      {onSuccess, onError},
    ),
    [user],
  );
}
