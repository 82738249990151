import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const BluetoothIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M6 6.5L17 17.5L11.5 23V1L17 6.5L6 17.5" />
  </Icon>
);
