import {useIsFocused} from '@react-navigation/native';
import React from 'react';

import {useSpinner} from '../../components/Spinner';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';

interface ScreenProps extends RootStackScreenProps<typeof Screens.Loading> {}

export const LoadingScreen: React.FC<ScreenProps> = () => {
  const isFocused = useIsFocused();

  useSpinner({
    isVisible: isFocused,
    color: colors.white,
    text: Strings.LOADING_DATA,
  });

  return null;
};
