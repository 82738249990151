import React from 'react';
import {
  Animated,
  LayoutChangeEvent,
  LayoutRectangle,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import {skeletonLoading} from '../../../../assets/images';
import {Image} from '../../../../components/ImageWithFilter';
import {Constants} from '../../../../constants';
import {useAnim} from '../../../../lib/hooks/useAnim';
import styled from '../../../../lib/utils/styled';

const AnimContainer = React.memo(function AnimContainer({
  layout,
}: {
  layout: LayoutRectangle;
}) {
  // eslint-disable-next-line no-unused-vars
  const [value, start, isDone, isAnimLoading, end] = useAnim();

  React.useEffect(() => {
    if (!isAnimLoading) {
      start(layout.width, 2500, true);
    }
  }, [layout, isAnimLoading]);

  React.useEffect(() => {
    if (isDone) end();
  }, [isDone]);

  return (
    <>
      <View
        style={{
          zIndex: 1,
          height: layout.height + 85,
          width: layout.width + 20,
          position: 'absolute',
          top: -40,
          left: 0,
          overflow: 'hidden',
        }}>
        <Animated.View
          style={{
            zIndex: -1,
            transform: [
              {
                translateX: value.interpolate({
                  inputRange: [0, layout.width / 2, layout.width],
                  outputRange: [
                    -(layout.width / 2) - 24,
                    Constants.IS_WEB
                      ? layout.width / 2 + 24
                      : layout.width - 24,
                    -(layout.width / 2) - 24,
                  ],
                }),
              },
            ],
            opacity: value.interpolate({
              inputRange: [
                0,
                layout.width / 8,
                layout.width / 4,
                layout.width / 2,
                layout.width / 2 + layout.width / 4,
                layout.width / 2 + layout.width / 4 + layout.width / 8,
                layout.width,
              ],
              outputRange: [1, 0.5, 1, 0.5, 1, 0.5, 1],
            }),
            height: '100%',
          }}>
          <Image source={skeletonLoading} style={{height: '100%'}} />
        </Animated.View>
      </View>
      <View style={{width: '100%', flex: 1}}>
        <Animated.View
          style={{
            width: '85%',
            backgroundColor: '#424242',
            borderRadius: 100,
            height: 10,
            marginTop: 6,
            opacity: value.interpolate({
              inputRange: [
                0,
                layout.width / 8,
                layout.width / 4,
                layout.width / 2,
                layout.width / 2 + layout.width / 4,
                layout.width / 2 + layout.width / 4 + layout.width / 8,
                layout.width,
              ],
              outputRange: [1, 0.5, 1, 0.5, 1, 0.5, 1],
            }),
          }}
        />
      </View>
    </>
  );
});

export const LoadingSkeleton = React.memo(function LoadingSkeleton({
  title,
  children,
  isLoading,
  style,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  isLoading: boolean;
  style?: StyleProp<ViewStyle>;
}) {
  const [layout, setLayout] = React.useState<LayoutRectangle>();

  const setMemoLayout = React.useCallback((e: LayoutChangeEvent) => {
    setLayout(e?.nativeEvent?.layout);
  }, []);

  return (
    <SectionContainer style={style} onLayout={setMemoLayout}>
      {title}
      {!layout ? null : isLoading ? (
        <AnimContainer layout={layout} />
      ) : (
        children
      )}
    </SectionContainer>
  );
});

const SectionContainer = styled(View)({
  position: 'relative',
  flexDirection: 'column',
  backgroundColor: '#1C1C1C',
  marginHorizontal: 24,
  borderRadius: 12,
  marginVertical: 14,
  height: 320,
  padding: 16,
  zIndex: 999,
  overflow: 'hidden',
});
