import {ProductName} from 'pikaparam';
import {Dimensions, Platform} from 'react-native';

import {
  ChamberType,
  DeviceModelType,
  LightingPattern,
  LumaAnimation,
  MiddlewareValue,
} from '../lib/types';

const navigator: Puffco.Navigator = window.navigator;

const client = navigator.userAgent;

export const Constants = {
  ACCESS_TOKEN_STORAGE_KEY: 'accessToken',
  REFRESH_TOKEN_STORAGE_KEY: 'refreshToken',

  COMPANY_ID: 0x0c03,

  FACTORY_HEX_COLORS: {
    low: '#0000ff',
    medium: '#6ee916',
    high: '#f80b00',
    peak: '#ffffff',
  },

  TEMPERATURE_MAX_FAHRENHEIT: 644, // 340C
  TEMPERATURE_MIN_FAHRENHEIT: 400,
  TEMPERATURE_WARNING_THRESHOLD: 100,
  DURATION_MAX: 60 * 2,
  DURATION_MIN: 60 * 0.25,

  BRIGHTNESS_MIN: 0,
  BRIGHTNESS_MAX: 255,
  BOOST_TEMPERATURE_MIN_FAHRENHEIT: 0,
  BOOST_TEMPERATURE_MAX_FAHRENHEIT: 36,
  BOOST_TEMPERATURE_MIN_CELSIUS: 0,
  BOOST_TEMPERATURE_MAX_CELSIUS: 20,
  BOOST_DURATION_MIN: 0,
  BOOST_DURATION_MAX: 60,
  DEFAULT_BOOST_TEMP_FAHRENHEIT: 10,
  DEFAULT_BOOST_TEMP_CELSIUS: 5,
  DEFAULT_BOOST_DURATION: 15,

  DABBING_ADDED_TEMP_FAHRENHEIT: 10,
  DABBING_ADDED_TEMP_CELSIUS: 5,
  DABBING_ADDED_TIME: 10,

  TEMP_HEAT_PROFILE_INDEX: -1,

  BATTERY_PRESERVATION_PCT: 80,

  IS_NATIVE_ANDROID: Platform.OS === 'android', // If using native Android app
  IS_WEB: Platform.OS === 'web',
  IS_ANDROID_WEB: client?.includes('Android'), // If viewing on web on an Android device
  IS_IOS_WEB: client?.includes('iPhone'), // If viewing on web on an iOS device
  IS_USING_DESKTOP_WEB:
    Platform.OS === 'web' &&
    !client?.includes('Android') &&
    !client?.includes('iPhone'),
  IS_USING_PATH_BROWSER: !!navigator.notifications, // If viewing on web and using path browser
  IS_USING_APP: Platform.OS === 'android' || !!navigator.notifications, // If on native Android or using Path browser

  LANTERN_TIME_SEC: 3600 * 2,
  MAX_HEAT_PROFILES: 25,
  MAX_TEMPO_COLORS_PER_MINUTE: 480,

  PEAK_NAME_MAX_LENGTH: 29,
  PEAK_NAME_PUP_MAX_LENGTH: 26,
  PROFILE_NAME_MAX_LENGTH: 31,

  CLEANING_FREQUENCY_MAX_LENGTH: 3,
  MAX_DAB_LIMIT: 500,
  DEFAULT_CLEANING_FREQUENCY_VALUES: [25, 50, 75, 100, 125],

  INDEX_NOT_FOUND: -1,

  ACTIVE_OPACITY: 1,
  INACTIVE_OPACITY: 0.4,

  CARD_HEIGHT: 110,
  CARD_WIDTH: 360,
  CARD_VERTICAL_MARGIN: 8,
  CARD_HORIZONTAL_MARGIN: 20,

  MOOD_LIGHT_CARD_HEIGHT: 87,

  SAFE_AREA_INSET_BOTTOM: 34,
  SAFE_AREA_INSET_LEFT: 0,
  SAFE_AREA_INSET_RIGHT: 0,
  SAFE_AREA_INSET_TOP: 44,

  TEXT_FIELD_MAX_LENGTH: 255,
  TEXT_PROMPT_MAX_LENGTH: 65535,

  VALID_PASSWORD_REGEX: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
  WEBSITE_URL: 'https://puffco.com',
  SHOP_URL_PATH:
    '?utm_source=puffco_app&utm_medium=shop_button&utm_campaign=puffco_app_shop',
  USER_MANUAL_URL:
    'https://cdn.shopify.com/s/files/1/0319/5549/files/Peak_Pro_Instructions.pdf?v=1600894495',
  GOOGLE_PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.puffco.android&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  PATH_INSTRUCTIONS_URL:
    'https://www.puffco.com/pages/path-browser-ios-instructions',
  CONTACT_URL: 'https://www.puffco.com/pages/contact',
  CLEANING_INSTRUCTIONS_URL:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/cleaning/PeakPro_Cleaning_Instructions.pdf',
  CLEANING_VIDEO_URL: 'https://www.youtube.com/watch?v=wIi3bht8em4',
  SHARE_PATH: 'share',
  PARTY_MODE: {
    PRE_T: [0xff, 0x20, 0x00, 0x01],
    T: [0xff, 0x20, 0x01, 0x01, 0x0, 0x0, 0x0, 0x0],
  },
  UNIT_CONVERSION: {
    SECONDS_TO_MILLISECONDS: 1000,
  },
  INITIAL_UUID: [
    // Initial UUID value when first upgrading to firmware T
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
    255,
  ],

  //BLE services and characteristics uuid

  CHARACTERISTIC_OFFSET: {
    lanternColorSetting: '048',
  },
  SCRATCHPAD_MAX_SIZE: 0x080,
  DEFAULT_TABLE_COLOR_BRIGHTNESS: 0xff,
  TABLE_COLOR_BYTES: {
    BRIGHTNESS: 0,
    SPEED: 1,
    LUMA_ANIMATION: 2,
    LED_API_TYPE_CODE: 3,
    PHASE_LOCK_NUMERATOR: 4,
    PHASE_LOCK_DENOMINATOR: 5,
    COLOR_AND_OFFSET_ARRAY_INDICES: 6,
    COLOR_ARRAY_LENGTH: 7,
  },
  LED_API_TYPE_CODE: {
    LANTERN_COLOR: 0x00,
    TABLE_COLOR: 0x01,
  },
  LED_API_2_ASYNC_LSB_CPS: 16375 / 65536, // Colors per second multiplier of speed byte when phase lock denominator = 0
  MAX_COLOR_ARRAY_LENGTH: 32,
  NVM_ACCESS_USER_ARRAY_STARTING_OFFSET: 0x08,
  NVM_ARRAY_INDICES: {
    HEAT_PROFILE_TEMP: 0,
    HEAT_PROFILE_0: 1,
    HEAT_PROFILE_1: 2,
    HEAT_PROFILE_2: 3,
    HEAT_PROFILE_3: 4,
    DEFAULT_LANTERN_ARRAY_INDEX: 5,
    ALTERNATIVE_LANTERN_ARRAY_INDEX: 6, // For smooth transition between mood lights
  },
  MOOD_LIGHT_WRITING_DELAY: {
    // In milliseconds
    THROTTLE: 500,
    DEBOUNCE: 1000,
  },
  MODE_COMMAND: {
    masterOff: 0,
    sleep: 1,
    idle: 2,
    tempSelectBegin: 3,
    tempSelectStop: 4,
    showBatteryLevel: 5,
    showVersion: 6,
    heatCycleStart: 7,
    heatCycleAbort: 8,
    heatCycleBoost: 9,
    factoryTest: 10,
    bonding: 11,
  },
  SLIDER_WHITE_VALUES: {
    whiteArea: 345 / 360,
    whiteHue: 0.999,
  },
  STD_HSL_VALUES: {
    saturation: 0.83,
    lightness: 0.5,
  },
  MINIMUM_FIRMWARE_VERSION: {
    TEMP_PROFILE: 'N',
    MOOD_LIGHTING: 'X',
    CHAMBER_TYPE: 'V',
    PREVIEW_COLOR: 'V',
    FIRMWARE_AUTHENTICATION: 'X',
    BATTERY_PRESERVATION: 'Y',
    VAPOR_SETTING: 'AC',
    LED3: 'AF',
    XL_CHAMBER: 'AG',
  },

  STOCK_MOOD_COLORS: [
    '#FF0807', // red
    '#07FF3D', // green
    '#073AFF', // blue
    '#FFFFFF', // white
    '#FFF907', // yellow
    '#FB07FF', // purple
  ],
  PAGE_HORIZONTAL_PADDING: 24,
  PROFILE_HALO_CONTAINER_HEIGHT: 350,
  PROFILE_TITLE_TOP_PADDING_BG_RATIO: 0.036,
  PROFILE_TEMP_FONT_BG_RATIO: 59 / 812,
  PROFILE_GLOW_HALO_BG_RATIO: 284 / 812,
  PROFILE_ANIMATED_HALO_BG_RATIO: 620 / 812,
  PROFILE_START_BTN_LOGO_BG_RATIO: 50 / 812,
  HORIZONTAL_GRADIENT_ANGLES: [90, 270],
  BOTTOM_SHEET_HEADER_HEIGHT: 70,
  IOS_CONIC_START_ANGLE: [0, 0, 0, 55, 50, 30, 30],
  DEFAULT_SNOOZE_VALUES: [10, 15, 20, 25],
  DIMENSIONS: {
    HEIGHT: Dimensions.get('screen').height,
    WIDTH: Dimensions.get('screen').width,
  },
  SCREEN_HEIGHT: {
    IPHONE_SE_FIRST_GEN: 568,
    IPHONE_8: 647,
    IPHONE_8_CHROME: 553,
    IPHONE_X: 812,
    IPHONE_X_PATH_NONFULLSCREEN: 632,
    IPHONE_12_PRO: 844,
    IPHONE_8_ZOOMED: 548,
    GALAXY_S8: 692,
    PX_734: 734,
    PX_785: 785,
    PX_895: 895,
    IPHONE_14_PRO_MAX: 932,
  },
  WINDOW_HEIGHT: {
    NOTE_20_ULTRA: 809,
  },
  DEVICE_HANDSHAKE: 'FUrZc0WilhUBteT2JlCc+A==',
  DEVICE_HANDSHAKE2: 'ZMZFYlbyb1scoSc3pd1x+w==',
  PEAK_IMAGE_ORIGINAL_DIMENSIONS: {
    // native resolution of 1x image before any scaling/transforms
    height: 480,
    width: 292,
  },
  MOOD_TYPE_AND_VALUE: {
    Static: 0,
    Disco: 1,
    Fade: 2,
    Spin: 3,
    Split: 4,
    Fill: 5,
    Tornado: 6,
    'Lava Lamp': 9,
    Confetti: 10,
    // 'Aurora Borealis': 11,
    // 'Warped Speed': 12
  },
  MOOD_TYPES: ['Disco', 'Fill', 'Fade', 'Spin', 'Split', 'Static'],
  ADVANCED_MOOD_TYPES: [
    // 'Tornado',
    'Lava Lamp',
    'Confetti',
    // 'Aurora Borealis',
    // 'Warped Speed',
  ],
  FACTORY_HEAT_PROFILE_DEFAULT_BYTE: [0x02, 0x03, 0x04, 0x05],
  MONTHS: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  WEEKS: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
} as const;

export enum DeviceMode {
  APP_LOADER = 'appLoader',
  RECONNECT = 'reconnectFromList',
  PREVIOUS = 'previousConnectedDevice',
}

export const ANDROID_NEW_BLE_PERMISSIONS_TARGET_SDK_VERSION = 31;
export const ANDROID_PUSH_NOTIFICATION_TARGET_SDK_VERSION = 33;

/* To define a default value, define a get function in
 * '../lib/utils/getDictionaryValue.ts'.
 */

export const CHAMBER_LOG_VALUES_DICTIONARY: Partial<
  Record<ChamberType, {minValue: number; maxValue: number}>
> = {
  [ChamberType.Performance]: {
    minValue: 0x0002,
    maxValue: 0x1979,
  },
  [ChamberType.XL]: {
    minValue: 0x197a,
    maxValue: 0x32f3,
  },
};

export const DEVICE_MODEL_TYPE: Partial<Record<ProductName, DeviceModelType>> =
  {
    OG: DeviceModelType.OG,
    Opal: DeviceModelType.LIGHTNING,
    Indiglow: DeviceModelType.LIGHTNING,
    Guardian: DeviceModelType.LIGHTNING,
    Onyx: DeviceModelType.LIGHTNING,
    Pearl: DeviceModelType.LIGHTNING,
    Desert: DeviceModelType.LIGHTNING,
    Flourish: DeviceModelType.LIGHTNING,
    Storm: DeviceModelType.LIGHTNING,
  } as const;

export const LIGHTING_PATTERN_DICTIONARY: Partial<
  Record<LumaAnimation, LightingPattern>
> = {
  [LumaAnimation.STEADY]: LightingPattern.STEADY,
  [LumaAnimation.BREATHING]: LightingPattern.BREATHING,
  [LumaAnimation.CIRCLING_SLOW]: LightingPattern.CIRCLING,
} as const;

export const LUMA_ANIMATION_DICTIONARY: Partial<
  Record<LightingPattern, LumaAnimation>
> = {
  [LightingPattern.STEADY]: LumaAnimation.STEADY,
  [LightingPattern.BREATHING]: LumaAnimation.BREATHING,
  [LightingPattern.CIRCLING]: LumaAnimation.CIRCLING_SLOW,
} as const;

export const MIDDLEWARE_VALUE_BYTE_SIZE: Partial<
  Record<MiddlewareValue, number>
> = {
  [MiddlewareValue.FLOAT]: 4,
  [MiddlewareValue.U_INT_8]: 1,
  [MiddlewareValue.U_INT_16]: 2,
  [MiddlewareValue.U_INT_32]: 4,
  [MiddlewareValue.INT_8]: 1,
  [MiddlewareValue.INT_32]: 4,
  [MiddlewareValue.ULID]: 16,
} as const;

/*
    Product Codes and Model Codes are mixed together.
    For explanation see: https://www.notion.so/halcyonmobile/Peak-Product-Model-Types-e42daaa64d54485da3fb8036e49c4a38
  */
export const PUFFCO_PRODUCTS: Record<string, ProductName> = {
  '0': 'OG',
  '1': 'Opal',
  '2': 'Indiglow',
  '21': 'OG',
  '22': 'Opal',
  '25': 'Indiglow',
  '26': 'Guardian',
  '4': 'Guardian',
  '4294967295': 'OG',
  '12': 'Pearl',
  '13': 'Onyx',
  '51': 'OG',
  '71': 'Onyx',
  '72': 'Pearl',
  '15': 'Desert',
  '74': 'Desert',
  '17': 'Flourish',
  '75': 'Flourish',
  '19': 'Storm',
  '78': 'Storm',
  '79': 'Onyx',
  '80': 'Pearl',
};

export const POSSIBLE_XL_CHAMBER_KITS = ['12', '13', '71', '72', '79', '80'];
export const XL_CHAMBER_KITS = ['19', '78'];
