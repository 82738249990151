import {Platform} from 'react-native';

import {Alert} from '../../components/Alert';
import {Alerts} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';

let forceUpdateVisible = false;

export const showForceUpdateAlert = (onPress: () => void) => {
  if (forceUpdateVisible) return;

  forceUpdateVisible = true;

  Alert.alert(
    Platform.OS === 'android'
      ? Alerts.NEEDS_UPDATE_TITLE
      : Alerts.NEEDS_REFRESH_TITLE,
    Platform.OS === 'android'
      ? Alerts.NEEDS_UPDATE_BODY
      : Alerts.NEEDS_REFRESH_BODY,
    [
      {
        text: Platform.OS === 'android' ? Strings.UPDATE : Strings.REFRESH,
        onPress: () => {
          onPress();
          if (Platform.OS === 'android') {
            // If user comes back in the app, the app will show again the alert
            forceUpdateVisible = false;
          }
        },
      },
    ],
    {
      cancelable: false,
      onDismiss: () => {
        if (Platform.OS === 'web') {
          // Backdrop pressed on web will reload page
          window?.location.reload();
        }
      },
    },
  );
};
