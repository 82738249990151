import React from 'react';

import {colors} from '../styles';

export type WebViewProps = {
  source: {
    uri?: string;
    html?: string;
  };
} & React.HTMLAttributes<HTMLDivElement>;

export const WebView = (props: WebViewProps) => {
  const {source, style, ...rest} = props;
  return (
    <div
      style={{
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
        flex: 1,
        display: 'flex',
        ...style,
      }}
      {...rest}>
      <iframe
        frameBorder={'0'}
        title={'iframe'}
        width={'100%'}
        style={{
          backgroundColor: colors.white,
          flex: 1,
        }}
        src={source?.uri}
        srcDoc={source?.html}
      />
    </div>
  );
};
