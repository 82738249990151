import React from 'react';
import {Dimensions, View, ViewProps} from 'react-native';

import {Constants} from '../constants';
import {useSafeArea} from '../lib/hooks/useSafeArea';

export const AutoAdjustScreen = ({
  children,
  style,
  ...rest
}: ViewProps & {children: React.ReactNode}) => {
  const {bottom} = useSafeArea();

  const height = Constants.IS_WEB
    ? window.innerHeight - bottom
    : Dimensions.get('window').height;

  return (
    <View style={[{height}, style]} {...rest}>
      {children}
    </View>
  );
};
