import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const ShareIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M16 6L12 2L8 6" />
    <Path d="M12 2V15" />
    <Path d="M8 10H6C4.89543 10 4 10.8954 4 12V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V12C20 10.8954 19.1046 10 18 10H16" />
  </Icon>
);
