import React from 'react';
import {Path} from 'react-native-svg';

import {Icon, IconProps} from './Icon';

export const PauseIcon: React.FC<IconProps> = props => (
  <Icon {...props}>
    <Path d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
  </Icon>
);
